import React, { useState, useEffect } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import '../css/main.css';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import MetricBox from '../components/MetricBoxComponent.js'
import LoadingSphere from '../components/LoadingComponent.js'
import { chartDescriptions} from '../js/echart_utils.js'


const PolkadotJSComponent = ( { onLoaded } ) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const [error, setError] = useState('');
  const [retries, setRetries] = useState(0);
  const maxRetries = 5;


  useEffect(() => {
    let provider;
    let api;

    const connectToChain = async () => {
      try {
        provider = new WsProvider('wss://rpc.polkadot.io');
        api = await ApiPromise.create({ provider });

        const chain = await api.rpc.system.chain();
        const totalIssuance = await api.query.balances.totalIssuance();
        const validators = await api.query.session.validators();

        const activeEra = await api.query.staking.activeEra();
        const activeEraIndex = activeEra.unwrap().index;
        const totalStakedValue = await api.query.staking.erasTotalStake(activeEraIndex);

        const parachains = await api.query.paras.parachains();
        const parachainCountValue = parachains.length;

        const auctionCounter = await api.query.auctions.auctionCounter();
        // const auctionInfo = await api.query.auctions.auctionInfo();
// 
        // const grandpaState = await api.query.grandpa.state();

        // const epochIndex = await api.query.babe.epochIndex();
        // const randomness = await api.query.babe.randomness();

        const validatorCount = await api.query.staking.validatorCount();
        // const minimumValidatorCount = await api.query.staking.minimumValidatorCount();

        const currentSlot = await api.query.babe.currentSlot();
        // const epochStart = await api.query.babe.epochStart();
        // const pendingEpochConfigChange = await api.query.babe.pendingEpochConfigChange();

        // const nextFeeMultiplier = await api.query.transactionPayment.nextFeeMultiplier();
        // const storageVersion = await api.query.transactionPayment.storageVersion();

        const counterForNominators = await api.query.staking.counterForNominators();
        const counterForValidators = await api.query.staking.counterForValidators();

        // const referendaCount = await api.query.referenda.referendumCount();
        // const referendumInfo = await api.query.referenda.referendumInfoFor(referendaCount - 1);

        const totalIssuanceValue = parseInt(totalIssuance.toString());
        // const totalStakedValueInt = parseInt(totalStakedValue.toString());
        // const stakedPercentageValue = (totalStakedValueInt / totalIssuanceValue) * 100;

        const lastRuntimeUpgrade = await api.query.system.lastRuntimeUpgrade();

        setMetrics({
          chain: chain.toString(),
          totalIssuance: totalIssuanceValue,
          activeValidators: validators.length,
          // totalStaked: totalStakedValueInt,
          // stakedPercentage: stakedPercentageValue.toFixed(2),
          parachainCount: parachainCountValue,
          auctionCounter: auctionCounter.toString(),
          // auctionInfo: auctionInfo.isSome ? `${auctionInfo.unwrap()[0].toString()}, ${auctionInfo.unwrap()[1].toString()}` : 'N/A',
          // grandpaState: grandpaState.toString(),
          // epochIndex: epochIndex.toString(),
          // randomness: randomness.toString(),
          validatorCount: validatorCount.toString(),
          // minimumValidatorCount: minimumValidatorCount.toString(),
          currentSlot: currentSlot.toString(),
          // epochStart: `${epochStart[0].toString()}, ${epochStart[1].toString()}`,
          // pendingEpochConfigChange: pendingEpochConfigChange.isSome ? pendingEpochConfigChange.unwrap().toString() : 'N/A',
          // nextFeeMultiplier: nextFeeMultiplier.toString(),
          // storageVersion: storageVersion.toString(),
          counterForNominators: counterForNominators.toString(),
          counterForValidators: counterForValidators.toString(),
          // referendaCount: referendaCount.toString(),
          // referendumInfo: referendumInfo.isSome ? referendumInfo.unwrap().toString() : 'N/A'
          lastRuntimeUpgrade: lastRuntimeUpgrade.isSome ? `${lastRuntimeUpgrade.unwrap().specVersion}` : 'N/A'
        });

        api.rpc.chain.subscribeNewHeads(async (lastHeader) => {
          const events = await api.query.system.events.at(lastHeader.hash);
          setMetrics(prevMetrics => ({
            ...prevMetrics,
            latestBlock: lastHeader.number.toNumber(),
            eventCount: events.length,
          }));
          setLoading(false);
          if (onLoaded) onLoaded();
        });

        provider.on('disconnected', () => {
          console.error('WebSocket disconnected');
          if (retries < maxRetries) {
            setTimeout(() => {
              setRetries(retries + 1);
            }, 2000);
          } else {
            setError('Failed to connect to the Polkadot network after multiple attempts.');
            setLoading(false);
          }
        });

        provider.on('error', (error) => {
          console.error('WebSocket error', error);
          setError('An error occurred with the WebSocket connection.');
          setLoading(false);
        });
      } catch (err) {
        console.error('Failed to load Polkadot API:', err);
        setError('Failed to connect to the Polkadot network.');
        setLoading(false);
      }
    };

    connectToChain();

    return () => {
      if (provider) {
        provider.disconnect();
      }
    };
  }, [retries]);

  if (loading) {
    return <div className="loading"><LoadingSphere/></div>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="polkadot-card">
      <h1 style={{ position: 'relative' }}>
        Relay Chain Metrics
        <button onClick={toggleExpansion} className="staking-toggle-button">
          {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </button>
      </h1>
      {isExpanded && (
        <div className="metrics-container">
          <div className="metrics-subsection">
            <h2>Chain Head</h2>
            <MetricBox title="Latest Block" value={metrics.latestBlock?.toLocaleString('en-US')} description={chartDescriptions.latestBlock} highlightOnChange={true} />
            <MetricBox title="Event Count" value={metrics.eventCount?.toLocaleString('en-US')} description={chartDescriptions.eventCount} highlightOnChange={true} />
          </div>
          <div className="metrics-subsection">
            <h2>Chain Info</h2>
            {/* <MetricBox title="Total Staked" value={Math.floor(metrics.totalStaked / 1e10).toLocaleString('en-US')} unit="DOT" highlightOnChange={true} /> */}
            <MetricBox title="Spec Version" value={metrics.lastRuntimeUpgrade?.toLocaleString('en-US')} description={chartDescriptions.specVersion} highlightOnChange={true} />
            <MetricBox title="Total Issuance" value={Math.floor(metrics.totalIssuance / 1e10).toLocaleString('en-US')} unit="DOT" description={chartDescriptions.totalIssuance} highlightOnChange={true} />
            {/* <MetricBox title="Staked %" value={metrics.stakedPercentage} unit="%" highlightOnChange={true} /> */}
          </div>
          <div className="metrics-subsection">
            <h2>Parachains</h2>
            <MetricBox title="# Parachains" value={metrics.parachainCount?.toLocaleString('en-US')} description={chartDescriptions.parachainCountValue}  />
            <MetricBox title="Auction Counter" value={metrics.auctionCounter} description={chartDescriptions.auctionCounter} />
          </div>
          <div className="metrics-subsection">
            <h2>Validators</h2>
            <MetricBox title="Active Validators" value={metrics.validatorCount} description={chartDescriptions.validatorCount}  />
            {/* <MetricBox title="Nominators" value={metrics.counterForNominators?.toLocaleString('en-US')}  description={chartDescriptions.counterForNominators} /> */}
            <MetricBox title="Nominators" value={Number(metrics.counterForNominators).toLocaleString('en-US')} description={chartDescriptions.counterForNominators} />
          </div>
          {/* <div className="metrics-subsection">
            <h2>Referenda</h2>
            <MetricBox title="Referenda Count" value={metrics.referendaCount} />
          </div> */}
        </div>
      )}
    </div>
  );
  
};

export default PolkadotJSComponent;
