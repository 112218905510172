import React from 'react';
import { Layout } from 'antd';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import polkadotLogo from './assets/polkadot_logo_light.png';
import relayChainImage from './assets/Polkadot_Token_PolkadotToken_Pink.png';
import systemChainsImage from './assets/chain_1.avif';
import parachainsImage from './assets/chain_2.avif';
import openGovImage from './assets/governance.avif';
import treasuryImage from './assets/treasury2.avif';
import coretimeImage from './assets/coretime.avif';
import blockSpaceImage from './assets/blocks_space.avif';
import stakingImage from './assets/staking.avif';
import heroImage from './assets/hero.avif';
import xcmImage from './assets/xcm.avif';


const { Content } = Layout;


const commonStyles = {
  sectionStyle: {
    padding: '10px 10px',
    borderBottom: '1px solid #e0e0e0',
    minHeight: '40vh',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  titleContainerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 10px',
  },
  textContainerStyle: {
    width: '55%',
    textAlign: 'justify',
  },
  imageContainerStyle: {
    width: '40%',
    marginLeft: '20px',
  },
  textStyle: {
    fontSize: '1.1em',
    lineHeight: '1.35',
  },
  listStyle: {
    fontSize: '1.1em',
    lineHeight: '1.35',
    marginLeft: '10px',
  },
};

const animationVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const AnimatedSection = ({ children, id, title }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={animationVariants}
      style={{ width: '100%' }}
    >
      <div style={commonStyles.titleContainerStyle}>
        <h2 id={id} style={{ margin: '2px 0', color: '#e6007a', fontSize: '2.5em' }}>
          {title}
        </h2>
      </div>
      {children}
    </motion.div>
  );
};

const WhatIsPolkadot = () => (
  <AnimatedSection id="what-is-polkadot" title="What is Polkadot?">
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 10px', width: '100%' }}>
  <div style={commonStyles.textContainerStyle}>
    <p style={commonStyles.textStyle}>
      Polkadot is the powerful, secure core of Web3, providing a shared foundation that unites some of the world’s most transformative apps and blockchains. Polkadot offers advanced modular architecture that allows devs to easily design and build their own specialized blockchain projects, pooled security that ensures the same high standard for secure block production across all connected chains and apps connected to it, and robust governance that ensures a transparent system where everyone has say in shaping the blockchain ecosystem for growth and sustainability.
    </p>
    <p style={commonStyles.textStyle}>
      Polkadot addresses the constraints of traditional blockchains by providing several key benefits:
    </p>
    <ul style={commonStyles.listStyle}>
      <li><strong>Scalability:</strong> Polkadot processes numerous transactions across multiple chains in parallel, preventing bottlenecks inherent in older networks that handled transactions sequentially.</li>
      <li><strong>Interoperability:</strong> Polkadot allows cross-blockchain transfers of any type of data or asset, enabling blockchain networks to interact with a variety of chains within the Polkadot network.</li>
      <li><strong>Shared Security:</strong> The Polkadot relay chain provides shared security for all connected parachains, making collaborative blockchain efforts economically viable under a unified security framework.</li>
    </ul>
    <p style={commonStyles.textStyle}>
      With Polkadot, you're not just a participant, you're a co-creator with the power to shape its future. For a comprehensive overview of Polkadot, visit the <a href="https://wiki.polkadot.network/docs/getting-started" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
    </p>
  </div>
  <div style={commonStyles.imageContainerStyle}>
    <img src={heroImage} alt="Polkadot Network" style={{ width: '100%' }} />
  </div>
</div>


  </AnimatedSection>
);

const SectionLink = ({ title, image, link }) => (
  <div style={{ margin: '20px', textAlign: 'center', transition: 'transform 0.3s' }}>
    <a href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
      <img
        src={image}
        alt={title}
        style={{
          width: '100px',
          height: '100px',
          objectFit: 'cover',
          // borderRadius: '8px',
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s',
        }}
        className="hover-zoom"
      />
      <h3 style={{ color: '#e6007a', marginTop: '10px' }}>{title}</h3>
    </a>
    <style jsx>{`
      .hover-zoom:hover {
        transform: translateY(-10px);
      }
    `}</style>
  </div>
);

const ScrollableSections = ({ collapsed }) => (
  <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: `0px 0 10px ${collapsed ? '80px' : '10px'}`, display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
    <SectionLink title="Relay Chain" image={relayChainImage} link="https://wiki.polkadot.network/docs/learn-architecture#relay-chain" />
    <SectionLink title="System Chains" image={systemChainsImage} link="https://wiki.polkadot.network/docs/learn-system-chains#overview" />
    <SectionLink title="Parachains" image={parachainsImage} link="https://wiki.polkadot.network/docs/learn-parachains" />
    <SectionLink title="Staking" image={stakingImage} link="https://wiki.polkadot.network/docs/learn-staking" />
    <SectionLink title="OpenGov" image={openGovImage} link="https://wiki.polkadot.network/docs/learn-polkadot-opengov" />
    <SectionLink title="Treasury" image={treasuryImage} link="https://wiki.polkadot.network/docs/learn-polkadot-opengov-treasury" />
    <SectionLink title="Async Backing" image={blockSpaceImage} link="https://wiki.polkadot.network/docs/learn-async-backing" />
    <SectionLink title="Coretime" image={coretimeImage} link="https://wiki.polkadot.network/docs/learn-agile-coretime" />
  
  </div>
);

const PolkadotNetworkPage = () => {
  return (
    <Layout className="overview-layout" style={{ maxWidth: '1300px', minWidth: '600px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', margin: '40px auto' }}>
      <Content>
        <div className="site-layout-content" style={{ padding: '24px', minHeight: '280px' }}>
          <section id="what-is-polkadot" style={commonStyles.sectionStyle}>
            <WhatIsPolkadot />
          </section>
          <ScrollableSections />
        </div>
      </Content>
    </Layout>
  );
};

export default PolkadotNetworkPage;



//full text
// import React from 'react';
// import { Layout } from 'antd';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import { Link } from 'react-scroll';
// import { LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
// import Footer from './components/Footer';
// import polkadotLogo from './assets/polkadot_logo_light.png';
// import relayChainImage from './assets/polkadot_logo_light.png';
// import systemChainsImage from './assets/polkadot_logo_light.png';
// import parachainsImage from './assets/polkadot_logo_light.png';
// import openGovImage from './assets/governance.avif';
// import treasuryImage from './assets/treasury2.avif';
// import coretimeImage from './assets/coretime.avif';
// import blockSpaceImage from './assets/blocks_space.avif';
// import stakingImage from './assets/staking.avif';
// import heroImage from './assets/hero.avif';


// const { Content } = Layout;

// const commonStyles = {
//   sectionStyle: {
//     padding: '10px 0',
//     borderBottom: '1px solid #e0e0e0',
//     minHeight: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     width: '100%',
//     justifyContent: 'space-between',
//     flexDirection: 'column',
//   },
//   titleContainerStyle: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     padding: '0 10px',
//   },
//   navButtonStyle: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     width: '40px',
//     height: '40px',
//     borderRadius: '50%',
//     backgroundColor: '#e6007a',
//     color: '#fff',
//     border: 'none',
//     cursor: 'pointer',
//     marginLeft: '10px',
//   },
//   textContainerStyle: {
//     width: '45%',
//     textAlign: 'justify',
//   },
//   imageContainerStyle: {
//     width: '50%',
//     marginLeft: '20px',
//   },
//   textStyle: {
//     fontSize: '1em',
//     lineHeight: '1.35',
//   },
//   listStyle: {
//     fontSize: '1em',
//     lineHeight: '1.35',
//     marginLeft: '10px',
//   },
// };

// const animationVariants = {
//   hidden: { opacity: 0, y: 50 },
//   visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
// };

// const AnimatedSection = ({ children, id, nextId, prevId, title }) => {
//   const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

//   return (
//     <motion.div
//       ref={ref}
//       initial="hidden"
//       animate={inView ? 'visible' : 'hidden'}
//       variants={animationVariants}
//       style={{ width: '100%' }}
//     >
//       <div style={commonStyles.titleContainerStyle}>
//         <h2 id={id} style={{ margin: '2px 0', color: '#e6007a', fontSize: '2.5em' }}>
//           {title}
//         </h2>
//         <div style={{ display: 'flex' }}>
//           {prevId && (
//             <Link to={prevId} smooth={true}>
//               <button style={commonStyles.navButtonStyle}>
//                 <LeftOutlined />
//               </button>
//             </Link>
//           )}
//           {nextId && (
//             <Link to={nextId} smooth={true}>
//               <button style={commonStyles.navButtonStyle}>
//                 <RightOutlined />
//               </button>
//             </Link>
//           )}
//           {id === 'treasury' && (
//             <Link to="what-is-polkadot" smooth={true}>
//               <button style={commonStyles.navButtonStyle}>
//                 <UpOutlined />
//               </button>
//             </Link>
//           )}
//         </div>
//       </div>
//       {children}
//     </motion.div>
//   );
// };

// const WhatIsPolkadot = () => (
//   <AnimatedSection id="what-is-polkadot" nextId="relay-chain" title="What is Polkadot?">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           Polkadot is a revolutionary blockchain protocol that connects multiple specialized blockchains into a single, cohesive network. It is designed to facilitate seamless communication between applications and smart contracts across various blockchains, promoting interoperability and scalability.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Polkadot addresses the constraints of traditional blockchains by providing several key benefits:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Scalability:</strong> Polkadot processes numerous transactions across multiple chains in parallel, preventing bottlenecks inherent in older networks that handled transactions sequentially.</li>
//           <li><strong>Interoperability:</strong> Polkadot allows cross-blockchain transfers of any type of data or asset, enabling blockchain networks to interact with a variety of chains within the Polkadot network.</li>
//           <li><strong>Shared Security:</strong> The Polkadot relay chain provides shared security for all connected parachains, making collaborative blockchain efforts economically viable under a unified security framework.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           By enabling multiple blockchains to share information and work together, Polkadot aims to build a truly decentralized web where users have control.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For a comprehensive overview of Polkadot, visit the <a href="https://wiki.polkadot.network/docs/getting-started" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={heroImage} alt="Polkadot Network" style={{ width: '100%'}} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const RelayChainInfo = () => (
//   <AnimatedSection id="relay-chain" nextId="system-chains" prevId="what-is-polkadot" title="Relay Chain">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           The Relay Chain is the core of the Polkadot network, ensuring the network's shared security, consensus, and cross-chain interoperability. It is designed to manage the network's essential functions, including the coordination of system and parachains.
//         </p>
//         <p style={commonStyles.textStyle}>
//           The Relay Chain's key responsibilities include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Consensus:</strong> Maintaining consensus across all connected chains.</li>
//           <li><strong>Cross-Chain Message Passing (XCMP):</strong> Enabling communication between parachains.</li>
//           <li><strong>Security:</strong> Offering shared security to all parachains within the Polkadot network.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           The Relay Chain itself does not support smart contracts but ensures that each parachain can securely and efficiently support its own smart contracts and specialized functions.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more information on the Relay Chain, refer to the <a href="https://wiki.polkadot.network/docs/learn-architecture#relay-chain" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={relayChainImage} alt="Relay Chain" style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const SystemChainsInfo = () => (
//   <AnimatedSection id="system-chains" nextId="parachains" prevId="relay-chain" title="System Chains">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           System chains are specialized parachains that provide core functionalities to the Polkadot network. Unlike regular parachains, system chains are deeply integrated with the Relay Chain and perform essential roles that support the entire network.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Key characteristics of system chains include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Governance:</strong> System chains often manage governance mechanisms for the Polkadot ecosystem, including proposing and voting on network upgrades and managing the Treasury.</li>
//           <li><strong>Consensus:</strong> They play a vital role in the Relay Chain's consensus process, ensuring secure and efficient block production and validation.</li>
//           <li><strong>Balance Tracking:</strong> System chains track and manage token balances across the network, facilitating transactions and staking.</li>
//           <li><strong>Security:</strong> As part of the Relay Chain, system chains contribute to and benefit from Polkadot’s shared security model, providing robust protection against attacks.</li>
//           <li><strong>Special Functions:</strong> System chains can handle specific tasks such as identity verification, stablecoin maintenance, and other functions requiring high reliability and security.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           The integration of system chains into the Relay Chain ensures that critical functions are performed with the highest level of security and efficiency, enhancing the overall robustness and scalability of the Polkadot network.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more detailed information on system chains, visit the <a href="https://wiki.polkadot.network/docs/learn-system-chains#overview" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={systemChainsImage} alt="System Chains" style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const ParachainsInfo = () => (
//   <AnimatedSection id="parachains" nextId="staking" prevId="system-chains" title="Parachains">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           Parachains are autonomous blockchains running in parallel to the Relay Chain within the Polkadot network. They leverage the shared security, scalability, and interoperability provided by the Relay Chain.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Key features of parachains include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Customization:</strong> Parachains can be optimized for specific use cases, enhancing performance and functionality.</li>
//           <li><strong>Interoperability:</strong> Parachains can communicate with other parachains and external networks, fostering a broader ecosystem of applications and services.</li>
//           <li><strong>Scalability:</strong> The addition of multiple parachains allows the network to handle a higher volume of transactions and data.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           Parachains are essential to Polkadot's vision of a decentralized web, enabling diverse applications and services to coexist and interact seamlessly.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more insights into parachains, visit the <a href="https://wiki.polkadot.network/docs/learn-parachains" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={parachainsImage} alt="Parachains" style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const StakingInfo = () => (
//     <AnimatedSection id="staking" prevId="parachains" nextId="asynchronous-backing" title="Staking">
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//         <div style={commonStyles.textContainerStyle}>
//           <p style={commonStyles.textStyle}>
//             Staking is a crucial component of Polkadot's network security and governance. By participating in staking, holders of DOT tokens can earn rewards and contribute to the network's stability and security.
//           </p>
//           <p style={commonStyles.textStyle}>
//             Key aspects of Staking include:
//           </p>
//           <ul style={commonStyles.listStyle}>
//             <li><strong>Nominators and Validators:</strong> Token holders can participate in staking as nominators or validators. Nominators back validators with their stakes, while validators are responsible for maintaining the network by producing new blocks and validating transactions.</li>
//             <li><strong>Rewards:</strong> Stakers earn rewards based on the amount of DOT they stake and the performance of the validators they support. This incentivizes both nominators and validators to act in the network's best interests.</li>
//             <li><strong>Slashing:</strong> To deter malicious behavior, Polkadot employs a slashing mechanism where misbehaving validators lose a portion of their staked DOT. This ensures that validators act honestly and maintain network security.</li>
//             <li><strong>Bonding and Unbonding:</strong> Staking DOT requires bonding (locking up) tokens for a certain period. After deciding to stop staking, there is an unbonding period during which the tokens remain locked before they can be transferred or used again.</li>
//             <li><strong>Governance Participation:</strong> Stakers play an active role in Polkadot's governance by voting on proposals and referendums, helping shape the future of the network through their participation and decisions.</li>
//           </ul>
//           <p style={commonStyles.textStyle}>
//             Staking not only secures the Polkadot network but also empowers token holders to actively participate in its governance and evolution.
//           </p>
//           <p style={commonStyles.textStyle}>
//             For comprehensive details about Staking, visit the <a href="https://wiki.polkadot.network/docs/learn-staking" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//           </p>
//         </div>
//         <div style={commonStyles.imageContainerStyle}>
//           <img src={stakingImage} alt="Staking" style={{ width: '100%' }} />
//         </div>
//       </div>
//     </AnimatedSection>
//   );

// const AsynchronousBackingInfo = () => (
//   <AnimatedSection id="asynchronous-backing" nextId="coretime" prevId="staking" title="Asynchronous Backing">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={blockSpaceImage} alt="Asynchronous Backing" style={{ width: '100%' }} />
//       </div>
      
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           Asynchronous Backing is a mechanism in the Polkadot network that enhances the efficiency and speed of parachain block production. By decoupling the production and approval of blocks, it allows for faster finality and increased throughput.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Key features of Asynchronous Backing include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Decoupled Processes:</strong> Block production and validation are handled separately, reducing wait times and improving overall network efficiency.</li>
//           <li><strong>Increased Throughput:</strong> By processing multiple blocks in parallel, Asynchronous Backing significantly increases the number of transactions that can be handled by the network.</li>
//           <li><strong>Faster Finality:</strong> Transactions achieve finality more quickly, enhancing the user experience and reducing the risk of chain reorganization.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           This innovation is a critical part of Polkadot's approach to scaling and improving blockchain technology, ensuring the network remains robust and efficient as it grows.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more details on Asynchronous Backing, visit the <a href="https://wiki.polkadot.network/docs/learn-async-backing" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const CoretimeInfo = () => (
//   <AnimatedSection id="coretime" nextId="open-gov" prevId="asynchronous-backing" title="Coretime">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           Coretime is a concept within the Polkadot network that refers to the dedicated periods during which parachains can execute their blocks. This mechanism ensures fair and efficient use of the network's resources.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Key aspects of Coretime include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Resource Allocation:</strong> Coretime allocates specific time slots to parachains, allowing them to execute transactions and process blocks efficiently.</li>
//           <li><strong>Fairness:</strong> By ensuring each parachain has dedicated execution time, Coretime prevents resource contention and promotes fair usage of the network.</li>
//           <li><strong>Optimized Performance:</strong> This structured approach to block execution helps maintain high performance and reliability across the network.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           Coretime is an integral part of Polkadot's design to support a diverse ecosystem of parachains, each with unique needs and functions.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more information on Coretime, refer to the <a href="https://wiki.polkadot.network/docs/learn-agile-coretime" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={coretimeImage} alt="Coretime" style={{ width: '100%' }} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const OpenGovInfo = () => (
//   <AnimatedSection id="open-gov" nextId="treasury" prevId="coretime" title="OpenGov">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//     <div style={commonStyles.imageContainerStyle}>
//         <img src={openGovImage} alt="OpenGov" style={{ width: '100%' }} />
//       </div>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           OpenGov is Polkadot's governance framework, allowing stakeholders to propose, vote on, and enact changes to the network protocol. The governance model is designed to be decentralized and transparent, ensuring all stakeholders have a say in the decision-making process.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Polkadot utilizes a sophisticated governance structure that includes several key mechanisms:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Referenda:</strong> Proposals for network changes, voted on by token holders. Any stakeholder can propose a referendum, and all token holders can participate in the voting process.</li>
//           <li><strong>Council:</strong> An elected body representing passive stakeholders. The Council proposes referenda and can veto dangerous or malicious proposals.</li>
//           <li><strong>Technical Committee:</strong> A group of development teams maintaining the Polkadot protocol, capable of fast-tracking urgent referenda.</li>
//           <li><strong>Conviction Voting:</strong> A system where token holders can increase their vote's weight by locking their tokens for a longer period, promoting long-term commitment to the network's health.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           OpenGov ensures Polkadot evolves according to its community's collective interests, balancing the needs of different stakeholders and adapting to new challenges and opportunities.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For more details on OpenGov, visit the <a href="https://wiki.polkadot.network/docs/learn-polkadot-opengov" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const TreasuryInfo = () => (
//   <AnimatedSection id="treasury" prevId="open-gov" title="Treasury">
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
//       <div style={commonStyles.textContainerStyle}>
//         <p style={commonStyles.textStyle}>
//           The Treasury is a pool of funds collected from block production rewards and transaction fees, used to finance projects that benefit the Polkadot ecosystem. Governed by the community, the Treasury supports a wide range of initiatives.
//         </p>
//         <p style={commonStyles.textStyle}>
//           Key aspects of the Treasury include:
//         </p>
//         <ul style={commonStyles.listStyle}>
//           <li><strong>Funding Proposals:</strong> Anyone can submit a proposal for funding, which is reviewed and voted on by the community. Proposals can cover infrastructure development, marketing, community events, research, and more.</li>
//           <li><strong>Burn Mechanism:</strong> To ensure efficient use of funds, a portion of the Treasury funds are burned if not allocated within a certain period, incentivizing prudent use of resources and supporting the network's native token value.</li>
//           <li><strong>Bounty System:</strong> The Treasury can issue bounties for specific tasks or projects, crowd-sourcing development, and incentivizing community participation in network improvements.</li>
//           <li><strong>Transparency and Accountability:</strong> All Treasury activities are recorded on the blockchain, ensuring transparency and accountability. The community can track fund expenditures and the impact of funded projects.</li>
//         </ul>
//         <p style={commonStyles.textStyle}>
//           The Treasury is a vital component of Polkadot's vision for a decentralized web, empowering the community to invest in projects that enhance the network and foster its growth.
//         </p>
//         <p style={commonStyles.textStyle}>
//           For comprehensive details about the Treasury, visit the <a href="https://wiki.polkadot.network/docs/learn-polkadot-opengov-treasury" target="_blank" rel="noopener noreferrer" style={{ color: '#e6007a' }}>Polkadot Wiki</a>.
//         </p>
//       </div>
//       <div style={commonStyles.imageContainerStyle}>
//         <img src={treasuryImage} alt="Treasury" style={{ width: '100%' }} />
//       </div>
//     </div>
//   </AnimatedSection>
// );

// const PolkadotNetworkPage = () => {
//   return (
//     <Layout className="layout">
//       <Content>
//         <div className="site-layout-content" style={{ padding: '24px', minHeight: '280px' }}>
//           {/* <h1 style={{ textAlign: 'center', marginBottom: '24px', color: '#e6007a' }}>Polkadot Network Overview</h1> */}
          
//           <section id="what-is-polkadot" style={commonStyles.sectionStyle}>
//             <WhatIsPolkadot />
//           </section>
          
//           <section id="relay-chain" style={commonStyles.sectionStyle}>
//             <RelayChainInfo />
//           </section>
          
//           <section id="system-chains" style={commonStyles.sectionStyle}>
//             <SystemChainsInfo />
//           </section>
          
//           <section id="parachains" style={commonStyles.sectionStyle}>
//             <ParachainsInfo />
//           </section>

//           <section id="staking" style={commonStyles.sectionStyle}>
//             <StakingInfo />
//           </section>

//           <section id="asynchronous-backing" style={commonStyles.sectionStyle}>
//             <AsynchronousBackingInfo />
//           </section>
          
//           <section id="coretime" style={commonStyles.sectionStyle}>
//             <CoretimeInfo />
//           </section>
          
//           <section id="open-gov" style={commonStyles.sectionStyle}>
//             <OpenGovInfo />
//           </section>
          
//           <section id="treasury" style={commonStyles.sectionStyle}>
//             <TreasuryInfo />
//           </section>
//         </div>
//       </Content>
//     </Layout>
//   );
// };

// export default PolkadotNetworkPage;
