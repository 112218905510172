import React, { useRef, useEffect } from 'react';
import { Box, TextField, MenuItem, Button, FormControl, InputLabel, Select } from '@mui/material';
import * as echarts from 'echarts';

const EChartsBuilder = ({ resultColumns, chartOptions, setChartOptions, results }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartOptions.xColumn && chartOptions.yColumn) {
      generateChart();
    }
  }, [results, chartOptions]);

  const generateChart = () => {
    if (!chartOptions.xColumn || !chartOptions.yColumn) {
      alert('Please select both X and Y columns for the chart.');
      return;
    }

    const chartData = results.map(row => ({
      x: row[chartOptions.xColumn],
      y: row[chartOptions.yColumn]
    }));

    const chartInstance = echarts.init(chartRef.current);

    const chartOptionsConfig = {
      xAxis: {
        type: 'category',
        data: chartData.map(item => item.x),
        name: chartOptions.xColumn
      },
      yAxis: {
        type: 'value',
        name: chartOptions.yColumn
      },
      series: [{
        data: chartData.map(item => item.y),
        type: chartOptions.chartType,
        name: chartOptions.seriesName
      }],
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [chartOptions.seriesName]
      }
    };

    // Special configurations for different chart types
    switch (chartOptions.chartType) {
      case 'pie':
        chartOptionsConfig.series = [{
          type: 'pie',
          data: chartData.map(item => ({ name: item.x, value: item.y }))
        }];
        break;
      case 'candlestick':
        chartOptionsConfig.series = [{
          type: 'candlestick',
          data: chartData.map(item => [item.open, item.close, item.low, item.high])
        }];
        chartOptionsConfig.xAxis.type = 'category';
        chartOptionsConfig.yAxis.type = 'value';
        break;
      case 'scatter':
        chartOptionsConfig.series = [{
          type: 'scatter',
          data: chartData.map(item => [item.x, item.y])
        }];
        break;
      case 'gauge':
        chartOptionsConfig.series = [{
          type: 'gauge',
          data: chartData.map(item => ({ name: item.x, value: item.y }))
        }];
        break;
      default:
        break;
    }

    chartInstance.setOption(chartOptionsConfig);
  };

  return (
    <Box className="chart-section" sx={{ marginTop: '20px', textAlign: 'center' }}>
      <Box className="chart-inputs" sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', justifyContent: 'center', marginBottom: '20px' }}>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Chart Type</InputLabel>
          <Select
            value={chartOptions.chartType}
            onChange={(e) => setChartOptions({ ...chartOptions, chartType: e.target.value })}
            label="Chart Type"
          >
            <MenuItem value="bar">Bar</MenuItem>
            <MenuItem value="line">Line</MenuItem>
            <MenuItem value="scatter">Scatter</MenuItem>
            <MenuItem value="pie">Pie</MenuItem>
            <MenuItem value="gauge">Gauge</MenuItem>
            <MenuItem value="candlestick">Candlestick</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>X-Axis</InputLabel>
          <Select
            value={chartOptions.xColumn}
            onChange={(e) => setChartOptions({ ...chartOptions, xColumn: e.target.value })}
            label="X-Axis"
          >
            <MenuItem value="">Select Column</MenuItem>
            {resultColumns.map((col) => (
              <MenuItem key={col} value={col}>{col}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Y-Axis</InputLabel>
          <Select
            value={chartOptions.yColumn}
            onChange={(e) => setChartOptions({ ...chartOptions, yColumn: e.target.value })}
            label="Y-Axis"
          >
            <MenuItem value="">Select Column</MenuItem>
            {resultColumns.map((col) => (
              <MenuItem key={col} value={col}>{col}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Series Name"
          value={chartOptions.seriesName || ''}
          onChange={(e) => setChartOptions({ ...chartOptions, seriesName: e.target.value })}
          variant="outlined"
          sx={{ flex: 1, minWidth: 200, marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={generateChart} sx={{ height: 'fit-content' }}>Generate Chart</Button>
      </Box>
      <Box ref={chartRef} className="chart-container" sx={{ width: '100%', height: '400px', marginTop: '20px', border: '1px solid #ddd' }}></Box>
    </Box>
  );
};

export default EChartsBuilder;
