import React, { useState, useEffect } from 'react';
import { outerContainerStyle, controlsContainerStyle, groupContainerStyle, groupTitleStyle, buttonStyle, selectedButtonStyle } from './js/charts_ecosystem.js';
const loadJSONData = async (jsonPath, relay, check, callback) => {
    try {
        const response = await fetch(jsonPath);
        const textData = await response.text();
        const jsonData = textData.trim().split('\n').map(line => JSON.parse(line));
        const filteredData = jsonData.filter(item => 
            (relay === 'all' || item.relay_chain === relay) &&
            (check === 'all' || (check === 'yes' ? item.CheckMetadataHash === 'yes' : item.CheckMetadataHash === 'no'))
        );
        callback(filteredData);
    } catch (error) {
        console.error('Failed to load JSON data:', error);
    }
};

const createCSV = (jsonData) => {
    const columnNames = Object.keys(jsonData[0] || {});
    let csvContent = columnNames.join(",") + "\n";

    jsonData.forEach(row => {
        const rowData = columnNames.map(column => {
            const value = row[column];
            return value === null ? 'N/A' : value;
        }).join(",");
        csvContent += rowData + "\n";
    });

    return csvContent;
};

const updateChartsConfig = async (relay, check, setTableData) => {
    const jsonPath = 'data/metadata000000000000.jsonl';
    await loadJSONData(jsonPath, relay, check, (data) => {
        setTableData(data);
    });
};

const populateTableMetadata = (data) => {
    return (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', fontFamily: "'Roboto', sans-serif", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
            <thead>
                <tr>
                    {['Date', 'Network', 'Chain', 'Has Metadata Hash', 'Metadata Versions', 'Metadata Segment'].map((header, index) => (
                        <th key={index} style={{ borderBottom: '2px solid #e0e0e0', padding: '16px', textAlign: 'left', backgroundColor: '#f5f5f5', fontWeight: '900', color: '#333', fontSize: '16px' }}>
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index} style={{ transition: 'background-color 0.3s, box-shadow 0.3s', backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9' }}>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{new Date(item.date).toLocaleDateString()}</td>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{item.relay_chain}</td>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{item.chain}</td>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{item.CheckMetadataHash}</td>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{item.metadata_versions}</td>
                        <td style={{ padding: '16px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', color: '#333' }}>{item.metadata}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const PolkadotMetadata = () => {
    const [relay, setRelay] = useState('polkadot');
    const [check, setCheck] = useState('all');
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        updateChartsConfig(relay, check, setTableData);
    }, [relay, check]);

    const handleRelayChange = (value) => {
        setRelay(value);
    };

    const handleCheckChange = (value) => {
        setCheck(value);
    };

    const downloadCSV = () => {
        const csvContent = createCSV(tableData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'metadata.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <div className="website-grid-container">
                <h2 style={{ color: '#333', fontSize: '24px', marginBottom: '10px' }}>CheckMetadataHash for Ledger App</h2>
                <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.6' }}>
                    The new Polkadot Ledger App requires a SignedExtension called CheckMetadataHash to be present in the runtime of a Polkadot-SDK chain to work. If a Polkadot-SDK has this new extension, together with other requirements, then the new Polkadot Ledger App will be compatible. In order to track adoption progress, Metadata is retrieved from all chains daily and queried to check if the new SignedExtension is present. The table will return yes/no depending on if the identifier was found.
                </p>
                <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.6' }}>
                    Example identifier:<br />
                    <code style={{ backgroundColor: '#e0e0e0', padding: '2px 4px', borderRadius: '4px' }}>
                        {`{ "identifier": "CheckMetadataHash", "type": "969", "additionalSigned": "528" }`}
                    </code>
                </p>
            </div>
            <div style={outerContainerStyle}>
                <div style={controlsContainerStyle}>
                    <div style={groupContainerStyle}>
                        <div style={groupTitleStyle}>Relay Chain</div>
                        <div>
                            <button style={relay === 'polkadot' ? selectedButtonStyle : buttonStyle} onClick={() => handleRelayChange('polkadot')}>
                                Polkadot
                            </button>
                            <button style={relay === 'kusama' ? selectedButtonStyle : buttonStyle} onClick={() => handleRelayChange('kusama')}>
                                Kusama
                            </button>
                            <button style={relay === 'rococo' ? selectedButtonStyle : buttonStyle} onClick={() => handleRelayChange('rococo')}>
                                Rococo
                            </button>
                            <button style={relay === 'westend' ? selectedButtonStyle : buttonStyle} onClick={() => handleRelayChange('westend')}>
                                Westend
                            </button>
                        </div>
                    </div>
                    <div style={groupContainerStyle}>
                        <div style={groupTitleStyle}>Has Metadata Hash</div>
                        <div>
                            <button style={check === 'all' ? selectedButtonStyle : buttonStyle} onClick={() => handleCheckChange('all')}>
                                All
                            </button>
                            <button style={check === 'yes' ? selectedButtonStyle : buttonStyle} onClick={() => handleCheckChange('yes')}>
                                Yes
                            </button>
                            <button style={check === 'no' ? selectedButtonStyle : buttonStyle} onClick={() => handleCheckChange('no')}>
                                No
                            </button>
                        </div>
                    </div>
                    <div>
                        <button onClick={downloadCSV} style={{ ...buttonStyle, margin: '10px 0', backgroundColor: '#E6007A', color: '#ffffff' }}>Download CSV</button>
                    </div>
                </div>
            </div>
            <div id="tableMetadata" style={{ maxWidth: '2000px', margin: '0 auto' }}>
                {populateTableMetadata(tableData)}
            </div>
        </div>
    );
};

export default PolkadotMetadata;
