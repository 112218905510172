// import React, { useState, useEffect } from 'react';
// import { ApiPromise, WsProvider } from '@polkadot/api';
// import '../css/PolkadotJSGeneralComponent.css';
// import BN from 'bn.js';
// import MetricBox from '../components/MetricBoxComponent.js'
// import LoadingSphere from '../components/LoadingComponent.js'
// import { chartDescriptions} from '../js/echart_utils.js'

// const PolkadotJSTreasuryComponent = ( {onLoaded} ) => {
//   const [metrics, setMetrics] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [retries, setRetries] = useState(0);
//   const maxRetries = 5;

//   const treasuryAddress = '13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB';

//   useEffect(() => {
//     let provider;
//     let api;

//     const connectToChain = async () => {
//       try {
//         provider = new WsProvider('wss://rpc.polkadot.io');
//         api = await ApiPromise.create({ provider });

//         // Fetch Balance Information
//         const accountInfo = await api.query.system.account(treasuryAddress);
//         const balance = accountInfo.data.free.div(new BN('10000000000')).toNumber().toLocaleString('en-US');

//         // Fetch Treasury Metrics
//         const approvals = await api.query.treasury.approvals();
//         const proposalCount = await api.query.treasury.proposalCount();

//         setMetrics({
//           balance,
//           approvals: approvals.length.toString(),
//           proposalCount: proposalCount.toString(),
//         });

//         setLoading(false);
//         if (onLoaded) onLoaded();
//       } catch (err) {
//         console.error('Failed to load Polkadot API:', err);
//         setError('Failed to connect to the Polkadot network.');
//         setLoading(false);
//       }
//     };

//     connectToChain();

//     return () => {
//       if (provider) {
//         provider.disconnect();
//       }
//     };
//   }, [retries]);

//   useEffect(() => {
//     if (error && retries < maxRetries) {
//       const retryTimeout = setTimeout(() => {
//         setRetries(retries + 1);
//         setError('');
//         setLoading(true);
//       }, 2000);
//       return () => clearTimeout(retryTimeout);
//     }
//   }, [error, retries, maxRetries]);

//   if (loading) {
//     return <div className="loading"><LoadingSphere/></div>;
//   }

//   if (error) {
//     return <p className="error-message">{error}</p>;
//   }

//   return (
//     <div className="polkadot-card">
//       <h1>Polkadot Treasury Metrics</h1>
//       <div className="metrics-container">
//         <MetricBox title="Balance" value={metrics.balance} description={chartDescriptions.treasuryBalance} unit="DOT" />
//         <MetricBox title="Proposal Count" value={metrics.proposalCount} description={chartDescriptions.proposalCount} />
//         {/* <MetricBox title="Approvals:" value={metrics.approvals} /> */}
//       </div>
//     </div>
//   );
// };

// export default PolkadotJSTreasuryComponent;
import React, { useState, useEffect } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import '../css/main.css';
import BN from 'bn.js';
import MetricBox from '../components/MetricBoxComponent.js';
import LoadingSphere from '../components/LoadingComponent.js';
import { chartDescriptions } from '../js/echart_utils.js';

const PolkadotJSTreasuryComponent = ({ onLoaded }) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [retries, setRetries] = useState(0);
  const maxRetries = 5;

  const treasuryAddresses = [
    { address: '13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB', node: 'wss://rpc.polkadot.io' },
    { address: '14xmwinmCEz6oRrFdczHKqHgWNMiCysE2KrA4jXXAAM1Eogk', node: 'wss://polkadot-asset-hub-rpc.polkadot.io' }
  ];

  const fetchDataFromPolkadot = async () => {
    try {
      const provider = new WsProvider(treasuryAddresses[0].node);
      const api = await ApiPromise.create({ provider });
      const accountInfo = await api.query.system.account(treasuryAddresses[0].address);
      const balance = accountInfo.data.free.div(new BN('10000000000')).toNumber().toLocaleString('en-US');
      const approvals = await api.query.treasury.approvals();
      const proposalCount = await api.query.treasury.proposalCount();

      return {
        balancePolkadot: balance,
        approvalsPolkadot: approvals.length.toString(),
        proposalCountPolkadot: proposalCount.toString()
      };
    } catch (err) {
      console.error('Failed to load Polkadot API:', err);
      throw new Error('Failed to connect to the Polkadot network.');
    }
  };

  const fetchDataFromAssetHub = async () => {
    try {
      const provider = new WsProvider(treasuryAddresses[1].node);
      const api = await ApiPromise.create({ provider });
      await api.isReady;

      const balanceUSDT = await api.query.assets.account(1984, treasuryAddresses[1].address);
      const balanceUSDC = await api.query.assets.account(1337, treasuryAddresses[1].address);
      const accountInfo = await api.query.system.account(treasuryAddresses[1].address);

      const balanceUSDTFormatted = balanceUSDT.isSome ? balanceUSDT.unwrap().balance.div(new BN('1000000')).toNumber().toLocaleString('en-US') : '0';
      const balanceUSDCFormatted = balanceUSDC.isSome ? balanceUSDC.unwrap().balance.div(new BN('1000000')).toNumber().toLocaleString('en-US') : '0';
      const balanceDOTFormatted = accountInfo.data.free.div(new BN('10000000000')).toNumber().toLocaleString('en-US');

      return {
        balanceUSDT: balanceUSDTFormatted,
        balanceUSDC: balanceUSDCFormatted,
        balanceAssetHubDOT: balanceDOTFormatted
      };
    } catch (err) {
      console.error('Failed to load Asset Hub API:', err);
      throw new Error('Failed to connect to the Asset Hub network.');
    }
  };

  useEffect(() => {
    const connectToChains = async () => {
      setLoading(true);
      try {
        const polkadotMetrics = await fetchDataFromPolkadot();
        const assetHubMetrics = await fetchDataFromAssetHub();

        setMetrics({ ...polkadotMetrics, ...assetHubMetrics });
        setLoading(false);
        if (onLoaded) onLoaded();
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    connectToChains();
  }, [retries]);

  useEffect(() => {
    if (error && retries < maxRetries) {
      const retryTimeout = setTimeout(() => {
        setRetries(retries + 1);
        setError('');
        setLoading(true);
      }, 2000);
      return () => clearTimeout(retryTimeout);
    }
  }, [error, retries, maxRetries]);

  if (loading) {
    return <div className="loading"><LoadingSphere /></div>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="polkadot-card">
      <h1>Treasury Metrics</h1>
      <div className="metrics-container">
        <MetricBox title="Polkadot Treasury Balance (DOT)" value={metrics.balancePolkadot} description={chartDescriptions.treasuryBalance} unit="DOT" />
        <MetricBox title="Asset Hub Treasury Balance (DOT)" value={metrics.balanceAssetHubDOT} description={chartDescriptions.treasuryBalanceAssetHub} unit="DOT" />
        {/* <MetricBox title="Polkadot Proposal Count" value={metrics.proposalCountPolkadot} description={chartDescriptions.proposalCount} /> */}
        <MetricBox title="Asset Hub Treasury Balance (USDT)" value={metrics.balanceUSDT} description={chartDescriptions.treasuryBalanceAssetHubUSDT} unit="USDT" />
        <MetricBox title="Asset Hub Treasury Balance (USDC)" value={metrics.balanceUSDC} description={chartDescriptions.treasuryBalanceAssetHubUSDC} unit="USDC" />
      </div>
    </div>
  );
};

export default PolkadotJSTreasuryComponent;
