import React, { useState } from 'react';
import { Tabs, Tooltip } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import './css/main.css';

const { TabPane } = Tabs;

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
      {copied ? (
        <CheckOutlined className="CopyIcon" />
      ) : (
        <CopyOutlined onClick={copyToClipboard} className="CopyIcon" />
      )}
    </Tooltip>
  );
};

const CodeSnippet = ({ language, code }) => (
  <div className="CodeSnippet">
    <CopyButton text={code} />
    <SyntaxHighlighter language={language} style={style}>
      {code}
    </SyntaxHighlighter>
  </div>
);

const PySubstrateTutorial = () => (
  <div>
    <section className="TutorialIntro">
      <h2>Introduction</h2>
      <p>The Py Substrate Interface library provides a simple and convenient way to interact with Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, querying blockchain data, and more.</p>
      <p><strong>Prerequisites:</strong> Basic Python knowledge and Python installation are required.</p>
    </section>

    <section className="TutorialInstallation">
      <h2>Installation Guide</h2>
      <p>To install the Py Substrate Interface library, use the following pip command:</p>
      <CodeSnippet language="bash" code={'pip install substrate-interface'} />
    </section>

    <section className="TutorialConnection">
      <h2>Connecting to a Node</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Code" key="1">
          <p>To connect to a Substrate node, you can use the following code snippet:</p>
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface

substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')`}
          />
        </TabPane>
      </Tabs>
    </section>

    <section className="TutorialQuerying">
      <h2>Querying Blockchain Data</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Block Hash" key="1">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Query block hash by block number
block_number = 123456
block_hash = substrate.get_block_hash(block_number)

# Print the block hash
print(f"Block Number: {block_number}")
print(f"Block Hash: {block_hash}")
`}
          />
        </TabPane>

        <TabPane tab="Events" key="2">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Query events by block hash
block_number = 123456
block_hash = substrate.get_block_hash(block_number)
events = substrate.get_events(block_hash)

# Print the events
print(f"Events at Block Number: {block_number} (Block Hash: {block_hash})")
for event in events:
    print(event.value)
`}
          />
        </TabPane>

        <TabPane tab="Account Balance" key="3">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Define the address you want to query
address = '13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB'

# Query the balance
result = substrate.query(
    module='System',
    storage_function='Account',
    params=[address])

# Print the free balance
free_balance = result.value['data']['free']
print(f"Address: {address}")
print(f"Free Balance: {free_balance}")

# Additional fields available in the result
reserved_balance = result.value['data']['reserved']
frozen = result.value['data']['frozen']

print(f"Reserved Balance: {reserved_balance}")
print(f"Frozen Balance: {frozen}")
`}
          />
        </TabPane>
      </Tabs>
    </section>

    {/* <section className="TutorialAdvanced">
      <h2>Advanced Usage</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Smart Contract Interaction" key="1">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface, Keypair

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Load keypair
keypair = Keypair.create_from_uri('//Alice')

# Smart contract call
call = substrate.compose_call(
    call_module='Contracts',
    call_function='call',
    call_params={
        'dest': '5FLSigC9H8ENJDqMr7QXKUVwAeNKf8nSfjqf9XPgq9jtCJzM',
        'value': 1000000000000,
        'gas_limit': 5000000000000,
        'data': '0xDEADBEEF'
    }
)

# Create extrinsic
extrinsic = substrate.create_signed_extrinsic(call, keypair)

# Send the extrinsic
receipt = substrate.submit_extrinsic(extrinsic, wait_for_inclusion=True)
print(f"Extrinsic sent with hash: {receipt['extrinsic_hash']}")
`}
          />
        </TabPane>

        <TabPane tab="Event Decoding" key="2">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Query events by block hash
block_number = 123456
block_hash = substrate.get_block_hash(block_number)
events = substrate.get_events(block_hash)

# Decode and print the events
for event in events:
    print(f"Event: {event.value['event_id']}")
    print(f"Module: {event.value['module_id']}")
    print(f"Params: {event.value['params']}")
`}
          />
        </TabPane>

        <TabPane tab="Submit Extrinsic" key="3">
          <CodeSnippet
            language="python"
            code={`from substrateinterface import SubstrateInterface, Keypair

# Connect to a Substrate node
substrate = SubstrateInterface(
    url="wss://rpc.polkadot.io",
    ss58_format=0,
    type_registry_preset='polkadot')

# Load keypair
keypair = Keypair.create_from_uri('//Alice')

# Compose the call
call = substrate.compose_call(
    call_module='Balances',
    call_function='transfer',
    call_params={
        'dest': '5FLSigC9H8ENJDqMr7QXKUVwAeNKf8nSfjqf9XPgq9jtCJzM',
        'value': 1000000000000
    }
)

# Create the extrinsic
extrinsic = substrate.create_signed_extrinsic(call, keypair)

# Submit the extrinsic
receipt = substrate.submit_extrinsic(extrinsic, wait_for_inclusion=True)
print(f"Extrinsic sent with hash: {receipt['extrinsic_hash']}")
`}
          />
        </TabPane>
      </Tabs>
      <p>For more advanced use cases, refer to the official <a href="https://polkascan.github.io/py-substrate-interface/getting-started/installation/">documentation</a>.</p>
    </section> */}

    <section className="TutorialFAQ">
      <h2>FAQs and Troubleshooting</h2>
      <p>Common issues and questions about using the Py Substrate Interface:</p>
      <ul>
        <li>Ensure your node is running and accessible.</li>
        <li>Verify your Python environment is correctly configured.</li>
        <li><a href="https://github.com/polkascan/py-substrate-interface"> Consult the official documentation for more detailed troubleshooting tips.</a></li>
      </ul>
    </section>

    <section className="TutorialCommunity">
      <h2>Feedback and Community</h2>
      <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
      <ul>
        <li><a href="https://github.com/paritytech">GitHub Repository</a></li>
        <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
      </ul>
    </section>
  </div>
);

const PolkadotJsTutorial = () => (
  <div>
    <section className="TutorialIntro">
      <h2>Introduction</h2>
      <p>The Polkadot.js API library provides a convenient way to interact with Polkadot and Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, and querying blockchain data using JavaScript.</p>
      <p><strong>Prerequisites:</strong> Basic JavaScript knowledge and Node.js installation are required.</p>
    </section>

    <section className="TutorialInstallation">
      <h2>Installation Guide</h2>
      <p>To install the Polkadot.js API library, use the following npm command:</p>
      <CodeSnippet language="bash" code={'npm install @polkadot/api'} />
    </section>

    <section className="TutorialConnection">
      <h2>Connecting to a Node</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Code" key="1">
          <p>To connect to a Polkadot node, you can use the following code snippet:</p>
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

const wsProvider = new WsProvider('wss://rpc.polkadot.io');
const api = await ApiPromise.create({ provider: wsProvider });

console.log('Connected to the node');`}
          />
        </TabPane>
      </Tabs>
    </section>

    <section className="TutorialQuerying">
      <h2>Querying Blockchain Data</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Block Hash" key="1">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  const blockHash = await api.rpc.chain.getBlockHash(123456);
  console.log('Block hash:', blockHash.toString());
  // Disconnect the provider to close the WebSocket connection
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>
    
        <TabPane tab="Events" key="2">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  const blockHash = await api.rpc.chain.getBlockHash(123456);
  const signedBlock = await api.rpc.chain.getBlock(blockHash);
  const allRecords = await api.query.system.events.at(blockHash);

  console.log('Events:', allRecords.toString());
  // Disconnect the provider to close the WebSocket connection
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>
        
        <TabPane tab="Account Balance" key="3">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  const { data: { free: balance } } = await api.query.system.account('13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB');
  console.log('Account balance:', balance.toString());
  // Disconnect the provider to close the WebSocket connection
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>

        <TabPane tab="Metadata" key="4">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  const metadata = await api.rpc.state.getMetadata();
  console.log('Chain metadata:', metadata.toString());
  // Disconnect the provider to close the WebSocket connection
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>

      </Tabs>
    </section>

    {/* <section className="TutorialAdvanced">
      <h2>Advanced Usage</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Submit Transaction" key="1">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider, Keyring } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  // Create a new keyring instance
  const keyring = new Keyring({ type: 'sr25519' });
  const alice = keyring.addFromUri('//Alice');

  // Transfer some funds
  const transfer = api.tx.balances.transfer('13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB', 123456789);

  // Sign and send the transaction
  const hash = await transfer.signAndSend(alice);
  console.log('Transfer sent with hash', hash.toHex());

  // Disconnect
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>

        <TabPane tab="Contract Call" key="2">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider, Keyring } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  // Create a new keyring instance
  const keyring = new Keyring({ type: 'sr25519' });
  const alice = keyring.addFromUri('//Alice');

  // Call a contract
  const contractCall = api.tx.contracts.call(
    '5FLSigC9H8ENJDqMr7QXKUVwAeNKf8nSfjqf9XPgq9jtCJzM',
    1000000000000,
    5000000000000,
    '0xDEADBEEF'
  );

  // Sign and send the transaction
  const hash = await contractCall.signAndSend(alice);
  console.log('Contract call sent with hash', hash.toHex());

  // Disconnect
  await api.disconnect();
}

main().catch(console.error);`}
          />
        </TabPane>

        <TabPane tab="Event Listening" key="3">
          <CodeSnippet
            language="javascript"
            code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

async function main() {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  const api = await ApiPromise.create({ provider: wsProvider });

  // Listen to balance change events
  api.query.system.events((events) => {
    console.log('Received events:', events);

    events.forEach((record) => {
      const { event, phase } = record;
      const types = event.typeDef;

      console.log('Phase', phase.toString());
      console.log('Event', event.section + '.' + event.method);
      console.log('Event data:', event.data.toString());
    });
  });
}

main().catch(console.error);`}
          />
        </TabPane>
      </Tabs>
      <p>For more advanced use cases, refer to the official <a href="https://polkadot.js.org/api/">documentation</a>.</p>
    </section> */}

    <section className="TutorialFAQ">
      <h2>FAQs and Troubleshooting</h2>
      <p>Common issues and questions about using the Polkadot.js API:</p>
      <ul>
        <li>Ensure your node is running and accessible.</li>
        <li>Verify your Node.js environment is correctly configured.</li>
        <li><a href="https://github.com/polkadot-js/api"> Consult the official documentation for more detailed troubleshooting tips.</a></li>
      </ul>
    </section>

    <section className="TutorialCommunity">
      <h2>Feedback and Community</h2>
      <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
      <ul>
        <li><a href="https://github.com/paritytech/">GitHub Repository</a></li>
        <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
      </ul>
    </section>
  </div>
);

const TutorialPage = () => {
  const [activeKey, setActiveKey] = useState("1");

  return (
    <div className="TutorialContainer">
      <header className="TutorialHeader">
        <h1>Polkadot API Tutorials</h1>
        <p>Learn how to interact with Substrate blockchain networks using various APIs</p>
      </header>

      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <TabPane tab="Py Substrate Interface" key="1">
          <PySubstrateTutorial />
        </TabPane>
        <TabPane tab="Polkadot.js API" key="2">
          <PolkadotJsTutorial />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TutorialPage;


// import React, { useState } from 'react';
// import { Tabs, Tooltip } from 'antd';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { tomorrow as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
// import './css/main.css';

// const { TabPane } = Tabs;

// const CopyButton = ({ text }) => {
//   const [copied, setCopied] = useState(false);

//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(text).then(() => {
//       setCopied(true);
//       setTimeout(() => setCopied(false), 2000);
//     });
//   };

//   return (
//     <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
//       {copied ? (
//         <CheckOutlined className="CopyIcon" />
//       ) : (
//         <CopyOutlined onClick={copyToClipboard} className="CopyIcon" />
//       )}
//     </Tooltip>
//   );
// };

// const CodeSnippet = ({ language, code }) => (
//   <div className="CodeSnippet">
//     <CopyButton text={code} />
//     <SyntaxHighlighter language={language} style={style}>
//       {code}
//     </SyntaxHighlighter>
//   </div>
// );

// const PySubstrateTutorial = () => (
//   <div>
//     <section className="TutorialIntro">
//       <h2>Introduction</h2>
//       <p>The Py Substrate Interface library provides a simple and convenient way to interact with Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, querying blockchain data, and more.</p>
//       <p><strong>Prerequisites:</strong> Basic Python knowledge and Python installation are required.</p>
//     </section>

//     <section className="TutorialInstallation">
//       <h2>Installation Guide</h2>
//       <p>To install the Py Substrate Interface library, use the following pip command:</p>
//       <CodeSnippet language="bash" code={'pip install substrate-interface'} />
//     </section>

//     <section className="TutorialConnection">
//       <h2>Connecting to a Node</h2>
//       <Tabs defaultActiveKey="1">
//         <TabPane tab="Code" key="1">
//           <p>To connect to a Substrate node, you can use the following code snippet:</p>
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')`}
//           />
//         </TabPane>
//       </Tabs>
//     </section>

//     <section className="TutorialQuerying">
//       <h2>Querying Blockchain Data</h2>
//       <Tabs defaultActiveKey="1">
//         <TabPane tab="Query Block Hash" key="1">
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Query block hash by block number
// block_number = 123456
// block_hash = substrate.get_block_hash(block_number)

// # Print the block hash
// print(f"Block Number: {block_number}")
// print(f"Block Hash: {block_hash}")
// `}
//           />
//         </TabPane>

//         <TabPane tab="Query Events" key="2">
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Query events by block hash
// block_number = 123456
// block_hash = substrate.get_block_hash(block_number)
// events = substrate.get_events(block_hash)

// # Print the events
// print(f"Events at Block Number: {block_number} (Block Hash: {block_hash})")
// for event in events:
//     print(event.value)
// `}
//           />
//         </TabPane>

//         <TabPane tab="Query Account Balance" key="3">
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Define the address you want to query
// address = '13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB'

// # Query the balance
// result = substrate.query(
//     module='System',
//     storage_function='Account',
//     params=[address])

// # Print the free balance
// free_balance = result.value['data']['free']
// print(f"Address: {address}")
// print(f"Free Balance: {free_balance}")

// # Additional fields available in the result
// reserved_balance = result.value['data']['reserved']
// frozen = result.value['data']['frozen']

// print(f"Reserved Balance: {reserved_balance}")
// print(f"Frozen Balance: {frozen}")
// `}
//           />
//         </TabPane>
//       </Tabs>
//     </section>

//     <section className="TutorialAdvanced">
//       <h2>Advanced Usage</h2>
//       <p>For more complex interactions such as smart contract interactions or event decoding, refer to the official <a href="https://polkascan.github.io/py-substrate-interface/getting-started/installation/">documentation</a>.</p>
//     </section>

//     <section className="TutorialFAQ">
//       <h2>FAQs and Troubleshooting</h2>
//       <p>Common issues and questions about using the Py Substrate Interface:</p>
//       <ul>
//         <li>Ensure your node is running and accessible.</li>
//         <li>Verify your Python environment is correctly configured.</li>
//         <li>Consult the official documentation for more detailed troubleshooting tips.</li>
//       </ul>
//     </section>

//     <section className="TutorialCommunity">
//       <h2>Feedback and Community</h2>
//       <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
//       <ul>
//         <li><a href="https://github.com/paritytech">GitHub Repository</a></li>
//         <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
//       </ul>
//     </section>
//   </div>
// );

// const PolkadotJsTutorial = () => (
//   <div>
//     <section className="TutorialIntro">
//       <h2>Introduction</h2>
//       <p>The Polkadot.js API library provides a convenient way to interact with Polkadot and Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, and querying blockchain data using JavaScript.</p>
//       <p><strong>Prerequisites:</strong> Basic JavaScript knowledge and Node.js installation are required.</p>
//     </section>

//     <section className="TutorialInstallation">
//       <h2>Installation Guide</h2>
//       <p>To install the Polkadot.js API library, use the following npm command:</p>
//       <CodeSnippet language="bash" code={'npm install @polkadot/api'} />
//     </section>

//     <section className="TutorialConnection">
//       <h2>Connecting to a Node</h2>
//       <Tabs defaultActiveKey="1">
//         <TabPane tab="Code" key="1">
//           <p>To connect to a Polkadot node, you can use the following code snippet:</p>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// const wsProvider = new WsProvider('wss://rpc.polkadot.io');
// const api = await ApiPromise.create({ provider: wsProvider });

// console.log('Connected to the node');`}
//           />
//         </TabPane>
//       </Tabs>
//     </section>

//     <section className="TutorialQuerying">
//       <h2>Querying Blockchain Data</h2>
//       <Tabs defaultActiveKey="1">
//         <TabPane tab="Query Account Balance" key="1">
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const { data: { free: balance } } = await api.query.system.account('13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB');
//   console.log('Account balance:', balance.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />
//         </TabPane>

//         <TabPane tab="Query Block Hash" key="2">
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const blockHash = await api.rpc.chain.getBlockHash(123456);
//   console.log('Block hash:', blockHash.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />
//         </TabPane>

//         <TabPane tab="Query Chain Metadata" key="3">
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const metadata = await api.rpc.state.getMetadata();
//   console.log('Chain metadata:', metadata.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />
//         </TabPane>

//         <TabPane tab="Query Events" key="4">
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const blockHash = await api.rpc.chain.getBlockHash(123456);
//   const signedBlock = await api.rpc.chain.getBlock(blockHash);
//   const allRecords = await api.query.system.events.at(blockHash);

//   console.log('Events:', allRecords.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />
//         </TabPane>
//       </Tabs>
//     </section>

//     <section className="TutorialAdvanced">
//       <h2>Advanced Usage</h2>
//       <p>For more complex interactions such as submitting transactions or decoding events, refer to the official <a href="https://polkadot.js.org/api/">documentation</a>.</p>
//     </section>

//     <section className="TutorialFAQ">
//       <h2>FAQs and Troubleshooting</h2>
//       <p>Common issues and questions about using the Polkadot.js API:</p>
//       <ul>
//         <li>Ensure your node is running and accessible.</li>
//         <li>Verify your Node.js environment is correctly configured.</li>
//         <li>Consult the official documentation for more detailed troubleshooting tips.</li>
//       </ul>
//     </section>

//     <section className="TutorialCommunity">
//       <h2>Feedback and Community</h2>
//       <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
//       <ul>
//         <li><a href="https://github.com/polkadot-js/api">GitHub Repository</a></li>
//         <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
//       </ul>
//     </section>
//   </div>
// );

// const TutorialPage = () => {
//   const [activeKey, setActiveKey] = useState("1");

//   return (
//     <div className="TutorialContainer">
//       <header className="TutorialHeader">
//         <h1>Polkadot API Tutorials</h1>
//         <p>Learn how to interact with Substrate blockchain networks using various APIs</p>
//       </header>

//       <Tabs activeKey={activeKey} onChange={setActiveKey}>
//         <TabPane tab="Py Substrate Interface" key="1">
//           <PySubstrateTutorial />
//         </TabPane>
//         <TabPane tab="Polkadot.js API" key="2">
//           <PolkadotJsTutorial />
//         </TabPane>
//       </Tabs>
//     </div>
//   );
// };

// export default TutorialPage;





//antd collapseable
// import React, { useState } from 'react';
// import { Tabs, Collapse } from 'antd';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { tomorrow as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { CopyOutlined } from '@ant-design/icons';
// import './css/main.css';

// const { TabPane } = Tabs;
// const { Panel } = Collapse;

// const CopyButton = ({ text }) => {
//   const copyToClipboard = () => {
//     navigator.clipboard.writeText(text).then(() => {
//       // alert('Copied to clipboard!');
//     });
//   };

//   return (
//     <CopyOutlined onClick={copyToClipboard} className="CopyIcon" />
//   );
// };

// const CodeSnippet = ({ language, code }) => (
//   <div className="CodeSnippet">
//     <CopyButton text={code} />
//     <SyntaxHighlighter language={language} style={style}>
//       {code}
//     </SyntaxHighlighter>
//   </div>
// );

// const PySubstrateTutorial = () => (
//   <div>
//     <Collapse accordion>
//       <Panel header="Introduction" key="1">
//         <section className="TutorialIntro">
//           <h2>Introduction</h2>
//           <p>The Py Substrate Interface library provides a simple and convenient way to interact with Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, querying blockchain data, and more.</p>
//           <p><strong>Prerequisites:</strong> Basic Python knowledge and Python installation are required.</p>
//         </section>
//       </Panel>

//       <Panel header="Installation Guide" key="2">
//         <section className="TutorialInstallation">
//           <h2>Installation Guide</h2>
//           <p>To install the Py Substrate Interface library, use the following pip command:</p>
//           <CodeSnippet language="bash" code={'pip install substrate-interface'} />
//         </section>
//       </Panel>

//       <Panel header="Connecting to a Node" key="3">
//         <section className="TutorialConnection">
//           <h2>Connecting to a Node</h2>
//           <p>To connect to a Substrate node, you can use the following code snippet:</p>
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot'
// )`}
//           />
//         </section>
//       </Panel>

//       <Panel header="Querying Blockchain Data" key="4">
//         <section className="TutorialQuerying">
//           <h2>Querying Blockchain Data</h2>
//           <p>Here are some examples of how to query different types of blockchain data:</p>

//           <h3>Query Block Hash</h3>
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Query block hash by block number
// block_number = 123456
// block_hash = substrate.get_block_hash(block_number)

// # Print the block hash
// print(f"Block Number: {block_number}")
// print(f"Block Hash: {block_hash}")
// `}
//           />

//           <h3>Query Events</h3>
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Query events by block hash
// block_number = 123456
// block_hash = substrate.get_block_hash(block_number)
// events = substrate.get_events(block_hash)

// # Print the events
// print(f"Events at Block Number: {block_number} (Block Hash: {block_hash})")
// for event in events:
//     print(event.value)
// `}
//           />

//           <h3>Query Account Balance</h3>
//           <CodeSnippet
//             language="python"
//             code={`from substrateinterface import SubstrateInterface

// # Connect to a Substrate node
// substrate = SubstrateInterface(
//     url="wss://rpc.polkadot.io",
//     ss58_format=0,
//     type_registry_preset='polkadot')

// # Define the address you want to query
// address = '13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB'

// # Query the balance
// result = substrate.query(
//     module='System',
//     storage_function='Account',
//     params=[address])

// # Print the free balance
// free_balance = result.value['data']['free']
// print(f"Address: {address}")
// print(f"Free Balance: {free_balance}")

// # Additional fields available in the result
// reserved_balance = result.value['data']['reserved']
// frozen = result.value['data']['frozen']

// print(f"Reserved Balance: {reserved_balance}")
// print(f"Frozen Balance: {frozen}")
// `}
//           />
//         </section>
//       </Panel>

//       <Panel header="Advanced Usage" key="5">
//         <section className="TutorialAdvanced">
//           <h2>Advanced Usage</h2>
//           <p>For more complex interactions such as smart contract interactions or event decoding, refer to the official <a href="https://polkascan.github.io/py-substrate-interface/getting-started/installation/">documentation</a>.</p>
//         </section>
//       </Panel>

//       <Panel header="FAQs and Troubleshooting" key="6">
//         <section className="TutorialFAQ">
//           <h2>FAQs and Troubleshooting</h2>
//           <p>Common issues and questions about using the Py Substrate Interface:</p>
//           <ul>
//             <li>Ensure your node is running and accessible.</li>
//             <li>Verify your Python environment is correctly configured.</li>
//             <li>Consult the official documentation for more detailed troubleshooting tips.</li>
//           </ul>
//         </section>
//       </Panel>

//       <Panel header="Feedback and Community" key="7">
//         <section className="TutorialCommunity">
//           <h2>Feedback and Community</h2>
//           <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
//           <ul>
//             <li><a href="https://github.com/paritytech">GitHub Repository</a></li>
//             <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
//           </ul>
//         </section>
//       </Panel>
//     </Collapse>
//   </div>
// );

// const PolkadotJsTutorial = () => (
//   <div>
//     <Collapse accordion>
//       <Panel header="Introduction" key="1">
//         <section className="TutorialIntro">
//           <h2>Introduction</h2>
//           <p>The Polkadot.js API library provides a convenient way to interact with Polkadot and Substrate-based blockchains. This tutorial will guide you through setting up the library, connecting to a node, and querying blockchain data using JavaScript.</p>
//           <p><strong>Prerequisites:</strong> Basic JavaScript knowledge and Node.js installation are required.</p>
//         </section>
//       </Panel>

//       <Panel header="Installation Guide" key="2">
//         <section className="TutorialInstallation">
//           <h2>Installation Guide</h2>
//           <p>To install the Polkadot.js API library, use the following npm command:</p>
//           <CodeSnippet language="bash" code={'npm install @polkadot/api'} />
//         </section>
//       </Panel>

//       <Panel header="Connecting to a Node" key="3">
//         <section className="TutorialConnection">
//           <h2>Connecting to a Node</h2>
//           <p>To connect to a Polkadot node, you can use the following code snippet:</p>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// const wsProvider = new WsProvider('wss://rpc.polkadot.io');
// const api = await ApiPromise.create({ provider: wsProvider });

// console.log('Connected to the node');`}
//           />
//         </section>
//       </Panel>

//       <Panel header="Querying Blockchain Data" key="4">
//         <section className="TutorialQuerying">
//           <h2>Querying Blockchain Data</h2>
//           <p>Here are some examples of how to query different types of blockchain data:</p>

//           <h3>Query Account Balance</h3>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const { data: { free: balance } } = await api.query.system.account('13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB');
//   console.log('Account balance:', balance.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />

//           <h3>Query Block Hash</h3>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const blockHash = await api.rpc.chain.getBlockHash(123456);
//   console.log('Block hash:', blockHash.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />

//           <h3>Query Chain Metadata</h3>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const metadata = await api.rpc.state.getMetadata();
//   console.log('Chain metadata:', metadata.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />

//           <h3>Query Events</h3>
//           <CodeSnippet
//             language="javascript"
//             code={`const { ApiPromise, WsProvider } = require('@polkadot/api');

// async function main() {
//   const wsProvider = new WsProvider('wss://rpc.polkadot.io');
//   const api = await ApiPromise.create({ provider: wsProvider });

//   const blockHash = await api.rpc.chain.getBlockHash(123456);
//   const signedBlock = await api.rpc.chain.getBlock(blockHash);
//   const allRecords = await api.query.system.events.at(blockHash);

//   console.log('Events:', allRecords.toString());
//   // Disconnect the provider to close the WebSocket connection
//   await api.disconnect();
// }

// main().catch(console.error);`}
//           />
//         </section>
//       </Panel>

//       <Panel header="Advanced Usage" key="5">
//         <section className="TutorialAdvanced">
//           <h2>Advanced Usage</h2>
//           <p>For more complex interactions such as submitting transactions or decoding events, refer to the official <a href="https://polkadot.js.org/api/">documentation</a>.</p>
//         </section>
//       </Panel>

//       <Panel header="FAQs and Troubleshooting" key="6">
//         <section className="TutorialFAQ">
//           <h2>FAQs and Troubleshooting</h2>
//           <p>Common issues and questions about using the Polkadot.js API:</p>
//           <ul>
//             <li>Ensure your node is running and accessible.</li>
//             <li>Verify your Node.js environment is correctly configured.</li>
//             <li>Consult the official documentation for more detailed troubleshooting tips.</li>
//           </ul>
//         </section>
//       </Panel>

//       <Panel header="Feedback and Community" key="7">
//         <section className="TutorialCommunity">
//           <h2>Feedback and Community</h2>
//           <p>We encourage you to submit feedback and join discussions in our community forums. Your insights help us improve our tools and tutorials.</p>
//           <ul>
//             <li><a href="https://github.com/polkadot-js/api">GitHub Repository</a></li>
//             <li><a href="https://forum.polkadot.network/">Polkadot Forum</a></li>
//           </ul>
//         </section>
//       </Panel>
//     </Collapse>
//   </div>
// );

// const TutorialPage = () => {
//   const [activeKey, setActiveKey] = useState("1");

//   return (
//     <div className="TutorialContainer">
//       <header className="TutorialHeader">
//         <h1>Polkadot API Tutorials</h1>
//         <p>Learn how to interact with Substrate blockchain networks using various APIs</p>
//       </header>

//       <Tabs activeKey={activeKey} onChange={setActiveKey}>
//         <TabPane tab="Py Substrate Interface" key="1">
//           <PySubstrateTutorial />
//         </TabPane>
//         <TabPane tab="Polkadot.js API" key="2">
//           <PolkadotJsTutorial />
//         </TabPane>
//       </Tabs>
//     </div>
//   );
// };

// export default TutorialPage;
