// import React from 'react';

// const InfoCubeIcon = ({ onMouseEnter, onMouseLeave }) => (
//   <div
//     onMouseEnter={onMouseEnter}
//     onMouseLeave={onMouseLeave}
//     style={{ position: 'relative', display: 'inline-block' }}
//   >
//     <svg width="30" height="30" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
//       {/* Cube */}
//       <polygon points="25,1 49,13 49,37 25,49 1,37 1,13" fill="none" stroke="#E6007A" strokeWidth="1" />
//       <polygon points="25,1 1,13 25,25 49,13" fill="#E6007A" />
//       <polygon points="1,13 25,25 25,49 1,37" fill="#D45682" />
//       <polygon points="49,13 25,25 25,49 49,37" fill="#BF4C85" />
//       {/* Small white "i" */}
//       {/* <text x="25" y="20" fill="white" fontSize="20" fontFamily="Arial, sans-serif" textAnchor="middle">i</text> */}
//     </svg>
//   </div>
// );

// export default InfoCubeIcon;

import React from 'react';
import PolkadotTokenImage from '../assets/Polkadot_Token_PolkadotToken_Pink.png';
import KusamaTokenImage from '../assets/kusama-ksm-logo.png';


const InfoCubeIconPolkadot = ({ onMouseEnter, onMouseLeave }) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={{ position: 'relative', display: 'inline-block' }}
  >
    <img 
      src={PolkadotTokenImage} 
      alt="Info Icon" 
      width="30" 
      height="30" 
      style={{ cursor: 'pointer' }} 
    />
  </div>
);

export default InfoCubeIconPolkadot;