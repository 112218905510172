import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Button } from 'antd';

const HeaderComponent = ({ title, setAccount, setProvider, account }) => {
  const rpcUrl = "https://rpc.api.moonbase.moonbeam.network";
  const moonbaseChainId = '0x507'; // Chain ID for Moonbase Alpha

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: moonbaseChainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: moonbaseChainId,
                chainName: 'Moonbase Alpha',
                rpcUrls: [rpcUrl],
                nativeCurrency: {
                  name: 'Dev',
                  symbol: 'DEV',
                  decimals: 18,
                },
                blockExplorerUrls: ['https://moonbase.moonscan.io/'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add network:', addError);
        }
      } else {
        console.error('Failed to switch network:', switchError);
      }
    }
  };

  const connectWallet = async () => {
    try {
      if (!window.ethereum) {
        alert('Please install MetaMask!');
        return;
      }

      await switchNetwork();
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(web3Provider);

      const accounts = await web3Provider.send('eth_requestAccounts', []);
      setAccount(accounts[0]);
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      const initProvider = async () => {
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3Provider);
      };
      initProvider();
    }
  }, [setProvider]);

  return (
    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', backgroundColor: 'white', color: 'black', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
      <h1>{title}</h1>
      <Button type="primary" onClick={connectWallet}>
        {account ? `Connected: ${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect Wallet'}
      </Button>
    </header>
  );
};

export default HeaderComponent;
