// ScrollAnimationContainer.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  opacity: 0;
  transition: opacity 1.9s ease-out;
`;

const ScrollAnimationContainer = ({ children, triggerPoint }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onScroll = () => {
    if (window.scrollY > parseInt(triggerPoint)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Container style={{ opacity: isVisible ? 1 : 0 }}>
      {children}
    </Container>
  );
};

export default ScrollAnimationContainer;
