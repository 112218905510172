
// //single data table test
// import React, { useEffect, useState } from 'react';

// function ClickHouseTest() {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://localhost:3001/api/data?table=test_table_2');
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         const result = await response.json();
//         setData(result);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className="App">
//       <h1>ClickHouse Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Chain</th>
//             <th>Number</th>
//             {/* Add more headers based on your actual table columns */}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row, index) => (
//             <tr key={index}>
//               <td>{row.chain}</td>
//               <td>{row.number}</td>
//               {/* Add more cells based on your actual table columns */}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default ClickHouseTest;


//with parameter
import React, { useState } from 'react';

function AddressLookup() {
  const [address, setAddress] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddressChange = event => {
    setAddress(event.target.value);
  };

  const handleSubmit = async () => {
    if (!address) {
      alert('Please enter an address');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`http://localhost:3001/api/data?table=address_test&address=${encodeURIComponent(address)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>Address Data Lookup</h1>
      <div>
        <input
          type="text"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter address"
        />
        <button onClick={handleSubmit}>Search</button>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>Balance</th>  {/* Adapt column names based on actual data */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.address}</td>
              <td>{item.balance}</td>  {/* Adapt column names based on actual data */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddressLookup;
