import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Select } from 'antd';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import { loadJSONData, get_chain_colors, renameChain, outerContainerStyle, controlsContainerStyle, groupContainerStyle, groupTitleStyle, buttonStyle, selectedButtonStyle } from './js/charts_ecosystem';
import { useDebouncedResize, chartDescriptions } from './js/echart_utils';


const PolkadotStatsEcosystemRace = () => {
  const [chartsConfig, setChartsConfig] = useState([]);
  const [frequency, setFrequency] = useState('monthly');
  const [relayChain, setRelayChain] = useState('polkadot');
  const [chainType, setChainType] = useState('substrate');
  const [metric, setMetric] = useState('accounts_active');
  const [chainOptions, setChainOptions] = useState([]);
  const [topNChains, setTopNChains] = useState(60);
  const [chainMapping, setChainMapping] = useState({});
  const chartRefs = useRef({});
  const { Option } = Select;
  const [selectedChains, setSelectedChains] = useState([]);
  const [hovered, setHovered] = useState({});
  const filePath = 'data/parachains_transactions_monthly000000000000.jsonl';
  

  const showDescription = id => setHovered({ ...hovered, [id]: true });
  const hideDescription = id => setHovered({ ...hovered, [id]: false });


  async function fetchAndProcessJSONLines(filePath, relayChain) {
    try {
      const response = await fetch(filePath);
      if (!response.ok) throw new Error('Network response was not ok.');
      const textData = await response.text();
      const jsonData = textData.trim().split('\n').map(line => JSON.parse(line));
      const { options, mapping } = getUniqueChainsForRelay(jsonData, relayChain);
      setChainOptions(options);
      setChainMapping(mapping); // Store the mapping in state
    } catch (error) {
      console.error('Failed to fetch or process the file:', error);
    }
  }

  function getUniqueChainsForRelay(data, relayChain) {
    const uniqueChains = new Set();
    const chainMapping = {};

    data.forEach(item => {
      if (item.relay_chain.toLowerCase() === relayChain.toLowerCase()) {
        const renamed = renameChain(item.chain);
        uniqueChains.add(renamed);
        chainMapping[renamed.toLowerCase()] = item.chain;
      }
    });

    return {
      options: Array.from(uniqueChains).map(chain => ({
        label: chain.charAt(0).toUpperCase() + chain.slice(1),
        value: chain.toLowerCase()
      })),
      mapping: chainMapping
    };
  }

  useDebouncedResize(() => {
    Object.values(chartRefs.current).forEach(chart => {
      if (chart) chart.resize();
    });
  }, 100);

  const createEchartElement = (elementId) => {
    const container = document.getElementById(elementId);
    if (!container) return;

    const existingChart = echarts.getInstanceByDom(container);
    if (existingChart) {
      echarts.dispose(existingChart);
    }

    chartRefs.current[elementId] = echarts.init(container, null, { renderer: 'svg' });
  };

  const loadAndUpdateCharts = () => {
    chartsConfig.forEach(async (config) => {
      const data = await loadJSONData(config.jsonPath, config.relay, selectedChains || []);
      if (data && chartRefs.current[config.elementId]) {
        config.build(chartRefs.current[config.elementId], config, data);
      }
    });
  };

  const renderControls = () => {
    return (
      <div style={outerContainerStyle}>
        <div style={controlsContainerStyle}>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Relay Chain</div>
            <div>
              <button style={relayChain === 'polkadot' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('polkadot')}>
                Polkadot
              </button>
              <button style={relayChain === 'kusama' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('kusama')}>
                Kusama
              </button>
              <button style={relayChain === 'solo' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('solo')}>
                Solo
              </button>
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Frequency</div>
            <div>
              <button style={frequency === 'daily' ? selectedButtonStyle : buttonStyle} onClick={() => setFrequency('daily')}>
                Daily
              </button>
              <button style={frequency === 'monthly' ? selectedButtonStyle : buttonStyle} onClick={() => setFrequency('monthly')}>
                Monthly
              </button>
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Chain Type</div>
            <div>
              <button style={chainType === 'substrate' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('substrate')}>
                Substrate
              </button>
              {metric !== 'events' && (
                <>
                  <button style={chainType === 'evm' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('evm')}>
                    EVM
                  </button>
                  <button style={chainType === 'combined' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('combined')}>
                    Combined
                  </button>
                </>
              )}
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Metric</div>
            <div>
              <button style={metric === 'accounts_active' ? selectedButtonStyle : buttonStyle} onClick={() => setMetric('accounts_active')}>
                Active Accounts
              </button>
              <button style={metric === 'transactions' ? selectedButtonStyle : buttonStyle} onClick={() => setMetric('transactions')}>
                Transactions
              </button>
              {chainType !== 'evm' && (
                <button style={metric === 'events' ? selectedButtonStyle : buttonStyle} onClick={() => setMetric('events')}>
                  Events
                </button>
              )}
            </div>
          </div>
        </div>
        <div style={{ ...groupContainerStyle, marginTop: '10px', width: '100%' }}>
          <div style={groupTitleStyle}>Chains</div>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Select chains"
            defaultValue={[]}
            onChange={(value) => setSelectedChains(value)}
            options={chainOptions}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadAndUpdateCharts = async () => {
      try {
        // Fetch unique chains for the initial relay chain
        await fetchAndProcessJSONLines(filePath, relayChain);
        
        // Get updated chart configurations
        const updatedChartsConfig = updateChartsConfig({ freq: frequency, relay: relayChain, y: chainType, metric, topN: topNChains });
        
        // Set the new charts config
        setChartsConfig(updatedChartsConfig);

        // Ensure charts are disposed and created after state update
        setTimeout(() => {
          updatedChartsConfig.forEach(config => {
            const container = document.getElementById(config.elementId);
            if (container) {
              const existingChart = echarts.getInstanceByDom(container);
              if (existingChart) {
                existingChart.dispose();
              }
              createEchartElement(config.elementId);
            }
          });

          // Load data and update charts
          updatedChartsConfig.forEach(async config => {
            try {
                const data = await loadJSONData(config.jsonPath, config.relay, selectedChains.map(chain => chainMapping[chain.toLowerCase()] || chain));              if (data && chartRefs.current[config.elementId]) {
                config.build(chartRefs.current[config.elementId], config, data);
              }
            } catch (dataError) {
              console.error(`Error loading data for ${config.elementId}:`, dataError);
            }
          });
        }, 0);
      } catch (error) {
        console.error('Error in loadAndUpdateCharts:', error);
      }
    };

    loadAndUpdateCharts();
  }, [relayChain, frequency, chainType, metric, selectedChains]);

  return (
    <div>
      {renderControls()}
      <div className="website-grid-container">
        {chartsConfig.map((config) => (
          <div
            key={config.elementId}
            style={{ 
              border: '1px solid #ddd', 
              borderRadius: '10px', 
              padding: '10px', 
              backgroundColor: '#fff', 
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              position: 'relative', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}
          >
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <h2 style={{ margin: 0, marginRight: '10px' }}>{config.title.replace('(Substrate + EVM)', '').trim()}</h2>
              <InfoCubeIconComponent/>
              {/* <InfoCubeIconComponent onMouseEnter={() => showDescription(config.elementId)} onMouseLeave={() => hideDescription(config.elementId)} /> */}

            </div>
            <div id={config.elementId} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
            {hovered[config.elementId] && (
              <div style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                fontSize: '14px',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.75)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '50%',
                textAlign: 'full',
                boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
              }}>
                {chartDescriptions[config.elementId]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function parachainsRace(chart, config, data) {
  const chainColors = get_chain_colors(config.relay);
  const title = config.title;
  const yName = config.yName;
  const xName = config.xName;
  const currentTheme = localStorage.getItem('theme') || 'light';
  const speed = xName === 'month' ? 1000 : 150;
  
  const parsedData = data.map(d => ({
    timestamp: d[xName],
    chain: d.chain,
    value: parseFloat(d[yName])
  }));
  
  const cumulativeData = {};
  const allChains = [...new Set(parsedData.map(item => item.chain))];
  const cumulativeValues = {};
  
  allChains.forEach(chain => {
    cumulativeValues[chain] = 0;
  });
  
  parsedData.forEach(item => {
    const { timestamp, chain, value } = item;
    if (!cumulativeData[timestamp]) {
      cumulativeData[timestamp] = {};
    }
    cumulativeValues[chain] += value;
    cumulativeData[timestamp][chain] = cumulativeValues[chain];
  });
  
  const timestamps = [...new Set(parsedData.map(item => item.timestamp))].sort();
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: xName === 'month' ? undefined : '2-digit',
  });
  
  const formattedTimestamps = timestamps.map(timestamp => dateFormatter.format(new Date(timestamp)));
  
  const chartData = timestamps.map(timestamp => {
    const dataAtTimestamp = { timestamp, formattedTimestamp: dateFormatter.format(new Date(timestamp)) };
    allChains.forEach(chain => {
      dataAtTimestamp[chain] = cumulativeData[timestamp][chain] || 0;
    });
    return dataAtTimestamp;
  });
  
  const option = {
    timeline: {
      axisType: 'category',
      playInterval: speed,
      autoPlay: true,
      loop: false,
      controlStyle: {
        show: false
      },
      data: formattedTimestamps
    },
    options: chartData.map(d => {
      const sortedChains = allChains.map(chain => ({
        chain,
        value: d[chain] || 0
      })).sort((a, b) => b.value - a.value).slice(0, 10);
  
      const categories = sortedChains.map(item => item.chain);
      const seriesData = sortedChains.map(item => ({
        value: item.value,
        name: item.chain,
        itemStyle: {
          color: chainColors[item.chain] || chainColors['other'],
          opacity: 0.8
        },
        label: {
          show: true,
          position: 'right',
          formatter: () => {
            return `{bold|${item.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}}`;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: 16,
            },
            normal: {
              fontWeight: 'normal',
              fontSize: 16,
            }
          }
        }
      }));
  
      return {
        title: {
          text: `${d.formattedTimestamp}`,
          left: 'center',
          textStyle: {
            fontFamily: 'Unbounded',
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        animationDuration: speed,
        animationDurationUpdate: speed,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        // tooltip: {
        //   trigger: 'axis',
        //   backgroundColor: 'rgba(50,50,50,0.7)',
        //   formatter: function (params) {
        //     let tooltipText = `Timestamp: ${d.formattedTimestamp}<br/>`;
        //     params.forEach(param => {
        //       tooltipText += `${param.seriesName}: ${param.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}<br/>`;
        //     });
        //     return tooltipText;
        //   }
        // },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              return value.toLocaleString(userLocale);
            }
          }
        },
        yAxis: {
          type: 'category',
          data: categories,
          inverse: true
        },
        series: [{
          type: 'bar',
          animationDuration: speed,
          animationEasing: 'linear',
          data: seriesData
        }]
      };
    })
  };
  
  chart.setOption(option, true);
}

function updateChartsConfig(config) {
  if (config.y === 'substrate') {
    config.title2 = "(Substrate Only)";
  } else if (config.y === 'evm') {
    config.title2 = "(EVM Only)";
  } else {
    config.title2 = "(Substrate + EVM)";
  }

  let race_path, race_yName, race_title;
  if (config.metric === 'accounts_active') {
    race_path = `data/parachains_accounts_active_${config.freq}000000000000.jsonl`;
    race_yName = config.y === 'combined' ? 'combined' : `num_accounts_active_${config.y}`;
    race_title = `Active Accounts ${config.title2}`;
  } else if (config.metric === 'transactions') {
    race_path = `data/parachains_transactions_${config.freq}000000000000.jsonl`;
    race_yName = config.y === 'combined' ? 'combined' : `num_transactions_${config.y}`;
    race_title = `Transactions ${config.title2}`;
  } else {
    race_path = `data/parachains_events_${config.freq}000000000000.jsonl`;
    race_yName = 'num_events';
    race_title = `Cumulative Events ${config.title2}`;
  }

  return [
    createRaceChartConfig('general_parachains_race', race_path, config, race_yName, race_title)
  ];
}

function createRaceChartConfig(elementId, jsonPath, config, yName, title) {
  return {
    elementId: elementId,
    jsonPath: jsonPath,
    freq: config.freq,
    relay: config.relay,
    xName: config.freq === 'monthly' ? 'month' : 'date',
    yName: yName,
    title: title,
    build: parachainsRace
  };
}

export default PolkadotStatsEcosystemRace;
