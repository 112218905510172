
import React from 'react';
// import Header from './components/Header';
// import Sidebar from './components/Sidebar';
import PolkadotJSLiveViewComponent from './components/PolkadotJSLiveViewComponent';
import DynamicContent from './components/DynamicContent';

function PolkadotJSC() {
  return (
    <div className="App" style={{ display: 'flex', minHeight: '100vh' }}>
      {/* <Sidebar /> */}
      <div style={{ flex: 1, padding: '20px' }}>
        {/* <Header /> */}
        <PolkadotJSLiveViewComponent />
        {/* <PolkadotStatsGeneral /> */}
      </div>
    </div>
  );
}

export default PolkadotJSC;