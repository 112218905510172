import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the typing effect
const typing = keyframes`
  from { width: 0; }
  to { width: 75%; }
`;

// Keyframes for the blinking caret
const blinkCaret = keyframes`
  from, to { border-color: transparent; }
  50% { border-color: black; }
`;

// Styled component for the TypingAnimation
const TypingText = styled.h1`
  overflow: hidden;
  font-size: 1.7em;
  white-space: nowrap;
  width: '75%';
  position: relative; /* Positioning for the caret */
  color: #E6007A; /* Hide the text initially */
  animation: ${typing} 3.5s steps(4000, end) forwards;

  @media (max-width: 700px) {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  @media (max-width: 500px) {
    font-size: 1.2em; /* Adjust font size for even smaller screens */
  }

  &::after {
    // content: '|';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: '75%';
    color: #E6007A; /* Color */
    background-color: transparent; /* Initial color to match keyframes */
    animation: ${blinkCaret} 1.75s step-end infinite;
  }
`;

// Functional component for TypingAnimation
const TypingAnimation = ({ text, onComplete }) => {
  useEffect(() => {
    // Timer to handle completion of typing animation
    const timer = setTimeout(() => {
      onComplete(); // Trigger the completion callback after the animation duration
    }, 6000); // Adjusted to match the duration of the typing animation

    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts or updates
  }, [onComplete, text]); // Re-run the effect if text or onComplete changes

  return <TypingText>{text}</TypingText>;
};

export default TypingAnimation;
