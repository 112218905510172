import React from 'react';
import './css/main.css'; // Ensure the CSS path is correct
import Footer from './components/Footer'; // Ensure this is a properly implemented component

function PolkadotTeam() {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <h1>The Team</h1>
                <h2> Follow Dotlake on X for updates
                     
                    <a href="https://twitter.com/dotlake_xyz" style={styles.twitterLink}>@dotlake_xyz</a>
                </h2>
            </header>
            <hr style={styles.hr} />

            <div style={styles.teamGrid}>
                {teamMembers.map(member => (
                    <div key={member.name} style={styles.card}>
                        <div style={styles.cardImage}>
                            <img src={member.image} alt={member.name} style={styles.image} />
                        </div>
                        <div style={styles.cardBody}>
                            <h3 style={styles.name}>{member.name}</h3>
                            <p style={styles.role}>{member.role}</p>
                            <a href={member.twitter} target="_blank" rel="noopener noreferrer" style={styles.twitterLink}>
                                {member.twitterHandle}
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <Footer />
        </div>
    );
}

const teamMembers = [
    {
        name: 'Karim Jedda',
        role: 'Lead Data Engineer',
        image: 'assets/eoyr/karim.png',
        twitter: 'https://twitter.com/KarimJDDA',
        twitterHandle: '@KarimJDDA'
    },
    {
        name: 'Pavla Mijic',
        role: 'Data Platform Lead',
        image: 'assets/eoyr/pavla.jpg',
        twitter: 'https://twitter.com/daemia10',
        twitterHandle: '@daemia10'
    },
    {
        name: 'Ollie C.',
        role: 'Senior Data Analyst',
        image: 'assets/eoyr/Ollie.jpeg',
        twitter: '', // Assuming no Twitter handle available
        twitterHandle: ''
    },
    {
        name: 'Will S.',
        role: 'Data Analyst',
        image: 'assets/eoyr/william.png',
        twitter: '', // Assuming no Twitter handle available
        twitterHandle: ''
    },
    {
        name: 'Roger J. Bos',
        role: 'Data Analyst',
        image: 'assets/eoyr/roger.jpeg',
        twitter: 'https://twitter.com/rogerjbos',
        twitterHandle: '@RogerJBos'
    }
];

const styles = {
    container: {
        maxWidth: '1200px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },
    header: {
        textAlign: 'left',
        marginBottom: '20px'
    },
    subHeader: {
        fontWeight: 'normal',
        color: '#666'
    },
    twitterLink: {
        marginLeft: '10px',
        color: '#1DA1F2'
    },
    hr: {
        margin: '20px 0'
    },
    teamGrid: {
        display: 'flex',
        justifyContent: 'space-around', // Adjusted for even spacing
        flexWrap: 'nowrap', // Ensures no wrapping to the next line
        overflowX: 'auto', // Allows horizontal scrolling on smaller screens
        paddingLeft: '10px', // Added padding to prevent cutting off the first card
        paddingRight: '0px' // Added padding for symmetry
    },
    card: {
        minWidth: '220px', // Ensures cards have a minimum width
        margin: '10px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
    },
    cardImage: {
        width: '100%',
        height: '220px'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    cardBody: {
        padding: '15px',
        textAlign: 'center'
    },
    name: {
        fontSize: '18px',
        margin: '10px 0 5px'
    },
    role: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '10px'
    }
};

export default PolkadotTeam;
