import React from 'react';
import ContentBlock from './ContentBlock';
import GraphBlock from './GraphBlock';

const DynamicContent = ({ type, content }) => {
  switch (type) {
    case 'text':
      return <ContentBlock content={content} />;
    case 'graph':
      return <GraphBlock data={content} />;
    default:
      return <ContentBlock content="Default content" />;
  }
};

export default DynamicContent;