// import React from 'react';
// import styled from 'styled-components';
// import './css/main.css'; // Make sure this CSS file includes modern, responsive design principles
// import watermarkImage from './assets/dotlake-transparent-high-quality.png';
// import Navbar from './components/Navbar';
// import ContentSection from './components/ContentSection';
// import ImageContainer from './components/ImageContainer';
// import Footer from './components/Footer';
// import InteractiveCube from './components/InteractiveCube';

// // Utilizing CSS Grid for layout for better control and responsiveness
// const MainContainer = styled.main`
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: auto 1fr auto;
//   grid-gap: 20px;
//   padding: 20px;
//   min-height: 100vh;
//   background-color: #f4f4f4;
//   box-sizing: border-box;
// `;

// const ContentSectionsContainer = styled.section`
//   display: grid;
//   grid-gap: 20px;
// `;

// const StyledInteractiveCube = styled(InteractiveCube)`
//   justify-self: center;
//   max-width: 500px; // Example style adjustment
// `;

// const ParityDataDashboards = () => {
//   return (
//     <>
//       <Navbar /> {/* Ensured Navbar is uncommented and part of the layout */}
//       <MainContainer>
//         <ContentSectionsContainer>
//           <ContentSection title="Welcome to Parity Data Dashboards">
//             At Parity Data, we're dedicated to enhancing understanding and transparency within the Polkadot Ecosystem through robust data analytics and visualizations. We're committed to fostering an environment where information is accessible and available to all.
//           </ContentSection>
//           <ContentSection title="What to expect">
//             We are continually adding to our collection of data visualizations for the Polkadot Ecosystem on this website. Please use the tabs at the top to navigate to which data category you would like to explore. For example, check out the <a href="./staking.html">staking</a> tab for metrics relating to the shared security of the network or head to the <a href="./eoyr.html">monthly report</a> tab for an in-depth analysis on a number of statistics along with explanatory information on the Polkadot protocol.
//           </ContentSection>
//         </ContentSectionsContainer>
//         <StyledInteractiveCube />
//       </MainContainer>
//       <ImageContainer src={watermarkImage} alt="Blockchain Analytics Visualization" className="small-bottom-left" />
//       <Footer /> {/* Ensure Footer is part of the layout for completeness */}
//     </>
//   );
// };

// export default ParityDataDashboards;




// import React from 'react';
// import styled from 'styled-components';
// // import React from 'react';
// // import './css/main.css'; // Ensure this imports ContentSection.css for content section styling
// // import watermarkImage from './assets/dotlake-transparent-high-quality.png';
// import Navbar from './components/Navbar';
// import ContentSection from './components/ContentSection';
// import ImageContainer from './components/ImageContainer';
// import Footer from './components/Footer'; // Assuming you have a Footer component
// import InteractiveCube from './components/InteractiveCube';
// // import { Spacer } from './components/Spacer';

// const HomepageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
// `;

// const HeroSection = styled.div`
//   width: 100%;
// `;

// const DashboardSection = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: start;
//   width: 100%;
//   padding: 20px;
// `;

// const DataVisualizationSection = styled.div`
//   flex: 1;
//   margin-right: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const ParityDataDashboards = () => {
//   return (
//     <HomepageContainer>
//       <Navbar />
//       <HeroSection>
//         <ContentSection title="Welcome to Parity Data Dashboards">
//           At Parity Data, we're dedicated to enhancing understanding and transparency within the Polkadot Ecosystem through robust data analytics and visualizations. We're committed to fostering an environment where information is accessible and available to all.
//         </ContentSection>
//       </HeroSection>
//       {/* <Spacer height={2} /> */}
//       <DashboardSection>
//         <ContentSection title="What to expect">
//           We are continually adding to our collection of data visualizations for the Polkadot Ecosystem on this website. Please use the tabs at the top to navigate to which data category you would like to explore. For example, check out the <a href="./staking.html">staking</a> tab for metrics relating to the shared security of the network or head to the <a href="./eoyr.html">monthly report</a> tab for an in-depth analysis on a number of statistics along with explanatory information on the Polkadot protocol.
//         </ContentSection>
//         <DataVisualizationSection>
//           <InteractiveCube />
//         </DataVisualizationSection>
//       </DashboardSection>
//       <Footer />
//     </HomepageContainer>
//   );
// };

// export default ParityDataDashboards;


////very nice version
// import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import './css/main.css';
// import watermarkImage from './assets/dotlake-transparent-high-quality.png';
// import Navbar from './components/Navbar';
// import HeroSection from './components/HeroSection';
// import HeroSectionSmallHeight from './components/HeroSectionSmallHeight';
// import ScrollAnimationContainer from './components/ScrollAnimationContainer';
// import ModularContentSection from './components/ModularContentSection';
// import InteractiveFooter from './components/InteractiveFooter';
// import InteractiveCube from './components/InteractiveCube';

// const fadeIn = keyframes`
//   from { opacity: 0; }
//   to { opacity: 1; }
// `;

// const typing = keyframes`
//   from { width: 10; }
//   to { width: 55%; }
// `;

// const blinkCaret = keyframes`
//   from, to { opacity: 1; }
//   50% { opacity: 0; }
// `;

// const MainContainer = styled.div`
//   animation: ${fadeIn} 2s ease-out;
//   overflow-x: hidden;
//   font-family: 'Roboto', sans-serif;
// `;

// const HeroSectionWithFlex = styled(HeroSection)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const HeroSectionSmallHeightWithFlex = styled(HeroSectionSmallHeight)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const TypingAnimation = styled.h1`
//   /* Add the typing-specific styles */
//   overflow: hidden;
//   font-size: 2em;
//   white-space: nowrap;
//   // border-right: 3px solid black; /* Adjust if your h1 style has a different cursor color */
//   width: 0;
//   position: relative; /* Positioning for the caret */
//   color: white; /* Hide the text */
//   animation: ${typing} 1.5s steps(1000, end) forwards; /* Typing animation */

//   /* Media query for smaller devices */
//   @media (max-width: 700px) {
//     font-size: 1.5em; /* Adjust font size for smaller screens */
//   }

//   /* Media query for even smaller devices */
//   @media (max-width: 500px) {
//     font-size: 1.2em; /* Adjust font size for even smaller screens */
//   }

//   /* Caret styling */
//   &::after {
//     content: '|'; /* Use '|' for caret */
//     position: absolute; /* Absolute positioning */
//     top: 50%; /* Align caret to the middle */
//     transform: translateY(-50%); /* Center caret vertically */
//     color: white; /* Color */
//     animation: ${blinkCaret} 0.75s step-end infinite; /* Blinking animation */
//   }
// `;

// const ParityDataDashboards = () => {
//   return (
//     <>
//       <MainContainer>
//       <HeroSectionSmallHeightWithFlex>
//           <TypingAnimation>Welcome to Parity Data Dashboards...</TypingAnimation>
//         </HeroSectionSmallHeightWithFlex>
//         <HeroSectionWithFlex> 
//           <InteractiveCube />
//         </HeroSectionWithFlex>
//         <HeroSectionSmallHeightWithFlex> 
//         </HeroSectionSmallHeightWithFlex>
//         <ScrollAnimationContainer triggerPoint="100px">
//           <ModularContentSection title="Our Vision">
//             <p>At Parity Data, we're dedicated to enhancing understanding and transparency within the Polkadot Ecosystem through robust data analytics and visualizations. We're committed to fostering an environment where information is accessible and available to all.</p>
//           </ModularContentSection>
//           <ModularContentSection title="Explore Our Dashboards">
//             <p>We are continually adding to our collection of data visualizations for the Polkadot Ecosystem on this website. Please use the tabs at the top to navigate to which data category you would like to explore. For example, check out the <a href="./staking.html">staking</a> tab for metrics relating to the shared security of the network or head to the <a href="./eoyr.html">monthly report</a> tab for an in-depth analysis on a number of statistics along with explanatory information on the Polkadot protocol.</p>
//           </ModularContentSection>
//           <InteractiveFooter backgroundImage={watermarkImage} />
//         </ScrollAnimationContainer>
//       </MainContainer>
//     </>
//   );
// };

// export default ParityDataDashboards;


import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import './css/main.css';
import watermarkImage from './assets/dotlake-transparent-high-quality.png';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import HeroSectionSmallHeight from './components/HeroSectionSmallHeight';
import ContentSection from './components/ContentSection';
import ScrollAnimationContainer from './components/ScrollAnimationContainer';
import ModularContentSection from './components/ModularContentSection';
import InteractiveFooter from './components/InteractiveFooter';
import InteractiveCube from './components/InteractiveCube';
import InteractiveSphere from './components/InteractiveSphere'
import TypingAnimation from './components/TypingAnimation';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const blinkCaret = keyframes`
  from, to { opacity: 1; }
  50% { opacity: 0; }
`;

const MainContainer = styled.div`
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
`;

const HeroSectionWithFlex = styled(HeroSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeroSectionSmallHeightWithFlex = styled(HeroSectionSmallHeight)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ParityDataDashboards = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentTyping, setCurrentTyping] = useState(0);
  const messages = [
    "Welcome to DotLake Dash...",
    "Explore our Resources...",
    // "Learn about Substrate and EVM Data...",
    // "Discover the Polkadot Network...",
    "Unlock Insights with Polkadot Data"
  ];

  const handleTypingComplete = () => {
    setCurrentTyping((prev) => (prev + 1) % messages.length);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <MainContainer>
        <HeroSectionSmallHeightWithFlex>
          <TypingAnimation key={currentTyping} text={messages[currentTyping]} onComplete={handleTypingComplete} />
        </HeroSectionSmallHeightWithFlex>
        <HeroSectionWithFlex>
          <InteractiveCube />
        </HeroSectionWithFlex>
        <ScrollAnimationContainer triggerPoint="50px">
          <ContentSection title="Our Vision">
            <p>At Parity Data, we're dedicated to enhancing understanding and transparency within the Polkadot Ecosystem through robust data analytics and visualizations. We're committed to fostering an environment where information is accessible and available to all.</p>
          </ContentSection>
          <ContentSection title="Explore Our Dashboards">
            <p>We are continually adding to our collection of data visualizations for the Polkadot Ecosystem on this website. Please use the tabs on the left side-bar to navigate to which data category you would like to explore. For example, check out the staking tab for metrics relating to the shared security of the network or head to the monthly report tab for an in-depth analysis on a number of statistics along with explanatory information on the Polkadot protocol.</p>
          </ContentSection>
          <InteractiveFooter backgroundImage={watermarkImage} />
        </ScrollAnimationContainer>
      </MainContainer>
    </>
  );
};

export default ParityDataDashboards;
