import React, { useState, useEffect } from 'react';

const MetricBox = ({ title, description, value, unit, highlightOnChange, children }) => {
  const [highlight, setHighlight] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (highlightOnChange) {
      setHighlight(true);
      const timer = setTimeout(() => setHighlight(false), 500); // Highlight for half a second
      return () => clearTimeout(timer);
    }
  }, [value, highlightOnChange]);

  const handleMouseEnter = () => setShowInfo(true);
  const handleMouseLeave = () => setShowInfo(false);

  return (
    <div
      className="metric-box"
      data-title={title}
      style={{ position: 'relative', marginBottom: '20px' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="title-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
        <strong className={`metric-title ${highlight ? 'updated' : ''}`} style={{ marginRight: '8px' }}>
          {/* Title is handled by ::before */}
        </strong>
      </div>
      <strong className={`metric-value ${highlight ? 'updated' : ''}`}>
        {value} {unit}
      </strong>
      {children && <div className="metric-children">{children}</div>}
      {showInfo && (
        <div className="tooltip">
          {description}
        </div>
      )}
    </div>
  );
};

export default MetricBox;
