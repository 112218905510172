import React from 'react';
import './css/main.css'; 
import Footer from './components/Footer';

function PolkadotDatasets() {
  return (
    <div className="container" style={styles.container}>
      {/* <header style={styles.header}>
        <h1 className="title">Datasets</h1>
        <h3 className="subtitle">
          Explore the extensive data repositories we offer for the Polkadot ecosystem.
        </h3>
      </header> */}
      <main style={styles.mainContent}>
      <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Dotlake</h2>
          <h3 style={styles.sectionSubtitle}>
            Analytical dataset maintained by the <strong>Parity Data Team</strong> on the Google Cloud Platform. Currently featuring <strong>70+ ecosystem chains</strong> as well as off-chain data, ready to be queried. Reach out to us for questions or requests for data; we'll roll it out in 2024 for the ecosystem to use.
          </h3>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Polkadot Ecosystem Data Sources</h2>
          <h3 style={styles.sectionSubtitle}>
            Below we compile all the places you can get data for the Polkadot ecosystem. For now, we'll add analytical datasets and we'll extend them to indexers and more - reach out if you want us to add yours.
          </h3>
          <div style={styles.imageRow}>
            <img src="assets/eoyr/dotlake_partition_1.png" alt="Partitioned DotLake blocks" style={styles.image} />
            <img src="assets/eoyr/dotlake_partition_2.png" alt="Partitioned DotLake blocks" style={styles.image} />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

const styles = {
  container: {
    padding: '40px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  header: {
    textAlign: 'left',
    marginBottom: '50px'
  },
  mainContent: {
    paddingTop: '20px'
  },
  section: {
    marginBottom: '40px'
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '15px'
  },
  sectionSubtitle: {
    fontSize: '18px',
    color: '#666',
    lineHeight: '1.6',
    marginBottom: '20px'
  },
  imageRow: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px'
  },
  image: {
    width: '48%',
    height: 'auto',
    borderRadius: '8px'
  }
};

export default PolkadotDatasets;
