// import React, { useRef, Suspense } from 'react';
// import { Canvas, useFrame, extend } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls } from '@react-three/drei';
// import { Environment, MeshReflectorMaterial } from '@react-three/drei';
// import clearPuresky from '../assets/clear_puresky_1k.hdr'; // Import the HDR file

// // Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
// extend({ OrbitControls });

// const Cube = () => {
//   const meshRef = useRef();
//   const [active, setActive] = React.useState(false);

//   const props = useSpring({
//     scale: active ? 2 : 1.5,
//     color: active ? '#E6007A' : 'hotpink',
//   });

//   useFrame(() => {
//     meshRef.current.rotation.y += 0.004;
//   });

//   return (
//     <a.mesh
//       ref={meshRef}
//       scale={props.scale}
//       onClick={() => setActive(!active)}
//       onPointerOver={() => setActive(true)}
//       onPointerOut={() => setActive(false)}>
//       <boxGeometry args={[2, 2, 2]} />
//       <a.meshStandardMaterial attach="material" color={props.color} metalness={0.5} roughness={0.1} />
//     </a.mesh>
//   );
// };

// const InteractiveCube = () => {
//   return (
//     <Canvas style={{ height: '600px', width: '100%' }} camera={{ position: [0, 0, 5], fov: 60 }}>
//       <Suspense fallback={null}>
//         <ambientLight intensity={0.3} />
//         <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={2} />
//         <directionalLight position={[-10, -10, -10]} intensity={1} />
//         <Cube />
//         <Environment files={clearPuresky}  background={false} /> {/* This will give us a nice environment background */}
//         <OrbitControls /> {/* This enables mouse interaction: pan, zoom, rotate */}
//       </Suspense>
//     </Canvas>
//   );
// };

// export default InteractiveCube;



//Makes a cube space out with particles
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment } from '@react-three/drei';
// import clearPuresky from '../assets/clear_puresky_1k.hdr';
// import * as THREE from 'three';

// const SmallCube = ({ position, targetPosition }) => {
//   const meshRef = useRef();
//   const [active, setActive] = useState(false);
//   const { positionSpring, rotationSpring } = useSpring({
//     positionSpring: active ? targetPosition : position,
//     rotationSpring: active ? [0, 0, 0] : [Math.PI / 4, Math.PI / 4, Math.PI / 4], // Targeting zero rotation
//     config: { mass: 5, tension: 100, friction: 120 },
//     onRest: () => {
//       setActive(!active); // Toggle the active state to restart the animation
//     },
//   });

//   useFrame(() => {
//     if (!active) {
//       // This keeps the cube rotating before it moves
//       meshRef.current.rotation.y += 0.01;
//     }
//   });

//   useEffect(() => {
//     setActive(true); // Initially trigger the movement towards the target position

//     return () => {
//       // Cleanup to prevent the state update on an unmounted component
//       clearTimeout(); // Clear the timeout when the component unmounts
//     };
//   }, [targetPosition]);

//   return (
//     <a.mesh
//       ref={meshRef}
//       position={positionSpring}
//       rotation={rotationSpring} // Apply animated rotation
//     >
//       <boxGeometry args={[0.3, 0.3, 0.3]} />
//       <meshStandardMaterial color={"#E6007A"} />
//     </a.mesh>
//   );
// };

// const Particles = ({ count = 5000 }) => {
//   const meshRef = useRef();
//   const positions = useMemo(() => {
//     const pos = new Float32Array(count * 3);
//     for (let i = 0; i < count; i++) {
//       pos[i * 3] = (Math.random() - 0.5) * 10;
//       pos[i * 3 + 1] = (Math.random() - 0.5) * 10;
//       pos[i * 3 + 2] = (Math.random() - 0.5) * 10;
//     }
//     return pos;
//   }, [count]);

//   useEffect(() => {
//     if (meshRef.current) {
//       const geometry = meshRef.current.geometry;
//       geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
//     }
//   }, [positions]);

//   useFrame(() => {
//     const time = Date.now() * 0.0003;
//     const geometry = meshRef.current.geometry;
//     const pos = geometry.attributes.position.array;

//     for (let i = 0; i < pos.length; i += 3) {
//       pos[i] += Math.sin(time + pos[i]) * 0.001;
//       pos[i + 1] += Math.cos(time + pos[i + 1]) * 0.001;
//       pos[i + 2] += Math.sin(time + pos[i + 2]) * 0.001;
//     }
//     geometry.attributes.position.needsUpdate = true;
//   });

//   return (
//     <points ref={meshRef}>
//       <bufferGeometry attach="geometry" />
//       <pointsMaterial attach="material" size={0.005} color="#E6007A" sizeAttenuation={true} transparent opacity={0.6} />
//     </points>
//   );
// };

// const calculateInitialPositions = (count) => {
//   let positions = [];
//   for (let i = 0; i < count; i++) {
//     positions.push([Math.random() * 5 - 2.5, Math.random() * 5 - 2.5, Math.random() * 5 - 2.5]);
//   }
//   return positions;
// };

// const calculateTargetPositions = () => {
//   const side = 3; // Number of cubes per side
//   const spacing = 0.35; // Space between cubes to avoid overlap
//   const start = -(side - 1) / 2 * spacing; // Starting position to center the cube at the origin
//   let positions = [];

//   for (let x = 0; x < side; x++) {
//     for (let y = 0; y < side; y++) {
//       for (let z = 0; z < side; z++) {
//         positions.push([
//           start + x * spacing,
//           start + y * spacing,
//           start + z * spacing,
//         ]);
//       }
//     }
//   }

//   return positions;
// };

// const InteractiveCube = () => {
//   const initialPositions = calculateInitialPositions(27);
//   const targetPositions = calculateTargetPositions();

//   return (
//     <Canvas style={{ height: '600px', width: '100%' }} camera={{ position: [0, -1, 1], fov: 60 }}>
//       <ambientLight intensity={0.3} />
//       <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={2} />
//       <directionalLight position={[-10, -10, -10]} intensity={1} />
//       {initialPositions.map((position, i) => (
//         <SmallCube key={i} position={position} targetPosition={targetPositions[i % targetPositions.length]} />
//       ))}
//       <Particles count={8000} />
//       <Environment files={clearPuresky} background={false} />
//       <OrbitControls />
//     </Canvas>
//   );
// };


// export default InteractiveCube;




// //crazy spring action
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment } from '@react-three/drei';
// import * as THREE from 'three';
// import clearPuresky from '../assets/clear_puresky_1k.hdr';

// const SmallCube = ({ position, targetPosition }) => {
//   const meshRef = useRef();
//   const [active, setActive] = useState(true); // Start active for initial animation
//   const { positionSpring } = useSpring({
//     positionSpring: targetPosition,
//     config: { mass: 5, tension: 200, friction: 50 },
//     onRest: () => setActive(false),
//   });

//   useFrame(() => {
//     if (!active) {
//       meshRef.current.rotation.y += 0.01;
//     }
//   });

//   return (
//     <a.mesh ref={meshRef} position={positionSpring.to((x, y, z) => [x, y, z])}>
//       <boxGeometry args={[0.3, 0.3, 0.3]} />
//       <meshStandardMaterial color={"#E6007A"} />
//     </a.mesh>
//   );
// };

// const calculateCubePositions = (side, spacing) => {
//   let positions = [];
//   const start = -(side - 1) / 2 * spacing;
//   for (let x = 0; x < side; x++) {
//     for (let y = 0; y < side; y++) {
//       for (let z = 0; z < side; z++) {
//         positions.push([start + x * spacing, start + y * spacing, start + z * spacing]);
//       }
//     }
//   }
//   return positions;
// };

// const useDynamicBlocks = (initialPositions, updateFrequency) => {
//   const [positions, setPositions] = useState(initialPositions);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Example: Add a new block at a random position
//       const newPosition = [
//         (Math.random() - 0.5) * 10,
//         (Math.random() - 0.5) * 10,
//         (Math.random() - 0.5) * 10,
//       ];
//       setPositions((currentPositions) => [...currentPositions, newPosition]);
//     }, updateFrequency);

//     return () => clearInterval(interval);
//   }, [updateFrequency]);

//   return positions;
// };

// const calculateCirclePositions = (count, radius, spacing) => {
//   let positions = [];
//   for (let i = 0; i < count; i++) {
//     const angle = (i / count) * Math.PI * 2;
//     const x = Math.cos(angle) * radius;
//     const y = Math.sin(angle) * radius;
//     // Use the spacing parameter to spread out cubes over the Z-axis
//     const z = (i - count / 2) * spacing;
//     positions.push([x, y, z]);
//   }
//   return positions;
// };

// const useExpandingCube = (updateFrequency, initialSize, spacing) => {
//   const [blocks, setBlocks] = useState([]);

//   useEffect(() => {
//     let size = initialSize;
//     const interval = setInterval(() => {
//       // Define logic to calculate the next block's position
//       // For simplicity, add blocks in a linear manner. Consider a more complex logic for 3D expansion
//       const nextPosition = [
//         (size % 9) * spacing - 4 * spacing, // Simple linear placement for demonstration
//         Math.floor((size / 9) % 9) * spacing - 4 * spacing,
//         Math.floor(size / 81) * spacing - 4 * spacing,
//       ];
//       setBlocks((currentBlocks) => [...currentBlocks, nextPosition]);
//       size++;
//     }, updateFrequency);

//     return () => clearInterval(interval);
//   }, [updateFrequency, initialSize, spacing]);

//   return blocks;
// };


// const InteractiveCube = () => {
//   const [isCube, setIsCube] = useState(true);
//   const side = 9; // For a 9x9 cube
//   const spacing = 0.5; // Define spacing
//   const cubePositions = calculateCubePositions(side, spacing);
//   const circlePositions = calculateCirclePositions(cubePositions.length, 5, spacing); // Adjust for circle
//   const dynamicCubePositions = useDynamicBlocks(cubePositions, 6000); // Adjust frequency as needed

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIsCube(!isCube);
//     }, 6000); // Toggle shape every 6 seconds
//     return () => clearInterval(interval);
//   }, [isCube]);

//   // Choose the positions array based on the current shape
//   const currentPositions = isCube ? dynamicCubePositions : circlePositions;

//   return (
//     <Canvas style={{ height: '600px', width: '100%' }} camera={{ position: [0, 0, 10], fov: 60 }}>
//       <ambientLight intensity={0.3} />
//       <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={2} />
//       <directionalLight position={[-10, -10, -10]} intensity={1} />
//       {currentPositions.map((position, i) => (
//         <SmallCube key={i} position={position} targetPosition={position} />
//       ))}
//       <Environment files={clearPuresky} background={false} />
//       <OrbitControls />
//     </Canvas>
//   );
// };

// export default InteractiveCube;







// //add one by one cube
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment } from '@react-three/drei';
// import * as THREE from 'three';
// import clearPuresky from '../assets/clear_puresky_1k.hdr';
// import backgroundImage from '../assets/Polkadot_Token_PolkadotToken_Pink.png';


// const SmallCube = ({ position, targetPosition }) => {
//   const meshRef = useRef();
//   const [active, setActive] = useState(true); // Start active for initial animation
//   const { positionSpring } = useSpring({
//     positionSpring: targetPosition,
//     config: { mass: 5, tension: 200, friction: 50 },
//     onRest: () => setActive(false),
//   });

//   useFrame(() => {
//     if (!active) {
//       meshRef.current.rotation.y += 0.01;
//     }
//   });

//   return (
//     <a.mesh ref={meshRef} position={positionSpring.to((x, y, z) => [x, y, z])}>
//       <boxGeometry args={[0.3, 0.3, 0.3]} />
//       <meshStandardMaterial color={"#E6007A"} />
//     </a.mesh>
//   );
// };

// const TransactionParticles = ({ transactionCount }) => {
//   return new Array(transactionCount).fill().map((_, i) => (
//     <mesh
//       key={i}
//       position={[Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5]}
//       // Animate these positions to simulate transactions moving between blocks
//     >
//       <sphereGeometry args={[0.05, 16, 16]} /> {/* Adjusted size to 0.05 */}
//       <meshStandardMaterial color="#E6007A" emissive="#E6007A" />
//     </mesh>
//   ));
// };

// const calculateCubePositions = (side, spacing) => {
//   let positions = [];
//   const start = -(side - 1) / 2 * spacing;
//   for (let x = 0; x < side; x++) {
//     for (let y = 0; y < side; y++) {
//       for (let z = 0; z < side; z++) {
//         positions.push([start + x * spacing, start + y * spacing, start + z * spacing]);
//       }
//     }
//   }
//   return positions;
// };

// const useDynamicBlocks = (initialPositions, updateFrequency) => {
//   const [positions, setPositions] = useState(initialPositions);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Example: Add a new block at a random position
//       const newPosition = [
//         (Math.random() - 0.5) * 10,
//         (Math.random() - 0.5) * 10,
//         (Math.random() - 0.5) * 10,
//       ];
//       setPositions((currentPositions) => [...currentPositions, newPosition]);
//     }, updateFrequency);

//     return () => clearInterval(interval);
//   }, [updateFrequency]);

//   return positions;
// };

// const calculateCirclePositions = (count, radius, spacing) => {
//   let positions = [];
//   for (let i = 0; i < count; i++) {
//     const angle = (i / count) * Math.PI * 2;
//     const x = Math.cos(angle) * radius;
//     const y = Math.sin(angle) * radius;
//     // Use the spacing parameter to spread out cubes over the Z-axis
//     const z = (i - count / 2) * spacing;
//     positions.push([x, y, z]);
//   }
//   return positions;
// };

// const useExpandingCube = (updateFrequency, initialSize, spacing, maxBlocks) => {
//   const [blocks, setBlocks] = useState([]);

//   useEffect(() => {
//     let size = initialSize;
//     const interval = setInterval(() => {
//       if (size < maxBlocks) { // Check if the current size is less than the maximum allowed
//         const nextPosition = [
//           (size % 6) * spacing - 4 * spacing,
//           Math.floor((size / 6) % 6) * spacing - 4 * spacing,
//           Math.floor(size / 36) * spacing - 4 * spacing,
//         ];
//         setBlocks((currentBlocks) => [...currentBlocks, nextPosition]);
//         size++;
//       }
//     }, updateFrequency);

//     return () => clearInterval(interval);
//   }, [updateFrequency, initialSize, spacing, maxBlocks]); // Include maxBlocks in the dependency array

//   return blocks;
// };


// const InteractiveCube = () => {
//   const spacing = 0.45;
//   const maxBlocks = 216; // Set the maximum number of blocks
//   const dynamicBlocks = useExpandingCube(150, 0, spacing, maxBlocks); // Pass maxBlocks as an argument
//   const transactionCount = 10; // Example fixed number of transactions

//   return (
//     <Canvas style={{ height: '600px', width: '100%', background: `url(${backgroundImage})`, backgroundSize: '150px', backgroundPosition: 'top', backgroundRepeat: 'no-repeat'}} camera={{ position: [0, 5, 5], fov: 60 }}>
//       <ambientLight intensity={0.3} />
//       <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={2} />
//       <directionalLight position={[-10, -10, -10]} intensity={1} />
//       {dynamicBlocks.map((position, i) => (
//         <SmallCube key={i} position={position} targetPosition={position} />
//       ))}
//       <TransactionParticles transactionCount={transactionCount} />
//       <Environment files={clearPuresky} background={false} />
//       <OrbitControls />
//     </Canvas>
//   );
// };




// export default InteractiveCube;

//crazy nodes
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment, Stars } from '@react-three/drei';
// import * as THREE from 'three';
// import backgroundImage from '../assets/Polkadot_Token_PolkadotToken_Pink.png';

// // Simplified Block Node Representation
// const BlockNode = ({ position, data }) => {
//   const meshRef = useRef();
//   const [hovered, setHovered] = useState(false);
//   const scale = useMemo(() => hovered ? 1.2 : 1, [hovered]); // Scale up when hovered
//   const color = useMemo(() => hovered ? "#FFFF00" : "#E6007A", [hovered]); // Highlight color on hover

//   useFrame(() => {
//     meshRef.current.rotation.y += 0.01; // Gentle rotation
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
//       <icosahedronGeometry args={[0.5, 1]} /> {/* More complex geometry for visual interest */}
//       <a.meshStandardMaterial color={color} />
//     </a.mesh>
//   );
// };

// // Simplified Transactions Flow
// const TransactionsFlow = ({ count }) => {
//   const meshRef = useRef();
//   const tempObject = new THREE.Object3D();

//   // Generate start and end positions for each particle
//   const transactions = useMemo(() => {
//     const starts = [];
//     const ends = [];
//     for (let i = 0; i < count; i++) {
//       starts.push(new THREE.Vector3((Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10));
//       ends.push(new THREE.Vector3((Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10));
//     }
//     return { starts, ends };
//   }, [count]);

//   // Geometry for the particles
//   const geometry = useMemo(() => new THREE.BufferGeometry(), []);
//   useMemo(() => {
//     const positions = new Float32Array(count * 3);
//     geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
//   }, [count]);

//   // Update function to animate particles
//   useFrame((state, delta) => {
//     const positions = geometry.attributes.position.array;

//     for (let i = 0; i < count; i++) {
//       // Simple linear interpolation for demonstration
//       const t = (Date.now() % 2000) / 2000;
//       const start = transactions.starts[i];
//       const end = transactions.ends[i];
//       tempObject.position.lerpVectors(start, end, t);
//       tempObject.updateMatrix();
//       tempObject.position.toArray(positions, i * 3);
//     }
//     geometry.attributes.position.needsUpdate = true;
//   });

//   return (
//     <points ref={meshRef} geometry={geometry}>
//       <pointsMaterial size={0.1} color={"#FFFF00"} />
//     </points>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   // Use a more sophisticated layout for blockchain visualization
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let i = 0; i < 50; i++) { // Placeholder count
//       positions.push([Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5]);
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas style={{ height: '600px', width: '100%', background: `url(${backgroundImage})`, backgroundSize: '810px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} camera={{ position: [0, 0, 20], fov: 75 }}>
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} data={{/* Block data here */}} />
//       ))}
//       <TransactionsFlow count={200} />
//       <Stars /> {/* Add a starry background for a cosmic feel */}
//       <OrbitControls />
//     </Canvas>
//   );
// };

// export default InteractiveCube;





// // //Use this version
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment, Stars, Line } from '@react-three/drei';
// import * as THREE from 'three';
// import backgroundImage from '../assets/Polkadot_Token_PolkadotToken_Pink.png';

// // Simplified Block Node Representation
// const BlockNode = ({ position, data }) => {
//   const meshRef = useRef();
//   const [hovered, setHovered] = useState(false);
//   const scale = useMemo(() => hovered ? 1.2 : 1, [hovered]);
//   const color = useMemo(() => hovered ? "#E6007A" : "#E6007A", [hovered]);

//   useFrame(() => {
//     meshRef.current.rotation.y += 0.01;
//   });

//   // Add a subtle glow effect
//   const glow = useMemo(() => {
//     const geo = new THREE.SphereGeometry(1, 8, 8);
//     const mat = new THREE.MeshBasicMaterial({ color: color, transparent: false, opacity: 1 });
//     return (
//       <primitive object={new THREE.Points(geo, mat)} scale={[2, 2, 2]} />
//     );
//   }, [color]);

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
//       {glow}
//     </a.mesh>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   // Create a grid of blocks
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -2; x < 2; x++) {
//       for (let y = -2; y < 2; y++) {
//         for (let z = -2; z < 2; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas style={{ height: '500px', 
//     width: '80%', 
//     // background: `url(${backgroundImage})`, 
//     backgroundSize: '600px',
//     backgroundColor: 'transparent', 
//     backgroundPosition: 'center', 
//     backgroundRepeat: 'no-repeat'}} 
//     camera={{ position: [45, 45, 45], fov: 60 }}>
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} data={{/* Block data here */}} />
//       ))}
//       {/* <Stars
//         radius={100} // The radius of the inner sphere
//         depth={50} // Depth of area where stars are placed
//         count={5000} // Number of stars
//         factor={4} // Variance in position in both directions
//         saturation={0} // Saturation 0 means grayscale
//         fade // Stars fade in and out as they rotate
//         color="#E6007A" // Custom color for the stars
//       /> */}
//       <OrbitControls />
//     </Canvas>
//   );
// };

// export default InteractiveCube;




// //neat but not quite there

// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Environment, Stars, Line } from '@react-three/drei';
// import * as THREE from 'three';
// import backgroundImage from '../assets/Polkadot_Token_PolkadotToken_Pink.png';

// // Enhanced Block Node Representation with pulsing glow effect
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();
//   const [hovered, setHovered] = useState(false);
//   const scale = useMemo(() => hovered ? 1.2 : 1, [hovered]);
//   const color = hovered ? "#E6007A" : "#FFFFFF"; // Change to white when not hovered for contrast

//   useFrame(() => {
//     meshRef.current.rotation.y += 0.02; // Speed up rotation for more dynamic effect
//   });

//   // Enhanced glowing effect with pulsation
//   const glow = useMemo(() => {
//     const geo = new THREE.SphereGeometry(1.5, 16, 16);
//     const mat = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 0.5 });
//     return <primitive object={new THREE.Points(geo, mat)} scale={hovered ? [3, 3, 3] : [2, 2, 2]} />;
//   }, [color, hovered]);

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
//       {glow}
//     </a.mesh>
//   );
// };

// // Main InteractiveCube Component with Visual Enhancements
// const InteractiveCube = () => {
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -2; x < 2; x++) {
//       for (let y = -2; y < 2; y++) {
//         for (let z = -2; z < 2; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas
//       style={{
//         height: '400px', 
//         width: '80%', 
//         // background: `url(${backgroundImage}) no-repeat center center fixed`, 
//         backgroundSize: 'cover',
//         backgroundColor: 'rgba(0, 0, 0, 0.5)' // Adding a semi-transparent black background to enhance visibility
//       }}
//       camera={{ position: [45, 45, 45], fov: 60 }}>
//       <ambientLight intensity={0.5} /> // Brighter ambient light for better illumination
//       <pointLight position={[10, 10, 10]} intensity={2} /> // Stronger point light for dramatic shadows
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} />
//       ))}
//       <OrbitControls />
//       <Stars // Re-enable the stars for a cosmic background effect
//         radius={100}
//         depth={50}
//         count={5000}
//         factor={4}
//         saturation={1} // Full color saturation for vibrant stars
//         fade
//         color="#E6007A"
//       />
//     </Canvas>
//   );
// };

// export default InteractiveCube;




// //with circles that could be linked to menu items
// import React, { useRef, useState, useMemo } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { useSpring, a } from '@react-spring/three';
// import { OrbitControls, Stars, Line, Html, Sphere, Float } from '@react-three/drei';
// import * as THREE from 'three';

// // Blockchain Node with metadata for concepts and enhanced interactions
// const BlockchainNode = ({ position, label, color }) => {
//   const meshRef = useRef();
//   const [hovered, setHovered] = useState(false);
//   const [active, setActive] = useState(false);
//   const { scale } = useSpring({ scale: hovered ? 1.5 : 1 });
  
//   useFrame(() => {
//     meshRef.current.rotation.y += 0.01;
//   });

//   return (
//     <a.mesh
//       ref={meshRef}
//       position={position}
//       scale={scale}
//       onClick={() => setActive(!active)}
//       onPointerOver={() => setHovered(true)}
//       onPointerOut={() => setHovered(false)}
//     >
//       <sphereGeometry args={[1, 16, 16]} />
//       <meshStandardMaterial color={hovered ? "#E6007A" : color} />
//       {active && (
//         <Html distanceFactor={10}>
//           <div style={{ color: 'white', display: 'block' }}>
//             {label}
//           </div>
//         </Html>
//       )}
//     </a.mesh>
//   );
// };

// // Dynamic Connections with animation
// const DynamicLines = ({ nodes }) => {
//   const points = useMemo(() => nodes.map(node => new THREE.Vector3(...node.position)), [nodes]);
//   const lineGeom = new THREE.BufferGeometry().setFromPoints(points);
//   const lineMat = new THREE.LineBasicMaterial({ color: '#FFFFFF', linewidth: 0.2 });

//   useFrame(({ clock }) => {
//     const time = clock.getElapsedTime();
//     lineMat.dashSize = Math.sin(time * 0.5) * 0.5 + 1;
//     lineMat.gapSize = Math.cos(time * 0.5) * 0.5 + 1;
//     lineMat.needsUpdate = true;
//   });

//   return <line geometry={lineGeom} material={lineMat} />;
// };

// // Main Blockchain Universe Component
// const BlockchainUniverse = () => {
//   const nodes = useMemo(() => [
//     { position: [10, 0, 0], label: 'Staking', color: '#00B2FF' },
//     { position: [-10, 0, 0], label: 'Address', color: '#552BBF' },
//     { position: [0, 10, 0], label: 'Transaction', color: '#E6007A' },
//     { position: [0, -10, 0], label: 'Message', color: '#D3FF33' },
//     { position: [5, 5, -10], label: 'Block', color: '#56F39A' },
//     // { position: [-5, -5, 10], label: 'Chain', color: '#20B2AA' }
//   ], []);

//   return (
//     <Canvas
//       style={{ height: '100vh', width: '75%' }}
//       camera={{ position: [0, 0, 30], fov: 70 }}>
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} color="white" />
//       {nodes.map((node, index) => (
//         <BlockchainNode key={index} {...node} />
//       ))}
//       <DynamicLines nodes={nodes} />
//       <Stars radius={100} depth={50} count={5000} factor={4} fade={true} />
//       <OrbitControls autoRotate autoRotateSpeed={0.5} enableZoom={true} enablePan={true} />
//     </Canvas>
//   );
// };

// export default BlockchainUniverse;



// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { Sphere } from '@react-three/drei';
// import * as THREE from 'three';

// // Function to generate a random position for each bubble
// const randomPosition = () => {
//   return [Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5];
// };

// const Bubble = ({ color, lifetime, size }) => {
//   const meshRef = useRef();
//   const [position, setPosition] = useState(() => randomPosition());
  
//   // Custom shader material that smoothly transitions colors
//   const material = useRef(new THREE.MeshStandardMaterial({
//     color: new THREE.Color(color),
//     metalness: 0, // reduced from 0.3 to 0
//     roughness: 0.5, // slightly more diffuse reflection
//     opacity: 1, // fully opaque for testing
//     transparent: true
// }));

//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.rotation.y += 0.005;
//       meshRef.current.rotation.x += 0.002;
//     }
//   });

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setPosition(randomPosition());
//     }, lifetime);
//     return () => clearTimeout(timeout);
//   }, [position, lifetime]);

//   return (
//     <mesh position={position} ref={meshRef}>
//       <Sphere args={[size, 32, 32]}>
//         <primitive attach="material" object={material.current} />
//       </Sphere>
//     </mesh>
//   );
// };

// const BubbleUniverse = () => {
//   // Colors more in line with Polkadot's visual identity
//   const colors = ['#E6007A', '#009EFA', '#191326', '#F2F6F9'];  // Assuming these are Polkadot-like colors
  
//   const bubbles = useMemo(() => {
//     return Array.from({ length: 35 }, (_, i) => ({
//       id: i,
//       color: colors[i % colors.length],
//       lifetime: Math.random() * 8000 + 5000,
//       size: Math.random() * 0.5 + 0.5
//     }));
//   }, [colors]);

//   return (
//     <Canvas style={{ height: '60vh', width: '100%' }}>
//       <ambientLight intensity={0.5} />  // Increased from 0.3 to 0.5
//       <pointLight position={[10, 10, 10]} intensity={2} color="white" />  // Increased intensity

//       {bubbles.map(bubble => (
//         <Bubble key={bubble.id} {...bubble} />
//       ))}
//     </Canvas>
//   );
// };

// export default BubbleUniverse;





// //work on this VERY CUTE!!!!
// import React, { useRef } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { a } from '@react-spring/three';
// import { OrbitControls } from '@react-three/drei';
// import * as THREE from 'three';

// // Simplified Block Node Representation
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();

//   useFrame(() => {
//     meshRef.current.rotation.y += 0.002;
//   });

//   // Create glitch effect material
//   const glitchMaterial = new THREE.MeshBasicMaterial({
//     color: "#E6007A",
//     wireframe: true, // Display as wireframe
//     wireframeLinewidth: 0.01, // Set wireframe line width
//     fog: false, // Disable fog
//   });

//   return (
//     <a.mesh ref={meshRef} position={position}>
//       <boxGeometry args={[2, 2, 2]} />
//       <meshBasicMaterial attach="material" {...glitchMaterial} />
//     </a.mesh>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   return (
//     <Canvas style={{ height: '120%', width: '90%' }}>
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {createBlockNodes()}
//       <OrbitControls />
//     </Canvas>
//   );
// };

// // Function to create a grid of blocks
// const createBlockNodes = () => {
//   const blockPositions = [];
//   for (let x = -2; x < 2; x++) {
//     for (let y = -2; y < 2; y++) {
//       for (let z = -2; z < 2; z++) {
//         blockPositions.push([x * 5, y * 5, z * 5]);
//       }
//     }
//   }
//   return blockPositions.map((pos, index) => (
//     <BlockNode key={index} position={pos} />
//   ));
// };

// export default InteractiveCube;


//almsot htere cute cubes rotating
// import React, { useRef, useEffect } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { a } from '@react-spring/three';
// import { OrbitControls } from '@react-three/drei';
// import * as THREE from 'three';

// // Simplified Block Node Representation
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();

//   // Function to animate the rotation
//   const animateRotation = () => {
//     if (meshRef.current) {
//       meshRef.current.rotation.y += 0.002;
//     }
//     requestAnimationFrame(animateRotation);
//   };

//   // Start rotation animation
//   useEffect(() => {
//     animateRotation();
//   }, []);

//   // Create glitch effect material
//   const glitchMaterial = new THREE.MeshBasicMaterial({
//     color: "#E6007A",
//     wireframe: true, // Display as wireframe
//     wireframeLinewidth: 0.01, // Set wireframe line width
//     fog: false, // Disable fog
//   });

//   return (
//     <a.mesh ref={meshRef} position={position}>
//       <boxGeometry args={[2, 2, 2]} />
//       <meshBasicMaterial attach="material" {...glitchMaterial} />
//     </a.mesh>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   // Reference to the camera
//   const cameraRef = useRef();

//   // Function to animate the camera
//   const animateCamera = () => {
//     if (cameraRef.current) {
//       // Move the camera around in a circle
//       const time = Date.now() * 0.0005;
//       const radius = 40; // Increase this value to zoom out more
//       const x = Math.sin(time) * radius;
//       const z = Math.cos(time) * radius;
//       cameraRef.current.position.set(x, 20, z);
  
//       // Look at the center of the scene
//       cameraRef.current.lookAt(0, 0, 0);
//     }
//     requestAnimationFrame(animateCamera);
//   };

//   // Start camera animation
//   useEffect(() => {
//     animateCamera();
//   }, []);

//   return (
//     <Canvas style={{ height: '120%', width: '90%' }}>
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {createBlockNodes()}
//       <OrbitControls />
//       <perspectiveCamera ref={cameraRef} />
//     </Canvas>
//   );
// };

// // Function to create a grid of blocks
// const createBlockNodes = () => {
//   const blockPositions = [];
//   for (let x = -2; x < 2; x++) {
//     for (let y = -2; y < 2; y++) {
//       for (let z = -2; z < 2; z++) {
//         blockPositions.push([x * 5, y * 5, z * 5]);
//       }
//     }
//   }
//   return blockPositions.map((pos, index) => (
//     <BlockNode key={index} position={pos} />
//   ));
// };

// export default InteractiveCube;





// import React, { useRef, useMemo } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { OrbitControls } from '@react-three/drei';
// import { a, useSpring } from '@react-spring/three';

// // BlockNode component manages its own rotation and position animation
// const BlockNode = ({ initialPosition, targetPosition }) => {
//   const meshRef = useRef();

//   const { pos } = useSpring({
//     from: { pos: initialPosition },
//     to: { pos: targetPosition },
//     config: { tension: 200, friction: 20 },
//     reset: false
//   });

//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.rotation.y += 0.001; // Slower rotation for visibility
//     }
//   });

//   return (
//     <a.mesh position={pos} ref={meshRef}>
//       <boxGeometry args={[20, 20, 20]} />
//       <meshBasicMaterial color="#E6007A" wireframe={true} />
//     </a.mesh>
//   );
// };

// // Main Canvas and scene setup
// const InteractiveCube = () => {
//   const initialPositions = useMemo(() => {
//     const positions = [];
//     // Position the cubes to form a hollow cube
//     for (let x = -1; x <= 1; x++) {
//       for (let y = -1; y <= 1; y++) {
//         for (let z = -1; z <= 1; z++) {
//           if (x !== 0 || y !== 0 || z !== 0) { // Skip the center cube on each face
//             const initialPosition = [x * 50, y * 50, z * 50]; // Start far apart
//             const targetPosition = [x * 4, y * 4, z * 4]; // Closer together
//             positions.push({ initialPosition, targetPosition });
//           }
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas style={{ height: '60vh', width: '95%' }}>
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} intensity={1} />
//       <pointLight position={[-10, -10, -10]} intensity={0.5} />
//       <OrbitControls />
//       {initialPositions.map((props, index) => (
//         <BlockNode key={index} {...props} />
//       ))}
//     </Canvas>
//   );
// };

// export default InteractiveCube;

// //also cool
// import React from 'react';
// import { Canvas } from '@react-three/fiber';
// import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import * as THREE from 'three';

// // Simplified Block Node Representation
// const BlockNode = ({ position }) => {
//   const glitchMaterial = new THREE.MeshBasicMaterial({
//     color: "#E6007A",
//     wireframe: true,
//     wireframeLinewidth: 0.01,
//     fog: false,
//   });

//   return (
//     <mesh position={position}>
//       <boxGeometry args={[2, 2, 2]} />
//       <primitive object={glitchMaterial} attach="material" />
//     </mesh>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   return (
//     <Canvas style={{ height: '130%', width: '95%' }}>  // Adjusted for full viewport coverage
//       <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={30} />
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {createBlockNodes()}
//       <OrbitControls />
//     </Canvas>
//   );
// };

// // Function to create a grid of blocks
// const createBlockNodes = () => {
//   const blockPositions = [];
//   for (let x = -2; x < 2; x++) {
//     for (let y = -2; y < 2; y++) {
//       for (let z = -2; z < 2; z++) {
//         blockPositions.push([x * 5, y * 5, z * 5]);
//       }
//     }
//   }
//   return blockPositions.map((pos, index) => (
//     <BlockNode key={index} position={pos} />
//   ));
// };

// export default InteractiveCube;





// import React, { useRef } from 'react';
// import { useSpring, a } from '@react-spring/three';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
// import * as THREE from 'three';

// // BlockNode component with breathing effect
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();
//   const { scale } = useSpring({
//     loop: true,
//     from: { scale: 1 },
//     to: [{ scale: 1.2 }, { scale: 1 }],
//     config: { duration: 2000 }, // Adjust timing to make it slower or faster
//   });

//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.rotation.y += 0.002;
//     }
//   });

//   const glitchMaterial = new THREE.MeshBasicMaterial({
//     color: "#E6007A",
//     wireframe: true,
//     wireframeLinewidth: 0.001,
//     fog: false,
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale}>
//       <boxGeometry args={[1.5, 1.5, 1.5]} />
//       <primitive object={glitchMaterial} attach="material" />
//     </a.mesh>
//   );
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   return (
//     <Canvas style={{ height: '130%', width: '95%' }}>
//       <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={3} />
//       <ambientLight intensity={0.3} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {createBlockNodes()}
//       <OrbitControls />
//     </Canvas>
//   );
// };

// // Function to create a grid of blocks
// const createBlockNodes = () => {
//   const blockPositions = [];
//   for (let x = -2; x < 2; x++) {
//     for (let y = -2; y < 2; y++) {
//       for (let z = -2; z < 2; z++) {
//         blockPositions.push([x * 5, y * 5, z * 5]);
//       }
//     }
//   }
//   return blockPositions.map((pos, index) => (
//     <BlockNode key={index} position={pos} />
//   ));
// };

// export default InteractiveCube;


//cool with bubble flying around
// import React, { useRef, useMemo, useEffect } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
// import { useSpring, a } from '@react-spring/three';
// import * as THREE from 'three';

// // BlockNode component with breathing effect
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();
//   const { scale } = useSpring({
//     loop: true,
//     from: { scale: 1 },
//     to: async (next) => {
//       while (1) {
//         await next({ scale: 1.2 });
//         await next({ scale: 1 });
//       }
//     },
//     config: { duration: 2000 },
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale}>
//       <boxGeometry args={[1.5, 1.5, 1.5]} />
//       <meshBasicMaterial color="#E6007A" wireframe={true} />
//     </a.mesh>
//   );
// };

// // Particle system for shooting effects
// const Particles = ({ blockPositions }) => {
//   const particlesRef = useRef([]);
//   const { scene } = useThree();

//   useEffect(() => {
//     // Clean up old particles
//     particlesRef.current.forEach(particle => scene.remove(particle));

//     // Initialize new particles
//     particlesRef.current = blockPositions.map((startPos, index) => {
//       const material = new THREE.MeshBasicMaterial({ color: "#E6007A" });
//       const geometry = new THREE.SphereGeometry(0.049, 64, 64);
//       const mesh = new THREE.Mesh(geometry, material);
//       mesh.position.set(...startPos);
//       scene.add(mesh);
//       return {
//         mesh,
//         targetIndex: (index + 1) % blockPositions.length
//       };
//     });
//     return () => particlesRef.current.forEach(particle => scene.remove(particle.mesh));
//   }, [blockPositions, scene]);

//   useFrame(() => {
//     particlesRef.current.forEach(particle => {
//       const targetPos = new THREE.Vector3(...blockPositions[particle.targetIndex]);
//       particle.mesh.position.lerp(targetPos, 0.009);  // Move particle towards the target
//       if (particle.mesh.position.distanceTo(targetPos) < 0.5) {
//         // Change target when close to current target
//         particle.targetIndex = Math.floor(Math.random() * blockPositions.length);
//       }
//     });
//   });

//   return null;
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -2; x < 2; x++) {
//       for (let y = -2; y < 2; y++) {
//         for (let z = -2; z < 2; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
    
//     <Canvas style={{ height: '130%', width: '95%' }}>
//       <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={3} />
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} />
//       ))}
//       <Particles blockPositions={blockPositions} />
//       <OrbitControls />
//     </Canvas>
//   );
// };

// export default InteractiveCube;



// //This is good one
// import React, { useRef, useMemo, useEffect } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
// import { useSpring, a } from '@react-spring/three';
// import * as THREE from 'three';

// // BlockNode component with breathing and rotation effects
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();
//   const { scale } = useSpring({
//     loop: true,
//     from: { scale: 1 },
//     to: async (next) => {
//       while (1) {
//         await next({ scale: 1.2 });
//         await next({ scale: 1 });
//       }
//     },
//     config: { duration: 2000 },
//   });

//   // Adding rotation effect
//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.rotation.x += 0.001;
//       meshRef.current.rotation.y += 0.001;
//     }
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale}>
//       <boxGeometry args={[0.6, 0.6, 0.6]} />
//       <meshBasicMaterial color="#E6007A" wireframe={true} />
//     </a.mesh>
//   );
// };

// // Particle system for shooting effects
// const Particles = ({ blockPositions }) => {
//   const particlesRef = useRef([]);
//   const { scene } = useThree();

//   useEffect(() => {
//     // Clean up old particles
//     particlesRef.current.forEach(particle => scene.remove(particle));

//     // Initialize new particles
//     particlesRef.current = blockPositions.map((startPos, index) => {
//       const material = new THREE.MeshBasicMaterial({ color: "#E6007A" });
//       const geometry = new THREE.SphereGeometry(0.031, 64, 64);
//       const mesh = new THREE.Mesh(geometry, material);
//       mesh.position.set(...startPos);
//       scene.add(mesh);
//       return {
//         mesh,
//         targetIndex: (index + 1) % blockPositions.length
//       };
//     });
//     return () => particlesRef.current.forEach(particle => scene.remove(particle.mesh));
//   }, [blockPositions, scene]);

//   useFrame(() => {
//     particlesRef.current.forEach(particle => {
//       const targetPos = new THREE.Vector3(...blockPositions[particle.targetIndex]);
//       particle.mesh.position.lerp(targetPos, 0.00791);  // Move particle towards the target
//       if (particle.mesh.position.distanceTo(targetPos) < 0.5) {
//         // Change target when close to current target
//         particle.targetIndex = Math.floor(Math.random() * blockPositions.length);
//       }
//     });
//   });

//   return null;
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -2; x < 2; x++) {
//       for (let y = -2; y < 2; y++) {
//         for (let z = -2; z < 2; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas style={{ height: '130%', width: '95%' }}>
//       <PerspectiveCamera makeDefault position={[3, 90, 60]} fov={4} />
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} />
//       ))}
//       <Particles blockPositions={blockPositions} />
//       <OrbitControls />
//     </Canvas>
//   );
// };

// export default InteractiveCube;





// import React, { useRef, useMemo, useEffect } from 'react';
// import { Canvas, useFrame, useThree } from '@react-three/fiber';
// import { OrbitControls, PerspectiveCamera, useHelper } from '@react-three/drei';
// import { useSpring, a } from '@react-spring/three';
// import * as THREE from 'three';
// import { EffectComposer, Bloom } from '@react-three/postprocessing';
// import { PointLightHelper } from 'three';

// // BlockNode component with breathing, rotation effects, and color transitions
// const BlockNode = ({ position }) => {
//   const meshRef = useRef();
//   const { scale, color } = useSpring({
//     loop: true,
//     from: { scale: 1, color: '#E6007A' },
//     to: async (next) => {
//       while (1) {
//         await next({ scale: 1.2, color: '#FF0080' });
//         await next({ scale: 1, color: '#E6007A' });
//       }
//     },
//     config: { duration: 2000 },
//   });

//   useFrame(() => {
//     if (meshRef.current) {
//       meshRef.current.rotation.x += 0.001;
//       meshRef.current.rotation.y += 0.001;
//     }
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale}>
//       <boxGeometry args={[2, 2, 2]} />
//       <a.meshBasicMaterial wireframe={true} color={color} />
//     </a.mesh>
//   );
// };

// // Particle system for shooting effects with color transitions
// const Particles = ({ blockPositions }) => {
//   const particlesRef = useRef([]);
//   const { scene } = useThree();

//   useEffect(() => {
//     // Clean up old particles
//     particlesRef.current.forEach(particle => scene.remove(particle));

//     // Initialize new particles
//     particlesRef.current = blockPositions.map((startPos, index) => {
//       const material = new THREE.MeshBasicMaterial({ color: '#E6007A' });
//       const geometry = new THREE.SphereGeometry(0.031, 64, 64);
//       const mesh = new THREE.Mesh(geometry, material);
//       mesh.position.set(...startPos);
//       scene.add(mesh);
//       return {
//         mesh,
//         targetIndex: (index + 1) % blockPositions.length
//       };
//     });
//     return () => particlesRef.current.forEach(particle => scene.remove(particle.mesh));
//   }, [blockPositions, scene]);

//   useFrame(() => {
//     particlesRef.current.forEach(particle => {
//       const targetPos = new THREE.Vector3(...blockPositions[particle.targetIndex]);
//       particle.mesh.position.lerp(targetPos, 0.00791);  // Move particle towards the target
//       if (particle.mesh.position.distanceTo(targetPos) < 0.5) {
//         // Change target when close to current target
//         particle.targetIndex = Math.floor(Math.random() * blockPositions.length);
//       }
//     });
//   });

//   return null;
// };

// // Main InteractiveCube Component with Enhanced Visualization
// const InteractiveCube = () => {
//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -2; x < 2; x++) {
//       for (let y = -2; y < 2; y++) {
//         for (let z = -2; z < 2; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   }, []);

//   return (
//     <Canvas style={{ height: '130%', width: '95%' }}>
//       <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={15} />
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} intensity={1.5} />
//       {blockPositions.map((pos, index) => (
//         <BlockNode key={index} position={pos} />
//       ))}
//       <Particles blockPositions={blockPositions} />
//       <OrbitControls />
//       <EffectComposer>
//         <Bloom luminanceThreshold={0.3} luminanceSmoothing={0.75} height={300} />
//       </EffectComposer>
//     </Canvas>
//   );
// };

// export default InteractiveCube;



// //This makes cube bya dding one block at a time
// import React, { useRef, useState, useEffect, useMemo } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
// import * as THREE from 'three';

// const BlockNode = ({ position }) => {
//   return (
//     <mesh position={position}>
//       <boxGeometry args={[1, 1, 1]} />
//       <meshBasicMaterial color="#E6007A" wireframe={true} />
//     </mesh>
//   );
// };

// const InteractiveCube = () => {
//   const [blocksToShow, setBlocksToShow] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBlocksToShow(prevCount => prevCount + 1);
//     }, 100);

//     return () => clearInterval(interval);
//   }, []);

//   const blockPositions = useMemo(() => {
//     const positions = [];
//     for (let x = -4; x <= 4; x++) {
//       for (let y = -4; y <= 4; y++) {
//         for (let z = -4; z <= 4; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions.slice(0, blocksToShow);
//   }, [blocksToShow]);

//   return (
//     <div style={{ height: '100vh', width: '100vw' }}>
//       <Canvas>
//         <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={75} />
//         <ambientLight intensity={0.5} />
//         <pointLight position={[10, 10, 10]} intensity={1.5} />
//         {blockPositions.map((pos, index) => (
//           <BlockNode key={index} position={pos} />
//         ))}
//         <OrbitControls />
//       </Canvas>
//     </div>
//   );
// };

// export default InteractiveCube;




// //lso neat, 3x3
// import React, { useRef, useState, useEffect } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
// import { useSpring, a } from '@react-spring/three';
// import { EffectComposer, Bloom } from '@react-three/postprocessing';

// const BlockNode = ({ position, delay }) => {
//   const meshRef = useRef();
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setIsVisible(true);
//     }, delay);
    
//     return () => clearTimeout(timeout);
//   }, [delay]);

//   const { scale } = useSpring({
//     scale: isVisible ? 1 : 0,
//     loop: true,
//     from: { scale: 0 },
//     to: { scale: 1 },
//     config: { duration: 12000 },
//   });

//   return (
//     <a.mesh ref={meshRef} position={position} scale={scale}>
//       <boxGeometry args={[1, 1, 1]} />
//       <a.meshBasicMaterial color="#E6007A" wireframe={true} />
//     </a.mesh>
//   );
// };

// const InteractiveCube = () => {
//   const [blocksToShow, setBlocksToShow] = useState(0);
//   const [blockPositions] = useState(() => {
//     const positions = [];
//     for (let x = -1; x <= 1; x++) {
//       for (let y = -1; y <= 1; y++) {
//         for (let z = -1; z <= 1; z++) {
//           positions.push([x * 5, y * 5, z * 5]);
//         }
//       }
//     }
//     return positions;
//   });

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBlocksToShow(prev => prev + 2);
//     }, 50); // Adjust the interval for the timing of each block's appearance

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div style={{ height: '125%', width: '95%' }}>
//       <Canvas>
//         <PerspectiveCamera makeDefault position={[30, 30, 30]} fov={22} />
//         <ambientLight intensity={0.5} />
//         <pointLight position={[10, 10, 10]} intensity={1.5} />
//         {blockPositions.slice(0, blocksToShow).map((pos, index) => (
//           <BlockNode key={index} position={pos} delay={index * 200} />
//         ))}
//         <OrbitControls />
//         {blocksToShow === blockPositions.length && (
//           <EffectComposer>
//             <Bloom luminanceThreshold={0.3} luminanceSmoothing={0.7} intensity={0.5} />
//           </EffectComposer>
//         )}
//       </Canvas>
//     </div>
//   );
// };

// export default InteractiveCube;



//wrireframe 3x3 works





// // ////////////////////////
// // ////Pretty good
import React, { useRef, useMemo, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, SpotLight } from '@react-three/drei';
import { useSpring, a } from '@react-spring/three';
import * as THREE from 'three';
import { EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';
import { extend} from '@react-three/fiber';

const BlockNode = ({ position }) => {
  const ref = useRef();
  const [hovered, setHovered] = useState(false); // State to manage hover

  // Animation for scale, which changes on hover
  const { scale } = useSpring({
    scale: hovered ? 1.5 : 1,
    config: { mass: 1, tension: 170, friction: 26 },
  });

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.x += 0.001;
      ref.current.rotation.y += 0.001;
    }
  });

  return (
    <a.lineSegments 
      ref={ref} 
      position={position} 
      scale={scale.to(s => [s, s, s])}
      onPointerOver={(e) => {
        e.stopPropagation();
        setHovered(true);
      }}
      onPointerOut={(e) => {
        e.stopPropagation();
        setHovered(false);
      }}
    >
      <edgesGeometry args={[new THREE.BoxGeometry(0.6, 0.6, 0.6)]} />
      <lineBasicMaterial color="#E6007A" />
    </a.lineSegments>
  );
};

// export default BlockNode;

// Particle system for shooting effects
const Particles = ({ blockPositions }) => {
  const particlesRef = useRef([]);
  const { scene } = useThree();

  useEffect(() => {
    // Clean up old particles
    particlesRef.current.forEach(particle => scene.remove(particle));

    // Initialize new particles
    particlesRef.current = blockPositions.map((startPos, index) => {
      const material = new THREE.MeshBasicMaterial({ color: "#E6007A" });
      const geometry = new THREE.SphereGeometry(0.031, 64, 64);
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(...startPos);
      scene.add(mesh);
      return {
        mesh,
        targetIndex: (index + 1) % blockPositions.length
      };
    });
    return () => particlesRef.current.forEach(particle => scene.remove(particle.mesh));
  }, [blockPositions, scene]);

  useFrame(() => {
    particlesRef.current.forEach(particle => {
      const targetPos = new THREE.Vector3(...blockPositions[particle.targetIndex]);
      particle.mesh.position.lerp(targetPos, 0.00791);  // Move particle towards the target
      if (particle.mesh.position.distanceTo(targetPos) < 0.5) {
        // Change target when close to current target
        particle.targetIndex = Math.floor(Math.random() * blockPositions.length);
      }
    });
  });

  return null;
};

// Main InteractiveCube Component with Enhanced Visualization
const InteractiveCube = () => {
  const blockPositions = useMemo(() => {
    const positions = [];
    for (let x = -2; x < 2; x++) {
      for (let y = -2; y < 2; y++) {
        for (let z = -2; z < 2; z++) {
          positions.push([x * 5, y * 5, z * 5]);
        }
      }
    }
    return positions;
  }, []);

  return (
    <Canvas style={{ height: '100%', width: '75%' }}>
      <PerspectiveCamera makeDefault position={[20, 60, 90]} fov={4} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1.5} />
      {blockPositions.map((pos, index) => (
        <BlockNode key={index} position={pos} />
      ))}
      <Particles blockPositions={blockPositions} />
      <OrbitControls />
    </Canvas>
  );
};

export default InteractiveCube;
// ///////////////////////////////

