// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csv-sql-reader {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sql-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.file-selection {
  max-width: 100%;
  align-items: left;
  margin-bottom: 20px;
}

/* .query-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
} */

.run-query-button {
  margin-top: 10px;
}

.results-section {
  margin-top: 20px;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.results-table th,
.results-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.results-table th {
  background-color: #f4f4f4;
}

.sql-chart-section {
  margin-top: 20px;
  text-align: center;
}

.sql-chart-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/css/CSVSqlReader.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;;;;GAKG;;AAEH;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".csv-sql-reader {\n  font-family: 'Roboto', sans-serif;\n  padding: 20px;\n  background-color: #f5f5f5;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.sql-title {\n  font-size: 2.5em;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.file-selection {\n  max-width: 100%;\n  align-items: left;\n  margin-bottom: 20px;\n}\n\n/* .query-section {\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  margin-bottom: 20px;\n} */\n\n.run-query-button {\n  margin-top: 10px;\n}\n\n.results-section {\n  margin-top: 20px;\n}\n\n.results-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 10px;\n}\n\n.results-table th,\n.results-table td {\n  padding: 10px;\n  border: 1px solid #ddd;\n  text-align: left;\n}\n\n.results-table th {\n  background-color: #f4f4f4;\n}\n\n.sql-chart-section {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.sql-chart-container {\n  width: 100%;\n  height: 400px;\n  margin-top: 20px;\n  border: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
