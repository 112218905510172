
//amazing sphere polkadot colours
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars, Float, Text, Sphere } from '@react-three/drei';
import * as THREE from 'three';

// Import chain color utility
import { get_chain_colors } from '../js/charts_ecosystem';

const ParticleEffect = () => {
  const particlesRef = useRef();
  useFrame(() => {
    particlesRef.current.rotation.y += 0.002;
  });

  return (
    <points ref={particlesRef}>
      <sphereGeometry args={[25, 64, 64]} />
      <pointsMaterial size={0.1} color="#E6007A" />
    </points>
  );
};

const InteractiveSphere = () => {
  const chainColors = get_chain_colors('polkadot');

  return (
    <Canvas camera={{ position: [0, 20, 70], fov: 60 }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[20, 20, 20]} intensity={2} />
      <spotLight position={[-20, -20, -20]} angle={0.2} penumbra={1} intensity={1} />
      <OrbitControls />
      {/* <Stars /> */}
      <Float speed={2} rotationIntensity={1} floatIntensity={2}>
          <meshStandardMaterial color="#E6007A" metalness={1} roughness={0.4} />
          {/* <Text position={[0, 0, 5]} fontSize={0.5} color="white">Polkadot</Text> */}
      </Float>
      <ParticleEffect />
    </Canvas>
  );
};

export default InteractiveSphere;
