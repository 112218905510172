"use strict";

//This is required for the EOYR report
import * as echarts from 'echarts';
import watermarkImage from '../assets/dotlake-transparent-high-quality.png'
import { useEffect } from 'react';


// Used by both loadJSON and loadCSV to rename chains
function renameChain(chainName) {
  switch (chainName) {
    case 'origintrail':
      return 'neuroweb';
    case 'statemint':
      return 'Polkadot Asset Hub';
    case 'polkadot_asset_hub':
      return 'Polkadot Asset Hub';
    case 'statemine':
      return 'Kusama Asset Hub';
    case 'kusama_asset_hub':
      return 'Kusama Asset Hub';
    default:
      return chainName;
  }
}

let watermark = {
  type: 'image',
  id: 'watermark',
  bounding: 'raw', // Set to 'raw' for no padding
  right: 'center', // Center horizontally
  // bottom: 'center', // Center vertically
  top: 'center',
  z: -1,
  style: {
    image: watermarkImage,
    width: 150, // Adjust width if necessary
    height: 150, // Adjust height if necessary
    opacity: 0.1 // Adjust opacity if necessary
  }
};

let customFormatter = function (params) {
  // Sort the items based on their value for the specific month
  params.sort((a, b) => b.value - a.value);

  // Filter out items with a value of 0
  let filteredParams = params.filter(param => param.value != 0);

  // Calculate total value for the month from filtered items
  let total = filteredParams.reduce((sum, param) => sum + param.value, 0);

  // Start table for tooltip content with header for alignment and minimal padding
  let tooltipContent = '<div><table class="tb">';

  // Conditionally add total row if number of filtered items is greater than 3
  if (filteredParams.length > 3) {
    tooltipContent += `<tr>
                        <th><b>Total:</b></th>
                        <th><b>${total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2  })}</b></th>
                      </tr>`;
  }

  // Generate rows for each series with right-aligned values, only for filtered items
  filteredParams.forEach(param => {
    tooltipContent += `<tr>
                        <td>${param.marker}${param.seriesName}:</td>
                        <td><b>${param.value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</b></td>
                       </tr>`;
  });

  // Close table and div
  tooltipContent += '</table></div>';

  // Optional: Add a header or any additional content to the tooltip
  let header = params[0] ? params[0].axisValueLabel : '';
  return `<div>${header}<br/>${tooltipContent}</div>`;
}

// Used primary by the parachains tab for charts
export function barchart(config, data) {

  const xName = config.freq == 'daily' ? 'date' : 'month';
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors();

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      // Include 'other' only if the actual number of chains exceeds topN
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      // If not exceeding topN, just keep the top chains without 'other'
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  // Before setting up the chart options
  let legendData = chains;
  
  // Dynamically adjust the legend's position based on the number of chains
  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '80%', top: '6%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%' 
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '22%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  // Dynamically add selector buttons if there are more than 3 items in the legend
  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  // Adjustments for legend and grid
  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['bar', 'line', 'stack'] },
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 1,
      textStyle: {
        fontSize: '10',
      },
      selected: legendData.reduce((obj, item) => {
          // This line ensures all your series are selected by default except for "Select All" and "Select None"
          // Since "Select All" and "Select None" are not in legendData, they won't affect this
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions, // Use the dynamically set selector options here
        ...legendPositionAndOrientation // Spread the dynamically determined properties
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  window[config.elementId].setOption(option, true);
}

// Used by stablecoins tab
export function line_chart(config, data) {

  // const xName = config.xName === undefined ? (config.freq == 'daily' ? 'date' : 'monthly') : config.xName;
  const xName = config.xName;
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors();

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      // Include 'other' only if the actual number of chains exceeds topN
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      // If not exceeding topN, just keep the top chains without 'other'
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    areaStyle: {},
    emphasis: {
      focus: 'series'
    },
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;//.concat(['Select All', 'Select None']);

  // Dynamically adjust the legend's position based on the number of chains
  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '80%', top: '6%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '22%', bottom: '0%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  // Adjustments for legend and grid
  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['bar', 'line', 'stack'] },
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'vertical',
      // left: '80%',
      // top: '8%',
      padding: 3,
      selected: legendData.reduce((obj, item) => {
          // This line ensures all your series are selected by default except for "Select All" and "Select None"
          // Since "Select All" and "Select None" are not in legendData, they won't affect this
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions, // Use the dynamically set selector options here
        ...legendPositionAndOrientation // Spread the dynamically determined properties
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  window[config.elementId].setOption(option, true);
}

const generatedColors = Array.from({ length: 8}, (_, index) => {
    const hue = (index * 137.508) % 360;
    const saturation = 50 + Math.random() * 40; // Vary saturation for richer colors
    const lightness = 65 + Math.random() * 20; // Vary lightness for contrast
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
});
    
function convertToMonthEndDate(dateString) {
  if (dateString.length === 7) { // Matches "YYYY-MM"
    const year = parseInt(dateString.substring(0, 4));
    const month = parseInt(dateString.substring(5, 7));
    const endDate = new Date(year, month, 0).getDate(); // Get last day of the month
    return `${dateString}-${endDate.toString().padStart(2, '0')}`;
  }
  return dateString; // Return original if not matching "YYYY-MM"
}

export function loadCSVData(csvPath, callback) {
    fetch(csvPath)
      .then(response => response.text())
      .then(data => {
        const rows = data.split('\n').filter(row => row.trim() !== '');
        const headers = rows[0].split(',');
        let dateColumnIndex = headers.indexOf('month');
        if (dateColumnIndex === -1) {
            dateColumnIndex = headers.indexOf('date'); // Check for 'date' if 'month' is not found
        }
  
        const csvArray = rows.slice(1).map(row => {
          const values = row.split(',');
          const entry = headers.reduce((obj, header, index) => {
            
            if (header === 'month' || header === 'date') {
              obj[header] = convertToMonthEndDate(values[index]);
            
            } else if (header === 'chain') { 
              obj[header] = renameChain(values[index]);
            
            } else {
              obj[header] = values[index];
            
            }
            return obj;
          }, {});
          return entry;
        });
  
        if (dateColumnIndex !== -1) {
            // Sort the array based on the date column
            csvArray.sort((a, b) => {
                const dateA = new Date(a[headers[dateColumnIndex]]);
                const dateB = new Date(b[headers[dateColumnIndex]]);
                return dateA - dateB;
            });
        }
  
        // No need to reverse() after sorting
        return csvArray;
      })
      .then(processedData => {
        callback(processedData);
      })
      .catch(error => {
        console.error('Error loading CSV data:', error);
      });
}

export function loadJSONData(jsonPath, relay, callback) {
  
  fetch(jsonPath)
    .then(response => response.text())
    .then(data => {
      const jsonArray = data.split('\n')
                            .filter(line => line.trim() !== '')
                            .map(JSON.parse);
      return jsonArray;
    })
    .then(generalData => {
      let filteredData = generalData.filter(element => element.relay_chain === relay);
      return filteredData;
    })
    .then(filteredData => {
      // if exactly two numeric field, create a `combined` field as well (i.e. Substrate + EVM)
      let enhancedData = filteredData.map(element => {
        // Rename some chains...
        if (element.chain) {
          element.chain = renameChain(element.chain);
        }

        // // get keys of numeric fields
        // let numericFields = Object.keys(element).filter(key => typeof element[key] === 'number');
        // // ensure there are exactly two numeric fields to sum
        // if (numericFields.length === 2) {
        //   element.combined = numericFields.reduce((sum, key) => sum + element[key], 0);
        // }
        return element;
      });
      return enhancedData;
      
    })
    .then(enhancedData => {
      callback(enhancedData); // invoke the callback with the enhanced data
    })
    .catch(error => {
      console.error('Error loading JSON data:', error);
    });
    
}

export function createEchartElement(elementId) {
    const container = document.getElementById(elementId);
    if (!container) return;
  
    // Dispose the existing chart instance if it exists
    const existingChart = echarts.getInstanceByDom(container);
    if (existingChart) {
      echarts.dispose(existingChart);
    }
  
    // Create a new ECharts instance
    window[elementId] = echarts.init(container);
}

///////// EOYR charts

// The stuff below this won't need much editing
const colors = {
  polkadot: '#E6007A',
  kusama: '#000000',
  emphasis: '#00B2FF',
  success: '#27ae60',
  failure: 'red',
  neutral: '#552BBF',
  background: '#95a5a6',
  heat: "#e67e22"
}

export function staking_rate(config, data){

  let months = data.map(item => item.month.slice(0, 10));
  let totalDotIssuance = data.map(item => parseFloat(item.total_dot_issuance));
  let totalDotStaked = data.map(item => parseFloat(item.total_dot_staked));
  let percentageStaked = data.map(item => 100 * parseFloat(item.percentage_staked));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Total DOT Issuance', 'Total DOT Staked', 'Percentage Staked']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    graphic: watermark,

    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'DOT',
      },
      {
        type: 'value',
        name: 'Percentage',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Total DOT Issuance',
        type: 'bar',
        data: totalDotIssuance,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Total DOT Staked',
        type: 'bar',
        data: totalDotStaked,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Percentage Staked',
        type: 'line',
        yAxisIndex: 1,
        data: percentageStaked,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  window[config.elementId].setOption(option, true);

}


export function nominators_validators(config, data){

  let months = data.map(item => item.month.slice(0, 10));
  let totalNominators = data.map(item => parseFloat(item.number_of_nominators));
  let totalActiveValidators = data.map(item => parseFloat(item.number_of_active_validators));
  let totalWaitingValidators = data.map(item => parseFloat(item.number_of_waiting_validators));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Nominators', 'Number of active Validators', 'Number of waiting Validators']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Active / Waiting Validators',
      },
      {
        type: 'value',
        name: 'Nominators',
      }
    ],
    series: [
      {
        name: 'Number of active Validators',
        type: 'bar',
        data: totalActiveValidators,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of waiting Validators',
        type: 'bar',
        data: totalWaitingValidators,
        color: colors.background,
        itemStyle: {
          opacity: 0.8
        }
       },
      {
        name: 'Number of Nominators',
        type: 'line',
        data: totalNominators,
        yAxisIndex: 1,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function nakamoto_coefficient(config, data){

  let months = data.map(item => item.month.slice(0, 10));
  let nakamotoCoefficient = data.map(item => parseFloat(item.nakamoto_coefficient));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Nakamoto Coefficient']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Nakamoto Coefficient',
      },
    ],
    series: [
      {
        name: 'Nakamoto Coefficient',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: nakamotoCoefficient,
        color: colors.polkadot 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function crowdloan_contribution_bar(config, data){
  data.reverse()
  let parachainName = Array.from(new Set(data.map(item => item.parachain_name)));
  let numberContributors = data.map(item => parseFloat(item.number_of_contributors));
  let contributionAmount = data.map(item => parseFloat(item.contribution_amount_DOT));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Contribution Amount (DOT)', 'Number of Contributors']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: parachainName,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
         name: 'Contribution Amount (DOT)',
      },
      {
        type: 'value',
         name: 'Number of Contributors',
      }
    ],
    series: [
      {
        name: 'Contribution Amount (DOT)',
        type: 'bar',
        data: contributionAmount,
        color: colors.polkadot
      },
      {
        name: 'Number of Contributors',
        type: 'bar',
        data: numberContributors,
        yAxisIndex: 1,
        color: colors.success
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function min_max_nominators_validators_stake(config, data){

  let months = data.map(item => item.month.slice(0, 10));
  let maxStake = data.map(item => parseFloat(item.max_validator_stake));
  let diffPercentTotalStake = data.map(item => 100 * parseFloat(item.difference_in_percent_of_total_stake));
  let minStake = data.map(item => parseFloat(item.min_validator_stake));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Maximum Stake', 'Minimum Stake', 'Difference in % of total stake']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Stake (DOT)',
      },
      {
        type: 'value',
        name: 'Difference in % of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Minimum Stake',
        type: 'bar',
        data: minStake,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Maximum Stake',
        type: 'bar',
        data: maxStake,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Difference in % of total stake',
        type: 'line',
        data: diffPercentTotalStake,
        yAxisIndex: 1,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function treasury_balance(config, data){

  let months = data.map(item => item.month.slice(0, 10));
  let treasuryBalance = data.map(item => parseFloat(item.treasury_balance_dot));
  let percentOfTotalBalance = data.map(item => parseFloat(item.percent_of_total_issuance));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Treasury Balance', 'Percent of Total Issuance']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Balance (DOT)',
      },
      {
        type: 'value',
        name: 'Percent of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Treasury Balance',
        type: 'bar',
        data: treasuryBalance,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Percent of Total Issuance',
        type: 'line',
        data: percentOfTotalBalance,
        yAxisIndex: 1,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function treasury_flows(config, data){

  var colorScheme = {
    'bounties': '#1f77b4', 
    'burnt': colors.kusama,
    'proposals': colors.emphasis,
    'transfers': '#ff9896',
    'inflation': colors.polkadot,
    'fees': colors.background
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.type]) {
                  aggregatedData[month][item.type] = 0;
              }
              aggregatedData[month][item.type] += parseFloat(item.sum_of_dot);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(type => {
          if (!seriesData[type]) {
              seriesData[type] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[type][monthIndex] = aggregatedData[month][type];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(type) {
      series.push({
          name: type,
          type: 'bar',
          stack: 'total',
          data: seriesData[type],
          itemStyle: {
              color: colorScheme[type],
              opacity: 0.8
          }
      });
  });
  
  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      formatter: customFormatter
      },
        legend: {
          bottom: '0%',
          left: 'center',
          data: Object.keys(seriesData)
        },
        graphic: watermark
        ,
    
        grid: {
            left: '3%',
            right: '4%',
            bottom: '9%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: months,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: {
            name: 'DOT',
            type: 'value'
        },
        series: series
    };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function referenda_by_outcome_og(config, data){

  var colorScheme = {
    'Approved': colors.success, 
    'Rejected': colors.failure,
    'TimedOut': colors.neutral,
    'Cancelled': colors.background,
  };

  let processedData = data.map(item => {
    const value = parseInt(item.number_of_referenda);
    
    return {
      value: value,
      name: item.outcome,
      itemStyle: {
        color: colorScheme[item.outcome]
      }
    };
  });
  
  // ECharts option
  var option = {
    tooltip: {
        trigger: 'item',
        formatter: function(params) {
          return `${params.seriesName} <br/>${params.name}: ${params.value} (${Math.round(params.percent)}%)`;
      }
    },
    legend: {
        bottom: '0%',
        left: 'center'
    },
    graphic: watermark,

    series: [
        {
            name: 'Referenda Outcome',
            type: 'pie',
            radius: ['40%', '70%'],
            label: {
                show: true,
                formatter: function(params) {
                  return `${params.name}: ${Math.round(params.percent)}%`;
              }
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true
            },
            data: processedData
        }
      ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function referenda_by_outcome_history(config, data, chart) {
  // Color scheme for different outcomes
  var colorScheme = {
    'Approved': colors.success,
    'Rejected': colors.failure,
    'TimedOut': colors.neutral,
    'Cancelled': colors.background,
    'Ongoing': colors.warning,
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Process data to aggregate by month and outcome
  let aggregatedData = {};
  let months = new Set();
  data.forEach(item => {
    const month = item.month;
    const outcome = item.outcome;
    const value = parseInt(item.number_of_referenda);

    if (!aggregatedData[month]) {
      aggregatedData[month] = {};
    }
    if (!aggregatedData[month][outcome]) {
      aggregatedData[month][outcome] = 0;
    }
    aggregatedData[month][outcome] += value;
    months.add(month);
  });

  // Convert months to a sorted array and format them
  const sortedMonths = Array.from(months).sort();
  const formattedMonths = sortedMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Get all unique outcomes
  const outcomes = Object.keys(colorScheme);

  // Prepare raw data and total data for stacking
  let rawData = outcomes.map(outcome => {
    return sortedMonths.map(month => aggregatedData[month] && aggregatedData[month][outcome] ? aggregatedData[month][outcome] : 0);
  });

  let totalData = sortedMonths.map((month, index) => {
    return outcomes.reduce((sum, outcome) => sum + (rawData[outcomes.indexOf(outcome)][index] || 0), 0);
  });

  // Prepare series data
  const series = outcomes.map((outcome, sid) => {
    return {
      name: outcome,
      type: 'bar',
      stack: 'total',
      barWidth: '60%',
      label: {
        show: true,
        position: 'inside',
        formatter: (params) => {
          const percent = totalData[params.dataIndex] > 0 ? (params.value * 100).toFixed(1) : 0;
          return `${percent}%`;
        }
      },
      itemStyle: {
        color: colorScheme[outcome],
        opacity: 0.8
      },
      data: rawData[sid].map((d, did) => totalData[did] <= 0 ? 0 : d / totalData[did])
    };
  });

  // Configure the chart option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center'
    },
    grid: {
      left: 100,
      right: 100,
      top: 50,
      bottom: 50
    },
    tooltip: {
      trigger: 'axis',
      // backgroundColor: tooltipBackgroundColor,
      axisPointer: { type: 'shadow' },
      formatter: function(params) {
        // Calculate the total value
        let totalValue = params.reduce((sum, item) => sum + (item.value * totalData[item.dataIndex]), 0);
        
        // Start the tooltip text with the date
        let tooltipText = `${params[0].axisValue}<br/>`;
    
        // Add each series' information to the tooltip text
        params.forEach(item => {
            const percent = item.value > 0 ? (item.value * 100).toFixed(1) : 0;
            tooltipText += `${item.marker} ${item.seriesName}: ${percent}% (${(item.value * totalData[item.dataIndex]).toFixed(2)})<br/>`;
        });
    
        // Add the total value at the end
        tooltipText += `Total: ${totalValue.toFixed(2)}`;
    
        return tooltipText;
      }
    },
    xAxis: {
      type: 'category',
      data: formattedMonths,
      axisLabel: {
        formatter: value => value // Ensure the formatted month is displayed as it is
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => `${(value * 100).toFixed(0)}%`
      }
    },
    series: series,
    graphic: watermark
  };

  // Set the option to the chart
  chart.setOption(option, true);
}

export function number_submitted_proposals_gov1vsopengov(config, data){
  
  var colorScheme = {
    'Gov 1': colors.emphasis,
    'Open Gov': colors.polkadot,
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  months.sort((a, b) => new Date(a) - new Date(b)); // Sort months in ascending order


  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.type]) {
                  aggregatedData[month][item.type] = 0;
              }
              aggregatedData[month][item.type] += parseFloat(item.number_of_treasury_proposals);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(type => {
          if (!seriesData[type]) {
              seriesData[type] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[type][monthIndex] = aggregatedData[month][type];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(type) {
      series.push({
          name: type,
          type: 'bar',
          stack: 'total',
          data: seriesData[type],
          itemStyle: {
              color: colorScheme[type]
          }
      });
  });
  
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
          data: Object.keys(seriesData)
      },
      graphic: {
        type: 'image',
        id: 'watermark',
        bounding: 'raw', // Set to 'raw' for no padding
        right: 'center', // Center horizontally
        // bottom: 'center', // Center vertically
        top: 'center',
        z: -1,
        style: {
          image: watermarkImage,
          width: 150, // Adjust width if necessary
          height: 150, // Adjust height if necessary
          opacity: 0.1 // Adjust opacity if necessary
        }
      },
  
      grid: {
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'DOT',
          type: 'value'
      },
      series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function average_votes_gov1vsopengov(config, data){

  var colorScheme = {
    'Gov 1': colors.emphasis,
    'Open Gov': colors.polkadot,
  };

  let averageVotes = data.map(item => ({
      value: parseFloat(item.average_votes),
      itemStyle: {
          color: colorScheme[item.gov_type]
        }
    })
  );
  let govType = data.map(item => item.gov_type);

  var option = {
    title: {
        text: 'Gov Type and Average Votes'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    xAxis: {
        type: 'value'
    },
    yAxis: {
        type: 'category',
        data: govType
    },
    series: [
        {
            name: 'Average Votes',
            type: 'bar',
            data: averageVotes,
          }
      ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function number_referenda_by_origin(config, data){

  var colorScheme = {
    'BigSpender': colors.polkadot,
    'MediumSpender': colors.emphasis,
    'SmallSpender': colors.success,
    'Root': colors.kusama
  };

  const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.origin)
        },
        graphic: {
          type: 'image',
          id: 'watermark',
          bounding: 'raw', // Set to 'raw' for no padding
          right: 'center', // Center horizontally
          // bottom: 'center', // Center vertically
          top: 'center',
          z: -1,
          style: {
            image: watermarkImage,
            width: 150, // Adjust width if necessary
            height: 150, // Adjust height if necessary
            opacity: 0.1 // Adjust opacity if necessary
          }
        },
    
        series: [
            {
                name: 'Origin Count',
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.map((item, index) => {
                    return {
                        value: item.origin_count,
                        name: item.origin,
                        itemStyle: {
                            color: colorScheme[item.origin]
                        }
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };


  const chart = window[config.elementId];
  chart.setOption(option);
}

export function number_of_votes_by_duration_of_lock(config, data){
  var colorScheme = {
    'Locked6x': colors.polkadot,
    'Locked5x': colors.emphasis,
    'Locked4x': colors.success,
    'None': colors.background
  };

  const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.conviction)
        },
        graphic: {
          type: 'image',
          id: 'watermark',
          bounding: 'raw', // Set to 'raw' for no padding
          right: 'center', // Center horizontally
          // bottom: 'center', // Center vertically
          top: 'center',
          z: -1,
          style: {
            image: watermarkImage,
            width: 150, // Adjust width if necessary
            height: 150, // Adjust height if necessary
            opacity: 0.1 // Adjust opacity if necessary
          }
        },
    
        series: [
            {
                name: 'Origin Count',
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.map((item, index) => {
                    return {
                        value: item.conviction_count,
                        name: item.conviction,
                        itemStyle: {
                            color: colorScheme[item.conviction]
                        }
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };


  const chart = window[config.elementId];
  chart.setOption(option);
}

// Function to aggregate votes by month and type
function aggregateVotersByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    // Initialize the month in accumulator if it doesn't exist
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    // Add the number of voters to the corresponding vote type
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_participation(config, data){

  let aggregatedVotes = aggregateVotersByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  
  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Participation',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

// Helper for monthly_number_of_voters
function aggregateDistinctByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    // Initialize the month in accumulator if it doesn't exist
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    // Add the number of voters to the corresponding vote type
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_distinct_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_distinct_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_number_of_voters(config, data){

  let aggregatedVotes = aggregateDistinctByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];
  
  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Distinct Voters',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

// Function to aggregate votes by month and type
function aggregateTokensByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    // Initialize the month in accumulator if it doesn't exist
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    // Add the number of voters to the corresponding vote type
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.sum_of_tokens, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.sum_of_tokens, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_capital_no_conviction(config, data){

  let aggregatedTokens = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];
  
  Object.entries(aggregatedTokens).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function monthly_voting_power_with_conviction(config, data){
  
  let aggregatedVotes = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];
  
  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function number_monthly_tokens_voted_by_direction(config, data){

  var colorScheme = {
    'nay': colors.failure,
    'aye': colors.success,
    'split': colors.emphasis
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.vote_direction]) {
                aggregatedData[month][item.vote_direction] = 0;
              }
              var sumOfTokens = item.sum_of_tokens === '' ? 0 : parseFloat(item.sum_of_tokens);
              aggregatedData[month][item.vote_direction] += sumOfTokens;
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(vote_direction => {
          if (!seriesData[vote_direction]) {
              seriesData[vote_direction] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[vote_direction][monthIndex] = aggregatedData[month][vote_direction];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(vote_direction) {
      series.push({
          name: vote_direction,
          type: 'bar',
          stack: 'total',
          data: seriesData[vote_direction],
          itemStyle: {
              color: colorScheme[vote_direction]
          }
      });
  });
  
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
        bottom: '0%',
        left: 'center',
        data: Object.keys(seriesData)
      },
      graphic: watermark,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: customFormatter
      },
  
      grid: {
          left: '3%',
          right: '4%',
          bottom: '9%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'DOT',
          type: 'value'
      },
      series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function number_of_parachains(config, data){
  let months = data.map(item => item.month.slice(0, 10));
  let numberOfParachains = data.map(item => parseFloat(item.number_of_parchains));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Parachains']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Parachains',
      },
    ],
    series: [
      {
        name: 'Number of Parachains',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: numberOfParachains,
        color: colors.polkadot 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function monthly_transactions(config, data){

  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.number_of_transactions);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000000).toFixed(1) + 'M'; // Format as '11.0M'
              }
          }
      });
  });
  
  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
        data: Object.keys(seriesData),
        left: 'center',
        bottom: '0%',
    },
    graphic: watermark,

    grid: {left: '3%', right: '4%', bottom: '20%', containLabel: true},
    xAxis: [
        {
            type: 'category',
            data: months,
            axisPointer: {
                type: 'shadow'
            }
        }
    ],
    yAxis: {
        name: 'Transactions (Substrate + EVM)',
        type: 'value'
    },
    series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function monthly_unique_addresses(config, data){
  data.reverse()

  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.number_of_unique_addresses);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000000).toFixed(1) + 'M'; // Format as '11.0M'
              }
          }
      });
  });

  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          },
          formatter: customFormatter
        },
      legend: {
          data: Object.keys(seriesData),
          left: 'center',
          bottom: '0%',
      },
      graphic: {
        type: 'image',
        id: 'watermark',
        bounding: 'raw', // Set to 'raw' for no padding
        right: 'center', // Center horizontally
        // bottom: 'center', // Center vertically
        top: 'center',
        z: -1,
        style: {
          image: watermarkImage,
          width: 150, // Adjust width if necessary
          height: 150, // Adjust height if necessary
          opacity: 0.1 // Adjust opacity if necessary
        }
      },
  
      grid: {left: '3%', right: '4%', bottom: '20%', containLabel: true},
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'Unique addresses',
          type: 'value'
      },
      series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function monthly_active_addresses(config, data){
 
  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.number_of_active_addresses);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000).toFixed(1) + 'K'; // Format as '11.0M'
              }
          }
      });
  });

  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
          data: Object.keys(seriesData),
          left: 'center',
          bottom: '0%',
      },
      graphic: {
        type: 'image',
        id: 'watermark',
        bounding: 'raw', // Set to 'raw' for no padding
        right: 'center', // Center horizontally
        // bottom: 'center', // Center vertically
        top: 'center',
        z: -1,
        style: {
          image: watermarkImage,
          width: 150, // Adjust width if necessary
          height: 150, // Adjust height if necessary
          opacity: 0.1 // Adjust opacity if necessary
        }
      },
  
      grid: {left: '3%', right: '4%', bottom: '20%', containLabel: true},
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'Active addresses',
          type: 'value'
      },
      series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

// export function xcm_monthly_by_type(config, data){

//   var colorScheme = {
//     'DMP': colors.emphasis,
//     'UMP': colors.polkadot,
//     'HRMP': colors.success
//   };

//   let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

//   var aggregatedData = {};
//   months.forEach(month => {
//       data.forEach(item => {
//           if (item.month.slice(0, 10) === month) {
//               if (!aggregatedData[month]) {
//                   aggregatedData[month] = {};
//               }
//               if (!aggregatedData[month][item.chain]) {
//                   aggregatedData[month][item.chain] = 0;
//               }
//               aggregatedData[month][item.chain] += parseFloat(item.method_count);
//           }
//       });
//   });
  
//   var seriesData = {};
//   Object.keys(aggregatedData).forEach(month => {
//       Object.keys(aggregatedData[month]).forEach(chain => {
//           if (!seriesData[chain]) {
//               seriesData[chain] = new Array(months.length).fill(0);
//           }
//           var monthIndex = months.indexOf(month);
//           seriesData[chain][monthIndex] = aggregatedData[month][chain];
//       });
//   });
  
//   var series = [];
//   Object.keys(seriesData).forEach(function(chain) {
//       series.push({
//           name: chain,
//           type: 'bar',
//           stack: 'total',
//           data: seriesData[chain],
//           itemStyle: {
//               color: colorScheme[chain]
//           }
//       });
//   });
  
//   // Updated part: Sorting series data by numeric value for tooltip
//   var sortedSeriesData = {};
//   months.forEach(month => {
//       let monthData = [];
//       Object.keys(aggregatedData[month]).forEach(chain => {
//           monthData.push({
//               chain: chain,
//               value: aggregatedData[month][chain]
//           });
//       });

//       // Sort monthData by value in descending order
//       monthData.sort((a, b) => b.value - a.value);

//       sortedSeriesData[month] = monthData;
//   });

//   // ECharts configuration
//   var option = {
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//           type: 'shadow'
//       },
//       formatter: customFormatter
//     },
//     legend: {
//       data: Object.keys(seriesData),
//       bottom: '0%',
//       left: 'center'
//     },
//     graphic: watermark,
//     grid: {left: '3%', right: '4%', bottom: '50%', containLabel: true},
//     xAxis: [
//         {
//             type: 'category',
//             data: months,
//             axisPointer: {
//                 type: 'shadow'
//             }
//         }
//     ],
//     yAxis: {
//         name: 'XCM Messages Sent',
//         type: 'value'
//     },
//     series: series
//   };

//   const chart = window[config.elementId];
//   chart.setOption(option);
// }

export function xcm_distinct_hrmp_channels(config, data){
  let months = data.map(item => item.month.slice(0, 10));
  let numberOfHRMPChannels = data.map(item => parseFloat(item.number_of_hrmp_channels));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['HRMP Channels']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'HRMP Channels',
      },
    ],
    series: [
      {
        name: 'HRMP Channels',
        type: 'line',
        areaStyle: {
          color: colors.emphasis,
          opacity: 0.5
        },
        data: numberOfHRMPChannels,
        color: colors.emphasis 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);


}

export function xcm_parachain_message_usage_hrmp_senders(config, data){

  data.reverse()

  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.method_count);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000).toFixed(1) + 'K'; // Format as '11.0M'
              }
          }
      });
  });
  
  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
        data: Object.keys(seriesData),
        left: 'center',
        bottom: '0%',
    },
    graphic: watermark,

    grid: {left: '3%', right: '4%', bottom: '20%', containLabel: true},
    xAxis: [
        {
            type: 'category',
            data: months,
            axisPointer: {
                type: 'shadow'
            }
        }
    ],
    yAxis: {
        name: 'HRMP Senders',
        type: 'value'
    },
    series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}


export function github_commits(config, data){

  data.reverse()
  
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfCommits = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.commit_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Commits']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'GitHub: Commits',
      },
    ],
    series: [
      {
        name: 'GitHub: Commits',
        type: 'bar',
        data: numberOfCommits,
        color: colors.emphasis 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);

}

export function github_active_developers(config, data){

  data.reverse()
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfAuthors = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.author_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Active Developers']
    },
    graphic: watermark,

    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'GitHub: Active Developers',
      },
    ],
    series: [
      {
        name: 'GitHub: Active Developers',
        type: 'bar',
        data: numberOfAuthors,
        color: colors.heat 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
  
}

export function github_issues_opened_and_closed(config, data){
  data.reverse()
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  let issuesClosed = data.filter(item => item.type == "Issues Closed").map(item => parseFloat(item.type_count));
  let issuesOpened = data.filter(item => item.type == "Issues Opened").map(item => parseFloat(item.type_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Opened', 'Issues Closed']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'voters',
      }
    ],
    series: [
      {
        name: 'Issues Opened',
        type: 'bar',
        data: issuesOpened,
        color: colors.polkadot
      },
      {
        name: 'Issues Closed',
        type: 'bar',
        data: issuesClosed,
        color: colors.success
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function github_issues_merged_pull_requests(config, data){

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  months.sort((a, b) => new Date(a) - new Date(b)); // Sort months in ascending order

  let issuesMerged = data.map(item => parseInt(item.total_pull_id_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Merged']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
      }
    ],
    series: [
      {
        name: 'Issues Merged',
        type: 'bar',
        data: issuesMerged,
        color: colors.success
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
  
}

export function github_unique_authors_and_orgs_commits(config, data){

  data.reverse()

  let org = data.map(item => item.org);
  let developers = data.map(item => parseFloat(item.developers));
  let commits = data.map(item => parseFloat(item.commits));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Developers', 'Commits']
    },
    graphic: watermark,

    grid: {
      left: '3%',
      right: '4%',
      bottom: '5%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: org,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Optional: if labels overlap, rotate them
      }

      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Developers',
      },
      {
        type: 'value',
        name: 'Commits',
      }
    ],
    series: [
      {
        name: 'Developers',
        type: 'bar',
        data: developers,
        color: colors.emphasis
      },
      {
        name: 'Commits',
        type: 'line',
        data: commits,
        yAxisIndex: 1,
        color: colors.polkadot
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function stackexchange_user_growth(config, data){

  let sortedData = data.sort((a, b) => new Date(a.creation_date) - new Date(b.creation_date));

  let months = sortedData.map(item => item.creation_date.slice(0, 10));

  let numUsers = sortedData.map(item => parseFloat(item.num_users));
  let runningTotals = sortedData.map(item => parseFloat(item.running_total));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['StackExchange Users', 'Running Totals']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
      },
      {
        type: 'value',
        name: 'Running Totals',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'StackExchange Users',
        type: 'bar',
        data: numUsers,
        color: colors.polkadot
      },
      {
        name: 'Running Totals',
        type: 'line',
        yAxisIndex: 1,
        data: runningTotals,
        smooth: true
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);

}

export function stackexchange_top_tags(config, data){

  const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        graphic: {
          type: 'image',
          id: 'watermark',
          bounding: 'raw', // Set to 'raw' for no padding
          right: 'center', // Center horizontally
          // bottom: 'center', // Center vertically
          top: 'center',
          z: -1,
          style: {
            image: watermarkImage,
            width: 150, // Adjust width if necessary
            height: 150, // Adjust height if necessary
            opacity: 0.1 // Adjust opacity if necessary
          }
        },
    
        series: [
            {
                name: 'Tag',
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.map((item, index) => {
                    return {
                        value: item.tags_count,
                        name: item.tags
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };


  const chart = window[config.elementId];
  chart.setOption(option);
}

export function stackexchange_activity_eoy(config, data){
  var colorScheme = {
    'post-question': colors.polkadot,
    'poast-answer': colors.success,
    'suggested-edit': colors.emphasis,
    'badge': colors.heat
  };

  let months = Array.from(new Set(data.map(item => item.date.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.date.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.timeline_type_post]) {
                  aggregatedData[month][item.timeline_type_post] = 0;
              }
              aggregatedData[month][item.timeline_type_post] += parseFloat(item.number_of_engagements);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(timeline_type_post => {
          if (!seriesData[timeline_type_post]) {
              seriesData[timeline_type_post] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[timeline_type_post][monthIndex] = aggregatedData[month][timeline_type_post];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(timeline_type_post) {
      series.push({
          name: timeline_type_post,
          type: 'bar',
          stack: 'total',
          data: seriesData[timeline_type_post],
          itemStyle: {
              color: colorScheme[timeline_type_post]
          }
      });
  });
  
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
          data: Object.keys(seriesData),
          bottom: '0%',
          left: 'center'
      },
      graphic: watermark,
  
      grid: {
          left: '3%',
          right: '4%',
          bottom: '9%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'Engagements',
          type: 'value'
      },
      series: series
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function polkadot_asset_hub_usdc(config, data){
  
  data.reverse()

  let months = data.map(item => item.date.slice(0, 10));
  months.sort((a, b) => new Date(a) - new Date(b)); // Sort months in ascending order

  let sumUSDC = data.map(item => parseFloat(item.sum_of_usdc));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of USDC']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of USDC',
      },
    ],
    series: [
      {
        name: 'Sum of USDC',
        type: 'line',
        areaStyle: {
          color: colors.success,
          opacity: 0.5
        },
        data: sumUSDC,
        color: colors.success 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function sum_of_staked_tokens(config, data){
  
  let months = data.map(item => item.date.slice(0, 10));
  let sumStaked = data.map(item => parseFloat(item.sum_of_staked_tokens));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of Staked DOT']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of Staked DOT',
        min: 6.5E8,
        max: 7.3E8
      },
    ],
    series: [
      {
        name: 'Sum of Staked DOT',
        type: 'line',
        areaStyle: {
          color: colors.success,
          opacity: 0.5
        },
        data: sumStaked,
        color: colors.success 
      },
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

export function qfour_auction_winners(config, data){

  let projectName = Array.from(new Set(data.map(item => item.project_name_para_id)));
  let auctionIndex = data.map(item => item.auction_index);
  let contributionAmount = data.map(item => parseFloat(item.contribution_amount));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: auctionIndex
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: projectName,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Optional: if labels overlap, rotate them
      }

      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Contribution Amount (DOT)',
      }
    ],
    series: [
      {
        name: 'Contribution Amount',
        type: 'bar',
        data: contributionAmount,
      }
    ]
  };

  const chart = window[config.elementId];
  chart.setOption(option);
}

function get_chain_colors(relay) {
  if (relay === 'polkadot') {
    return {
      'other': '#CCCCCC', // Color for 'Other' category
      'polkadot': '#E6007A',
      'astar': '#56F39A', // Bright Light Green
      'aventus': '#552BBF', // Deep Purple
      'equilibrium': '#00B2FF', // Bright Blue
      'moonbeam': '#33D6FF', // Aqua Blue
      'origintrail': '#AA7BDB', // Light Purple
      'neutoweb': '#AA7BDB', // Light Purple
      'phala': '#FF7F50', // Orange
      'acala': '#2f4f4f', // darkslategray
      'ajuna': '#556b2f', // darkolivegreen
      'bifrost': '#8b4513', // saddlebrown
      'bitgreen': '#6b8e23', // olivedrab
      'centrifuge': '#2e8b57', // seagreen
      'clover': '#228b22', // forestgreen
      'collectives': '#8b0000', // darkred
      'composable': '#483d8b', // darkslateblue
      'crust': '#008080', // teal
      'darwinia': '#b8860b', // darkgoldenrod
      'efinity': '#4682b4', // steelblue
      'frequency': '#d2691e', // chocolate
      'hydradx': '#9acd32', // yellowgreen
      'integritee': '#4b0082', // indigo
      'interlay': '#32cd32', // limegreen
      'invarch': '#00ff00',
      'kapex': '#8fbc8f', // darkseagreen
      'kilt-spiritnet': '#8b008b', // darkmagenta
      'kylin': '#b03060', // maroon3
      'litentry': '#48d1cc', // mediumturquoise
      'manta': '#9932cc', // darkorchid
      'mythos': '#a020f0',
      'nodle': '#ff0000', // red
      'parallel': '#ff8c00', // darkorange
      'pendulum': '#ffd700', // gold
      'polemic': '#00ffff', 
      'polkadex': '#ffff00', // yellow
      'polkadot-hashed': '#c71585', // mediumvioletred
      'polkadot-t3rn': '#0000cd', // mediumblue
      'Polkadot Asset Hub': '#00B2FF',
      'statemint': '#00ff00', // lime
      'subsocial': '#7cfc00',
      'unique': '#00ff7f', // springgreen
      'zeitgeist': '#dc143c' // crimson
      // Add more chains and their colors here
    }
  } else if (relay === 'kusama') {
    return {
      'other': '#CCCCCC', // Color for 'Other' category
      'kusama': '#black', // red
      'altair': '#ffff00', // yellow
      'amplitude': '#00bfff', // deepskyblue
      'bajun': '#b03060', // maroon3
      'basilisk': '#800080', // purple
      'bridgehub': '#f4a460', // sandybrown
      'calamari': '#f0e68c', // khaki
      'crab': '#8fbc8f', // darkseagreen
      'dorafactory': '#daa520', // goldenrod
      'encointer': '#9acd32', // yellowgreen
      'ipci': '#d2691e', // chocolate
      'kabocha': '#000080', // navy
      'karura': '#9370db', // mediumpurple
      'khala': '#0000ff', // blue
      'kintsugi': '#fa8072', // salmon
      'Kusama Asset Hub': '#00B2FF',
      'mangata': '#6495ed', // cornflower
      'moonriver': '#a020f0', // purple3
      'parallel-heiko': '#ff6347', // tomato
      'picasso': '#adff2f', // greenyellow
      'pichiu': '#4682b4', // steelblue
      'quartz': '#008b8b', // darkcyan
      'robonomics': '#008000', // green
      'shiden': '#483d8b', // darkslateblue
      'statemine': '#556b2f', // darkolivegreen
      'tinker': '#d3d3d3', // lightgray
      'turing': '#00B2FF' // dimgray
      // Add more chains and their colors here
    }
  } else if (relay === 'solo') {
    return {
      'other': '#CCCCCC', // Color for 'Other' category
      'aleph-zero': '#f0e68c',
      'sora': '#7f0000', // maroon2
      'chainflip': '#56F39A', // Bright Light Green
      'joystream': '#552BBF', // Deep Purple
      'ternoa': '#00B2FF', // Bright Blue
      'bittensor': '#33D6FF', // Aqua Blue
      'vara': '#AA7BDB', // Light Purple
  }
}
}
  
//debouncing graphs and sizing
  export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  
  export const useChartResize = (echartsInstances, wait = 300) => {
    useEffect(() => {
      const handleResize = debounce(() => {
        echartsInstances.current.forEach(chart => {
          if (chart) chart.resize();
        });
      }, wait);
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [echartsInstances, wait]); // Make sure to include echartsInstances and wait in the dependency array
  };
  
  export const useDebouncedResize = (callback, delay) => {
      useEffect(() => {
        const debouncedHandleResize = debounce(callback, delay);
    
        window.addEventListener('resize', debouncedHandleResize);
    
        return () => window.removeEventListener('resize', debouncedHandleResize);
      }, [callback, delay]);
    };


//Parsing html content
export function parseHtmlContent(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const sectionElements = doc.querySelectorAll('details[id]');
    const sections = [];

    sectionElements.forEach(element => {
        const id = element.id;
        const htmlContent = element.outerHTML;

        // Check if the section contains a chart
        const chartId = `chart_${id.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_')}`;
        const chartElement = doc.querySelector(`#${chartId}`);

        // Include the section, chart placeholder ID, and HTML content if found
        if (chartElement) {
            const chartPlaceholderId = chartElement.id;
            sections.push({ id, html: htmlContent, chartPlaceholderId });
        } else {
            sections.push({ id, html: htmlContent });
        }
    });

    return sections;
}

//Grid layout:
export const chartContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(750px, 1fr))',
  gap: '20px',
  padding: '20px',
  backgroundColor: '#f4f4f4',
  boxSizing: 'border-box'
}

//chart descriptions all
export const chartDescriptions = {
  //ecosystem stats
  parachains_transactions: 'The number of fee-paying, signed extrinsics for substrate and calls to `evm.Executed` and/or `ethereum.Executed` for EVM. Failed transactions are included provided a fee was paid.',
  parachains_transfers: 'Description for parachains transfers.',
  parachains_unique_accounts: 'The number of unique accounts with a non-zero balance.',
  parachains_accounts_active: 'The number of active substrate accounts is based on fee-paying unique accounts with signed extrinsics. Active EVM accounts are based on extrinsics calls to `evm.Executed` and/or `ethereum.Executed`.',
  parachains_events: 'The number of events originiating from extrinsic calls along with OnInitialize and OnFinalize type events.',
  //block weights
  block_weight_avg_chart: 'The block weights are based on extrinsic events, both successful and failed. Inherints are not captured which causes the mandatory block weights to be undercounted.',
  block_weight_max_chart: 'The block weights are based on extrinsic events, both successful and failed. Inherints are not captured which causes the mandatory block weights to be undercounted.',
  //staking
  staking_staked_total: 'The total amount of DOT staked over time, highlighting the growth in network participation. Amount of DOT staked = Maximum daily value from `staking.ErasTotalStake` storage function queries at 3 hour intervals.',
  staking_staked_addresses:  'The number of unique addresses participating in staking, indicating network decentralization. Number of staked addresses = Maximum daily value of the number of accounts returned from a query map of`staking.ledger` storage function at 3 hour intervals.' ,
  staking_nominators_count: 'Illustrates the total number of nominators in the Polkadot network, reflecting the community engagement in staking. Number of Nominators = Maximum daily value from `staking.CounterForNominators` storage function queries at 3 hour intervals.',
  staking_validators_count: 'Represents the number of validators securing the network, a critical component for network security and efficiency. Number of Validators = Maximum daily value from `staking.CounterForValidators` storage function queries at 3 hour intervals.',
  staking_active_era: 'The current era in the staking system. An era is a period during which staking rewards are calculated and paid out.',
  staking_chill_threshold: 'The percentage of nominators who must submit a chill transaction before the staking system will stop electing a particular validator.',
  staking_current_era: 'The most recent era that has ended, updated at the end of each era.',
  staking_eras_reward_points: 'The reward points earned by validators in the current active era, which are used to calculate staking rewards.',
  staking_eras_total_stake: 'The total amount of DOT tokens staked in the current active era, reflecting the level of participation in securing the network.',
  staking_validator_count: 'The total number of validators active on the Polkadot network.',
  staking_slash_reward_fraction: 'The fraction of slashed rewards that will be given to the reporter.',
  staking_min_nominator_bond: 'The minimum amount of DOT that must be bonded to become a nominator.',
  staking_max_validators_count: 'The maximum number of validators that can be active in the network.',
  staking_min_max_validator_stake: 'The minimum and maximum amounts of Validators staking in DOT on the Polkadot Relay Chain.',
  staking_minimum_active_stake: 'The minimum amount of stake required to be an active validator.',
  staking_total_issuance: 'The total supply of DOT tokens currently issued in the Polkadot network.',
  staking_staked_percentage: 'The percentage of the total DOT supply that is currently staked in the network.',
  staking_counter_for_pool_members: 'The total number of pool members participating in the nomination pools.',
  staking_total_value_locked: 'The total amount of value locked in the nomination pools.',
  staking_bonded_pools_counter: 'The total number of bonded pools in the network.',
  staking_nominators_validators: 'The number of active and waiting Validators per month on Polkadot Relay Chain.',
  //ink
  general_ink_num_contract_codes: 'The number of distinct code hashes from storage function `Contracts.CodeInfoOf`.',
  general_ink_num_contracts_deployed: 'The number of distinct contracts deployed from storage function `Contracts.OwnerInfoOf`.',
  general_ink_num_owners: 'The number of distinct contract owners from storage function `Contracts.OwnerInfoOf`.',
  general_ink_fees_usd: 'The sum of transactions fees converted to USD based on end-of-day prices.',
  general_tvl_usd: 'The sum of native token deposited in the smart contract converted to USD based on end-of-day prices.',
  general_ink_language_donut: 'The programming language used for the smart contract development.',
  tableContractsVersion: 'The contracts pallet spec verion obtained from the storage function.',
  //treasury
  polkadotTreasury: 'Polkadot Treasury Month End Balance = Free balance from `System.Account` storage function for 13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB at the last block of the month.',
  polkadotTreasuryAssets:   'Free balance from `System.Account` storage function for 13UVJyLnbVp9RBZYFwFGyDvVd1y27Tt8tkntv6Q7JVPhFsTB on Polkadot & 14xmwinmCEz6oRrFdczHKqHgWNMiCysE2KrA4jXXAAM1Eogk on AssetHub, USDC/USDT = balance from `Assets.Account` storage function for assetID 1984/1337 on AssetHub.',
  kusamaTreasury: 'Kusama Treasury Month End Balance = Free balance from `System.Account` storage function for F3opxRbN5ZbjJNU511Kj2TLuzFcDq9BGduA9TgiECafpg29 at the last block of the month.',
  treasuryBalanceAssetHub: 'Free balance in DOT from `System.Account` storage function to Asset-Hub treasury account at the latest block height.',
  treasuryBalanceAssetHubUSDC: 'Free balance in USDC from `Assets.Account` storage function to Asset-Hub treasury account at the latest block height.',
  treasuryBalanceAssetHubUSDT: 'Free balance in USDT from `Assets.Account` storage function to Asset-Hub treasury account at the latest block height.',
  treasuryBalance: 'Free balance in DOT from `System.Account` storage function to Polkadot treasury account at the latest block height.',
  proposalCount: 'Number of proposals that have been made.',
  treasuryFlows: 'A detailed view on the inflow and outflow from the Treasury address including inflation, fees, transfers, bounties, burnt, proposals and other actions.',
  
  openGovReferenda: 'The number of referenda Approved, Rejected, Timed Out, Cancelled and ongoing in % of Referenda per month',
  openGovReferendaOrigin: 'Breakdown of which Origin tracks Referenda were called on. OpenGov allows for the use of multiple tracks based on the size and scope of the proposal.',
  openGovVotesDurationLock: "Conviction voting in Polkadot allows participants to boost their voting power by voluntarily locking their tokens for a specified period. The longer the lock period, the greater their vote's influence, enabling a weighted voting mechanism that reflects both the amount of stake and the commitment duration of the voter's support for specific proposals. Read more about vote multipliers and lenghts of lock here. This pie chart shows the all-time popularity of conviction locks in Open Gov (from mid-June 2023 until now).",
  openGovMonthlyPariticipation: 'Participation is counted for both Direct voters, those who vote on a referenda themselves, and Delgated Voters who allow a specific party/parties to vote on their behalf.',
  openGovMonthlyVoters: 'The number of distinct voters who have voted on a referenda in the given month.',
  openGovVotingPowerType: 'The amount of DOT voted on referenda per month by applying with and without conviction multiplication.',
  openGovVotingPowerDirection: 'The direction of voting power for referenda per month broken down by aye, nay, split and split_abstain.',

  //stablecoins
  USDTAssetHub: 'Supply value for USDt (Asset ID 1984) from `Assets.Asset` storage function on Polkadot AssetHub.',
  USDTParachains:  'USDt (Asset ID 1984) balances for parachain sovereign accounts on Polkadot AssetHub. Any USDt not in a Parachain Sovereign Account is attributed to Polkadot AssetHub.',
  USDCAssethub:  'Supply value for USDC (Asset ID 1337) from `Assets.Asset` storage function on Polkadot AssetHub, excluding the pre-minted balance not in circulation.',
  USDCParachains: 'USDC (Asset ID 1337) balances for parachain sovereign accounts on Polkadot AssetHub. Any USDC not in a Parachain Sovereign Account is attributed to the Polkadot AssetHub.',
  //polkadot stats general
  blocks: "Blocks are a collection of data, such as transactions, that together indicate a state transition of the blockchain. Polkadot's network demonstrates impressive dynamism, producing around 14,000 blocks daily or one every 6 seconds. This high throughput signifies the network's efficiency and its capability to support all core functions effectively. Polkadot's unique architecture, emphasizing shared security and interoperability, underlines its robust performance and scalability. The continuous activity highlights the network's reliability.",
  events: "Events are emitted for certain operations on the runtime. For example a balance transfer extrinsic produced an event called `balances.transfer`. The chart shows the number of events happening on Polkadot over time. Surges in activity can be seen overall during peak usage.",
  transfers: "Tracking the number of balance.transfer events, the graphic shows how addresses are transacting with DOT over time. Balance transfers are transfers of token balances between accounts. ",
  extrinsics: "Counting the number of extrinsics executed per day. Extrinsics are a SCALE encoded array consisting of a version number, signature, and varying data types indicating the resulting runtime function to be called, including the parameters required for that function to be executed. These state changes are invoked from the outside world, i.e. they are not part of the system itself. Extrinsics can take two forms, 'inherents' and 'transactions'. For more technical details see the polkadot spec.",
  newAccounts: "Tracking the number of New Accounts created on Polkadot by counting the System.NewAccount event.",
  //js api
  chain: "Retrieves the name of the chain that the node is connected to, such as 'Polkadot' or 'Kusama'.",
  latestBlock: "Latest block on the Polkadot Relay Chain.",
  totalIssuance: "The total supply of DOT tokens currently issued in the Polkadot network.",
  validators: "A list of current validators in the Polkadot network, responsible for producing new blocks and securing the blockchain.",
  activeEra: "The current era in the staking system. An era is a period during which staking rewards are calculated and paid out.",
  totalStakedValue: "The total amount of DOT tokens staked in the current active era, reflecting the level of participation in securing the network.",
  parachains: "The list of parachains currently registered and active on the Polkadot network.",
  parachainCountValue: "The total number of parachains currently operating on the Polkadot network.",
  auctionCounter: "The total number of parachain slot auctions that have been conducted so far on the Polkadot network.",
  validatorCount: "The total number of active validators on the Polkadot network.",
  currentSlot: "The current slot number in the BABE (Blind Assignment for Blockchain Extension) consensus mechanism, which is responsible for block production.",
  counterForNominators: "The total number of nominators participating in the staking process on the Polkadot network.",
  counterForValidators: "The total number of validators that have been elected to participate in the network.",
  lastRuntimeUpgrade: "Information about the last runtime upgrade, including the spec version and spec name at the time of the upgrade.",
  chillThreshold: 'The percentage of nominators who must submit a chill transaction before the staking system will stop electing a particular validator.',
  currentEra: 'The most recent era that has ended, updated at the end of each era.',
  erasRewardPoints: 'The reward points earned by validators in the current active era, which are used to calculate staking rewards.',
  erasTotalStake: 'The total amount of DOT tokens staked in the current active era, reflecting the level of participation in securing the network.',
  slashRewardFraction: 'The fraction of slashed rewards that will be given to the reporter.',
  minNominatorBond: 'The minimum amount of DOT that must be bonded to become a nominator.',
  maxValidatorsCount: 'The maximum number of validators that can be active in the network.',
  minimumActiveStake: 'The minimum amount of stake required to be an active nominator.',
  stakedPercentage: 'The percentage of the total DOT supply that is currently staked in the network.',
  counterForPoolMembers: 'The total number of pool members participating in the nomination pools.',
  totalValueLocked: 'The total amount of value locked in the nomination pools.',
  bondedPoolsCounter: 'The total number of bonded pools in the network.',
  counterForPools: 'The number of active nomination pools.',
  eventCount: 'The number of Events in the latest block.',
  specVersion: 'The version of the runtime specification currently in use by the Polkadot network, indicating the latest protocol changes and upgrades.',
  nakamoto_coefficient: 'The Nakamoto Coefficient measures the decentralization of a blockchain network by indicating the minimum number of nodes (or entities) required to potentially control the network, such as carrying out a 51% attack. A higher Nakamoto Coefficient suggests a greater level of decentralization and security, as more entities would be needed to compromise the network.',

  xcmMessages: 'The count of XCM messages sent per month broken down by HRMP, DMP and UMP.',
  hrmpChannels: ' The number of open HRMP Channels across the Polkadot Network.',
  xcmUsage: 'The count of XCM messages sent per month broken down by Parachain. Messages consist of HRMP, DMP and UMP.',
  
  stackExchangeGrowth: 'The number of new users per week and all time count on the Substrate Stack Exchange Platform.',
  stackExchangeTags: 'The top 25 tags found on issues posted to Stack Exchange.',
  stackExchangeActivity: 'The number of posts and answers by users on Stack Exchange per week.'

};

