// ContentSection component enhanced with new styles
import React from 'react';
import '../css/main.css'; // Assuming you have a CSS file for this component

const ContentSection = ({ title, children }) => {
  return (
    <div className="content-section">
      <div className="content-heading">
        <h1>{title}</h1>
      </div>
      <div className="content-body">{children}</div>
    </div>
  );
};

export default ContentSection;
