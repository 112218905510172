import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import PolkadotJSTreasuryComponent from './components/PolkadotJSTreasuryComponent';
import { lineChart } from './js/charts_ecosystem.js';
import { chartDescriptions } from './js/echart_utils.js';

const PolkadotStatsTreasury = () => {
  const polkadotChartNativeRef = useRef(null);
  const polkadotChartUSDRef = useRef(null);
  const kusamaChartRef = useRef(null);
  const chartRefs = [polkadotChartNativeRef, polkadotChartUSDRef, kusamaChartRef];
  const [hovered, setHovered] = useState({ 'Polkadot Treasury Assets': false, 'Polkadot Treasury Assets in USD': false, 'Kusama Treasury': false });
  const [chartsVisible, setChartsVisible] = useState(false);

  const chartTitles = [
    'Polkadot Treasury Assets',
    'Polkadot Treasury Assets in USD',
    // 'Kusama Treasury'
  ];

  const chartContents = [
    chartDescriptions.polkadotTreasuryAssets,
    chartDescriptions.polkadotTreasuryAssets,
    // chartDescriptions.kusamaTreasury
  ];

  const resizeCharts = useCallback(() => {
    chartRefs.forEach(ref => {
      if (ref.current) {
        const chart = echarts.getInstanceByDom(ref.current);
        if (chart) chart.resize();
      }
    });
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeCharts);
    chartRefs.forEach(ref => {
      if (ref.current) {
        ref.current.chart = echarts.init(ref.current);
        resizeObserver.observe(ref.current);
      }
    });

    window.addEventListener('resize', resizeCharts);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', resizeCharts);
      chartRefs.forEach(ref => {
        if (ref.current?.chart) {
          ref.current.chart.dispose();
        }
      });
    };
  }, [resizeCharts]);

  const fetchDataAndInitChart = async (url, chartRef, seriesName, filter, xName, yName, yTitle) => {
    try {
      const response = await fetch(url);
      const rawData = await response.text();
      const data = parseData(rawData, filter, xName, yName);

      if (chartRef.current) {
        const chart = echarts.getInstanceByDom(chartRef.current) || echarts.init(chartRef.current);

        const config = getConfig(chartRef, seriesName, xName, yName, yTitle, data);
        console.log(chartRef);

        lineChart(chart, config, data);
      }
    } catch (error) {
      console.error(`Error fetching and initializing chart for ${seriesName}:`, error);
    }
  };

  const parseData = (rawData, filter, xName, yName) => {
    const parsedData = rawData.split('\n')
      .filter(Boolean)
      .map(JSON.parse)
      .map(item => ({
        ...item,
        date: item[xName],
        y: parseFloat(item[yName])
      }));

    if (filter) {
      return parsedData.filter(item => item.chain_name === "kusama");
    } else {
      return parsedData;
    }
  };

  const getConfig = (chartRef, seriesName, xName, yName, yTitle, data) => {
    const colorScheme = { 'DOT': '#E6007A', 'kusama': 'black', 'USDC': '#56F39A', 'USDT': '#4682b4' };

    if (chartRef === kusamaChartRef) {
      return {
        xName,
        yName,
        title: 'Kusama Treasury',
        yTitle: yTitle,
        type: 'line',
        grouping: 'chain_name',
        stack: '',
        colorScheme: colorScheme,
        data,
        series: [{
          name: 'Kusama',
          type: 'line',
          data: data.map(item => [item.date, item.y]),
          lineStyle: { color: colorScheme['KSM'] }
        }]
      };
    } else {
      return {
        xName,
        yName,
        title: seriesName,
        yTitle: yTitle,
        type: (yName === 'balance' || yName === 'balance_usd') ? 'bar' : 'line',
        grouping: (yName === 'balance' || yName === 'balance_usd') ? 'asset' : 'chain',
        stack: chartRef === polkadotChartUSDRef ? 'total' : '',
        colorScheme: colorScheme,
        data
      };
    }
  };

  useEffect(() => {
    if (chartsVisible) {
      fetchDataAndInitChart('data/ecosystem_monthly_treasury_assets_polkadot000000000000.jsonl', polkadotChartNativeRef, 'Amount of Tokens', false, 'month', 'balance', 'Amount of Tokens');
      fetchDataAndInitChart('data/ecosystem_monthly_treasury_assets_polkadot000000000000.jsonl', polkadotChartUSDRef, 'Amount in USD', false, 'month', 'balance_usd', 'Amount in USD');
      // fetchDataAndInitChart('data/ecosystem_monthly_treasury_relay_new000000000000.jsonl', kusamaChartRef, 'Amount of KSM', true, 'date', 'monthly_average_treasury_balance', 'Amount of KSM');
    }
  }, [chartsVisible]);

  return (
    <div className="website-grid-container">
      <PolkadotJSTreasuryComponent onLoaded={() => setChartsVisible(true)} />
      {chartsVisible && chartTitles.map((title, index) => (
        <div key={title} style={{ width: '100%', maxWidth: '100%', padding: '20px', border: '2px solid #e0e0e0', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', boxSizing: 'border-box', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 style={{ margin: 0, marginRight: '10px' }}>{title}</h2>
            <InfoCubeIconComponent
              onMouseEnter={() => setHovered({ ...hovered, [title]: true })}
              onMouseLeave={() => setHovered({ ...hovered, [title]: false })}
            />
          </div>
          {hovered[title] && (
            <div style={{
              position: 'absolute',
              transform: 'translate(-50%, -30%)',
              zIndex: 2,
              fontSize: '14px',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.75)',
              padding: '20px',
              borderRadius: '10px',
              maxWidth: '50%',
              textAlign: 'full',
              boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
            }}>
              {chartContents[index]}
            </div>
          )}
          <div ref={chartRefs[index]} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
        </div>
      ))}
    </div>
  );
};

export default PolkadotStatsTreasury;
