import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Select, InputNumber } from 'antd';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import { updateChartsConfig, barChart, loadJSONData, renameChain, outerContainerStyle, controlsContainerStyle, groupContainerStyle, groupTitleStyle, buttonStyle, selectedButtonStyle} from './js/charts_ecosystem';
import { useDebouncedResize, chartDescriptions } from './js/echart_utils';
import LoadingSphere from './components/LoadingComponent.js'

const PolkadotStatsEcosystem = () => {
  const [chartsConfig, setChartsConfig] = useState([]);
  const [frequency, setFrequency] = useState('monthly');
  const [relayChain, setRelayChain] = useState('polkadot');
  const [chainType, setChainType] = useState('substrate');
  const [chainOptions, setChainOptions] = useState([]);
  const [chainMapping, setChainMapping] = useState({});
  const [topNChains, setTopNChains] = useState(60);
  const chartRefs = useRef({});
  const { Option } = Select;
  const [selectedChains, setSelectedChains] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [hovered, setHovered] = useState({});
  const filePath = 'data/parachains_transactions_monthly000000000000.jsonl';
  const [loading, setLoading] = useState(true); // Loading state
  const [chartLoading, setChartLoading] = useState({});


  const showDescription = id => setHovered({ ...hovered, [id]: true });
  const hideDescription = id => setHovered({ ...hovered, [id]: false });

  async function fetchAndProcessJSONLines(filePath, relayChain) {
    try {
        const response = await fetch(filePath);
        if (!response.ok) throw new Error('Network response was not ok.');
        const textData = await response.text();
        const jsonData = textData.trim().split('\n').map(line => JSON.parse(line));
        const { options, mapping } = getUniqueChainsForRelay(jsonData, relayChain);
        setChainOptions(options);
        setChainMapping(mapping); // Store the mapping in state
        // console.log('Fetched and processed JSON lines:', jsonData); // Add this line
        // console.log('Chain options:', options); // Add this line
        // console.log('Chain mapping:', mapping); // Add this line
    } catch (error) {
        console.error('Failed to fetch or process the file:', error);
    }
}

function getUniqueChainsForRelay(data, relayChain) {
    const uniqueChains = new Set();
    const chainMapping = {};

    data.forEach(item => {
        if (item.relay_chain.toLowerCase() === relayChain.toLowerCase()) {
            const renamed = renameChain(item.chain);
            uniqueChains.add(renamed);
            chainMapping[renamed.toLowerCase()] = item.chain;
        }
    });

    return {
        options: Array.from(uniqueChains).map(chain => ({
            label: chain.charAt(0).toUpperCase() + chain.slice(1),
            value: chain.toLowerCase()
        })),
        mapping: chainMapping
    };
}

  useDebouncedResize(() => {
    Object.values(chartRefs.current).forEach(chart => {
      if (chart) chart.resize();
    });
  }, 100);

  const createEchartElement = (elementId) => {
    const container = document.getElementById(elementId);
    if (!container) {
      // console.error(`Container not found for elementId: ${elementId}`); // Add this line
      return;
    }
  
    const existingChart = echarts.getInstanceByDom(container);
    if (existingChart) {
      echarts.dispose(existingChart);
    }
  
    chartRefs.current[elementId] = echarts.init(container, null, { renderer: 'svg' });
    // console.log(`Echart initialized for ${elementId}`); // Add this line
  };

  const loadAndUpdateCharts = () => {
    // setLoading(true); 
    const originalChains = selectedChains.map(chain => chainMapping[chain.toLowerCase()] || chain);

    chartsConfig.forEach(async (config) => {
      const data = await loadJSONData(config.jsonPath, config.relay, originalChains || []);
      if (data && chartRefs.current[config.elementId]) {
        barChart(chartRefs.current[config.elementId], config, data);
      }
    });

  };

  const renderControls = () => {
  
    return (
      <div style={outerContainerStyle}>
        <div style={controlsContainerStyle}>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Relay Chain</div>
            <div>
              <button style={relayChain === 'polkadot' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('polkadot')}>
                Polkadot
              </button>
              <button style={relayChain === 'kusama' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('kusama')}>
                Kusama
              </button>
              <button style={relayChain === 'solo' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('solo')}>
                Solo
              </button>
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Frequency</div>
            <div>
              <button style={frequency === 'daily' ? selectedButtonStyle : buttonStyle} onClick={() => setFrequency('daily')}>
                Daily
              </button>
              <button style={frequency === 'monthly' ? selectedButtonStyle : buttonStyle} onClick={() => setFrequency('monthly')}>
                Monthly
              </button>
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Chain Type</div>
            <div>
              <button style={chainType === 'substrate' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('substrate')}>
                Substrate
              </button>
              <button style={chainType === 'evm' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('evm')}>
                EVM
              </button>
              <button style={chainType === 'combined' ? selectedButtonStyle : buttonStyle} onClick={() => setChainType('combined')}>
                Combined
              </button>
            </div>
          </div>
        </div>
        <div style={{ ...groupContainerStyle, marginTop: '10px', width: '100%' }}>
          <div style={groupTitleStyle}>Chains</div>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }} // Set width to 100% to take up full width
            placeholder="Select chains"
            defaultValue={[]}
            onChange={(value) => setSelectedChains(value)}
            options={chainOptions}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadAndUpdateCharts = async () => {
      try {
        await fetchAndProcessJSONLines(filePath, relayChain);
        const updatedChartsConfig = updateChartsConfig(frequency, relayChain, chainType, topNChains, selectedChains);
        
        const filteredChartsConfig = updatedChartsConfig.filter(config => {
          if (config.elementId === 'parachains_unique_accounts') {
            return frequency === 'monthly' && relayChain === 'polkadot' && chainType !== 'evm';
          }
          if (config.elementId === 'parachains_events') {
            return chainType !== 'evm';
          }
          return true;
        });
  
        setChartsConfig(filteredChartsConfig);
  
        setTimeout(() => {
          filteredChartsConfig.forEach(config => {
            const container = document.getElementById(config.elementId);
            if (container) {
              const existingChart = echarts.getInstanceByDom(container);
              if (existingChart) {
                existingChart.dispose();
              }
              createEchartElement(config.elementId);
            }
          });
  
          filteredChartsConfig.forEach(async config => {
            try {
              const data = await loadJSONData(config.jsonPath, config.relay, selectedChains.map(chain => chainMapping[chain.toLowerCase()] || chain));
              console.log(`Data loaded for ${config.elementId}:`, data); // Add this line to log the data
              if (data && chartRefs.current[config.elementId]) {
                config.build(chartRefs.current[config.elementId], config, data);
              }
            } catch (dataError) {
              console.error(`Error loading data for ${config.elementId}:`, dataError);
            }
          });
          // setLoading(false); 
        }, 0);
      } catch (error) {
        console.error('Error in loadAndUpdateCharts:', error);
      }
    };
  
    loadAndUpdateCharts();
    setLoading(false); 
  }, [relayChain, frequency, chainType, selectedChains]);
  
  
  



  return (
    <div>
      {renderControls()}
      {loading ? (
        <LoadingSphere />
      ) : (
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(750px, 1fr))', gap: '20px', padding: '20px', backgroundColor: '#f4f4f4', boxSizing: 'border-box' }}>
        {chartsConfig.map((config) => (
          <div
            key={config.elementId}
            style={{ 
              border: '1px solid #ddd', 
              borderRadius: '10px', 
              padding: '10px', 
              backgroundColor: '#fff', 
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              position: 'relative', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}
          >
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <h2 style={{ margin: 0, marginRight: '10px' }}>{config.title.replace('(Substrate + EVM)', '').trim()}</h2>
              <InfoCubeIconComponent onMouseEnter={() => showDescription(config.elementId)} onMouseLeave={() => hideDescription(config.elementId)} />
            </div>
            <div id={config.elementId} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
            {hovered[config.elementId] && (
              <div style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                fontSize: '14px',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.75)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '50%',
                textAlign: 'full',
                boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
              }}>
                {chartDescriptions[config.elementId]}
              </div>
            )}
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default PolkadotStatsEcosystem;



