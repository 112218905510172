import * as echarts from 'echarts';
import watermarkImage from '../assets/dotlake-transparent-high-quality.png';

// var freq = 'monthly';
var relay = 'polkadot';
// var yName2 = 'substrate';
// var title2 = '(Substrate + EVM)';

let watermark = {
  type: 'image',
  id: 'watermark',
  bounding: 'raw', // Set to 'raw' for no padding
  right: 'center', // Center horizontally
  // bottom: 'center', // Center vertically
  top: 'center',
  z: -1,
  style: {
    image: watermarkImage,
    width: 150, // Adjust width if necessary
    height: 150, // Adjust height if necessary
    opacity: 0.1 // Adjust opacity if necessary
  }
};

let customFormatter = function (params) {
  // Sort the items based on their value for the specific month
  params.sort((a, b) => b.value - a.value);

  // Filter out items with a value of 0
  let filteredParams = params.filter(param => param.value !== 0);

  // Calculate total value for the month from filtered items
  let total = filteredParams.reduce((sum, param) => sum + param.value, 0);

  // Start table for tooltip content with header for alignment and minimal padding
  let tooltipContent = '<div><table class="tb">';

  // Conditionally add total row if number of filtered items is greater than 3
  if (filteredParams.length > 3) {
    tooltipContent += `<tr>
                        <th><b>Total:</b></th>
                        <th><b>${total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2  })}</b></th>
                      </tr>`;
  }

  // Generate rows for each series with right-aligned values, only for filtered items
  filteredParams.forEach(param => {
    tooltipContent += `<tr>
                        <td>${param.marker}${param.seriesName}:</td>
                        <td><b>${param.value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</b></td>
                       </tr>`;
  });

  // Close table and div
  tooltipContent += '</table></div>';

  // Optional: Add a header or any additional content to the tooltip
  let header = params[0] ? params[0].axisValueLabel : '';
  return `<div>${header}<br/>${tooltipContent}</div>`;
}

// Function to update charts configuration based on parameters
export function updateChartsConfig(freq, relay, yName2, topN, selectedChains) {
  return [
      {
          elementId: 'parachains_transactions',
          folder: 'Ecosystem',
          jsonPath: `data/parachains_transactions_${freq}000000000000.jsonl`,
          freq,
          relay,
          selectedChains,
          topN: topN,
          yName: yName2 === 'combined' ? 'combined' : `num_transactions_${yName2}`,
          title: `Number of Transactions`,
          build: barChart
      },
      {
          elementId: 'parachains_unique_accounts',
          folder: 'Ecosystem',
          jsonPath: `data/parachains_unique_accounts_monthly000000000000.jsonl`,
          freq,
          relay,
          selectedChains,
          topN: topN,
          yName: 'number_of_unique_addresses', 
          title: 'Number of Unique Accounts',
          build: uniqueAccountsBarChart, 
          showInDaily: false, 
          showInPolkadot: true 
      },
      {
          elementId: 'parachains_accounts_active',
          folder: 'Ecosystem',
          jsonPath: `data/parachains_accounts_active_${freq}000000000000.jsonl`,
          freq,
          relay,
          selectedChains,
          topN: topN,
          yName: yName2 === 'combined' ? 'combined' : `num_accounts_active_${yName2}`,
          title: `Number of Active Accounts`,
          build: barChart
      },
      {
          elementId: 'parachains_events',
          folder: 'Ecosystem',
          jsonPath: `data/parachains_events_${freq}000000000000.jsonl`,
          freq,
          relay,
          selectedChains,
          topN: topN,
          yName: 'num_events',
          title: 'Number of Events',
          build: barChart
      }
  ];
}

export const generatedColors = Array.from({ length: 8}, (_, index) => {
    const hue = (index * 137.508) % 360;
    const saturation = 50 + Math.random() * 40; // Vary saturation for richer colors
    const lightness = 65 + Math.random() * 20; // Vary lightness for contrast
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  });

// Function to create and initialize chart elements
export function createEchartElement(elementId) {
    const container = document.getElementById('general_' + elementId);
    if (!container) return;
  
    // Dispose the existing chart instance if it exists
    const existingChart = echarts.getInstanceByDom(container);
    if (existingChart) {
      echarts.dispose(existingChart);
    }
  
    // Create a new ECharts instance
    window['general_' + elementId] = echarts.init(container);
}

export function parachains_race(config, data, chart) {
  const chainColors = get_chain_colors();
  const title = config.title;
  const yName = config.yName;
  const xName = config.xName;
  // const currentTheme = localStorage.getItem('theme') || 'light';
  const speed = xName === 'month' ? 1000 : 150;
  
  const parsedData = data.map(d => ({
    timestamp: d[xName],
    chain: d.chain,
    value: parseFloat(d[yName])
  }));
  
  const cumulativeData = {};
  const allChains = [...new Set(parsedData.map(item => item.chain))];
  const cumulativeValues = {};
  
  allChains.forEach(chain => {
    cumulativeValues[chain] = 0;
  });
  
  parsedData.forEach(item => {
    const { timestamp, chain, value } = item;
    if (!cumulativeData[timestamp]) {
      cumulativeData[timestamp] = {};
    }
    cumulativeValues[chain] += value;
    cumulativeData[timestamp][chain] = cumulativeValues[chain];
  });
  
  const timestamps = [...new Set(parsedData.map(item => item.timestamp))].sort();
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: xName === 'month' ? undefined : '2-digit',
  });
  
  const formattedTimestamps = timestamps.map(timestamp => dateFormatter.format(new Date(timestamp)));
  
  const chartData = timestamps.map(timestamp => {
    const dataAtTimestamp = { timestamp, formattedTimestamp: dateFormatter.format(new Date(timestamp)) };
    allChains.forEach(chain => {
      dataAtTimestamp[chain] = cumulativeData[timestamp][chain] || 0;
    });
    return dataAtTimestamp;
  });
  
  const option = {
    timeline: {
      axisType: 'category',
      playInterval: speed,
      autoPlay: true,
      loop: false,
      controlStyle: {
        show: false
      },
      data: formattedTimestamps
    },
    options: chartData.map(d => {
      const sortedChains = allChains.map(chain => ({
        chain,
        value: d[chain] || 0
      })).sort((a, b) => b.value - a.value).slice(0, 10);
  
      const categories = sortedChains.map(item => item.chain);
      const seriesData = sortedChains.map(item => ({
        value: item.value,
        name: item.chain,
        itemStyle: {
          color: chainColors[item.chain] || chainColors['other'],
          opacity: 0.8
        },
        label: {
          show: true,
          position: 'right',
          formatter: () => {
            return `{bold|${item.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}}`;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: 16,
            },
            normal: {
              fontWeight: 'normal',
              fontSize: 16,
            }
          }
        }
      }));
  
      return {
        graphic: watermark,
        title: {
          text: `${title} - ${d.formattedTimestamp}`,
          left: 'center',
          textStyle: {
            fontFamily: 'Unbounded',
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        animationDuration: speed,
        animationDurationUpdate: speed,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let tooltipText = `Timestamp: ${d.formattedTimestamp}<br/>`;
            params.forEach(param => {
              tooltipText += `${param.seriesName}: ${param.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}<br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              return value.toLocaleString(userLocale);
            }
          }
        },
        yAxis: {
          type: 'category',
          data: categories,
          inverse: true
        },
        series: [{
          type: 'bar',
          animationDuration: speed,
          animationEasing: 'linear',
          data: seriesData
        }]
      };
    })
  };
  
  chart.setOption(option, true);
}

export function renameChain(chainName) {
  switch (chainName) {
    case 'alephzero':
      return 'aleph-zero';
    case 'origintrail':
      return 'neuroweb';
    case 'statemint':
      return 'Polkadot Asset Hub';
    case 'polkadot_asset_hub':
      return 'Polkadot Asset Hub';
    case 'statemine':
      return 'Kusama Asset Hub';
    case 'kusama_asset_hub':
      return 'Kusama Asset Hub';
    default:
      return chainName;
  }
}

// export function loadJSONData(jsonPath, relay, selectedChains) {
//   // Append a timestamp to the request URL to avoid caching
//   const urlWithNoCache = `${jsonPath}?v=${new Date().getTime()}`;
//   // console.log(urlWithNoCache); // Log the modified path for verification

//   return fetch(urlWithNoCache)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok.');
//       }
//       return response.text();
//     })
//     .then(data => {
//       // Process the text data to JSON objects
//       const jsonArray = data.split('\n')
//         .filter(line => line.trim() !== '')
//         .map(line => JSON.parse(line));
//       return jsonArray;
//     })
//     .then(generalData => {
//       // Initially filter data based on the relay_chain value
//       let filteredData = generalData.filter(element => element.relay_chain === relay);
//       // Further filter data if selectedChains is not empty
//       if (selectedChains.length > 0) {
//           filteredData = filteredData.filter(element => selectedChains.includes(element.chain));
//       }
//       return filteredData;
//     })
//     .then(filteredData => {
//       // Enhance data if necessary, e.g., combining numeric fields
//       let enhancedData = filteredData.map(element => {
//         // Rename some chains...
//         if (element.chain) {
//           element.chain = renameChain(element.chain);
//         }
//         return element;
//       });
//       // console.log("Filtered Data:", enhancedData); // Log the enhanced data for debugging
//       return enhancedData;
//     })
//     .catch(error => {
//       // console.error("Error loading JSON data:", error);
//       // Consider handling the error by returning an empty array or a specific error object
//       throw error;
//     });
// }
export function loadJSONData(jsonPath, relay, selectedChains) {
  const urlWithNoCache = `${jsonPath}?v=${new Date().getTime()}`;

  return fetch(urlWithNoCache)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.text();
    })
    .then(data => {
      const jsonArray = data.split('\n')
        .filter(line => line.trim() !== '')
        .map(line => JSON.parse(line));
      return jsonArray;
    })
    .then(generalData => {
      if (jsonPath.includes('unique_accounts')) {
        // Special handling for unique accounts data
        let filteredData = generalData;
        if (selectedChains.length > 0) {
          filteredData = filteredData.filter(element => selectedChains.includes(element.chain));
        }
        return filteredData;
      } else {
        let filteredData = generalData.filter(element => element.relay_chain === relay);
        if (selectedChains.length > 0) {
          filteredData = filteredData.filter(element => selectedChains.includes(element.chain));
        }
        return filteredData;
      }
    })
    .then(filteredData => {
      return filteredData.map(element => {
        if (element.chain) {
          element.chain = renameChain(element.chain);
        }
        return element;
      });
    })
    .catch(error => {
      console.error("Error loading JSON data:", error);
      throw error;
    });
}


export function loadJSONDataInk(jsonPath, relayOrChain) {
  // Append a timestamp to the request URL to avoid caching
  const urlWithNoCache = `${jsonPath}?v=${new Date().getTime()}`;

  return fetch(urlWithNoCache)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok.');
          }
          return response.text();
      })
      .then(data => {
          // Process the text data to JSON objects
          return data.split('\n')
              .filter(line => line.trim() !== '')
              .map(line => JSON.parse(line));
      })
      .then(generalData => {
          // Filter data based on the relay_chain or chain value
          return generalData.filter(element => element.relay_chain === relayOrChain || element.chain === relayOrChain);
      })
      .then(filteredData => {
          // Enhance data if necessary, e.g., combining numeric fields
          return filteredData.map(element => {
              let numericFields = Object.keys(element).filter(key => typeof element[key] === 'number');
              if (numericFields.length === 2) {
                  element.combined = numericFields.reduce((sum, key) => sum + element[key], 0);
              }
              return element;
          });
      })
      .catch(error => {
          console.error("Error loading JSON data:", error);
          throw error; // Ensure errors are propagated up to handle them appropriately in the UI
      });
}

export function loadJSONDataWeight(jsonPath, relay, weight) {
  const urlWithNoCache = `${jsonPath}?v=${new Date().getTime()}`;

  return fetch(urlWithNoCache)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.text();
    })
    .then(data => {
      // console.log("Raw data loaded:", data); // Debug statement
      return data.split('\n')
        .filter(line => line.trim() !== '')
        .map(line => JSON.parse(line));
    })
    .then(generalData => {
      // console.log("Parsed JSON data:", generalData); // Debug statement
      return generalData.filter(element => element.relay_chain === relay);
    })
    .then(filteredData => {
      // console.log("Filtered data for relay chain:", filteredData); // Debug statement
      return filteredData.map(element => {
        element.value = element[weight]; // Directly assign the value based on weightType
        return element;
      });
    })
    .catch(error => {
      console.error("Error loading JSON data:", error);
      throw error;
    });
}

export function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

export function populateTable(config, data) {
  const table = document.getElementById(config.elementId);

  if (!table) {
    console.error(`Table with element ID ${config.elementId} not found.`);
    return;
  }

  const tableBody = table.querySelector('tbody');

  if (!tableBody) {
    console.error(`Table body not found for element ID ${config.elementId}.`);
    return;
  }

  // Clear existing table rows
  tableBody.innerHTML = '';

  data.forEach(item => {
    const row = tableBody.insertRow();
    const cellDate = row.insertCell(0);
    const cellNetwork = row.insertCell(1);
    const cellChain = row.insertCell(2);
    const cellVersion = row.insertCell(3);

    cellDate.textContent = item.date;  // Assuming the date format is already correct
    cellNetwork.textContent = item.relay_chain;
    cellChain.textContent = item.chain;
    cellVersion.textContent = item.version;
  });
}

export function get_chain_colors(relay) {
    if (relay === 'polkadot') {
      return {
        'other': '#CCCCCC', // Color for 'Other' category
        'polkadot': '#E6007A',
        'astar': '#56F39A', // Bright Light Green
        'aventus': '#552BBF', // Deep Purple
        'equilibrium': '#00B2FF', // Bright Blue
        'moonbeam': '#33D6FF', // Aqua Blue
        'origintrail': '#AA7BDB', // Light Purple
        'neutoweb': '#AA7BDB', // Light Purple
        'phala': '#FF7F50', // Orange
        'acala': '#2f4f4f', // darkslategray
        'ajuna': '#556b2f', // darkolivegreen
        'bifrost': '#8b4513', // saddlebrown
        'bitgreen': '#6b8e23', // olivedrab
        'centrifuge': '#2e8b57', // seagreen
        'clover': '#228b22', // forestgreen
        'collectives': '#8b0000', // darkred
        'composable': '#483d8b', // darkslateblue
        'crust': '#008080', // teal
        'darwinia': '#b8860b', // darkgoldenrod
        'efinity': '#4682b4', // steelblue
        'frequency': '#d2691e', // chocolate
        'hydradx': '#9acd32', // yellowgreen
        'integritee': '#4b0082', // indigo
        'interlay': '#32cd32', // limegreen
        'invarch': '#00ff00',
        'kapex': '#8fbc8f', // darkseagreen
        'kilt-spiritnet': '#8b008b', // darkmagenta
        'kylin': '#b03060', // maroon3
        'litentry': '#48d1cc', // mediumturquoise
        'manta': '#9932cc', // darkorchid
        'mythos': '#a020f0',
        'nodle': '#ff0000', // red
        'parallel': '#ff8c00', // darkorange
        'pendulum': '#ffd700', // gold
        'polemic': '#00ffff', 
        'polkadex': '#ffff00', // yellow
        'polkadot-hashed': '#c71585', // mediumvioletred
        'polkadot-t3rn': '#0000cd', // mediumblue
        'Polkadot Asset Hub': '#00B2FF',
        'statemint': '#00ff00', // lime
        'subsocial': '#7cfc00',
        'unique': '#00ff7f', // springgreen
        'zeitgeist': '#dc143c' // crimson
        // Add more chains and their colors here
      }
    } else if (relay === 'kusama') {
      return {
        'other': '#CCCCCC', // Color for 'Other' category
        'kusama': '#black', // red
        'altair': '#ffff00', // yellow
        'amplitude': '#00bfff', // deepskyblue
        'bajun': '#b03060', // maroon3
        'basilisk': '#800080', // purple
        'bridgehub': '#f4a460', // sandybrown
        'calamari': '#f0e68c', // khaki
        'crab': '#8fbc8f', // darkseagreen
        'dorafactory': '#daa520', // goldenrod
        'encointer': '#9acd32', // yellowgreen
        'ipci': '#d2691e', // chocolate
        'kabocha': '#000080', // navy
        'karura': '#9370db', // mediumpurple
        'khala': '#0000ff', // blue
        'kintsugi': '#fa8072', // salmon
        'Kusama Asset Hub': '#00B2FF',
        'mangata': '#6495ed', // cornflower
        'moonriver': '#a020f0', // purple3
        'parallel-heiko': '#ff6347', // tomato
        'picasso': '#adff2f', // greenyellow
        'pichiu': '#4682b4', // steelblue
        'quartz': '#008b8b', // darkcyan
        'robonomics': '#008000', // green
        'shiden': '#483d8b', // darkslateblue
        'statemine': '#556b2f', // darkolivegreen
        'tinker': '#d3d3d3', // lightgray
        'turing': '#00B2FF' // dimgray
        // Add more chains and their colors here
      }
    } else if (relay === 'solo') {
      return {
        'other': '#CCCCCC', // Color for 'Other' category
        'aleph-zero': '#f0e68c',
        'sora': '#7f0000', // maroon2
        'chainflip': '#56F39A', // Bright Light Green
        'joystream': '#552BBF', // Deep Purple
        'ternoa': '#00B2FF', // Bright Blue
        'bittensor': '#33D6FF', // Aqua Blue
        'vara': '#AA7BDB', // Light Purple
    }
  }
}

export function uniqueAccountsBarChart(chart, config, data) {
  const xName = 'month';
  const yName = 'number_of_unique_addresses';
  const yTitle = config.title;
  const grouping = 'chain';
  const topN = config.topN === undefined ? 50 : config.topN;

  const colorScheme = get_chain_colors(config.relay);

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: 'bar',
    stack: 'total',
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      bottom: '-2.5%'
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 0.1,
      textStyle: {
        fontSize: '10',
      },
      icon: 'circle',
      itemWidth: 8,
      itemHeight: 8,
      selected: legendData.reduce((obj, item) => {
          obj[item] = true;
          return obj;
      }, {}),
      selector: selectorOptions,
      selectorPosition: 'start',
      left: '700%',
      ...legendPositionAndOrientation
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  chart.setOption(option, true);
}

    // Extras  
    // '#1e90ff', // dodgerblue
    // '#dda0dd', // plum
    // '#90ee90', // lightgreen
    // '#ff1493', // deeppink
    

// Helper function to generate a color palette
export function generateColors(count) {
    return Array.from({ length: count }, (_, index) => {
        const hue = (index * 137.508) % 360; // Golden angle approximation
        return `hsl(${hue}, 70%, 65%)`;
    });
}

export function barchart(config, data, chart) {
  const xName = config.freq === 'daily' ? 'date' : 'month';
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors(relay);

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  const formattedDateMap = new Map();
  data.forEach(item => {
    const originalDate = new Date(item[xName]);
    const formattedDate = dateFormatter.format(originalDate);
    item.formattedDate = formattedDate;
    formattedDateMap.set(item[xName], formattedDate);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort((a, b) => new Date(a) - new Date(b));

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    data: seriesData[chain],
    itemStyle: { 
      color: colorScheme[chain],
      opacity: 0.8
    },
  }));

  // Before setting up the chart options
  let legendData = chains;
  
  // Dynamically adjust the legend's position based on the number of chains
  // let legendPositionAndOrientation = chains.length > 10 ? {
  //   orient: 'vertical', left: '75%', top: '6%', height: '85%'
  // } : {
  //   orient: 'horizontal', left: 'center', bottom: '2%' 
  // };

  // let gridOptions = chains.length > 10 ? { 
  //   left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  // } : {
  //   left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  // }

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }
  // Dynamically add selector buttons if there are more than 3 items in the legend
  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  // Adjustments for legend and grid
  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      // Adjust the toolbox position
      bottom: '-2.5%', // Move the toolbox higher by changing the percentage value
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 0.1,
      textStyle: {
        fontSize: '10',
      },
      icon: 'circle', // You can change 'circle' to 'rect' or other shapes as needed
      itemWidth: 8, // Adjust the width of the legend icon
      itemHeight: 8,
      selected: legendData.reduce((obj, item) => {
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions,
      ...legendPositionAndOrientation 
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates.map(date => formattedDateMap.get(date)), axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  chart.setOption(option, true);
}

export function barChart(chart, config, data) {

  const xName = config.freq === 'daily' ? 'date' : 'month';
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors(relay);

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  // if (config.elementId==='chart_polkadot_monthly_transactions') {
  //   // console.log(data);
  // }

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      // Include 'other' only if the actual number of chains exceeds topN
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      // If not exceeding topN, just keep the top chains without 'other'
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  // Before setting up the chart options
  let legendData = chains;
  
  // Dynamically adjust the legend's position based on the number of chains
  // let legendPositionAndOrientation = chains.length > 10 ? {
  //   orient: 'vertical', left: '75%', top: '6%', height: '85%'
  // } : {
  //   orient: 'horizontal', left: 'center', bottom: '2%' 
  // };

  // let gridOptions = chains.length > 10 ? { 
  //   left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  // } : {
  //   left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  // }

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }
  // Dynamically add selector buttons if there are more than 3 items in the legend
  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  // Adjustments for legend and grid
  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      // Adjust the toolbox position
      bottom: '-2.5%', // Move the toolbox higher by changing the percentage value
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 0.1,
      textStyle: {
        fontSize: '9.2',
      },
      icon: 'circle', // You can change 'circle' to 'rect' or other shapes as needed
      itemWidth: 8, // Adjust the width of the legend icon
      itemHeight: 8, // Adjust the height of the legend icon
      selected: legendData.reduce((obj, item) => {
          // This line ensures all your series are selected by default except for "Select All" and "Select None"
          // Since "Select All" and "Select None" are not in legendData, they won't affect this
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions,
      selectorPosition: 'start',
      left: '700%', // Use the dynamically set selector options here
        ...legendPositionAndOrientation // Spread the dynamically determined properties
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  // console.log("Option for Chart:", option);
  // window[config.elementId].setOption(option, true);
  chart.setOption(option, true);
}


export function lineChart(chart, config, data) {
  // Extract configuration parameters
  const xName = config.xName;
  const yName = config.yName;
  const yTitle = config.yTitle;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = config.stack === undefined ? (type === 'bar' ? 'total' : '') : config.stack;

  const colorScheme = config.colorScheme === undefined ? get_chain_colors(relay) : config.colorScheme;

  // Ensure yName values are parsed as floats
  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, { 'other': otherSum });
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    areaStyle: {},
    emphasis: {
      focus: 'series'
    },
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? {
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true
  }

  let selectorOptions = chains.length > 3 ? [{
    type: 'all',
    title: 'Select All'
  }, {
    type: 'inverse',
    title: 'Invert Selection'
  }] : [];

  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      bottom: '-2.5%'
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'vertical',
      padding: 3,
      icon: 'circle',
      itemWidth: 8,
      itemHeight: 8,
      selected: legendData.reduce((obj, item) => {
        obj[item] = true;
        return obj;
      }, {}),
      selector: selectorOptions,
      ...legendPositionAndOrientation
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  chart.setOption(option, true);
}

export function lineChartStaking(chart, config, data) {
  const xName = config.xName;
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors(relay);

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    areaStyle: {},
    emphasis: {
      focus: 'series'
    },
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  // Calculate the minimum value for the y-axis with 10% leeway
  const yValues = data.map(item => item[yName]);
  const yMin = Math.min(...yValues);
  const yLeeway = yMin * 0.1;
  const yAxisMin = yMin - yLeeway;

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      bottom: '-2.5%'
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'vertical',
      padding: 3,
      icon: 'circle',
      itemWidth: 8,
      itemHeight: 8,
      selected: legendData.reduce((obj, item) => {
          obj[item] = true;
          return obj;
      }, {}),
      selector: selectorOptions,
        ...legendPositionAndOrientation
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { 
      name: yTitle, 
      type: 'value', 
      min: yAxisMin,
      axisLabel: {
        formatter: (value, index) => {
          if (index === 0) {
            return '';
          }
          return value.toLocaleString(); // Add comma separators
        }
      }
    },
    series: series,
  };

  chart.setOption(option, true);
}

export function horizontalBarChart(chart, config, data) {
  const xName = config.xName; // Represents the count or value field
  const yName = config.yName; // Represents the main category (e.g., chain)
  const grouping = config.grouping; // Represents the sub-category (e.g., language)
  // const title = config.title;

  // Ensure the value field is treated as a number
  data.forEach(item => {
    item[xName] = parseFloat(item[xName]);
  });

  // Aggregate data by main category (yName) and sub-category (grouping)
  const aggregatedData = {};
  data.forEach(item => {
    if (!aggregatedData[item[yName]]) {
      aggregatedData[item[yName]] = {};
    }
    if (!aggregatedData[item[yName]][item[grouping]]) {
      aggregatedData[item[yName]][item[grouping]] = 0;
    }
    aggregatedData[item[yName]][item[grouping]] += item[xName];
  });

  // Extract unique main categories (e.g., chains) and sub-categories (e.g., languages)
  const yCategories = Object.keys(aggregatedData).sort();
  const groupings = Array.from(new Set(data.map(item => item[grouping]))).sort();

  // Prepare series data for each sub-category (grouping)
  const series = groupings.map(group => ({
    name: group,
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: yCategories.map(yCat => aggregatedData[yCat][group] || 0)
  }));

  // Chart configuration
  const option = {
    graphic: watermark,
    title: {
      // text: title,
      left: 'center',
      top: '0%'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: groupings,
      orient: 'horizontal',
      padding: 1,
      textStyle: {
        fontSize: '10',
      },
      left: 'center',
      bottom: '2%'
    },
    grid: { left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: yCategories
    },
    series: series
  };

  chart.setOption(option, true);
}

export function populateTableInk(config, data) {
  const tableContainer = document.getElementById(config.elementId);

  if (!tableContainer) {
    console.error(`Container with element ID ${config.elementId} not found.`);
    return;
  }

  // Clear existing content
  tableContainer.innerHTML = '';

  // Create table elements
  const table = document.createElement('table');
  table.style.width = '100%';
  table.style.borderCollapse = 'collapse';
  table.style.marginTop = '20px';
  table.style.fontFamily = "'Roboto', sans-serif";
  table.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  table.style.borderRadius = '8px';
  table.style.overflow = 'hidden';

  const thead = document.createElement('thead');
  const headerRow = document.createElement('tr');
  const headers = ['Date', 'Network', 'Chain', 'Version'];

  headers.forEach(headerText => {
    const th = document.createElement('th');
    th.style.borderBottom = '2px solid #e0e0e0';
    th.style.padding = '16px';
    th.style.textAlign = 'left';
    th.style.backgroundColor = '#f5f5f5';
    th.style.fontWeight = '900';
    th.style.color = '#333';
    th.style.fontSize = '16px';
    th.textContent = headerText;
    headerRow.appendChild(th);
  });

  thead.appendChild(headerRow);
  table.appendChild(thead);

  const tbody = document.createElement('tbody');

  data.forEach((item, index) => {
    const row = document.createElement('tr');
    row.style.transition = 'background-color 0.3s, box-shadow 0.3s';

    row.addEventListener('mouseenter', () => {
      row.style.backgroundColor = '#e0f7fa';
      row.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
    });

    row.addEventListener('mouseleave', () => {
      row.style.backgroundColor = index % 2 === 0 ? '#ffffff' : '#f9f9f9';
      row.style.boxShadow = 'none';
    });

    row.style.backgroundColor = index % 2 === 0 ? '#ffffff' : '#f9f9f9';

    const cellDate = document.createElement('td');
    cellDate.style.padding = '16px';
    cellDate.style.borderBottom = '1px solid #e0e0e0';
    cellDate.style.fontSize = '14px';
    cellDate.style.color = '#333';
    cellDate.textContent = new Date(item.date).toLocaleDateString();
    row.appendChild(cellDate);

    const cellNetwork = document.createElement('td');
    cellNetwork.style.padding = '16px';
    cellNetwork.style.borderBottom = '1px solid #e0e0e0';
    cellNetwork.style.fontSize = '14px';
    cellNetwork.style.color = '#333';
    cellNetwork.textContent = item.relay_chain;
    row.appendChild(cellNetwork);

    const cellChain = document.createElement('td');
    cellChain.style.padding = '16px';
    cellChain.style.borderBottom = '1px solid #e0e0e0';
    cellChain.style.fontSize = '14px';
    cellChain.style.color = '#333';
    cellChain.textContent = item.chain;
    row.appendChild(cellChain);

    const cellVersion = document.createElement('td');
    cellVersion.style.padding = '16px';
    cellVersion.style.borderBottom = '1px solid #e0e0e0';
    cellVersion.style.fontSize = '14px';
    cellVersion.style.color = '#333';
    cellVersion.textContent = item.version;
    row.appendChild(cellVersion);

    tbody.appendChild(row);
  });

  table.appendChild(tbody);
  tableContainer.appendChild(table);
}

export function parachainsRace(chart, config, data) {
  const chainColors = get_chain_colors();
  const title = config.title;
  const yName = config.yName;
  const xName = config.xName;
  const currentTheme = localStorage.getItem('theme') || 'light';
  const speed = xName === 'month' ? 1000 : 150;

  const parsedData = data.map(d => ({
    timestamp: d[xName],
    chain: d.chain,
    value: parseFloat(d[yName])
  }));

  const cumulativeData = {};
  const allChains = [...new Set(parsedData.map(item => item.chain))];
  const cumulativeValues = {};

  allChains.forEach(chain => {
    cumulativeValues[chain] = 0;
  });

  parsedData.forEach(item => {
    const { timestamp, chain, value } = item;
    if (!cumulativeData[timestamp]) {
      cumulativeData[timestamp] = {};
    }
    cumulativeValues[chain] += value;
    cumulativeData[timestamp][chain] = cumulativeValues[chain];
  });

  const timestamps = [...new Set(parsedData.map(item => item.timestamp))].sort();
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: xName === 'month' ? undefined : '2-digit',
  });

  const formattedTimestamps = timestamps.map(timestamp => dateFormatter.format(new Date(timestamp)));

  const chartData = timestamps.map(timestamp => {
    const dataAtTimestamp = { timestamp, formattedTimestamp: dateFormatter.format(new Date(timestamp)) };
    allChains.forEach(chain => {
      dataAtTimestamp[chain] = cumulativeData[timestamp][chain] || 0;
    });
    return dataAtTimestamp;
  });

  const option = {
    timeline: {
      axisType: 'category',
      playInterval: speed,
      autoPlay: true,
      loop: false,
      controlStyle: {
        show: false
      },
      data: formattedTimestamps
    },
    options: chartData.map(d => {
      const sortedChains = allChains.map(chain => ({
        chain,
        value: d[chain] || 0
      })).sort((a, b) => b.value - a.value).slice(0, 10);

      const categories = sortedChains.map(item => item.chain);
      const seriesData = sortedChains.map(item => ({
        value: item.value,
        name: item.chain,
        itemStyle: {
          color: chainColors[item.chain] || chainColors['other'],
          opacity: 0.8
        },
        label: {
          show: true,
          position: 'right',
          formatter: () => {
            return `{bold|${item.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}}`;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: 16,
            },
            normal: {
              fontWeight: 'normal',
              fontSize: 16,
            }
          }
        }
      }));

      return {
        graphic: watermark,
        title: {
          text: `${title} - ${d.formattedTimestamp}`,
          left: 'center',
          textStyle: {
            fontFamily: 'Unbounded',
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        animationDuration: speed,
        animationDurationUpdate: speed,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        tooltip: {
          trigger: 'axis',
          backgroundColor: currentTheme === 'dark' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.7)',
          formatter: function (params) {
            let tooltipText = `Timestamp: ${d.formattedTimestamp}<br/>`;
            params.forEach(param => {
              tooltipText += `${param.seriesName}: ${param.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}<br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              return value.toLocaleString(userLocale);
            }
          }
        },
        yAxis: {
          type: 'category',
          data: categories,
          inverse: true
        },
        series: [{
          type: 'bar',
          animationDuration: speed,
          animationEasing: 'linear',
          data: seriesData
        }]
      };
    })
  };

  chart.setOption(option, true);
}

export const chartsConfig = updateChartsConfig();


//Buttons
export const outerContainerStyle = {
  backgroundColor: '#fff', // Set the background color to white
  padding: '20px', // Padding around the entire container
  borderRadius: '12px', // Rounded corners
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  margin: '20px auto', // Center the container with some margin
  maxWidth: '2000px', // Max width for the container
};

export const controlsContainerStyle = {
  position: 'sticky',
  display: 'flex',
  flexWrap: 'wrap', // Allows controls to wrap for better layout
  gap: '10px', // Space between controls
  justifyContent: 'space-between', // Adjust to space controls properly
  padding: '10px', // Padding around the controls
};

export const groupContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid #ccc',
  borderRadius: '8px',
  padding: '10px', // Reduce padding inside the group container
  position: 'relative',
  width: 'auto',
  minWidth: '120px', // Adjust min-width to fit more buttons
  backgroundColor: '#fff',
  flexGrow: 1, // Allow groups to grow to fill space evenly
};

export const groupTitleStyle = {
  fontWeight: '600',
  color: '#E6007A',
  fontSize: '0.75rem', // Reduce font size for title
  position: 'absolute',
  top: '-10px',
  backgroundColor: '#fff',
  padding: '0 4px',
  left: '50%',
  transform: 'translateX(-50%)',
};

export const buttonStyle = {
  padding: '6px 12px', // Reduce button size
  margin: '3px', // Reduce margin between buttons
  borderRadius: '15px',
  border: '1px solid #E6007A',
  cursor: 'pointer',
  fontWeight: '500',
  backgroundColor: '#fff',
  color: '#E6007A',
  fontSize: '0.8rem', // Reduce font size for buttons
};

export const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#E6007A',
  color: '#ffffff',
};


