import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  padding: 20px;
  margin: 20px 0;
  background-color: #f0f0f0; /* Example background color */
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const Title = styled.h2`
  margin-bottom: 15px;
`;

const ModularContentSection = ({ title, children }) => {
  return (
    <SectionContainer>
      <Title>{title}</Title>
      {children}
    </SectionContainer>
  );
};

export default ModularContentSection;
