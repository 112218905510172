// InteractiveFooter.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns children (like your <p> tags) to center, but allows for override */
  border-radius: 5px;
`;

const DisclaimerText = styled.p`
  text-align: left; /* Left-aligns the text */
  max-width: 100%; /* Ensures the paragraph does not exceed the container width */
  color: #000;
`;

const CopyrightText = styled.p`
  text-align: center; /* Center-aligns the text */
  width: 100%; /* Ensures the paragraph stretches to full container width */
  color: #000;
  margin-top: 20px; /* Adds some spacing between the paragraphs */
`;

const InteractiveFooter = ({ backgroundImage }) => {
  return (
    <FooterContainer>
      <DisclaimerText>Disclaimer: These metrics and charts are provided by Parity Technologies Limited "as is" and without warranties of any kind, either express or implied. While we strive to maintain the accuracy and reliability of the data presented, errors may occur, and we disclaim any liability for such occurrences. Should you identify any inaccuracies, please feel free to contact us.
        Usage of Parity DotLake's dashboards and data requires attribution to Parity DotLake as the source. This acknowledgment is crucial not only for respecting intellectual property rights but also for supporting the ongoing provision and enhancement of our services to the community.</DisclaimerText>
      <CopyrightText>© 2024 Parity Data Dashboards. All rights reserved.</CopyrightText>
      {/* Add any interactive elements or links here */}
    </FooterContainer>
  );
};

export default InteractiveFooter;
