import { useEffect, useState, useRef } from 'react';

const useResizeObserver = (ref, onResize) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;

    if (!observeTarget) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      const { width, height } = entry.contentRect;

      if (width !== dimensions.width || height !== dimensions.height) {
        setDimensions({ width, height });

        // Call the onResize callback if it's provided
        if (typeof onResize === 'function') {
          onResize({ width, height });
        }
      }
    });

    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref, dimensions, onResize]);

  return dimensions;
};

export default useResizeObserver;

