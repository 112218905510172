import React, { useState, useEffect, useRef } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import ReactECharts from 'echarts-for-react';
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto';
import Papa from 'papaparse';

const PolkadotJSComponent = () => {
  const [address, setAddress] = useState('');
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [decimals, setDecimals] = useState(12);
  const [options, setOptions] = useState({});
  const [votingData, setVotingData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]); // Define state for pie chart data
  const [pieChartOptions, setPieChartOptions] = useState({});
  const [votingOptions, setVotingOptions] = useState({});
  const [activeTab, setActiveTab] = useState('results');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const echartsRef = useRef(null);

  useEffect(() => {
    const initApi = async () => {
      const provider = new WsProvider('wss://rpc.polkadot.io');
      const api = await ApiPromise.create({ provider });
      const chainDecimals = api.registry.chainDecimals[0];
      setDecimals(chainDecimals);
    };
    initApi();

    const handleResize = () => {
      if (echartsRef.current) {
        echartsRef.current.resize();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateAddress = (input) => {
    try {
      decodeAddress(input);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    setAddress(input);
    setError(validateAddress(input) ? '' : 'Invalid address format');
  };

  const handleQuery = async () => {
    if (error || !address) {
      alert('Please correct the errors before querying.');
      return;
    }

    setLoading(true);
    const provider = new WsProvider('wss://rpc.polkadot.io');
    const api = await ApiPromise.create({ provider });

    try {
      const identityData = await api.query.identity.identityOf(address);
      const identityInfo = identityData.isSome ? identityData.unwrap().toHuman() : null;
      const displayName = identityInfo && Array.isArray(identityInfo) && identityInfo[0] && identityInfo[0].info && identityInfo[0].info.display && identityInfo[0].info.display.Raw ? identityInfo[0].info.display.Raw : 'No identity set';

      const accountInfo = await api.query.system.account(address);
      const totalBalance = Number(accountInfo.data.free / (10 ** decimals));
      const reserved = Number(accountInfo.data.reserved / (10 ** decimals));
      const frozen = Number(accountInfo.data.frozen / (10 ** decimals));
      const finalBalance = totalBalance + reserved + frozen;
      const freeAmount = totalBalance - frozen;

      const locks = await api.query.balances.locks(address);
      let stakedAmount = 0;
      locks.forEach((lock) => {
        if (lock.reasons.toString() === 'All' || lock.reasons.toString() === 'Staking') {
          stakedAmount += Number(lock.amount) / (10 ** decimals);
        }
      });

      setResult({ finalBalance, totalBalance, freeAmount, reserved, frozen, stakedAmount, displayName });

      setOptions({
        tooltip: { trigger: 'item' },
        legend: { top: '5%', left: 'center', textStyle: { color: 'black' }},
        series: [{
          name: 'Account Metrics',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
          label: { show: false, position: 'center' },
          emphasis: { label: { show: true, fontSize: '18', fontWeight: 'bold' }},
          labelLine: { show: false },
          data: [
            { value: freeAmount, name: 'Transferable', itemStyle: { color: '#E6007A' }},
            { value: stakedAmount, name: 'Staked', itemStyle: { color: '#B00058' }},
            { value: reserved, name: 'Reserved', itemStyle: { color: '#FFC0CB' }}
          ]
        }]
      });
      await fetchVotingData(address); 
    } catch (apiError) {
      console.error('API Error:', apiError);
      setError('Failed to fetch data. Check console for details.');
    }

    setLoading(false);
    await api.disconnect(); 
  };

  function formatNumberWithCommas(value) {
    return parseFloat(value.toFixed(2)).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const fetchVotingData = async (address) => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/data/polkadot_OpenGov_voter_history.csv`);
      if (!response.ok) {
        throw new Error('Failed to fetch voting data.');
      }
  
      const csvText = await response.text();
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          const filteredData = results.data.filter(row => row.voter === address);
          const transformedData = filteredData.map(({ referendum_index, vote_direction, votes, split_abstain}) => ({
            referendum_index, 
            vote_direction, 
            votes: parseInt(votes.replace(/,/g, ''), 10),
            split_abstain: parseInt(split_abstain.replace(/,/g, ''), 10)
          }));
  
          setVotingData(filteredData);
          prepareVotingChart(transformedData);
  
          // Count the occurrences of each vote direction
          const voteCounts = {
            'aye': 0,
            'nay': 0,
            'split_abstain': 0,
          };
  
          filteredData.forEach(item => {
            voteCounts[item.vote_direction]++;
          });
  
          const pieChartData = Object.entries(voteCounts).map(([direction, count]) => ({
            name: direction,
            value: count,
          }));
          
  
          // Set the pie chart data and options
          setPieChartData(pieChartData);
          setPieChartOptions(preparePieChartOptions(pieChartData));
        }
      });
    } catch (error) {
      console.error("Error fetching or parsing the CSV:", error);
    }
  };
  

  const prepareVotingChart = (data) => {
    const referendumData = {};

    data.forEach(item => {
        if (!referendumData[item.referendum_index]) {
            referendumData[item.referendum_index] = { aye: 0, nay: 0, abstain: 0, split_abstain: 0, split_aye: 0, split_nay: 0 };
        }
        referendumData[item.referendum_index][item.vote_direction] += parseInt(item.votes, 10) || 0;
        if (item.split_abstain && parseInt(item.split_abstain, 10) !== 0) {
            referendumData[item.referendum_index].split_abstain += parseInt(item.split_abstain, 10);
        }
        if (item.split_aye && parseInt(item.split_aye, 10) !== 0) {
            referendumData[item.referendum_index].aye += parseInt(item.split_aye, 10);
        }
        if (item.split_nay && parseInt(item.split_nay, 10) !== 0) {
            referendumData[item.referendum_index].nay += parseInt(item.split_nay, 10);
        }
    });
  
    const categories = Object.keys(referendumData).sort((a, b) => parseInt(a) - parseInt(b));
    const ayeData = [];
    const nayData = [];
    const splitAbstainData = [];

    categories.forEach(category => {
        ayeData.push(referendumData[category].aye || 0);
        nayData.push(referendumData[category].nay || 0);
        splitAbstainData.push(referendumData[category].split_abstain || 0);
    });

    const series = [];

    if (ayeData.some(val => val !== 0)) {
        series.push({
            name: 'aye',
            type: 'bar',
            stack: 'total',
            data: ayeData,
            emphasis: { focus: 'series' },
            itemStyle: { color: '#2DD078' }
        });
    }

    if (nayData.some(val => val !== 0)) {
        series.push({
            name: 'nay',
            type: 'bar',
            stack: 'total',
            data: nayData,
            emphasis: { focus: 'series' },
            itemStyle: { color: '#E44663' }
        });
    }

    if (splitAbstainData.some(val => val !== 0)) {
        series.push({
            name: 'split_abstain',
            type: 'bar',
            stack: 'total',
            data: splitAbstainData,
            emphasis: { focus: 'series' },
            itemStyle: { color: '#9E9E9E' }
        });
    }
  
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' }
        },
        legend: {
            data: series.map(item => item.name),
        },
        grid: {
            left: '5%',
            right: '4%',
            bottom: '8%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: categories,
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Referendum Number',
                nameLocation: 'middle', // Place the x-axis label underneath the axis
                nameGap: 25 // Adjust the gap between the axis and the label
            }
            
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Voted Amount (DOT)',
                // nameLocation: 'middle', // Place the y-axis label on the left side of the axis
                // nameGap: 40 // Adjust the gap between the axis and the label
            }
        ],
        series: series
    };
  
    setVotingOptions(options);
};

const preparePieChartData = (data) => {
    const voteCounts = {
        'aye': 0,
        'nay': 0,
        'split_abstain': 0,
    };

    data.forEach(item => {
        voteCounts[item.vote_direction]++;
    });

    // Filter out categories with zero counts
    const filteredData = Object.entries(voteCounts)
        .filter(([direction, count]) => count !== 0)
        .map(([direction, count]) => ({
            name: direction,
            value: count,
        }));

    return filteredData;
};

const preparePieChartOptions = (data) => {
    const colors = {
      'aye': '#2DD078',
      'nay': '#E44663',
      'split_abstain': '#9E9E9E',
    };

    // Calculate total count of all sections
    const totalCount = data.reduce((total, item) => total + item.value, 0);

    return {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        top: '5%',
        left: 'center',
        textStyle: {
          color: 'black',
        },
      },
      graphic: [
        // Add a custom graphic element for the total count in the middle
        // {
        //   type: 'text',
        //   left: 'center',
        //   top: 'center',
        //   style: {
        //     text: totalCount.toString(), // Display the total count as text
        //     textAlign: 'center',
        //     fontSize: 20,
        //     fill: '#000', // Text color
        //   },
        // },
      ],
      series: [
        {
          name: 'Vote Direction',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: true,
            position: 'inside', // Show labels inside the pie chart
            formatter: '{b}: {c}', // Format: label: value (percentage)
            fontSize: 12,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data.map(item => ({
            ...item,
            itemStyle: {
              color: colors[item.name],
            },
          })),
        },
      ],
    };
};  

  const nextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = votingData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className="polkadot-card" style={{ width: '90%', maxWidth: '1400px', margin: '0 auto' }}>
      <h1>Polkadot Account Explorer</h1>
      <div className="input-group">
        <input type="text" value={address} onChange={handleInputChange} className={`input ${error ? 'input-error' : ''}`} placeholder="Enter Polkadot Address" />
        <button onClick={handleQuery} disabled={loading || error} className="query-button">{loading ? 'Loading...' : 'Query'}</button>
      </div>
      {error && <p className="error-message">{error}</p>}
      {result.totalBalance && (
        <>
          <div className="tab-navigation" style={{ display: result.totalBalance ? 'block' : 'none' }}>
            <button 
              className={`tab-button ${activeTab === 'results' ? 'active' : ''}`}
              onClick={() => setActiveTab('results')}
            >
              Account Details
            </button>
            <button 
              className={`tab-button ${activeTab === 'history' ? 'active' : ''}`}
              onClick={() => setActiveTab('history')}
            >
              Voting History
            </button>
          </div>
          <div className="results">
            {activeTab === 'results' && (
              <>
                <div className="metrics">
                  <div className="metric-item" data-title="Identity"><span>{result.displayName}</span></div>
                  <div className="metric-item" data-title="Balance"><span>{formatNumberWithCommas(result.finalBalance)} DOT</span></div>
                  <div className="metric-item" data-title="Transferable"><span>{formatNumberWithCommas(result.freeAmount)} DOT</span></div>
                  <div className="metric-item" data-title="Staked"><span>{formatNumberWithCommas(result.stakedAmount)} DOT</span></div>
                  <div className="metric-item" data-title="Reserved"><span>{formatNumberWithCommas(result.reserved)} DOT</span></div>
                </div>
                <br></br>
                <div className="chart-container" style={{ border: '1px solid #ccc', padding: '10px' }}>
                  <ReactECharts ref={echartsRef} option={options} style={{ height: '30vh', width: '100%'}} />
                </div>
              </>
            )}
            
            {activeTab === 'history' && (
              <div className="voting-table" style={{ padding: '10px', marginTop: '20px' }}>
                <div style={{ marginBottom: '10px' }}>
                  <div className="chart-container" style={{ border: '1px solid #ccc', padding: '10px', float: 'left', height: '80%', width: '73%', marginBottom: '15px' }}>
                    <ReactECharts option={votingOptions} style={{ height: '22vh', width: '100%'}} />
                  </div>
                  <div className="chart-container" style={{ border: '1px solid #ccc', padding: '10px', float: 'right', height: '80%', width: '25%', marginBottom: '15px' }}>
                    <ReactECharts option={preparePieChartOptions(preparePieChartData(votingData))} style={{ height: '22vh', width: '100%'}} />
                  </div>
                </div>
                <br></br>
                <table style={{ clear: 'both', marginTop: '20px'  }}>
                  <thead>
                    <tr>
                      <th>Referendum Index</th>
                      <th>Vote Type</th>
                      <th>Vote Direction</th>
                      <th>Conviction</th>
                      <th>Capital</th>
                      <th>Votes</th>
                      <th>Split Abstain</th>
                      <th>Split Aye</th>
                      <th>Split Nay</th>
                      <th>Outcome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.map((item, index) => (
                      <tr key={index}>
                        <td>{item.referendum_index}</td>
                        <td>{item.vote_type}</td>
                        <td>{item.vote_direction}</td>
                        <td>{item.conviction || '-'}</td>
                        <td>{item.capital}</td>
                        <td>{item.votes}</td>
                        <td>{item.split_abstain}</td>
                        <td>{item.split_aye}</td>
                        <td>{item.split_nay}</td>
                        <td>{item.outcome}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                  <button onClick={nextPage} disabled={indexOfLastRow >= votingData.length}>Next</button>
                </div>
                <br></br>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );  
}  

export default PolkadotJSComponent;
