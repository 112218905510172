// components/GraphBlock.js
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const GraphBlock = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
          type: 'shadow'        // 'shadow' as tooltip trigger type
        }
      },
      xAxis: {
        type: 'category',
        data: data.labels,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Votes',
        type: 'bar',
        barWidth: '60%',
        data: data.datasets[0].data,
        itemStyle: {
          color: (params) => {  // Dynamic coloring
            return data.datasets[0].backgroundColor[params.dataIndex];
          }
        }
      }]
    };

    myChart.setOption(option);

    // Clean up code for when the component unmounts or data changes
    return () => {
      myChart.dispose();
    };
  }, [data]);  // Only re-run the effect if `data` changes

  return (
    <div ref={chartRef} style={{ width: '100%', height: '400px', padding: '20px', margin: '10px', backgroundColor: 'white', borderRadius: '8px' }}>
    </div>
  );
};

export default GraphBlock;
