import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingScreen = () => {
  // Polkadot primary color and background color
  const polkadotPrimaryColor = '#e6007a';
  const backgroundColor = '#f5f5f5';

  // Custom spinner icon
  const antIcon = <LoadingOutlined style={{ fontSize: 48, color: polkadotPrimaryColor }} spin />;

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: backgroundColor }}>
      <div style={{ textAlign: 'center' }}>
        <Spin indicator={antIcon} />
        {/* <div style={{ marginTop: 20, color: polkadotPrimaryColor, fontFamily: 'Arial, sans-serif', fontSize: '1.5em' }}>
          Loading Data...
        </div> */}
      </div>
    </div>
  );
};

export default LoadingScreen;
