import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { loadJSONDataWeight, barchart, outerContainerStyle, controlsContainerStyle, groupContainerStyle, groupTitleStyle, buttonStyle, selectedButtonStyle } from './js/charts_ecosystem';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import { useDebouncedResize, chartDescriptions } from './js/echart_utils';

const PolkadotBlockWeight = () => {
  const [relayChain, setRelayChain] = useState('polkadot');
  const [weightType, setWeightType] = useState('normal'); // 'normal', 'operational', 'mandatory'
  const avgChartRef = useRef(null);
  const maxChartRef = useRef(null);
  const [chartsConfig, setChartsConfig] = useState([]);
  const [hovered, setHovered] = useState({});
  const showDescription = id => setHovered({ ...hovered, [id]: true });
  const hideDescription = id => setHovered({ ...hovered, [id]: false });

  useDebouncedResize(() => {
    [avgChartRef.current, maxChartRef.current].forEach(chart => {
      if (chart) chart.resize();
    });
  }, 100);

  useEffect(() => {
    const newConfig = {
      relay: relayChain,
      y: weightType,
    };

    const updatedChartsConfig = updateChartsConfig(newConfig);
    setChartsConfig(updatedChartsConfig);

    updatedChartsConfig.forEach(cfg => {
      const initializeChart = () => {
        const container = document.getElementById(cfg.elementId);
        if (!container) {
          // console.error(`Container for ${cfg.elementId} not found`);
          return;
        }

        const existingChart = echarts.getInstanceByDom(container);
        const chart = existingChart || echarts.init(container, null, { renderer: 'svg' });

        loadJSONDataWeight(cfg.jsonPath, relayChain, cfg.yName).then(data => {
          if (!Array.isArray(data)) {
            // console.error(`Expected array but got ${typeof data}`);
            return;
          }
          if (data.length === 0) {
            // console.warn(`No data loaded for ${cfg.elementId}`);
            return;
          }
          barchart(cfg, data, chart);
        }).catch(error => console.error(`Failed to load data for chart ${cfg.elementId}:`, error));

        if (cfg.elementId.includes('avg')) {
          avgChartRef.current = chart;
        } else {
          maxChartRef.current = chart;
        }
      };

      if (document.getElementById(cfg.elementId)) {
        initializeChart();
      } else {
        const interval = setInterval(() => {
          if (document.getElementById(cfg.elementId)) {
            clearInterval(interval);
            initializeChart();
          }
        }, 100);
      }
    });
  }, [relayChain, weightType]);

  const renderControls = () => {
    
    return (
      <div style={{ width: '100%', margin: '0 auto' }}>
        <div style={outerContainerStyle}>
        <div style={controlsContainerStyle}>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Relay Chain</div>
            <div>
              <button style={relayChain === 'polkadot' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('polkadot')}>
                Polkadot
              </button>
              <button style={relayChain === 'kusama' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('kusama')}>
                Kusama
              </button>
              <button style={relayChain === 'solo' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('solo')}>
                Solo
              </button>
            </div>
          </div>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Weight Type</div>
            <div>
              <button style={weightType === 'normal' ? selectedButtonStyle : buttonStyle} onClick={() => setWeightType('normal')}>
                Normal
              </button>
              <button style={weightType === 'operational' ? selectedButtonStyle : buttonStyle} onClick={() => setWeightType('operational')}>
                Operational
              </button>
              <button style={weightType === 'mandatory' ? selectedButtonStyle : buttonStyle} onClick={() => setWeightType('mandatory')}>
                Mandatory
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };
  

  return (
    <div>
      {renderControls()}
      <div className="website-grid-container">
        {chartsConfig.map((config) => (
          <div
            key={config.elementId}
            style={{
              width: '100%',
              maxWidth: '100%',
              padding: '20px',
              border: '2px solid #e0e0e0',
              borderRadius: '15px',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              boxSizing: 'border-box',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <h2 style={{ margin: 0, marginRight: '10px' }}>{config.title}</h2>
              <InfoCubeIconComponent onMouseEnter={() => showDescription(config.elementId)} onMouseLeave={() => hideDescription(config.elementId)} />
            </div>
            <div id={config.elementId} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
            {hovered[config.elementId] && (
              <div style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                fontSize: '14px',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.75)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '50%',
                textAlign: 'full',
                boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
              }}>
                {chartDescriptions[config.elementId]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PolkadotBlockWeight;

const updateChartsConfig = (config) => {
  return [
    createChartConfig('block_weight_avg_chart', config, 'avg'),
    createChartConfig('block_weight_max_chart', config, 'max')
  ];
};

const createChartConfig = (elementId, config, yType) => {
  const title = yType === 'avg' ? 'Average Block Weight' : 'Max Block Weight';
  const yName = `${config.y}_${yType}`;

  return {
    elementId,
    jsonPath: `data/block_weights000000000000.jsonl`,
    freq: 'daily',
    relay: config.relay,
    yName,
    title,
    build: barchart
  };
};


