import React from 'react';
// import Header from './components/Header';
// import Sidebar from './components/Sidebar';
// import PolkadotJSComponent from './components/PolkadotJSComponent';
import PolkadotAccountExplorerComponent from './components/PolkadotAccountExplorerComponent';

import DynamicContent from './components/DynamicContent';

function PolkadotAccountExplorer() {
  return (
    <div className="App" style={{ display: 'flex', minHeight: '100vh' }}>
      {/* <Sidebar /> */}
      <div style={{ flex: 1, padding: '20px' }}>
        {/* <Header /> */}
        <PolkadotAccountExplorerComponent />
        {/* <PolkadotStatsGeneral /> */}
      </div>
    </div>
  );
}

export default PolkadotAccountExplorer;



