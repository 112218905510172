// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import DataWebsite from './DataWebsite';
// // import HomePage from './HomePage'; // Your new Home Page component

// function App() {
//   return (
//     <Router>
//       <Routes>
//         {/* <Route path="/dashboard" element={<Dashboard />} /> */}
//         <Route path="/DataWebsite" element={<DataWebsite />} />
//         <Route path="/" element={<DataWebsite />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DataWebsite from './DataWebsite';

const App = () => (
  <Router>
    <Routes>
      <Route path="/*" element={<DataWebsite />} />
    </Routes>
  </Router>
);

export default App;

