import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Layout, Menu, Tabs, Collapse } from 'antd';
import './css/main.css';
import * as echarts from 'echarts';
import { chartsConfig } from './js/eoy_charts.js';
import { loadCSVData } from './js/echart_utils.js';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import MonthlyReportAppendix from './components/MonthlyReportAppendixComponent';
import ChartDescriptionComponent from './components/ChartDescriptionComponent'


const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const PolkadotMonthlyReport = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [activeNestedKey, setActiveNestedKey] = useState(chartsConfig[0]?.elementId || 'chart_polkadot_staking_rate');


    const chartRefs = useRef({});
    const sectionRefs = useRef({});
    const [tabsVisible, setTabsVisible] = useState(true);

    const toggleTabs = () => {
        setTabsVisible(!tabsVisible);
      };

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };
      
  
    const createEchartElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        return echarts.init(element);
      }
      return null;
    };
  
    const resizeCharts = () => {
      Object.values(chartRefs.current).forEach(chart => {
        if (chart) {
          chart.resize();
        }
      });
    };
  
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    };
  
    const handleResize = useCallback(debounce(() => {
      resizeCharts();
    }, 100), []);
  
    useEffect(() => {
      const initCharts = () => {
        chartsConfig.forEach((config) => {
          if (!chartRefs.current[config.elementId]) {
            const chart = createEchartElement(config.elementId);
            if (chart) {
              chartRefs.current[config.elementId] = chart;
              loadCSVData(config.csvPath, (data) => {
                config.build(chart, data);
                chart.resize(); // Ensure the chart resizes properly after data is loaded
              });
            } else {
            //   console.error(`Failed to create chart for ${config.elementId}`);
            }
          }
        });
      };
  
      setTimeout(initCharts, 100);
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        Object.values(chartRefs.current).forEach(chart => chart && chart.dispose());
        chartRefs.current = {};
      };
    }, [activeKey, activeNestedKey, handleResize]);
  

  return (
        <Layout className="layout">
            <Menu theme="#f5f5f5" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" style={{ fontWeight: 'bold' }}>
                    <img src="assets/eoyr/dots.png" alt="DotLake" style={{ marginRight: '8px', verticalAlign: 'middle', height: '18px' }} />
                    DotLake Monthly Report
                </Menu.Item>            
            </Menu>

            <Content style={{ padding: '10px 10px', maxWidth: '1600px' }}>
                {/* <Collapse
                    activeKey={collapsed ? [] : ['1']}
                    onChange={toggleCollapse}
                    expandIcon={({ isActive }) =>
                        isActive ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
                    }
                > */}
            {/* <Panel header="Main Sections" key="1" className="site-collapse-custom-panel"> */}
                
            <Tabs defaultActiveKey="1" onChange={(key) => setActiveKey(key)} type="line" tabPosition="top">            

            <TabPane tab="Introduction" key="1">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Introduction" key="1">
                        <Tabs
                            defaultActiveKey="1"
                            onChange={(key) => setActiveNestedKey(key)}
                            type="card"
                            tabPosition="top"
                            className="custom-tab-bar"
                            style={{ maxWidth: '2000px' }}
                        >
                            <TabPane tab="1.1. Overview" key="1">
                                <div style={{ backgroundColor: '#fff', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <h2>1.1. Overview</h2>
                                        <p>Welcome to our comprehensive report on Polkadot, a groundbreaking blockchain platform designed to enable a decentralized web. This report combines detailed dashboards and metrics with informative wiki-style descriptions to provide valuable insights into various aspects of Polkadot.</p>
                                        <p>Polkadot, developed by Web3 Foundation, aims to solve the scalability and interoperability issues facing blockchain networks. By enabling multiple blockchains to interoperate in a secure and scalable manner, Polkadot is paving the way for the next generation of blockchain technology.</p>
                                        <p>If you're new to Polkadot, we recommend starting with the "What is Polkadot" section to understand its design and architecture. For those with technical expertise, you may wish to dive directly into the "Shared Security of the Network" section, which offers the first set of graphs and in-depth analyses.</p>
                                        <p>All charts presented are in monthly increments unless otherwise specified. Your feedback is crucial to us. Please share any suggestions or requests for additional data and metrics for future reports: <a href="mailto:data-team@parity.io">data-team@parity.io</a>.</p>
                                    </div>
                                    <img src="assets/eoyr/polkadot_logo_dark.png" alt="Polkadot Overview" style={{ width: '30%', marginLeft: '20px' }} />
                                </div>
                            </TabPane>
                            {/* <TabPane tab="1.2 Get Started" key="2">
                                <div style={{ backgroundColor: '#fff', padding: '24px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <h2>Getting Started with Polkadot</h2>
                                        <p>Begin your journey into the Polkadot ecosystem by understanding its core components and unique features. This section provides a primer on the basics of Polkadot, ideal for newcomers.</p>
                                        <p>Polkadot's unique architecture allows it to process multiple transactions on several chains in parallel, improving scalability and throughput.</p>
                                        <p>Learn about Polkadot's multi-chain architecture, consensus mechanisms, and the roles of different network participants such as validators, nominators, and collators:</p>
                                        <ul>
                                            <li><strong>Validators:</strong> Secure the Relay Chain by staking DOT, validating proofs from collators, and participating in consensus.</li>
                                            <li><strong>Nominators:</strong> Secure the Relay Chain by selecting trustworthy validators and staking DOT.</li>
                                            <li><strong>Collators:</strong> Maintain parachains by collecting transactions from users and producing proofs for validators.</li>
                                        </ul>
                                        <p>Explore the tools and resources available to developers and users, including Polkadot.js, Substrate, and various educational materials to help you get started with building and interacting with the Polkadot network.</p>
                                    </div>
                                    <img src="assets/eoyr/alpha.png" alt="Getting Started" style={{ width: '30%', marginLeft: '20px' }} />
                                </div>
                            </TabPane> */}
                        </Tabs>
                    </Panel>
                </Collapse>
            </TabPane>


            <TabPane tab="What is Polkadot" key="2">
            <Collapse defaultActiveKey={['1']}>
                <Panel header="What is Polkadot?" key="1">
                <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
                    <TabPane tab="2.1. Blockspace Ecosystem" key="1">
                    <h2 id="section2-1">2.1. The blockspace ecosystem for boundless innovation</h2>
                    <div style={{ overflow: 'auto' }}>
                        <img 
                        className="m-1" 
                        src="assets/eoyr/blocks_space.avif" 
                        alt="Blockspace Ecosystem" 
                        style={{ width: '40%', marginRight: '20px', marginBottom: '20px', float: 'right' }} 
                        />
                        <p><strong>Polkadot</strong> is a heterogeneous multichain platform characterized by shared security and interoperability. It is designed to enable multiple blockchains to transfer messages, including value, in a trust-free fashion. Key components of its architecture include the Relay Chain, Parachains, and Bridges.</p>
                        <p><strong>Relay Chain:</strong> The central chain of Polkadot, responsible for the network's shared security, consensus, and cross-chain interoperability. It is deliberately minimal in functionality (e.g., does not support smart contracts) and focuses on coordinating the system, including parachains.</p>
                        <p><strong>Parachains - dedicated and on-demand:</strong> Polkadot facilitates a sharded network where transactions are processed in parallel. Parachains are application-specific layer-1 individual blockchains that connect to the Relay Chain, have their own tokens, and can be optimized for specific use cases. L1 chains attached to Polkadot benefit from its shared security model, including the Nominated-Proof-of-Stake (NPoS) mechanism, which provides out-of-the-box security without the need for individual chains to bootstrap their own. Parachains can have a dedicated slot procured by on-chain auction (current model), or operate on a more flexible on-demand basis, procuring Bulk or Instantaneous Coretime, trading blockspace, and utilising only specific amount of blockspace needed for individual block production (new model).</p>
                        <p><strong>Cross-Consensus Messaging Format (XCM)</strong> facilitates trust-free message passing between parachains, enhancing interoperability.</p>
                        <p><strong>Bridges:</strong> These enable connections between Polkadot and external blockchains (like Ethereum), allowing for the transfer of data and tokens.</p>
                        <p>The <strong>technology stack</strong> of Polkadot includes:</p>
                        <ul>
                        <li><strong>Polkadot Runtime:</strong> The core state transition logic, upgradable without a hard fork.</li>
                        <li><strong>Polkadot Host:</strong> The environment executing the runtime, involving components like networking (Libp2p), state storage, consensus engines (GRANDPA and BABE), Wasm interpreter, and low-level blockchain primitives.</li>
                        <li><strong>WebAssembly (Wasm):</strong> Used as the compilation target for the runtime, ensuring platform-agnostic and efficient execution.</li>
                        </ul>
                        {/* <img 
                        className="m-1" 
                        src="assets/eoyr/Polkadot_Token_PolkadotToken_Pink.png" 
                        alt="Blockspace Ecosystem" 
                        style={{ width: '30%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                        /> */}
                        <p><strong>Key features</strong> include:</p>
                        <ul>
                        <li><strong>Forkless Upgrades:</strong> Enabled by storing the runtime on-chain and using Wasm, Polkadot can upgrade without hard forks.</li>
                        <li><strong>Consensus Mechanisms:</strong> It employs Nominated Proof-of-Stake (NPoS), hybrid consensus (combining BABE for block production and GRANDPA for finality), and BEEFY for bridging with non-Polkadot networks.</li>
                        </ul>
                        <p>Stakeholders in the Polkadot ecosystem include <strong>Validators</strong> (block producers on the Relay Chain), <strong>Nominators</strong> (stake bonders supporting validators), and <strong>Collators</strong> (maintaining parachains and providing proofs to validators).</p>
                    </div>
                    </TabPane>

                    <TabPane tab="2.2. Ubiquitous Computing Engine" key="2">
                    <h2 id="section2-2">2.2. Ubiquitous computing engine</h2>
                    <div style={{ overflow: 'auto' }}>
                        <img 
                        className="m-1" 
                        src="assets/eoyr/coretime.avif" 
                        alt="Ubiquitous Computing Engine" 
                        style={{ width: '50%', marginLeft: '20px', marginBottom: '20px', float: 'left' }} 
                        />
                        <p>Based on <a href="https://www.youtube.com/watch?v=GIB1WeVuJD0" target="_blank" rel="noopener noreferrer">Gavin Wood's talk at Polkadot Decoded 2023</a>, the direction of Polkadot 1.0 illuminates a shift towards more abstract and generalized blockchain functionality, moving beyond the original concepts in its whitepaper.</p>
                        <p>Polkadot is a platform for building applications using underlying resources called <a href="https://wiki.polkadot.network/docs/polkadot-v1#polkadots-blockspace" target="_blank" rel="noopener noreferrer">Blockspace</a>. It provides a resilient general-purpose continuation computation and operates like a multicore computer with chains called parachains operating in parallel.</p>
                        <p>Polkadot is transitioning from a chain-centric ecosystem, where each parachain owned a dedicated execution core, to an application-centric model, where cores are shared resources. The previous model used slot auctions for securing parachain slots, but with the shift towards a coretime marketplace, applications can now purchase or reserve coretime on demand, significantly lowering entry barriers for developers.</p>
                        <p>The new model introduces agile core usage and coretime allocation, ensuring efficient utilization of Polkadot's computation and blockspace according to application needs, without waste. "Accords" are set to improve cross-chain communication and security of XCM messages. Furthermore, Polkadot plans to increase its scalability by offloading on-chain logic to system parachains, thus freeing up more bandwidth for parachain protocols and accords.</p>
                        <p>The coretime marketplace represents a departure from traditional slot auctions, treating coretime as a tradeable tokenized commodity. Applications can opt for bulk or instantaneous coretime rental, with options to standardize the rental through a broker system or purchase immediate use at a spot price.</p>
                        <p>Polkadot's shift towards application-centricity focuses on leveraging the collaborative potential of different chains to build applications that operate across chains, addressing real-world problems.</p>
                        <p>Polkadot is perfecting its implementation through <a href="https://github.com/polkadot-fellows/RFCs" target="_blank" rel="noopener noreferrer">RFCs</a> to continue being a decentralized, secure, ubiquitous computing engine to power the next generation of <a href="https://wiki.polkadot.network/docs/web3-and-polkadot" target="_blank" rel="noopener noreferrer">Web3</a> applications.</p>
                    </div>
                    </TabPane>
                </Tabs>
                </Panel>
            </Collapse>
            </TabPane>

            <TabPane tab="Shared Security" key="3">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Shared Security" key="1">
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="3.1. Polkadot's Security Architecture" key="1">
                        <h2 id="section3-1">3.1. Polkadot's Security Architecture</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/p_all.png" 
                            alt="Blockspace Ecosystem" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p><strong>Relay Chain:</strong> The core of Polkadot, crucial for its security, consensus, and cross-chain communication. It doesn't directly support smart contracts or user applications, focusing instead on coordinating the entire system. Validators on the Polkadot network are staked in DOT on the Relay Chain, which features a limited range of transaction types primarily for governance, parachain auctions, and NPoS participation. Its functionality is intentionally minimal, delegating specialized core protocol features to various system chains and specific tasks to parachains that offer diverse implementations and features. This design ensures efficient overall coordination and flexibility within the Polkadot ecosystem.</p>
                            <p><strong>Nominated Proof of Stake (NPoS)</strong> on Polkadot is an election algorithm designed to achieve three primary goals: maximizing the total amount staked, maximizing the stake behind the minimally staked validator, and minimizing stake variance across validators. It employs algorithms like Sequential Phragmén, Phragmms, and Star Balancing for computing solutions. The Sequential Phragmén method elects validators based on their self-stake and stakes from nominators, balancing weights between validators after each election. This complex optimization is performed off-chain by Polkadot to maintain consistent block times.</p>
                            <p>NPoS functions in the background, requiring little user intervention, although understanding its mechanics is beneficial for nominators. In Polkadot's system, voter weight is proportional to token holdings, mirroring a corporate shareholder election more than a traditional political one. The Weighted Phragmén algorithm, an extension of the basic method, sequentially elects candidates while building a weighted mapping of each nominator's selection for validators, ensuring fair stake distribution. Post-election, a redistribution process attempts to minimize variance in voter stakes among elected candidates, ideally allowing each nominator to support a single validator per era.</p>
                            <p>In Polkadot, proportional representation ensures decentralization by preventing any minority group from having excessive influence. Traditional systems focus on avoiding underrepresentation of groups, but Polkadot goes further by also avoiding overrepresentation. This approach enhances the security of the network, as a more evenly distributed representation makes it harder for any single group to dominate or manipulate the system. Polkadot's use of the seqPhragmen election rule is unique in this respect, offering an added layer of security through balanced representation.</p>
                        </div>
                        </TabPane>

                        <TabPane tab="3.2. Decentralization - Nakamoto Coefficient" key="2">
                        <h2 id="section3-2">3.2. Decentralization - Nakamoto Coefficient</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/chainscape_2.png" 
                            alt="Parachains" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'left' }} 
                            />
                            <p>In the world of blockchain and cryptocurrencies, decentralization is a key feature for security and fairness. One common approach to quantify network's decentralization is through the Nakamoto Coefficient metric. In simplified terms, it represents a minimum number of validators controlling more than 33% of stake in PoS networks.</p>
                            <p><strong>Polkadot's Approach to Decentralization</strong></p>
                            <p>Polkadot employs a distinctive method to ensure network decentralization, primarily focusing on its validator selection process. Unlike other blockchain networks where a validator's stake amount can significantly influence consensus power, Polkadot operates differently. All active validators in Polkadot's GRANDPA consensus protocol have equal voting power regardless of their stake. This design choice is crucial in maintaining a balanced and decentralized network.</p>
                            <p><strong>The Phragmen Election Algorithm</strong></p>
                            <p>Polkadot's network utilizes the Nominated-Proof-of-Stake (NPoS) mechanism and Phragmen election algorithm to select validators. This algorithm optimizes the validator set by maximizing the total value staked, boosting the stake behind the least staked validator in the set, and minimizing the variance in stake distribution. With a total of ~1,000 validators, a third actively participate in validation at any given moment, while the remainder are queued, awaiting their turn. This dynamic validator set evolves every era (about 24 hours), ensuring robust network security and participation. Below you can see a monthly snapshot of this process where the number of active validators and waiting validators are listed for the last block of a given month - the number of active validators is constant while there can be small fluctuations in the number of waiting validators.</p>
                        </div>
                        </TabPane>

                        <TabPane tab="3.3. Polkadot Tokenomics" key="3">
                        <h2 id="section3-3">3.3. Polkadot Tokenomics</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/staking.avif" 
                            alt="Polkadot Staking" 
                            style={{ width: '30%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p>Polkadot's tokenomics revolve around its native token, DOT, which serves multiple purposes including economics, slashing, governance, and parachain allocation. In terms of economics, DOTs are minted or burned to reward nodes running the consensus protocol, fund the treasury, and control the inflation rate. Polkadot's proof-of-stake system involves validators, who stake DOTs to produce blocks and reach consensus, and nominators, who support validators with their stakes and share in rewards and penalties.</p>
                            <p>The inflation model in Polkadot is non-linear, and driven by the Nominated Proof of Stake (NPoS) payments, where the inflation rate is a function of the staking rate and aims to balance security and liquidity by adjusting incentives for staking DOTs. The inflation rate comprises various factors, including inflation from minting for NPoS and the treasury, deflation from slashing, and transaction fee burns.</p>
                            <p>Validators and nominators earn rewards through a point system based on their participation in validity checking and block production. These rewards are proportional to the points earned in each era, which are then divided amongst validators and nominators. The payment distribution within each validator slot is based on a proportional stake, with a fixed commission fee for the validator and the remainder shared among all parties in the slot.</p>
                            <p>Polkadot's tokenomics are designed to incentivize network participation and security while balancing the needs of governance, staking, and parachain development. This approach creates a dynamic ecosystem where token holders are actively involved in network maintenance and decision-making.</p>
                        </div>
                        </TabPane>
                    </Tabs>
                    </Panel>
                </Collapse>

                <div style={{ height: '20px' }}></div> {/* Separator container */}
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Shared Security Charts" key="1">
                    <div style={{ backgroundColor: '#fff'}}>
                    <Tabs
                        defaultActiveKey={chartsConfig[0]?.elementId || 'chart_polkadot_staking_rate'}
                        onChange={(key) => {
                            setActiveNestedKey(key); // Always set activeNestedKey to the key of the first chart tab
                            // setActiveSharedSecurityKey(key); // Set the active section tab key
                            // handleResize();
                        }}
                        type="list"
                        tabPosition="left"
                        className="custom-tab-bar"
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="Staking Rate" key="chart_polkadot_staking_rate">
                            <div ref={el => sectionRefs.current['chart_polkadot_staking_rate'] = el} id="chart_polkadot_staking_rate" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="staking_staked_total" />
                        </TabPane>

                        <TabPane tab="Nominators & Validators" key="chart_polkadot_nominators_validators">
                            <div ref={el => sectionRefs.current['chart_polkadot_nominators_validators'] = el} id="chart_polkadot_nominators_validators" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="staking_nominators_validators" />
                        </TabPane>

                        <TabPane tab="Nakamoto Coefficient" key="chart_polkadot_nakamoto_coefficient">
                            <div ref={el => sectionRefs.current['chart_polkadot_nakamoto_coefficient'] = el} id="chart_polkadot_nakamoto_coefficient" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="nakamoto_coefficient" />
                        </TabPane>

                        <TabPane tab="Min and Max Validator Stakes" key="chart_polkadot_min_max_validator_stakes">
                            <div ref={el => sectionRefs.current['chart_polkadot_min_max_validator_stakes'] = el} id="chart_polkadot_min_max_validator_stakes" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="staking_min_max_validator_stake" />
                        </TabPane>
                        </Tabs>
                    </div>
                    </Panel>
                </Collapse>
            </TabPane>

            <TabPane tab="Governance" key="4">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Governance" key="1">
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="4.1. Gov 1" key="1">
                        <h2 id="section4-1">4.1. Gov 1 - the first iteration of Polkadot Governance</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/governance.avif" 
                            alt="Blockspace Ecosystem" 
                            style={{ width: '30%', marginRight: '20px', marginBottom: '20px', float: 'left' }} 
                            />
                            <p>Polkadot initially implemented an innovative decentralized governance system that featured a tri-cameral structure: a technocratic committee for upgrade timelines, an elected executive body that initiated legislation to manage parameters, administration, and spending, and a general voting system that favored long-term stakeholders. Somewhat inspired by parliamentary democracy, this system functioned effectively for 2–3 years, efficiently managing treasury funds and implementing upgrades and critical fixes.</p>
                            <p>However, there were challenges. The centralization and lack of anonymity in the executive council (the Council) posed risks to the protocol and its members, who could be pressured into biased decisions. The Technical Committee faced similar issues of centralization and vulnerability. In a society where decentralization is increasingly vital for safety and security, these aspects were concerning.</p>
                            <p>Another limitation was the singular, all-encompassing referendum model, curtailing the decision throughput by restricting the number of proposals that could be considered simultaneously and leading to prolonged voting periods. This system favored in-depth consideration of a few proposals over a broader range of many, inadvertently curtailing the potential of collective decision-making.</p>
                            <p>The governance model also had inherent exclusivity due to high barriers to entry, impacting diversity and inclusivity, and ultimately affecting turnout and legitimacy.</p>
                            <p>Although the original model was considered advanced in the Web3 space, by acknowledging that this first version was a starting point, improvements and iterations were always anticipated. As a result, a new model was designed and launched, set to advance Polkadot's governance, addressing these issues and evolving the ecosystem's decision-making process even further.</p>
                        </div>
                        </TabPane>

                        <TabPane tab="4.2. Introducing OpenGov" key="2">
                        <h2 id="section4-2">4.2. Introducing OpenGov</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/governance2.avif" 
                            alt="Blockspace Ecosystem" 
                            style={{ width: '30%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p>OpenGov, the advanced governance system for Polkadot, aims to address the shortcomings of the previous model. It retains core principles like the 50% stake rule for decision-making and Conviction Voting, which gives more weight to long-term stakeholders. However, it simplifies governance by removing separate bodies like the Council and Technical Committee, and does away with alternating proposal timetables and public queues. The focus is on referenda as the primary decision-making tool, allowing numerous simultaneous referenda for more agile and inclusive governance.</p>
                            <p>This system enables anyone to initiate or vote on referenda anytime, potentially leading to numerous concurrent votes. To manage this, Polkadot OpenGov introduces unique features for referendum management, including various 'Origins' and 'Tracks' to categorize referenda based on their complexity and potential impact. More significant decisions require stricter safeguards and longer consideration periods, while less critical ones have simpler processes.</p>
                            <p><strong>Key differences to Governance V1</strong></p>
                            <ul>
                            <li>Simplification and Decentralization: OpenGov eliminates the Council and Technical Committee, creating a simpler structure with only the Public (token holders) and the Technical Fellowship involved. This approach ensures a more decentralized governance model.</li>
                            <li>Multiple Origins and Tracks: In OpenGov, referenda are executed from multiple origins, each with a different track. This allows for categorizing proposals based on their importance and urgency, enabling simultaneous execution within and between origin tracks.</li>
                            <li>Public Proposal Submission: Unlike Governance V1, where proposals could be submitted by the Council or the Public, in OpenGov, all proposals are submitted by the public, enhancing the democratic nature of the system.</li>
                            <li>Origin-Specific Approval and Support Curves: OpenGov uses origin-specific approval and support curves, determining the amount of approval and support needed over time. This system replaces the Adaptive Quorum Biasing used in V1, leading to a more equalitarian and time-responsive approval process.</li>
                            <li>Flexible Voting and Enactment Periods: OpenGov allows multiple referenda to be voted on simultaneously, with customizable periods for voting and enactment based on the origin's importance and urgency.</li>
                            <li>Cancellation and Blacklisting: OpenGov introduces a special operation for canceling ongoing referenda, allowing immediate rejection of proposals, with a provision for slashing the deposit if the proposal is malicious or spam. This cancellation operation comes with its own Origin and Track, designed for urgent execution.</li>
                            <li>Improved Voting Options: Voters in OpenGov can choose to abstain or split votes, in addition to the traditional aye or nay options, offering more engagement in the voting process.</li>
                            <li>Agile Multirole Delegations: Voters can delegate votes to different accounts depending on origins, allowing more tailored and expertise-based delegations.</li>
                            <li>Polkadot Fellowship and Whitelisting: <a href="https://github.com/polkadot-fellows/manifesto" target="_blank" rel="noopener noreferrer">Polkadot Fellowship</a>, a more decentralized entity than the Technical Committee, can whitelist proposals, enabling them to have shorter lead-in, confirmation, and enactment periods. This process ensures a quicker response for urgent and critical proposals.</li>
                            </ul>
                            <p>OpenGov was launched on Kusama, Polkadot's "canary" network, in November 2022. After six months of operating on the canary-network Kusama, it went live on the Polkadot on <strong>June 15, 2023</strong>. This new governance model significantly enhances Polkadot's decentralization by enabling the community to have more direct control over decision-making processes.</p>
                        </div>
                        </TabPane>

                        <TabPane tab="4.3. Polkadot 1.0" key="3">
                        <h2 id="section4-3">4.3. Polkadot 1.0 - completion of Polkadot Whitepaper and handover to community</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/interoperability.avif" 
                            alt="Interoperability" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p>In July 2023, the first runtime was released under the Polkadot Technical Fellowship as an independent collective - v1.0. This version fully realized the groundbreaking vision outlined in the <a href="https://polkadot.network/whitepaper/" target="_blank" rel="noopener noreferrer">2016 Polkadot whitepaper</a>, which proposed a scalable, heterogeneous multi-chain network. Key features include heterogeneous sharding, advanced staking, on-chain governance, cross-chain communication, and forkless upgrades.</p>
                            <p>The journey to Polkadot 1.0 involved significant advancements in blockspace management, transaction efficiency, and scalability, addressing the blockchain trilemma and enhancing the developer experience.</p>
                            <p>Polkadot 1.0 also introduced “Long Term Support” (LTS) releases, ensuring critical security fixes for parachains without the need to upgrade to newer versions with breaking changes. The LTS releases are maintained for 6 months, providing enterprises and users with prioritized stability and a secure parachain codebase.</p>
                            <p>With the 1.0 release, all runtime code has been transitioned to a repository managed by the Technical Fellowship, which marked a full handover to the community, fostering greater decentralization, transparency and collaboration.</p>
                            <p>Future development will focus on community-driven discussions and consensus, potentially introducing new allocation mechanisms and 'accords' between blockchains. Polkadot's continuous innovation emphasizes interoperability, scalability, and upgradeability.</p>
                            <p><strong>The Polkadot Treasury</strong></p>
                            <p>Consistent across both Governance V1 and OpenGov implementations has been the role of the <a href="https://wiki.polkadot.network/docs/learn-polkadot-opengov-treasury" target="_blank" rel="noopener noreferrer">Polkadot Treasury</a>.</p>
                        </div>
                        </TabPane>
                    </Tabs>
                    </Panel>
                </Collapse>

                <div style={{ height: '20px' }}></div> {/* Separator container */}
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Governance Charts" key="1">
                    {/* <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}> */}
                    <Tabs
                        defaultActiveKey={chartsConfig[0]?.elementId || 'chart_polkadot_treasury_balance'}
                        onChange={(key) => {
                            setActiveNestedKey(key); // Always set activeNestedKey to the key of the first chart tab
                            // setActiveSharedSecurityKey(key); // Set the active section tab key
                            // handleResize();
                        }}
                        type="list"
                        tabPosition="left"
                        className="custom-tab-bar"
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="Treasury Balance" key="1">
                            <div ref={el => sectionRefs.current['chart_polkadot_treasury_balance'] = el} id="chart_polkadot_treasury_balance" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="polkadotTreasury" />
                        </TabPane>

                        <TabPane tab="Treasury Flows" key="chart_polkadot_treasury_flows">
                            <div ref={el => sectionRefs.current['chart_polkadot_treasury_flows'] = el} id="chart_polkadot_treasury_flows" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="treasuryFlows" />
                        </TabPane>

                        <TabPane tab="Referenda by Outcome" key="chart_opengov_number_of_referenda_by_outcome_history">
                            {/* <h2><strong>Number of Referenda by Outcome</strong></h2>
                            <p>Open Gov was implemented in mid-June of 2023 (deployed on Polkadot runtime / approved to be included by the community), marking the transition from Gov 1, used in the first half of the year. The below pie chart displays data since the launch of Open Gov (mid-June 2023) until now.</p>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">OpenGov: Number of referenda by outcome</h3> */}
                                    <div id="chart_opengov_number_of_referenda_by_outcome_history" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovReferenda" />
                                   {/* </div>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </TabPane>

                        <TabPane tab="Referenda by Origin" key="chart_number_of_referenda_by_origin">
                            {/* <p><strong>Number of Referenda by Origin (Open Gov):</strong></p>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">Number of referenda by Origin</h3> */}
                                    <div id="chart_number_of_referenda_by_origin" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovReferendaOrigin" />
                                    {/* </div>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </TabPane>

                        <TabPane tab="Votes by Duration of Lock" key="chart_number_of_votes_by_duration_of_lock">
                            {/* <p><strong>Number of Votes by Duration of Lock (Open Gov)</strong></p>
                            <p>Conviction voting in Polkadot allows participants to boost their voting power by voluntarily locking their tokens for a specified period. The longer the lock period, the greater their vote's influence, enabling a weighted voting mechanism that reflects both the amount of stake and the commitment duration of the voter's support for specific proposals. Read more about <a href="https://wiki.polkadot.network/docs/learn-polkadot-opengov#voluntary-locking-conviction-voting" target="_blank" rel="noopener noreferrer">vote multipliers and lengths of lock here.</a> This pie chart shows the all-time popularity of conviction locks in Open Gov (from mid-June 2023 until now).</p>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">Number of votes by Duration of lock</h3> */}
                                    <div id="chart_number_of_votes_by_duration_of_lock" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovVotesDurationLock" />
                                    {/* </div>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </TabPane>

                        <TabPane tab="Monthly Participation" key="chart_monthly_opengov_monthly_participation">
                            {/* <p><strong>Monthly Participation (Open Gov)</strong></p>
                            <p>Participation is the number of non-distinct addresses multiplied by the number of referenda voted on for a given month.</p>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">OpenGov Monthly Participation</h3> */}
                                    <div id="chart_monthly_opengov_monthly_participation" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovMonthlyPariticipation" />
                                    {/* </div>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </TabPane>

                        <TabPane tab="Monthly Voters" key="chart_monthly_opengov_number_of_voters">
                            {/* <p><strong>Monthly Number of Voters (Open Gov)</strong></p>
                            <p>The number of voters is the number of distinct addresses that noted on one or more referenda during a given month.</p>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">OpenGov Number of monthly voters</h3> */}
                                    <div id="chart_monthly_opengov_number_of_voters" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovMonthlyVoters" />
                                    {/* </div>
                                </div>
                                </div>
                            </div>
                            </div> */}
                        </TabPane>

                        <TabPane tab="Voting Power by Type" key="chart_monthly_opengov_capital_by_type_no_conviction">
                            {/* <p><strong>Monthly Voting Power by Type (Open Gov)</strong></p> */}
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">OpenGov Monthly capital by type (no conviction)</h3>
                                    <div id="chart_monthly_opengov_capital_by_type_no_conviction" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovVotingPowerType" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">OpenGov Monthly voting power by type (w/ conviction)</h3>
                                    <div id="chart_monthly_opengov_voting_power_by_type_with_conviction" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovVotingPowerType" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </TabPane>

                        <TabPane tab="Voting Power by Direction" key="chart_monthly_tokens_voted_by_direction_no_conviction">
                            {/* <p><strong>Monthly Voting Power by Direction (Open Gov)</strong></p> */}
                            <div className="container mt-5 card-style">
                            <div className="row">
                                <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">Monthly Tokens voted by Direction (no conviction)</h3>
                                    <div id="chart_monthly_tokens_voted_by_direction_no_conviction" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovVotingPowerDirection" />

                                    </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                <div className="container mt-5 card-style">
                                 <div className="row"></div>
                                <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                    <h3 className="card-title">Monthly Tokens voted by Direction (with conviction)</h3>
                                    <div id="chart_monthly_tokens_voted_by_direction_with_conviction" style={{ width: '100%', height: '400px' }}></div>
                                    <ChartDescriptionComponent chartId="openGovVotingPowerDirection" />
                                    </div>
                                </div>
                                </div>
                            </div>
               
                        </TabPane>
                        </Tabs>
                    {/* </div> */}
                    </Panel>
                </Collapse>
            </TabPane>      

            <TabPane tab="Ecosystem" key="5">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Ecosystem" key="1">
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="5.1. Parachains" key="1">
                        <h2 id="section5-1">5.1. Parachains</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/composability.avif" 
                            alt="Parachains" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p>Polkadot parachains are specialized blockchains that integrate with the Polkadot network, each designed for specific use cases. They offer solutions to the scalability and flexibility challenges in blockchain technology by allowing multiple blockchains to operate independently yet securely, leveraging the shared security provided by the Polkadot Relay Chain. This shared security is a significant value proposition for chains becoming parachains, as it allows them to tap into the economic security of the Relay Chain without the need to maintain their own validator set, which is a crucial distinction from independent chains connected by bridges.</p>
                            <p>Parachains can have their own economies and native tokens, but they are not required to. They function using schemes like Proof-of-Stake to select validators, although they rely on the Relay Chain for validation and finalization. Transaction fees and other economic models can be implemented at the discretion of each parachain.</p>
                            <p>Overall, the true potential of parachains is vast, with use cases ranging from encrypted consortium chains and high-frequency chains to privacy chains and smart contract chains. They represent a significant component of Polkadot's architecture, designed to enhance the scalability, interoperability, and flexibility of the network.</p>
                        </div>
                        </TabPane>

                        <TabPane tab="5.2. System Parachains" key="2">
                        <h2 id="section5-2">5.2. System Parachains</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/systemChain.png" 
                            alt="System Parachains" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'left' }} 
                            />
                            <p>System chains, also known as "System Parachains," are specialized parachains that host key network functionalities. They are governed by the network rather than individual governance systems, ensuring that essential services and features are maintained efficiently and securely for the benefit of the entire Polkadot ecosystem.</p>
                            <p><strong>Core Protocol Features:</strong> System parachains contain essential elements of the Polkadot protocol, but unlike standard parachains, they are not acquired through auctions. Instead, their execution cores are allocated by network governance. This setup allows Polkadot to utilize its parallel execution capability for hosting core protocol logic, improving scalability and efficiency.</p>
                            <p><strong>Evolution of Terminology:</strong> Previously known as "Common Good Parachains," the term has evolved to "System Parachains" for clarity. This change reflects their role in the network and their management by on-chain governance mechanisms, rather than having separate native tokens or governance systems.</p>
                            <p><strong>Existing System Parachains:</strong></p>
                            <ul>
                            <li><strong>Asset Hub:</strong> This parachain acts as an asset portal for tracking and managing the issuance of assets, including reserve-backed stablecoins and non-fungible tokens (NFTs). It offers efficient transaction processing with significantly lower fees compared to the Relay Chain.</li>
                            <li><strong>Encointer:</strong> A blockchain platform for self-sovereign ID and universal basic income, Encointer focuses on financial inclusivity and mitigates Sybil attacks using a Proof of Personhood system. It allows groups to create and manage their digital community tokens.</li>
                            <li><strong>Collectives:</strong> This parachain hosts on-chain collectives that contribute to network stewardship and decentralized governance, allowing networks to express legislative opinions within other networks.</li>
                            <li><strong>Bridge Hubs:</strong> Dedicated to creating bridges between networks, Bridge Hubs facilitate trust-minimized bridges to other networks, enhancing interoperability and network integration.</li>
                            </ul>
                        </div>
                        </TabPane>
                    </Tabs>
                    </Panel>
                </Collapse>

                <div style={{ height: '20px' }}></div> {/* Separator container */}
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Ecosystem Charts" key="1">
                    {/* <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}> */}
                    <Tabs
                        defaultActiveKey={chartsConfig[0]?.elementId || 'chart_polkadot_number_of_parachains'}
                        onChange={(key) => {
                            setActiveNestedKey(key); // Always set activeNestedKey to the key of the first chart tab
                            // setActiveSharedSecurityKey(key); // Set the active section tab key
                            // handleResize();
                        }}
                        type="list"
                        tabPosition="left"
                        className="custom-tab-bar"
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="Number of Parachains" key="chart_polkadot_number_of_parachains">
                        {/* <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}> */}
                            <div ref={el => sectionRefs.current['chart_polkadot_number_of_parachains'] = el} id="chart_polkadot_number_of_parachains" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="parachainCountValue" />
                        {/* </div> */}
                        </TabPane>

                        <TabPane tab="Monthly Transactions" key="chart_polkadot_monthly_transactions">
                            <div ref={el => sectionRefs.current['chart_polkadot_monthly_transactions'] = el} id="chart_polkadot_monthly_transactions" style={{ width: '100%', height: '600px' }}></div>
                            <ChartDescriptionComponent chartId="parachains_transactions" />
                        </TabPane>

                        <TabPane tab="Unique Addresses" key="chart_polkadot_unique_addresses">
                            <div ref={el => sectionRefs.current['chart_polkadot_unique_addresses'] = el} id="chart_polkadot_unique_addresses" style={{ width: '100%', height: '600px' }}></div>
                            <ChartDescriptionComponent chartId="parachains_unique_accounts" />
                        </TabPane>

                        <TabPane tab="Active Addresses" key="chart_polkadot_active_addresses">
                            <div ref={el => sectionRefs.current['chart_polkadot_active_addresses'] = el} id="chart_polkadot_active_addresses" style={{ width: '100%', height: '600px' }}></div>
                            <ChartDescriptionComponent chartId="parachains_accounts_active" />
                        </TabPane>

                        <TabPane tab="XCM Messages Sent" key="chart_xcm_sent_monthly_by_type">
                            <div ref={el => sectionRefs.current['chart_xcm_sent_monthly_by_type'] = el} id="chart_xcm_sent_monthly_by_type" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="xcmMessages" />
                        </TabPane>

                        <TabPane tab="Distinct HRMP Channels" key="chart_xcm_distinct_hrmp">
                            <div ref={el => sectionRefs.current['chart_xcm_distinct_hrmp'] = el} id="chart_xcm_distinct_hrmp" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="hrmpChannels" />
                        </TabPane>

                        <TabPane tab="Parachain Message Usage" key="chart_xcm_message_usage_per_month_hrmp_senders">
                            <div ref={el => sectionRefs.current['chart_xcm_message_usage_per_month_hrmp_senders'] = el} id="chart_xcm_message_usage_per_month_hrmp_senders" style={{ width: '100%', height: '600px' }}></div>
                            <ChartDescriptionComponent chartId="xcmUsage" />
                       </TabPane>
                        </Tabs>
                    {/* </div> */}
                    </Panel>
                </Collapse>
            </TabPane>

            <TabPane tab="Developer Community" key="6">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Developer Community" key="1">
                    <div style={{ backgroundColor: '#fff'}}>
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        // className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="6.1. Developers" key="1">
                        <h2 id="section6-1">6.1. Developers</h2>
                        <div style={{ overflow: 'auto' }}>
                            <img 
                            className="m-1" 
                            src="assets/eoyr/world.png" 
                            alt="Developers" 
                            style={{ width: '40%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                            />
                            <p>Polkadot's developer community is a prominent force in the blockchain space, standing out for its strength and active engagement. According to <a href="https://www.developerreport.com/" target="_blank" rel="noopener noreferrer">Electric Capital</a>, Polkadot is one of the leading development ecosystems in the blockchain world, continuously ranked second only to Ethereum.</p>
                            <p>Polkadot reached 500 full-time developers (developers that contributed code 10+ days out of a month) in just 3.6 years since network launch, the fastest growth rate of any blockchain ecosystem. This recognition is a testament to the diligent contributions made within the ecosystem and the significant number of developers actively involved in it.</p>
                            <p>The <a href="https://polkadot.network/development/blockchain-academy/" target="_blank" rel="noopener noreferrer">Polkadot Blockchain Academy</a> (PBA) offers a unique educational experience focused on blockchain technology, using Polkadot and Substrate as its foundations. The program, designed for both developers and Polkadot project founders, is led by experts from the Polkadot ecosystem, Parity Technologies, and the Web3 Foundation. The curriculum includes theoretical modules on economics, governance, game theory, cryptography, and practical applications in Polkadot-specific technologies. The Academy aims to equip participants with the knowledge and skills to build blockchains and contribute significantly to the Polkadot ecosystem. It welcomes both new and experienced individuals passionate about Web3 and blockchain technology.</p>
                            <p>As of September 2023, PBA has made its <a href="https://polkadot-blockchain-academy.github.io/pba-book/" target="_blank" rel="noopener noreferrer">course materials publicly available</a> after a year of development and refinement. This initiative is part of their continuous effort to enhance the curriculum and learning experience, aimed at equipping builders with essential skills for thriving in the Polkadot ecosystem.</p>
                            <p>The <a href="https://polkadot.network/development/developer-heroes/" target="_blank" rel="noopener noreferrer">Polkadot Developer Heroes Program</a> is a community-focused initiative for developers who are actively engaged in the Polkadot ecosystem. It is open to developers of all levels who contribute technically, participate in discussions, and create technical content related to Polkadot. The program offers various benefits, including dedicated educational space, exclusive content, mentoring sessions, networking activities, career opportunities, and more. It aims to connect, grow, and inspire the Polkadot developer community, providing a platform for knowledge sharing and professional development. It includes two categories: Rookies (beginners in Polkadot development) and Heroes (advocates for Polkadot technology). Activities include mentoring, contributing to the Polkadot stack, content creation, and speaking at events. Interested participants can sign up and nominate themselves for the program.</p>
                        </div>
                        </TabPane>

                    </Tabs>
                    </div>
                    </Panel>
                </Collapse>

                <div style={{ height: '20px' }}></div> {/* Separator container */}
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Developer Community Charts" key="1">
                    {/* <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}> */}
                    <Tabs
                        defaultActiveKey={chartsConfig[0]?.elementId || 'chart_stackexchange_user_growth'}
                        onChange={(key) => {
                            setActiveNestedKey(key); // Always set activeNestedKey to the key of the first chart tab
                            // setActiveSharedSecurityKey(key); // Set the active section tab key
                            // handleResize();
                        }}
                        type="list"
                        tabPosition="left"
                        className="custom-tab-bar"
                        style={{ maxWidth: '2000px' }}
                    >
                        <TabPane tab="Weekly User Growth" key="chart_stackexchange_user_growth">
                            <div ref={el => sectionRefs.current['chart_stackexchange_user_growth'] = el} id="chart_stackexchange_user_growth" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="stackExchangeGrowth" />
                        </TabPane>

                        <TabPane tab="Top 25 Tags" key="chart_stackexchange_top_25_tags">
                            <div ref={el => sectionRefs.current['chart_stackexchange_top_25_tags'] = el} id="chart_stackexchange_top_25_tags" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="stackExchangeTags" />
                        </TabPane>

                        <TabPane tab="Weekly Activity" key="chart_stackexchange_activity_eoy">
                            <div ref={el => sectionRefs.current['chart_stackexchange_activity_eoy'] = el} id="chart_stackexchange_activity_eoy" style={{ width: '100%', height: '400px' }}></div>
                            <ChartDescriptionComponent chartId="stackExchangeActivity" />
                        </TabPane>
                        </Tabs>
                    {/* </div> */}
                    </Panel>
                </Collapse>
            </TabPane>

            <TabPane tab="Appendices" key="7">
            <Collapse defaultActiveKey={['1']}>
                    <Panel header="Appendices" key="1">
            {/* <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}> */}
            
            <MonthlyReportAppendix />
            {/* </div> */}
            </Panel>
            </Collapse>
            </TabPane> 

            <TabPane tab="About" key="8">
            <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <h2>DotLake</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ flex: '1 1 50%', padding: '0px' }}>
                    <p>As the Data Team at <a href="https://www.parity.io/" target="_blank" rel="noopener noreferrer">Parity Technologies</a>, we're driven by the conviction that data is the cornerstone of meaningful advancements. It's through understanding data that we can unlock the full potential of the Polkadot ecosystem and demonstrate its value to the world. Our mission is to decode Polkadot's block data and ecosystem data, transforming them into valuable community insights.</p>
                    <p>Our journey began with the creation of a data warehouse (“DotLake”) to first serve Parity's internal data needs. We've now broadened our scope, sharing our initiatives and learnings with the wider community. We plan to progressively make our datasets available to the public in 2024, alongside dashboards, metrics, code, and best practices we employed to ingest and decode Substrate data (<a href="https://forum.polkadot.network/t/select-from-polkadot/2593" target="_blank" rel="noopener noreferrer">Select * from Polkadot</a>, <a href="https://forum.polkadot.network/t/pieces-of-a-decentralized-data-lake/3682" target="_blank" rel="noopener noreferrer">Pieces of a decentralized data lake</a>, etc.).</p>
                </div>
                <div style={{ flex: '1 1 50%', padding: '5px' }}>
                    <img className="m-1" src="assets/eoyr/dotlake-batch.png" alt="Dotlake Batch" style={{ width: '75%' }} />
                </div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ flex: '1 1 50%', padding: '5px' }}>
                    <img className="m-1" src="assets/eoyr/dotlake-rt.png" alt="Dotlake Real time" style={{ width: '75%'}} />
                </div>
                <div style={{ flex: '1 1 50%', padding: '0px' }}>
                    <p>The heart of our solution is DotLake, a scalable and cost-efficient data platform built on Google Cloud Platform (GCP). It's designed to store all blocks, events, extrinsics, and more for all the chains in the Polkadot ecosystem. We've utilized a range of existing technologies, keeping the architecture simple with low operational overhead. This includes tools like Terraform, the Substrate Sidecar, Rust & Python programming languages, and various GCP services like Cloud Storage, BigQuery, Cloud Run & Jobs, and Workflows & Functions.</p>
                    <p>Our approach allows us to process data from block number N to M, storing the raw results as JSON in Google Cloud Storage. This method not only provides a convenient abstraction, but also avoids the pitfalls of custom block parsing strategies in the ever-evolving Substrate Framework. The key to our platform's efficiency is our Block Compressor, which optimizes and reduces the data size significantly, making it more manageable for analytical purposes.</p>
                </div>
                </div>
                <p>DotLake currently consists of 70+ Polkadot & Kusama chains with their full history and is continuously expanding. The focus of this report is the Polkadot relay chain and its parachains.</p>
            </div>
            </TabPane>


            <TabPane tab="General Disclaimer" key="9">
            <div style={{ overflow: 'auto', backgroundColor: '#fff', padding: '24px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <img 
                className="m-1" 
                src="assets/eoyr/how-it-works.avif" 
                alt="General Disclaimer" 
                style={{ width: '50%', marginLeft: '20px', marginBottom: '20px', float: 'right' }} 
                />
                <h2>General Disclaimer</h2>
                <p>The following report is provided by the Parity Data Team for informational purposes only. All data is sourced from DotLake which gathers data directly from the distinct chains. It is important to clarify the following points:</p>
                <p style={{ paddingLeft: '20px' }}><strong>- Not Investment Advice:</strong> The contents of this report, including all data, analyses, and opinions, are not intended to be, and should not be interpreted as, investment advice. This report does not recommend buying, selling, or holding any cryptocurrencies or digital assets, including Polkadot. The decision to engage in any investment activity related to cryptocurrencies should be made independently and with careful consideration of the risks involved.</p>
                <p style={{ paddingLeft: '20px' }}><strong>- Accuracy and Reliability of Data:</strong> While the Parity Data Team has endeavored to ensure the accuracy and reliability of the information contained in this report, the data is provided “as is.” We do not guarantee its correctness, completeness, or suitability for any purpose. The team relies on sources believed to be reliable but does not independently verify all data and therefore cannot assure its accuracy or completeness.</p>
                <p style={{ paddingLeft: '20px' }}><strong>- No Liability:</strong> The Parity Data Team, its affiliates, and contributors will not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use of or reliance on information in this report. The readers should bear all risks associated with the use of this report and should conduct their own analysis and verification.</p>
                <p style={{ paddingLeft: '20px' }}><strong>- Subject to Change:</strong> The information in this report is current as of the date of publication and is subject to change without notice. We have no obligation to update any content in this report.</p>
                <p style={{ paddingLeft: '20px' }}><strong>- Intellectual Property:</strong> All content in this report is the intellectual property of the Parity Data Team. Reproduction or distribution of any part of this report without prior written permission is prohibited.</p>
                <p><i>By accessing and using this report, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</i></p>
            </div>
            </TabPane>
        </Tabs>
      </Content>

    </Layout>

  );
};

export default PolkadotMonthlyReport;

