// import React from 'react';
// import logo from '../assets/Polkadot_Token_PolkadotToken_Pink.png'; // Adjust the path as necessary

// const PolkadotIcon = (props) => (
//   <img
//     src={logo}
//     alt="Polkadot Logo"
//     style={{ width: '1.15em', height: '1.15em'}}
//     {...props}
//   />
// );

// export default PolkadotIcon;


import React from 'react';
import polkadotFlameGif from '../assets/dot.gif'; // Adjust the path as necessary

const PolkadotIcon = () => (
  <img src={polkadotFlameGif} alt="Polkadot Flame" style={{ width: '17px', height: '17px', marginRight: '7px' }} />
);

export default PolkadotIcon;
