import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import { barChart, horizontalBarChart, loadJSONDataInk, populateTableInk, get_chain_colors, generateColors, customFormatter, watermark, outerContainerStyle, controlsContainerStyle, groupContainerStyle, groupTitleStyle, buttonStyle, selectedButtonStyle } from './js/charts_ecosystem';
import { useDebouncedResize, chartDescriptions } from './js/echart_utils';

const PolkadotInk = () => {
  const [chartsConfig, setChartsConfig] = useState([]);
  const [relayChain, setRelayChain] = useState('mainnet');
  const chartRefs = useRef({});
  const [hovered, setHovered] = useState({});

  const showDescription = id => setHovered({ ...hovered, [id]: true });
  const hideDescription = id => setHovered({ ...hovered, [id]: false });

  useDebouncedResize(() => {
    Object.values(chartRefs.current).forEach(chart => {
      if (chart) chart.resize();
    });
  }, 100);

  useEffect(() => {
    // Prepare chart instances for each config
    chartsConfig.forEach((config) => {
      if (config.type !== 'table') {
        createEchartElement(config.elementId, config.type);
      }
    });
    // Update charts after instances are prepared
    loadAndUpdateCharts();
  }, [chartsConfig]);

  const createEchartElement = (elementId, type) => {
    if (type !== 'table') {
      const container = document.getElementById(elementId);
      if (!container) return;

      const existingChart = echarts.getInstanceByDom(container);
      if (existingChart) {
        echarts.dispose(existingChart);
      }

      chartRefs.current[elementId] = echarts.init(container, null, { renderer: 'svg' });
    }
  };

  const relayChainRef = useRef(relayChain);
  useEffect(() => {
    relayChainRef.current = relayChain;
  }, [relayChain]);

  const loadAndUpdateCharts = () => {
    chartsConfig.forEach(async (config) => {
      if (config.elementId === 'general_tvl_usd') {
        const data = await loadJSONDataInk(config.jsonPath);
        if (data && chartRefs.current[config.elementId]) {
          barChart(chartRefs.current[config.elementId], config, data);
        }
      } else if (config.type === 'bar') {
        const data = await loadJSONDataInk(config.jsonPath, relayChainRef.current);
        if (data && chartRefs.current[config.elementId]) {
          barChart(chartRefs.current[config.elementId], config, data);
        }
      } else if (config.type === 'horizontalBar') {
        const data = await loadJSONDataInk(config.jsonPath, relayChainRef.current);
        if (data && chartRefs.current[config.elementId]) {
          horizontalBarChart(chartRefs.current[config.elementId], config, data);
        }
      } else if (config.elementId === 'tableContractsVersion') {
        const data = await loadJSONDataInk(config.jsonPath, relayChainRef.current);
        if (data) {
          populateTableInk(config, data);
        }
      }
    });
  };

  const renderControls = () => {
    return (
      <div style={outerContainerStyle}>
        <div style={controlsContainerStyle}>
          <div style={groupContainerStyle}>
            <div style={groupTitleStyle}>Network</div>
            <div>
              <button style={relayChain === 'mainnet' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('mainnet')}>
                Mainnet
              </button>
              <button style={relayChain === 'testnet' ? selectedButtonStyle : buttonStyle} onClick={() => setRelayChain('testnet')}>
                Testnet
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadCharts = async () => {
      const newChartsConfig = [
        {
          elementId: 'general_ink_num_contract_codes',
          jsonPath: 'data/ink_num_code_hash_daily000000000000.jsonl',
          freq: 'daily',
          relay: relayChain,
          xName: 'date',
          yName: 'code_hash',
          title: 'Distinct Code Hashes',
          type: 'bar',
          build: barChart
        },
        {
          elementId: 'general_ink_num_contracts_deployed',
          jsonPath: 'data/ink_num_contracts_deployed_daily000000000000.jsonl',
          freq: 'daily',
          relay: relayChain,
          xName: 'date',
          yName: 'contracts_deployed',
          title: 'Contracts Deployed',
          type: 'bar',
          build: barChart
        },
        {
          elementId: 'general_ink_num_owners',
          jsonPath: 'data/ink_num_owners_daily000000000000.jsonl',
          freq: 'daily',
          relay: relayChain,
          xName: 'date',
          yName: 'num_owners',
          title: 'Contract Owners',
          type: 'bar',
          build: barChart
        },
        {
          elementId: 'general_ink_language_donut',
          jsonPath: 'data/ink_view_daily000000000000.jsonl',
          freq: 'daily',
          title: 'Contract Language Distribution',
          grouping: 'language',
          yName: 'chain',
          xName: 'count',
          type: 'horizontalBar',
          build: horizontalBarChart
        }
      ];
  
      // Conditionally add transaction fees and TVL deposits charts for mainnet
      if (relayChain === 'mainnet') {
        newChartsConfig.push(
          {
            elementId: 'general_ink_fees_usd',
            jsonPath: 'data/ink_fees_usd_daily000000000000.jsonl',
            freq: 'daily',
            relay: relayChain,
            xName: 'date',
            yName: 'fees_usd',
            title: 'Transaction Fees (USD)',
            type: 'bar',
            build: barChart
          },
          {
            elementId: 'general_tvl_usd',
            jsonPath: 'data/ink_tvl_daily000000000000.jsonl',
            freq: 'daily',
            relay: relayChain,
            xName: 'date',
            yName: 'TVL USD',
            title: 'Deposits (USD)',
            type: 'bar',
            build: barChart
          }
        );
      }
  
      // Always add the tableContractsVersion configuration last
      newChartsConfig.push({
        elementId: 'tableContractsVersion',
        jsonPath: 'data/ink_version_daily000000000000.jsonl',
        title: 'Contract Language Version',
        freq: 'daily',
        relay: relayChain,
        build: populateTableInk
      });
  
      setChartsConfig(newChartsConfig);
      loadAndUpdateCharts();
    };
  
    loadCharts();
  }, [relayChain]);
  

  return (
    <div>
      {renderControls()}
      <div className="website-grid-container">
        {chartsConfig.map((config) => (
          <div
            key={config.elementId}
            style={{
              width: '100%',
              maxWidth: '100%',
              padding: '20px',
              border: '2px solid #e0e0e0',
              borderRadius: '15px',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              boxSizing: 'border-box',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <h2 style={{ textAlign: 'center', margin: 0, marginRight: '10px' }}>{config.title}</h2>
              <InfoCubeIconComponent onMouseEnter={() => showDescription(config.elementId)} onMouseLeave={() => hideDescription(config.elementId)} />
            </div>
            <div id={config.elementId} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
            {hovered[config.elementId] && (
              <div style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                fontSize: '14px',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.75)',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '50%',
                textAlign: 'full',
                boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
              }}>
                {chartDescriptions[config.elementId]}
              </div>
            )}
          </div>
        ))}
        <table id="tableContractsVersion" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default PolkadotInk;











