import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import PolkadotJSStakingComponent from './components/PolkadotJSStakingComponent';
import { get_chain_colors, lineChartStaking } from './js/charts_ecosystem.js';
import { chartDescriptions } from './js/echart_utils.js';

const PolkadotStatsStaking = () => {
  const chartRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [hovered, setHovered] = useState({});
  const [chartsVisible, setChartsVisible] = useState(false);

  const showDescription = id => setHovered({ ...hovered, [id]: true });
  const hideDescription = id => setHovered({ ...hovered, [id]: false });

  const chartInfo = {
    staking_staked_total: { title: 'Total DOT Staked', description: chartDescriptions.staking_staked_total },
    staking_staked_addresses: { title: 'Number of Addresses Staking', description: chartDescriptions.staking_staked_addresses },
    staking_nominators_count: { title: 'Number of Nominators', description: chartDescriptions.staking_nominators_count },
    staking_validators_count: { title: 'Number of Validators', description: chartDescriptions.staking_validators_count },
  };

  const resizeCharts = useCallback(() => {
    chartRefs.forEach(ref => {
      if (ref.current) {
        const chart = echarts.getInstanceByDom(ref.current);
        if (chart) chart.resize();
      }
    });
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeCharts);
    chartRefs.forEach(ref => {
      if (ref.current) {
        ref.current.chart = echarts.init(ref.current);
        resizeObserver.observe(ref.current);
      }
    });

    window.addEventListener('resize', resizeCharts);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', resizeCharts);
      chartRefs.forEach(ref => {
        if (ref.current?.chart) {
          ref.current.chart.dispose();
        }
      });
    };
  }, [resizeCharts]);

  const fetchDataAndInitChart = async (url, index, title, keyForData) => {
    try {
      const response = await fetch(url);
      const rawData = await response.text();
      const data = rawData.split('\n')
        .filter(Boolean)
        .map(JSON.parse)
        .map(item => ({ ...item, chain: 'polkadot', relay_chain: 'polkadot' }))
        .reverse();

      const container = chartRefs[index].current;
      if (container) {
        let chart = echarts.getInstanceByDom(container) || echarts.init(container);
        const yValues = data.map(item => item[keyForData]);
        const yMin = Math.min(...yValues);
        const yLeeway = yMin * 0.1; // 10% leeway
        const roundToNearest = (value, nearest) => Math.floor(value / nearest) * nearest;
        const config = {
          xName: 'day',
          yName: keyForData,
          title: title,
          type: 'line',
          colorScheme: get_chain_colors('polkadot'),
          yAxisMin: roundToNearest(yMin - yLeeway, yMin >= 1000 ? 1000 : 100),
        };
        lineChartStaking(chart, config, data);
        chartRefs[index].current.chart = chart;
      }
    } catch (error) {
      console.error(`Error fetching and initializing chart for ${title}:`, error);
    }
  };

  useEffect(() => {
    if (chartsVisible) {
      fetchDataAndInitChart('data/staking_total_dot_staked000000000000.jsonl', 0, 'Polkadot: Total DOT Staked', 'staked');
      fetchDataAndInitChart('data/staking_total_addresses_staked000000000000.jsonl', 1, 'Polkadot: Number of Addresses Staking', 'number_of_addresses');
      fetchDataAndInitChart('data/staking_total_nominators_polkadot000000000000.jsonl', 2, 'Polkadot: Number of Nominators', 'number_of_nominators');
      fetchDataAndInitChart('data/staking_total_validators_polkadot000000000000.jsonl', 3, 'Polkadot: Number of Validators', 'number_of_validators');
    }
  }, [chartsVisible]);

  return (
  <div className="website-grid-container">
    <PolkadotJSStakingComponent onLoaded={() => setChartsVisible(true)} />
      {chartsVisible && Object.entries(chartInfo).map(([id, info], index) => (
        <div key={id} style={{ width: '100%', maxWidth: '100%', padding: '20px', border: '2px solid #e0e0e0', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', boxSizing: 'border-box', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0, marginRight: '10px' }}>{info.title}</h2>
            <InfoCubeIconComponent onMouseEnter={() => showDescription(id)} onMouseLeave={() => hideDescription(id)} />
          </div>
          <div ref={chartRefs[index]} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
          {hovered[id] && (
            <div style={{
              position: 'absolute',
              transform: 'translate(-50%, -30%)',
              zIndex: 2,
              fontSize: '14px',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.75)',
              padding: '20px',
              borderRadius: '10px',
              maxWidth: '50%',
              textAlign: 'full',
              boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
            }}>
              {info.description}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PolkadotStatsStaking;
