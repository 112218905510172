// "use strict";
import * as echarts from 'echarts';
import watermarkImage from '../assets/dotlake-transparent-high-quality.png'
import * as e from './echart_utils.js';

// Configurations 

const colors = {
  polkadot: '#E6007A',
  kusama: '#000000',
  emphasis: '#00B2FF',
  success: '#27ae60',
  failure: 'red',
  neutral: '#552BBF',
  background: '#95a5a6',
  heat: "#e67e22",
  powderBlue: '#B0E0E6'
}

const generatedColors = Array.from({ length: 75 }, (_, index) => {
  const hue = (index * 137.508) % 360;
  const saturation = 50 + Math.random() * 30; // Vary saturation for richer colors
  const lightness = 40 + Math.random() * 40; // Vary lightness for contrast
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
});

const watermark = {
  type: 'image',
  id: 'watermark',
  bounding: 'raw', // Set to 'raw' for no padding
  right: 'center', // Center horizontally
  // bottom: 'center', // Center vertically
  top: 'center',
  z: -1,
  style: {
    image: watermarkImage,
    width: 150, // Adjust width if necessary
    height: 150, // Adjust height if necessary
    opacity: 0.1 // Adjust opacity if necessary
  }
};

const tooltipBackgroundColor = 'rgba(255, 255, 255, 0.8)';


let customFormatter = function (params) {
  // Sort the items based on their value for the specific month
  params.sort((a, b) => b.value - a.value);

  // Filter out items with a value of 0
  let filteredParams = params.filter(param => param.value != 0);

  // Calculate total value for the month from filtered items
  let total = filteredParams.reduce((sum, param) => sum + param.value, 0);

  // Start table for tooltip content with header for alignment and minimal padding
  let tooltipContent = '<div><table class="tb">';

  // Conditionally add total row if number of filtered items is greater than 3
  if (filteredParams.length > 3) {
    tooltipContent += `<tr>
                        <th><b>Total:</b></th>
                        <th><b>${total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2  })}</b></th>
                      </tr>`;
  }

  // Generate rows for each series with right-aligned values, only for filtered items
  filteredParams.forEach(param => {
    tooltipContent += `<tr>
                        <td>${param.marker}${param.seriesName}:</td>
                        <td><b>${param.value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</b></td>
                       </tr>`;
  });

  // Close table and div
  tooltipContent += '</table></div>';

  // Optional: Add a header or any additional content to the tooltip
  let header = params[0] ? params[0].axisValueLabel : '';
  return `<div>${header}<br/>${tooltipContent}</div>`;
}

// function convertToMonthEndDate(dateString) {
//   if (dateString.length === 7) { // Matches "YYYY-MM"
//     const year = parseInt(dateString.substring(0, 4));
//     const month = parseInt(dateString.substring(5, 7));
//     const endDate = new Date(year, month, 0).getDate(); // Get last day of the month
//     return `${dateString}-${endDattoString().padStart(2, '0')}`;
//   }
//   return dateString; // Return original if not matching "YYYY-MM"
// }

//working chart config old
// export const chartsConfig = [
//   {
//     folder: 'Staking',
//     elementId: 'chart_polkadot_staking_rate',
//     csvPath: `${process.env.PUBLIC_URL}/data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv`,
//     build: staking_rate,
//   },
//   {
//     folder: 'Staking',
//     elementId: 'chart_polkadot_nominators_validators',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__nominators___validators_2023.csv`,
//     build: nominators_validators,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_polkadot_nakamoto_coefficient',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__nakamoto_coefficient_2023.csv`,
//     build: nakamoto_coefficient,
//   },
//   {
//     folder: 'Staking',
//     elementId: 'chart_polkadot_min_max_validator_stakes',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__minimum_and_maximum_validator_stakes_2023.csv`,
//     build: min_max_nominators_validators_stake,
//   },
//   {
//     folder: 'Treasury',
//     elementId: 'chart_polkadot_treasury_balance',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__treasury_balance_2023.csv`,
//     build: treasury_balance,
//   },
//   {
//     folder: 'Treasury',
//     elementId: 'chart_polkadot_treasury_flows',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__treasury_flows_2023.csv`,
//     build: treasury_flows,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_govone_number_of_referenda_by_outcome',
//     csvPath: `${process.env.PUBLIC_URL}/data/gov_1__number_of_referenda_by_outcome_2023.csv`,
//     build: referenda_by_outcome,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_opengov_number_of_referenda_by_outcome',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__number_of_referenda_by_outcome_2023.csv`,
//     build: referenda_by_outcome,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_number_submitted_proposals_gov1_vs_opengov',
//     csvPath: `${process.env.PUBLIC_URL}/data/number_of_treasury_proposals__gov1_vs_open_gov__2023.csv`,
//     build: number_submitted_proposals_gov1vsopengov,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_average_number_votes_gov1_vs_opengov',
//     csvPath: `${process.env.PUBLIC_URL}/data/average_number_of_votes__gov_1_vs_open_gov___2023.csv`,
//     build: average_votes_gov1vsopengov,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_number_of_referenda_by_origin',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__number_of_referenda_by_origin_2023.csv`,
//     build: number_referenda_by_origin,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_number_of_votes_by_duration_of_lock',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__number_of_votes_by_duration_of_lock_2023.csv`,
//     build: number_of_votes_by_duration_of_lock,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_monthly_opengov_number_of_voters',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__monthly_referenda_and_voters_2023.csv`,
//     build: monthly_number_of_voters,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_monthly_opengov_capital_by_type_no_conviction',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__monthly_capital_by_type_no_conviction_2023.csv`,
//     build: monthly_capital_no_conviction,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_monthly_opengov_voting_power_by_type_with_conviction',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__monthly_voting_power_by_type_conviction_2023.csv`,
//     build: monthly_voting_power_with_conviction,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_monthly_tokens_voted_by_direction_no_conviction',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__monthly_tokens_voted_by_direction__no_conviction__2023.csv`,
//     build: number_monthly_tokens_voted_by_direction,
//   },
//   {
//     folder: 'OpenGov',
//     elementId: 'chart_monthly_tokens_voted_by_direction_with_conviction',
//     csvPath: `${process.env.PUBLIC_URL}/data/open_gov__monthly_tokens_voted_by_direction__w__conviction___2023.csv`,
//     build: number_monthly_tokens_voted_by_direction,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_polkadot_number_of_parachains',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__number_of_parachains_2023.csv`,
//     build: number_of_parachains,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_polkadot_monthly_transactions',
//     csvPath: `${process.env.PUBLIC_URL}/data/events_substrate_2023.csv`,
//     // csvPath: `${process.env.PUBLIC_URL}/data/polkadot__monthly_transactions_by_parachain_and_relay_chain_2023.csv`,
//     build: monthly_transactions,
//   },
//   // {
//   //   folder: 'Polkadot',
//   //   elementId: 'chart_polkadot_unique_addresses',
//   //   csvPath: `${process.env.PUBLIC_URL}/data/events_substrate_2023.csv`,
//   //   // csvPath: `${process.env.PUBLIC_URL}/data/polkadot__number_of_unique_addresses_2023.csv`,
//   //   build: monthly_unique_addresses,
//   // },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_polkadot_active_addresses',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__monthly_active_addresses_by_parachain_and_relay_chain_2023.csv`,
//     build: monthly_active_addresses,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_xcm_sent_monthly_by_type',
//     csvPath: `${process.env.PUBLIC_URL}/data/xcm__messages_sent_monthly_by_type_2023.csv`,
//     build: xcm_monthly_by_type,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_xcm_distinct_hrmp',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__number_of_hrmp_channels_2023.csv`,
//     build: xcm_distinct_hrmp_channels,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_xcm_message_usage_per_month_hrmp_senders',
//     csvPath: `${process.env.PUBLIC_URL}/data/xcm__parachain_message_usage_per_month__hrmp_senders__2023.csv`,
//     build: xcm_parachain_message_usage_hrmp_senders,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_github_commits',
//     csvPath: `${process.env.PUBLIC_URL}/data/github__commits_2023.csv`,
//     build: github_commits,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_github_active_developers',
//     csvPath: `${process.env.PUBLIC_URL}/data/github__active_developers_2023.csv`,
//     build: github_active_developers,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_github_issues_opened_closed_per_month',
//     csvPath: `${process.env.PUBLIC_URL}/data/github__issues_opened_and_closed_per_month_2023.csv`,
//     build: github_issues_opened_and_closed,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_github_merged_pull_requests',
//     csvPath: `${process.env.PUBLIC_URL}/data/github__merged_pull_requests__ecosystem__2023.csv`,
//     build: github_issues_merged_pull_requests,
//   },
//   { 
//     folder: 'Developer',
//     elementId: 'chart_github_unique_devs_and_org_commits',
//     csvPath: `${process.env.PUBLIC_URL}/data/github__unique_develoeprs and total_org_commits_2023.csv`,
//     build: github_unique_authors_and_orgs_commits,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_stackexchange_user_growth',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot_december_fees.csv`,
//     // csvPath: `${process.env.PUBLIC_URL}/data/stack_exchange__user_growth__weekly__2023.csv`,
//     build: stackexchange_user_growth,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_stackexchange_top_25_tags',
//     csvPath: `${process.env.PUBLIC_URL}/data/stack_exchange__top_25_tags_2023.csv`,
//     build: stackexchange_top_tags,
//   },
//   {
//     folder: 'Developer',
//     elementId: 'chart_stackexchange_activity_eoy',
//     csvPath: `${process.env.PUBLIC_URL}/data/stack_exchange__activity_2023.csv`,
//     build: stackexchange_activity_eoy,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_assethub_sum_usdc',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot_asset_hub__sum_of_usdc_2023.csv`,
//     build: polkadot_asset_hub_usdc,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_crowdloan_contribution',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__crowdloan_contribution_first_5_parachain_auctions.csv`,
//     build: parachain_contributors_and_amount,
//   },
//   {
//     folder: 'Staking',
//     elementId: 'chart_sum_staked_tokens',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__sum_of_staked_tokens_Oct_22_Nov_30_2023.csv`,
//     build: sum_of_staked_tokens,
//   },
//   {
//     folder: 'Polkadot',
//     elementId: 'chart_qfour_auction_winners',
//     csvPath: `${process.env.PUBLIC_URL}/data/polkadot__q4_auction_winners_Q4_2023.csv`,
//     build: qfour_auction_winners,
//   },
// ];

export const chartsConfig = [
  {
    elementId: 'chart_polkadot_staking_rate',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv`,
    build: staking_rate
  },
  {
    elementId: 'chart_polkadot_nominators_validators',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_shared_security_number_of_nominators000000000000.csv`,
    build: nominators_validators
  },
  {
    elementId: 'chart_polkadot_nakamoto_coefficient',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_shared_security_nakamoto_coefficient000000000000.csv`,
    build: nakamoto_coefficient
  },
  {
    elementId: 'chart_polkadot_min_max_validator_stakes',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake000000000000.csv`,
    build: min_max_nominators_validators_stake
  },
  {
    elementId: 'chart_polkadot_treasury_balance',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_treasury_balance000000000000.csv`,
    build: treasury_balance
  },
  {
    elementId: 'chart_polkadot_treasury_flows',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_treasury_flows000000000000.csv`,
    build: treasury_flows
  },
  {
    elementId: 'chart_opengov_number_of_referenda_by_outcome_history',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_referenda_by_outcome_opengov_history000000000000.csv`,
    build: referenda_by_outcome
  },
  {
    elementId: 'chart_number_of_referenda_by_origin',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_referenda_by_origin_opengov000000000000.csv`,
    build: number_referenda_by_origin
  },
  {
    elementId: 'chart_number_of_votes_by_duration_of_lock',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_votes_by_duration_of_lock_opengov000000000000.csv`,
    build: number_of_votes_by_duration_of_lock
  },
  {
    elementId: 'chart_monthly_opengov_monthly_participation',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_monthly_participation000000000000.csv`,
    build: monthly_participation
  },
  {
    elementId: 'chart_monthly_opengov_number_of_voters',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_voters000000000000.csv`,
    build: monthly_number_of_voters
  },
  {
    elementId: 'chart_monthly_opengov_capital_by_type_no_conviction',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov000000000000.csv`,
    build: monthly_capital_no_conviction
  },
  {
    elementId: 'chart_monthly_opengov_voting_power_by_type_with_conviction',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov000000000000.csv`,
    build: monthly_voting_power_with_conviction
  },
  {
    elementId: 'chart_monthly_tokens_voted_by_direction_no_conviction',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov000000000000.csv`,
    build: number_monthly_tokens_voted_by_direction
  },
  {
    elementId: 'chart_monthly_tokens_voted_by_direction_with_conviction',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov000000000000.csv`,
    build: number_monthly_tokens_voted_by_direction
  },
  {
    elementId: 'chart_polkadot_number_of_parachains',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_parachains_number_of_parachains000000000000.csv`,
    build: number_of_parachains
  },
  {
    elementId: 'chart_polkadot_monthly_transactions',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_parachains_number_of_transactions_extrinsics000000000000.csv`,
    freq: 'monthly',
    relay: 'polkadot',
    yName: 'combined',
    title: 'Transactions (Substrate + EVM)',
    topN: 50,
    build: barchart
  },
  {
    elementId: 'chart_polkadot_unique_addresses',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_parachains_number_of_unique_addresses000000000000.csv`,
    freq: 'monthly',
    relay: 'polkadot',
    yName: 'number_of_unique_addresses',
    title: 'Unique Addresses',
    topN: 50,
    build: barchart
  },
  {
    elementId: 'chart_polkadot_active_addresses',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_parachains_number_of_active_addresses000000000000.csv`,
    freq: 'monthly',
    relay: 'polkadot',
    yName: 'combined',
    title: 'Active Addresses',
    build: barchart
  },
  {
    elementId: 'chart_xcm_sent_monthly_by_type',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_xcm_messages_by_type000000000000.csv`,
    freq: 'monthly',
    relay: 'polkadot',
    yName: 'method_count',
    grouping: 'msg_type',
    title: 'XCM Messages Sent',
    build: barchart
  },
  {
    elementId: 'chart_xcm_distinct_hrmp',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_parachains_number_of_hrmp_channels000000000000.csv`,
    build: xcm_distinct_hrmp_channels
  },
  {
    elementId: 'chart_xcm_message_usage_per_month_hrmp_senders',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_xcm_parachain_message_usag_per_month_hrmp_senders000000000000.csv`,
    freq: 'monthly',
    relay: 'polkadot',
    yName: 'method_count',
    title: 'HRMP Senders',
    build: barchart
  },
  {
    elementId: 'chart_stackexchange_user_growth',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_stack_exchange_user_growth000000000000.csv`,
    build: stackexchange_user_growth
  },
  {
    elementId: 'chart_stackexchange_top_25_tags',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_stack_exchange_top_25_tags000000000000.csv`,
    build: stackexchange_top_tags
  },
  {
    elementId: 'chart_stackexchange_activity_eoy',
    csvPath: `${process.env.PUBLIC_URL}/data/eoyr_stack_exchange_activity000000000000.csv`,
    build: stackexchange_activity_eoy
  },
];























function staking_rate(chart, data){

  // if (!data || data.length === 0) {
  //   consolerror('Data is missing or empty.');
  //   return;
  // }
  // data = data.reverse();

  let months = data.map(item => item.month.slice(0, 10));
  let totalDotIssuance = data.map(item => parseFloat(item.total_dot_issuance));
  let totalDotStaked = data.map(item => parseFloat(item.total_dot_staked));
  let percentageStaked = data.map(item => 100*parseFloat(item.percentage_staked));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Total DOT Issuance', 'Total DOT Staked', 'Percentage Staked']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    graphic: watermark,

    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'DOT',
      },
      {
        type: 'value',
        name: 'Percentage',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Total DOT Issuance',
        type: 'bar',
        data: totalDotIssuance,
        color: colors.polkadot
      },
      {
        name: 'Total DOT Staked',
        type: 'bar',
        data: totalDotStaked,
        color: colors.neutral
      },
      {
        name: 'Percentage Staked',
        type: 'line',
        yAxisIndex: 1,
        data: percentageStaked,
        color: colors.emphasis
      }
    ]
  };

  // 
  chart.setOption(option);
}

function nominators_validators(chart, data){
  //must fix reverse
  // data = data.reverse();

  let months = data.map(item => item.month.slice(0, 10));
  let totalNominators = data.map(item => parseFloat(item.number_of_nominators));
  let totalActiveValidators = data.map(item => parseFloat(item.number_of_active_validators));
  let totalWaitingValidators = data.map(item => parseFloat(item.number_of_waiting_validators));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Nominators', 'Number of active Validators', 'Number of waiting Validators']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Active / Waiting Validators',
      },
      {
        type: 'value',
        name: 'Nominators',
      }
    ],
    series: [
      {
        name: 'Number of active Validators',
        type: 'bar',
        data: totalActiveValidators,
        color: colors.polkadot
      },
      {
        name: 'Number of waiting Validators',
        type: 'bar',
        data: totalWaitingValidators,
        color: colors.background
       },
      {
        name: 'Number of Nominators',
        type: 'line',
        data: totalNominators,
        yAxisIndex: 1,
        color: colors.neutral
      }
    ]
  };

  // const chart = window['general_' + config.elementId];
  chart.setOption(option);
}

function nakamoto_coefficient(chart, data){
  // data = data.reverse();
  let months = data.map(item => item.month.slice(0, 10));
  let nakamotoCoefficient = data.map(item => parseFloat(item.nakamoto_coefficient));
  

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Nakamoto Coefficient']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Nakamoto Coefficient',
      },
    ],
    series: [
      {
        name: 'Nakamoto Coefficient',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: nakamotoCoefficient,
        color: colors.polkadot 
      },
    ]
  };

  // const chart = window['general_' + config.elementId];
  chart.setOption(option);
}

function min_max_nominators_validators_stake(chart, data){

  let months = data.map(item => item.month.slice(0, 10));
  let maxStake = data.map(item => parseFloat(item.max_validator_stake));
  let diffPercentTotalStake = data.map(item => 100 * parseFloat(item.difference_in_percent_of_total_stake));
  let minStake = data.map(item => parseFloat(item.min_validator_stake));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Maximum Stake', 'Minimum Stake', 'Difference in % of total stake']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Stake (DOT)',
      },
      {
        type: 'value',
        name: 'Difference in % of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Minimum Stake',
        type: 'bar',
        data: minStake,
        color: colors.emphasis
      },
      {
        name: 'Maximum Stake',
        type: 'bar',
        data: maxStake,
        color: colors.polkadot
      },
      {
        name: 'Difference in % of total stake',
        type: 'line',
        data: diffPercentTotalStake,
        yAxisIndex: 1,
        color: colors.neutral
      }
    ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function treasury_balance(chart, data){
  // data = data.reverse();

  let months = data.map(item => item.month.slice(0, 10));
  let treasuryBalance = data.map(item => parseFloat(item.treasury_balance_dot));
  let percentOfTotalBalance = data.map(item => parseFloat(item.percent_of_total_issuance));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Treasury Balance', 'Percent of Total Issuance']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Balance (DOT)',
      },
      {
        type: 'value',
        name: 'Percent of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Treasury Balance',
        type: 'bar',
        data: treasuryBalance,
        color: colors.polkadot
      },
      {
        name: 'Percent of Total Issuance',
        type: 'line',
        data: percentOfTotalBalance,
        yAxisIndex: 1,
        color: colors.emphasis
      }
    ]
  };

  
  chart.setOption(option);
}

function treasury_flows(chart, data){

  var colorScheme = {
    'bounties': '#1f77b4', 
    'burnt': colors.kusama,
    'proposals': colors.emphasis,
    'transfers': '#ff9896',
    'inflation': colors.polkadot,
    'fees': colors.background
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.type]) {
                  aggregatedData[month][item.type] = 0;
              }
              aggregatedData[month][item.type] += parseFloat(item.sum_of_dot);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(type => {
          if (!seriesData[type]) {
              seriesData[type] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[type][monthIndex] = aggregatedData[month][type];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(type) {
      series.push({
          name: type,
          type: 'bar',
          stack: 'total',
          data: seriesData[type],
          itemStyle: {
              color: colorScheme[type]
          }
      });
  });
  
  // Updated part: Sorting series data by numeric value for tooltip
  var sortedSeriesData = {};
  months.forEach(month => {
      let monthData = [];
      Object.keys(aggregatedData[month]).forEach(chain => {
          monthData.push({
              chain: chain,
              value: aggregatedData[month][chain]
          });
      });

      // Sort monthData by value in descending order
      monthData.sort((a, b) => b.value - a.value);

      sortedSeriesData[month] = monthData;
  });

  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      formatter: customFormatter
      },
        legend: {
          bottom: '0%',
          left: 'center',
          data: Object.keys(seriesData)
        },
        graphic: watermark
        ,
    
        grid: {
            left: '3%',
            right: '4%',
            bottom: '9%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: months,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: {
            name: 'DOT',
            type: 'value'
        },
        series: series
    };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

// function referenda_by_outcome(chart, data){

//   var colorScheme = {
//     'Approved': colors.success, 
//     'Rejected': colors.failure,
//     'TimedOut': colors.neutral,
//     'Cancelled': colors.background,
//   };

//   let processedData = data.map(item => {
//     const value = parseInt(item.number_of_referenda);
    
//     return {
//       value: value,
//       name: item.outcome,
//       itemStyle: {
//         color: colorScheme[item.outcome]
//       }
//     };
//   });
  
//   // ECharts option
//   var option = {
//     tooltip: {
//         trigger: 'item',
//         formatter: function(params) {
//           return `${params.seriesName} <br/>${params.name}: ${params.value} (${Math.round(params.percent)}%)`;
//       }
//     },
//     legend: {
//         bottom: '0%',
//         left: 'center'
//     },
//     graphic: watermark,

//     series: [
//         {
//             name: 'Referenda Outcome',
//             type: 'pie',
//             radius: ['40%', '70%'],
//             label: {
//                 show: true,
//                 formatter: function(params) {
//                   return `${params.name}: ${Math.round(params.percent)}%`;
//               }
//             },
//             emphasis: {
//                 label: {
//                     show: true,
//                     fontSize: 40,
//                     fontWeight: 'bold'
//                 }
//             },
//             labelLine: {
//                 show: true
//             },
//             data: processedData
//         }
//       ]
//   };

//   // const chart = window[config.elementId];
//   chart.setOption(option);
// }




function referenda_by_outcome(chart, data) {
  // Color scheme for different outcomes
  var colorScheme = {
    'Approved': colors.success,
    'Rejected': colors.failure,
    'TimedOut': colors.neutral,
    'Cancelled': colors.background,
    'Ongoing': colors.warning,
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    // day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Process data to aggregate by month and outcome
  let aggregatedData = {};
  let months = new Set();
  data.forEach(item => {
    const month = item.month;
    const outcome = item.outcome;
    const value = parseInt(item.number_of_referenda);

    if (!aggregatedData[month]) {
      aggregatedData[month] = {};
    }
    if (!aggregatedData[month][outcome]) {
      aggregatedData[month][outcome] = 0;
    }
    aggregatedData[month][outcome] += value;
    months.add(month);
  });

  // Convert months to a sorted array and format them
  const sortedMonths = Array.from(months).sort();
  const formattedMonths = sortedMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Get all unique outcomes
  const outcomes = Object.keys(colorScheme);

  // Prepare raw data and total data for stacking
  let rawData = outcomes.map(outcome => {
    return sortedMonths.map(month => aggregatedData[month] && aggregatedData[month][outcome] ? aggregatedData[month][outcome] : 0);
  });

  let totalData = sortedMonths.map((month, index) => {
    return outcomes.reduce((sum, outcome) => sum + (rawData[outcomes.indexOf(outcome)][index] || 0), 0);
  });

  // Prepare series data
  const series = outcomes.map((outcome, sid) => {
    return {
      name: outcome,
      type: 'bar',
      stack: 'total',
      barWidth: '60%',
      label: {
        show: true,
        position: 'inside',
        formatter: (params) => {
          const percent = totalData[params.dataIndex] > 0 ? (params.value * 100).toFixed(1) : 0;
          return `${percent}%`;
        }
      },
      itemStyle: {
        color: colorScheme[outcome],
        opacity: 0.8
      },
      data: rawData[sid].map((d, did) => totalData[did] <= 0 ? 0 : d / totalData[did])
    };
  });

  // Configure the chart option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center'
    },
    grid: {
      left: 100,
      right: 100,
      top: 50,
      bottom: 50
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: { type: 'shadow' },
      formatter: function(params) {
        // Calculate the total value
        let totalValue = params.reduce((sum, item) => sum + (item.value * totalData[item.dataIndex]), 0);
        
        // Start the tooltip text with the date
        let tooltipText = `${params[0].axisValue}<br/>`;
    
        // Add each series' information to the tooltip text
        params.forEach(item => {
            const percent = item.value > 0 ? (item.value * 100).toFixed(1) : 0;
            tooltipText += `${item.marker} ${item.seriesName}: ${percent}% (${(item.value * totalData[item.dataIndex]).toFixed(2)})<br/>`;
        });
    
        // Add the total value at the end
        tooltipText += `Total: ${totalValue.toFixed(2)}`;
    
        return tooltipText;
      }
    },
    xAxis: {
      type: 'category',
      data: formattedMonths,
      axisLabel: {
        formatter: value => value // Ensure the formatted month is displayed as it is
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => `${(value * 100).toFixed(0)}%`
      }
    },
    series: series,
    graphic: watermark
  };

  // Set the option to the chart
  chart.setOption(option, true);
}

function monthly_participation(chart, data) {
  let aggregatedVotes = aggregateVotersByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    // day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Participation',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}


function number_submitted_proposals_gov1vsopengov(chart, data){
  
  var colorScheme = {
    'Gov 1': colors.emphasis,
    'Open Gov': colors.polkadot,
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  months.sort((a, b) => new Date(a) - new Date(b)); // Sort months in ascending order


  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.type]) {
                  aggregatedData[month][item.type] = 0;
              }
              aggregatedData[month][item.type] += parseFloat(item.number_of_treasury_proposals);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(type => {
          if (!seriesData[type]) {
              seriesData[type] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[type][monthIndex] = aggregatedData[month][type];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(type) {
      series.push({
          name: type,
          type: 'bar',
          stack: 'total',
          data: seriesData[type],
          itemStyle: {
              color: colorScheme[type]
          }
      });
  });
  
  // ECharts configuration
  var option = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    legend: {
        data: Object.keys(seriesData)
    },
    graphic: {
      type: 'image',
      id: 'watermark',
      bounding: 'raw', // Set to 'raw' for no padding
      right: 'center', // Center horizontally
      // bottom: 'center', // Center vertically
      top: 'center',
      z: -1,
      style: {
        image: watermarkImage,
        width: 150, // Adjust width if necessary
        height: 150, // Adjust height if necessary
        opacity: 0.1 // Adjust opacity if necessary
      }
    },

    grid: {
        left: '3%',
        right: '4%',
        bottom: '5%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            data: months,
            axisPointer: {
                type: 'shadow'
            }
        }
    ],
    yAxis: {
        name: 'DOT',
        type: 'value'
    },
    series: series
};

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function average_votes_gov1vsopengov(chart, data){

  var colorScheme = {
    'Gov 1': colors.emphasis,
    'Open Gov': colors.polkadot,
  };

  let averageVotes = data.map(item => ({
      value: parseFloat(item.average_votes),
      itemStyle: {
          color: colorScheme[item.gov_type]
        }
    })
  );
  let govType = data.map(item => item.gov_type);

  var option = {
    title: {
        text: 'Gov Type and Average Votes'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    graphic: {
      type: 'image',
      id: 'watermark',
      bounding: 'raw', // Set to 'raw' for no padding
      right: 'center', // Center horizontally
      // bottom: 'center', // Center vertically
      top: 'center',
      z: -1,
      style: {
        image: watermarkImage,
        width: 150, // Adjust width if necessary
        height: 150, // Adjust height if necessary
        opacity: 0.1 // Adjust opacity if necessary
      }
    },
    xAxis: {
        type: 'value'
    },
    yAxis: {
        type: 'category',
        data: govType
    },
    series: [
        {
            name: 'Average Votes',
            type: 'bar',
            data: averageVotes,
          }
      ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function number_referenda_by_origin(chart, data){

  var colorScheme = {
    'BigSpender': colors.polkadot,
    'MediumSpender': colors.emphasis,
    'SmallSpender': colors.success,
    'Root': colors.kusama
  };

  const option = {
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        orient: 'vertical',
        left: 'left',
        data: data.map(item => item.origin)
    },
    graphic: {
      type: 'image',
      id: 'watermark',
      bounding: 'raw', // Set to 'raw' for no padding
      right: 'center', // Center horizontally
      // bottom: 'center', // Center vertically
      top: 'center',
      z: -1,
      style: {
        image: watermarkImage,
        width: 150, // Adjust width if necessary
        height: 150, // Adjust height if necessary
        opacity: 0.1 // Adjust opacity if necessary
      }
    },

    series: [
        {
            name: 'Origin Count',
            type: 'pie',
            radius: ['40%', '70%'],
            data: data.map((item, index) => {
                return {
                    value: item.origin_count,
                    name: item.origin,
                    itemStyle: {
                        color: colorScheme[item.origin]
                    }
                };
            }),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};


  
  chart.setOption(option);
}

function number_of_votes_by_duration_of_lock(chart, data){
  var colorScheme = {
    'Locked6x': colors.polkadot,
    'Locked5x': colors.emphasis,
    'Locked4x': colors.success,
    'None': colors.background
  };

  const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.conviction)
        },
        graphic: {
          type: 'image',
          id: 'watermark',
          bounding: 'raw', // Set to 'raw' for no padding
          right: 'center', // Center horizontally
          // bottom: 'center', // Center vertically
          top: 'center',
          z: -1,
          style: {
            image: watermarkImage,
            width: 150, // Adjust width if necessary
            height: 150, // Adjust height if necessary
            opacity: 0.1 // Adjust opacity if necessary
          }
        },
    
        series: [
            {
                name: 'Origin Count',
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.map((item, index) => {
                    return {
                        value: item.conviction_count,
                        name: item.conviction,
                        itemStyle: {
                            color: colorScheme[item.conviction]
                        }
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };


  // const chart = window[config.elementId];
  chart.setOption(option);
}

function aggregateVotersByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    // Initialize the month in accumulator if it doesn't exist
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    // Add the number of voters to the corresponding vote type
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

function monthly_number_of_voters(chart, data) {
  let aggregatedVotes = aggregateDistinctByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    // day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Distinct Voters',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}

function aggregateTokensByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    // Initialize the month in accumulator if it doesn't exist
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    // Add the number of voters to the corresponding vote type
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.sum_of_tokens, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.sum_of_tokens, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

function aggregateDistinctByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_distinct_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_distinct_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

function monthly_capital_no_conviction(chart, data){

  let aggregatedTokens = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];
  
  Object.entries(aggregatedTokens).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function monthly_voting_power_with_conviction(chart, data){
  
  let aggregatedVotes = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];
  
  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  // console.log("aggregatedVotes", aggregatedVotes);

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function number_monthly_tokens_voted_by_direction(chart, data){

  var colorScheme = {
    'nay': colors.failure,
    'aye': colors.success,
    'split': colors.emphasis
  };
  data = data.reverse();
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.vote_direction]) {
                  aggregatedData[month][item.vote_direction] = 0;
              }
              aggregatedData[month][item.vote_direction] += parseFloat(item.sum_of_tokens);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(vote_direction => {
          if (!seriesData[vote_direction]) {
              seriesData[vote_direction] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[vote_direction][monthIndex] = aggregatedData[month][vote_direction];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(vote_direction) {
      series.push({
          name: vote_direction,
          type: 'bar',
          stack: 'total',
          data: seriesData[vote_direction],
          itemStyle: {
              color: colorScheme[vote_direction]
          }
      });
  });
  
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
        bottom: '0%',
        left: 'center',
        data: Object.keys(seriesData)
      },
      graphic: watermark,
      grid: {
          left: '3%',
          right: '4%',
          bottom: '9%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'DOT',
          type: 'value'
      },
      series: series
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}

function number_of_parachains(chart, data){
  let months = data.map(item => item.month.slice(0, 10));
  let numberOfParachains = data.map(item => parseFloat(item.number_of_parchains));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Parachains']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Parachains',
      },
    ],
    series: [
      {
        name: 'Number of Parachains',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: numberOfParachains,
        color: colors.polkadot 
      },
    ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);
}


function parachain_contributors_and_amount(chart, data) {
  data.reverse();

  let parachainNames = data.map(item => item.parachain_name);
  let contributors = data.map(item => parseFloat(item.number_of_contributors));
  let contributionAmounts = data.map(item => parseFloat(item.contribution_amount_DOT));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Contribution Amount (DOT)', 'Number of Contributors'] // Updated legend data
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(1);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: parachainNames,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Contribution Amount (DOT)',
      },
      {
        type: 'value',
        name: 'Number of Contributors',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'Contribution Amount (DOT)',
        type: 'bar',
        data: contributionAmounts,
        color: colors.polkadot
      },
      {
        name: 'Number of Contributors',
        type: 'line',
        yAxisIndex: 1,
        data: contributors,
        color: colors.success,
        smooth: true
      }
    ]
  };

  // 
  chart.setOption(option);
}

// function monthly_transactions(chart, data) {
//   // Array of distinct colors
//   // var distinctColors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];
//   var distinctColors = generatedColors; var colorScheme = {};
//   //   var distinctColors = generateDistinctColors(50);  var colorScheme = {};
  

//   // Convert scientific notation to numbers and sort data by number_of_events
//   data.forEach(item => {
//     item.Num_Events = parseFloat(item.Num_Events);
//   });
//   data.sort((a, b) => b.Num_Events - a.Num_Events);
  
//   let months = Array.from(new Set(data.map(item => item.Date.slice(0, 7)))).sort();

//   var aggregatedData = {};
//   data.forEach(item => {
//       let month = item.Date.slice(0, 7);
//       let chain = item.Chain;
//       if (!aggregatedData[month]) {
//           aggregatedData[month] = {};
//       }
//       if (!aggregatedData[month][chain]) {
//           aggregatedData[month][chain] = 0;
//       }
//       aggregatedData[month][chain] += item.Num_Events;

//       // Populate colorScheme dynamically
//       if (!colorScheme[chain]) {
//           colorScheme[chain] = null; // Placeholder, will be replaced with color
//       }
//   });

//   var seriesData = {};
//   Object.keys(aggregatedData).forEach(month => {
//       Object.keys(aggregatedData[month]).forEach(chain => {
//           if (!seriesData[chain]) {
//               seriesData[chain] = new Array(months.length).fill(0);
//           }
//           var monthIndex = months.indexOf(month);
//           seriesData[chain][monthIndex] = aggregatedData[month][chain];
//       });
//   });

//   // Assign distinct colors to each chain
//   var chains = Object.keys(colorScheme);
//   chains.forEach((chain, index) => {
//       colorScheme[chain] = distinctColors[index % distinctColors.length];
//       chains.sort((a, b) => a.localeCompare(b));
//   });
  

//   var series = chains.map(chain => {
//       return {
//           name: chain,
//           type: 'bar',
//           stack: 'total',
//           data: seriesData[chain],
//           itemStyle: {
//               color: colorScheme[chain] || '#000000' // Default color if not defined
//           }
//       };
//   });

//   let rowSize = Math.ceil(chains.length / 3);  // Determine the size of each row
//   let legends = [];
//   const totalRows = Math.ceil(chains.length / rowSize); // Calculate the total number of rows needed

//     for (let i = totalRows - 1; i >= 0; i--) {
//       const start = i * rowSize;
//       const end = Math.min(start + rowSize, chains.length);
//       legends.push({
//         data: chains.slice(start, end),
//         left: 'right',
//         bottom: `${(totalRows - 1 - i) * (100 / (totalRows + 1))}%`, // Adjust bottom positioning
//         textStyle: {
//           fontSize: 7 // Adjust font size as needed
//         },
//       });
//     }



//   var watermarkImage = 'dotlake-small.a486ef76.png'; // Replace with the actual path to your image

//   // ECharts configuration
//   var option = {
//       tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//               type: 'shadow'
//           }
//       },
//       legend: legends,
//       graphic: {
//         type: 'image',
//         id: 'watermark',
//         bounding: 'raw', // Set to 'raw' for no padding
//         right: 'center', // Center horizontally
//         bottom: 'center', // Center vertically
//         // top: 'bottom',
//         z: -1,
//         style: {
//           image: watermarkImage,
//           width: 80, // Adjust width if necessary
//           height: 80, // Adjust height if necessary
//           opacity: 0.5 // Adjust opacity if necessary
//         }
//       },
//       grid: {
//           left: '3%',
//           top: '10%',
//           right: '4%',
//           bottom: '20%',
//           containLabel: true
//       },
//       xAxis: {
//           type: 'category',
//           data: months,
//           axisPointer: {
//               type: 'shadow'
//           }
//       },
//       yAxis: {
//           name: 'Number of Events',
//           type: 'value'
//       },
//       series: series
//   };

  
//   chart.setOption(option);
// }

// function monthly_unique_addresses(chart, data){
//   data.reverse()

//   var colorScheme = {
//     'polkadot': colors.polkadot, 
//   };

//   let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

//   var aggregatedData = {};
//   months.forEach(month => {
//       data.forEach(item => {
//           if (item.month.slice(0, 10) === month) {
//               if (!aggregatedData[month]) {
//                   aggregatedData[month] = {};
//               }
//               if (!aggregatedData[month][item.chain]) {
//                   aggregatedData[month][item.chain] = 0;
//               }
//               aggregatedData[month][item.chain] += parseFloat(item.number_of_unique_addresses);
//           }
//       });
//   });

//   var totalPerMonth = {};
//   months.forEach(month => {
//       totalPerMonth[month] = 0;
//       Object.keys(aggregatedData[month]).forEach(chain => {
//           totalPerMonth[month] += aggregatedData[month][chain];
//       });
//   });
  
//   var seriesData = {};
//   Object.keys(aggregatedData).forEach(month => {
//       Object.keys(aggregatedData[month]).forEach(chain => {
//           if (!seriesData[chain]) {
//               seriesData[chain] = new Array(months.length).fill(0);
//           }
//           var monthIndex = months.indexOf(month);
//           seriesData[chain][monthIndex] = aggregatedData[month][chain];
//       });
//   });
  
//   var series = [];
//   var chains = Object.keys(seriesData);
//   chains.forEach(function(chain, index) {
//       series.push({
//           name: chain,
//           type: 'bar',
//           stack: 'total',
//           data: seriesData[chain],
//           itemStyle: {
//               color: colorScheme[chain]
//           },
//           // Show label only for the top bar in the stack
//           label: {
//               show: index === chains.length - 1, // Show label only for the last chain
//               position: 'top',
//               formatter: function(params) {
//                   // Get the total transactions for the month
//                   var month = params.name;
//                   var total = totalPerMonth[month];
//                   return (total / 1000000).toFixed(1) + 'M'; // Format as '11.0M'
//               }
//           }
//       });
//   });
//   // ECharts configuration
//   var option = {
//       tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//               type: 'shadow'
//           }
//       },
//       legend: {
//           data: Object.keys(seriesData),
//           left: 'center',
//           bottom: '0%',
//           type: 'scroll',
//       },
//       grid: {left: '3%', right: '4%', containLabel: true},
//       xAxis: [
//           {
//               type: 'category',
//               data: months,
//               axisPointer: {
//                   type: 'shadow'
//               }
//           }
//       ],
//       yAxis: {
//           name: 'Unique Addresses',
//           type: 'value'
//       },
//       series: series
//   };

  
//   chart.setOption(option);
// }

function monthly_active_addresses(chart, data){

  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.number_of_active_addresses);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000).toFixed(1) + 'K'; // Format as '11.0M'
              }
          }
      });
  });
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
          data: Object.keys(seriesData),
          left: 'center',
          bottom: '0%',
          type: 'scroll',
      },
      grid: {left: '3%', right: '4%', containLabel: true},
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'Active Addresses',
          type: 'value'
      },
      series: series
  };

  
  chart.setOption(option);
}

function xcm_monthly_by_type(chart, data){
  
  var colorScheme = {
    'DMP': colors.emphasis,
    'UMP': colors.polkadot,
    'HRMP': colors.success
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.msg_type]) {
                  aggregatedData[month][item.msg_type] = 0;
              }
              aggregatedData[month][item.msg_type] += parseFloat(item.method_count);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(chain) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          }
      });
  });
  
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
        data: Object.keys(seriesData),
        left: 'center',
        bottom: '0%',
      },
      grid: {left: '3%', right: '4%', containLabel: true},
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'XCM Messages Sent',
          type: 'value'
      },
      series: series
  };

  
  chart.setOption(option);
}

function xcm_distinct_hrmp_channels(chart, data){
  // data = data.reverse();
  let months = data.map(item => item.month.slice(0, 10));
  let numberOfHRMPChannels = data.map(item => parseFloat(item.number_of_hrmp_channels));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['HRMP Channels']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(0);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'HRMP Channels',
      },
    ],
    series: [
      {
        name: 'HRMP Channels',
        type: 'line',
        areaStyle: {
          color: colors.emphasis,
          opacity: 0.5
        },
        data: numberOfHRMPChannels,
        color: colors.emphasis 
      },
    ]
  };

  
  chart.setOption(option);


}

function xcm_parachain_message_usage_hrmp_senders(chart, data){

  var colorScheme = {
    'polkadot': colors.polkadot, 
  };

  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.month.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.chain]) {
                  aggregatedData[month][item.chain] = 0;
              }
              aggregatedData[month][item.chain] += parseFloat(item.method_count);
          }
      });
  });

  var totalPerMonth = {};
  months.forEach(month => {
      totalPerMonth[month] = 0;
      Object.keys(aggregatedData[month]).forEach(chain => {
          totalPerMonth[month] += aggregatedData[month][chain];
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(chain => {
          if (!seriesData[chain]) {
              seriesData[chain] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[chain][monthIndex] = aggregatedData[month][chain];
      });
  });
  
  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
      series.push({
          name: chain,
          type: 'bar',
          stack: 'total',
          data: seriesData[chain],
          itemStyle: {
              color: colorScheme[chain]
          },
          // Show label only for the top bar in the stack
          label: {
              show: index === chains.length - 1, // Show label only for the last chain
              position: 'top',
              formatter: function(params) {
                  // Get the total transactions for the month
                  var month = params.name;
                  var total = totalPerMonth[month];
                  return (total / 1000).toFixed(1) + 'K'; // Format as '11.0M'
              }
          }
      });
  });
  // ECharts configuration
  var option = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      legend: {
          data: Object.keys(seriesData),
          left: 'center',
          bottom: '0%',
      },
      grid: {left: '3%', right: '4%', bottom: '20%', containLabel: true},
      xAxis: [
          {
              type: 'category',
              data: months,
              axisPointer: {
                  type: 'shadow'
              }
          }
      ],
      yAxis: {
          name: 'HRMP Messages Sent',
          type: 'value'
      },
      series: series
  };

  
  chart.setOption(option);
}

function github_commits(chart, data){
  data.reverse()
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfCommits = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.commit_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Commits']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(0);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Commits',
      },
    ],
    series: [
      {
        name: 'GitHub: Commits',
        type: 'bar',
        data: numberOfCommits,
        color: colors.emphasis 
      },
    ]
  };

  
  chart.setOption(option);

}

function github_active_developers(chart, data){

  data.reverse()
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfAuthors = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.author_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Active Developers']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(0);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Active Developers',
      },
    ],
    series: [
      {
        name: 'GitHub: Active Developers',
        type: 'bar',
        data: numberOfAuthors,
        color: colors.heat 
      },
    ]
  };

  
  chart.setOption(option);
  
}

function github_issues_opened_and_closed(chart, data){
  // data.reverse()
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  let issuesClosed = data.filter(item => item.type == "Issues Closed").map(item => parseFloat(item.type_count));
  let issuesOpened = data.filter(item => item.type == "Issues Opened").map(item => parseFloat(item.type_count));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Opened', 'Issues Closed']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(1);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Issues',
      }
    ],
    series: [
      {
        name: 'Issues Opened',
        type: 'bar',
        data: issuesOpened,
        color: colors.polkadot
      },
      {
        name: 'Issues Closed',
        type: 'bar',
        data: issuesClosed,
        color: colors.success
      }
    ]
  };

  
  chart.setOption(option);
}

function github_issues_merged_pull_requests(chart, data){
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  let issuesMerged = data.map(item => parseFloat(item.total_pull_id_count));

  // Sort data by month
  let sortedData = months.map((month, index) => ({
    month: month,
    issuesMerged: issuesMerged[index],
  })).sort((a, b) => new Date(a.month) - new Date(b.month));

  // Extract sorted values
  months = sortedData.map(item => item.month);
  issuesMerged = sortedData.map(item => item.issuesMerged);

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Merged']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(1);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Merged PRs',
      }
    ],
    series: [
      {
        name: 'Issues Merged',
        type: 'bar',
        data: issuesMerged,
        color: colors.success
      }
    ]
  };

  
  chart.setOption(option);
}

function github_unique_authors_and_orgs_commits(chart, data){
    // Sort data by developers in descending order
    data.sort((a, b) => parseFloat(b.developers) - parseFloat(a.developers));
  
    let organizations = data.map(item => item.org);
    let numDevelopers = data.map(item => parseFloat(item.developers));
    let commits = data.map(item => parseFloat(item.commits));
    let cumulativeDevelopers = numDevelopers.map((sum => value => sum += value)(0));
  
    // ECharts option
    var option = {
      legend: {
        bottom: '0%',
        left: 'center',
        data: ['Developers', 'Commits']
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function (params) {
          return params.map(param => {
            return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(1);
          }).join('<br/>');
        }
      },
      xAxis: [
        {
          type: 'category',
          data: organizations,
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            rotate: 45,
            interval: 0, 
            fontSize: 8
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Developers',
        },
        {
          type: 'value',
          name: 'Commits',
          position: 'right'
        }
      ],
      series: [
        {
          name: 'Developers',
          type: 'bar',
          data: numDevelopers,
          color: colors.powderBlue
          // colors.success
        },
        {
          name: 'Commits',
          type: 'line',
          yAxisIndex: 1,
          data: commits,
          smooth: true
        }
      ]
    };
  
    
    chart.setOption(option);
}

function stackexchange_user_growth(chart, data){

  let sortedData = data.sort((a, b) => new Date(a.creation_date) - new Date(b.creation_date));

  let months = sortedData.map(item => item.creation_date.slice(0, 10));

  let numUsers = sortedData.map(item => parseFloat(item.num_users));
  let runningTotals = sortedData.map(item => parseFloat(item.running_total));

  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['StackExchange Users', 'Running Totals']
    },
    graphic: watermark,

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
      },
      {
        type: 'value',
        name: 'Running Totals',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'StackExchange Users',
        type: 'bar',
        data: numUsers,
        color: colors.polkadot
      },
      {
        name: 'Running Totals',
        type: 'line',
        yAxisIndex: 1,
        data: runningTotals,
        smooth: true
      }
    ]
  };

  // const chart = window[config.elementId];
  chart.setOption(option);

}  
  
function stackexchange_top_tags(chart, data){

  const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
            {
                name: 'Tag',
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.map((item, index) => {
                    return {
                        value: item.tags_count,
                        name: item.tags
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
        ,
        graphic: watermark
    };


  
  chart.setOption(option);
}

function stackexchange_activity_eoy(chart, data) {
  var colorScheme = {
    'post-question': colors.polkadot,
    'poast-answer': colors.success,
    'suggested-edit': colors.emphasis,
    'badge': colors.heat
  };

  let months = Array.from(new Set(data.map(item => item.date.slice(0, 10))));

  var aggregatedData = {};
  months.forEach(month => {
    data.forEach(item => {
      if (item.date.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.timeline_type_post]) {
          aggregatedData[month][item.timeline_type_post] = 0;
        }
        aggregatedData[month][item.timeline_type_post] += parseFloat(item.number_of_engagements);
      }
    });
  });

  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(timeline_type_post => {
      if (!seriesData[timeline_type_post]) {
        seriesData[timeline_type_post] = new Array(months.length).fill(0);
      }
      var monthIndex = months.indexOf(month);
      seriesData[timeline_type_post][monthIndex] = aggregatedData[month][timeline_type_post];
    });
  });

  var series = [];
  Object.keys(seriesData).forEach(function(timeline_type_post) {
    series.push({
      name: timeline_type_post,
      type: 'bar',
      stack: 'total',
      data: seriesData[timeline_type_post],
      itemStyle: {
        color: colorScheme[timeline_type_post]
      }
    });
  });


  // ECharts configuration
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: Object.keys(seriesData)
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'Engagements',
      type: 'value'
    },
    series: series,
    graphic: watermark
  };

  chart.setOption(option);
}


function polkadot_asset_hub_usdc(chart, data){
  
  let months = data.map(item => item.date.slice(0, 10));
  let sumUSDC = data.map(item => parseFloat(item.sum_of_usdc));


  // ECharts option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of USDC']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(0);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of USDC',
      },
    ],
    series: [
      {
        name: 'Sum of USDC',
        type: 'line',
        areaStyle: {
          color: colors.success,
          opacity: 0.5
        },
        data: sumUSDC,
        color: colors.success 
      },
    ]
  };

  
  chart.setOption(option);
}

function sum_of_staked_tokens(chart, data) {
  data = data.reverse();  
  let months = data.map(item => item.date.slice(0, 10));
  let sumStaked = data.map(item => parseFloat(item.sum_of_staked_tokens));

  // Background image URL
  const backgroundImageUrl = 'path/to/dotlake-small.a486ef76.png';
  var watermarkImage = 'dotlake-small.a486ef76.png'; // Replace with the actual path to your image

  // ECharts option
  var option = {
    
    graphic: {
      type: 'image',
      id: 'watermark',
      bounding: 'raw', // Set to 'raw' for no padding
      
      right: 'center', // Center horizontally
      bottom: 'center', // Center vertically
      z: -1,
      style: {
        image: watermarkImage,
        width: 80, // Adjust width if necessary
        height: 80, // Adjust height if necessary
        opacity: 0.06 // Adjust opacity if necessary
      }
    },
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of Staked DOT']
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {
        return params.map(param => {
          return '<strong>' + param.seriesName + '</strong>' + ': ' + param.value.toFixed(0);
        }).join('<br/>');
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of Staked DOT',
        min: 0,
      },
    ],
    series: [
      {
        name: 'Sum of Staked DOT',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: sumStaked,
        color: colors.polkadot 
      },
    ]
  };

  
  chart.setOption(option);
}

function qfour_auction_winners(chart, data) {
  data = data.reverse();
  let auctionIndices = data.map(item => item.auction_index);
  let projectNames = data.map(item => item.project_name_para_id);
  let contributionAmounts = data.map(item => parseFloat(item.contribution_amount));

  // ECharts option
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        return (
          '<strong>' +
          params[0].name +
          '</strong><br/>' +
          params.map(param => {
            return (
              param.seriesName + ': ' + param.value.toFixed(1) + ' DOT<br/>' +
              'Project Name: ' + projectNames[auctionIndices.indexOf(param.name)]
            );
          }).join('<br/>')
        );
      }
    },
    xAxis: [
      {
        type: 'category',
        data: auctionIndices,
        axisPointer: {
          type: 'shadow'
        },
        name: 'Auction Index'
      }
    ],
    yAxis: {
      type: 'value',
      name: 'DOT'
    },
    series: [
      {
        name: 'Contribution Amount',
        type: 'bar',
        data: contributionAmounts,
        color: colors.polkadot
      }
    ]
  };

  
  chart.setOption(option);
}


//had to adjust instead of the config for the bar chart to take the right axis for the monthly report. Replace with better solution!
// function barchart(chart, data) {
//   const xName = chart.freq == 'daily' ? 'date' : 'month';
//   const elementId = chart._dom.id;
//     let yName = chart.yName; // Default to using yName from the chart object

//     // Adjust properties based on elementId and yName from the chart
//     if (elementId === 'chart_polkadot_monthly_transactions') {
//         yName = 'combined';
//     } else if (elementId === 'chart_polkadot_unique_addresses') {
//         yName = 'number_of_unique_addresses';
//     } else if (elementId === 'chart_polkadot_active_addresses') {
//         yName = 'combined';
//     } else if (elementId === 'chart_xcm_sent_monthly_by_type') {
//         yName = 'method_count';
//         chart.grouping = 'msg_type'; // updating grouping as it's a special case
//     } else if (elementId === 'chart_xcm_message_usage_per_month_hrmp_senders') {
//         yName = 'method_count';
//     }

//   const yTitle = chart.title;
//   const grouping = chart.grouping === undefined ? 'chain' : chart.grouping;
//   const topN = chart.topN === undefined ? 50 : chart.topN;
//   const type = chart.type === undefined ? 'bar' : chart.type;
//   const stack = type === 'bar' ? 'total' : '';
//   const colorScheme = get_chain_colors('polkadot');
//   // console.log(chart.relay)

//   // console.log(xName)
//   // console.log(yName)
//   // console.log(yTitle)
//   // console.log(grouping)

//   data.forEach(item => {
//     item[yName] = parseFloat(item[yName]);
//   });

//   const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

//   let aggregatedData = {};
//   data.forEach(item => {
//     const xSeries = item[xName];
//     const chain = item[grouping];
//     if (!aggregatedData[xSeries]) {
//       aggregatedData[xSeries] = {};
//     }
//     if (!aggregatedData[xSeries][chain]) {
//       aggregatedData[xSeries][chain] = 0;
//     }
//     aggregatedData[xSeries][chain] += item[yName];
//   });

//   let topChainsSet = new Set();
//   Object.keys(aggregatedData).forEach(month => {
//     let chains = Object.keys(aggregatedData[month]);
//     chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
//     let topChains = chains.slice(0, topN);
//     topChains.forEach(chain => topChainsSet.add(chain));

//     if (chains.length > topN) {
//       // Include 'other' only if the actual number of chains exceeds topN
//       let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
//       aggregatedData[month] = topChains.reduce((obj, key) => {
//         obj[key] = aggregatedData[month][key];
//         return obj;
//       }, {'other': otherSum});
//     } else {
//       // If not exceeding topN, just keep the top chains without 'other'
//       aggregatedData[month] = topChains.reduce((obj, key) => {
//         obj[key] = aggregatedData[month][key];
//         return obj;
//       }, {});
//     }
//   });

//   let chains = Array.from(topChainsSet);
//   if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
//     chains.push('other');
//   }

//   chains.sort((a, b) => {
//     let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
//     let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
//     return bSum - aSum;
//   });

//   chains.forEach((chain, index) => {
//     if (!colorScheme[chain]) {
//       colorScheme[chain] = generatedColors[index % generatedColors.length];
//     }
//   });

//   let seriesData = {};
//   chains.forEach(chain => {
//     seriesData[chain] = new Array(dates.length).fill(0);
//   });

//   Object.keys(aggregatedData).forEach(month => {
//     const monthIndex = dates.indexOf(month);
//     chains.forEach(chain => {
//       seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
//     });
//   });

//   let series = chains.map(chain => ({
//     name: chain,
//     type: 'bar',
//     stack: 'total',
//     data: seriesData[chain],
//     itemStyle: { color: colorScheme[chain] },
//   }));

//   let legendData = chains;//.concat(['Select All', 'Select None']);
//   // Adjustments for legend and grid  
  
//   let legendPositionAndOrientation = chains.length > 7 ? {
//     orient: 'vertical', left: '80%', top: '6%', height: '85%'
//   } : {
//     orient: 'horizontal', left: 'center', bottom: '2%' 
//   };

//   let gridOptions = chains.length > 7 ? { 
//     left: '2%', top: '2%', right: '22%', bottom: '5%', containLabel: true 
//   } : {
//     left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
//   }

//   // Dynamically add selector buttons if there are more than 3 items in the legend
//   let selectorOptions = chains.length > 3 ? [{
//       type: 'all',
//       title: 'Select All'
//   }, {
//       type: 'inverse',
//       title: 'Invert Selection'
//   }] : [];

//   // Adjustments for legend and grid
//   let option = {
//     toolbox: {
//       show: true,
//       feature: {
//         magicType: { type: ['bar', 'line', 'stack'] },
//       },
//     },
//     tooltip: {
//       trigger: 'axis',
//       confine: true,
//       axisPointer: { type: 'shadow' },
//       formatter: customFormatter
//     },
//     legend: {
//       data: legendData,
//       orient: 'horizontal',
//       padding: 1,
//       textStyle: {
//         fontSize: '10',
//       },
//       selected: legendData.reduce((obj, item) => {
//           // This line ensures all your series are selected by default except for "Select All" and "Select None"
//           // Since "Select All" and "Select None" are not in legendData, they won't affect this
//           obj[item] = true; // Initially, all items are selected
//           return obj;
//       }, {}),
//       selector: selectorOptions, // Use the dynamically set selector options here
//         ...legendPositionAndOrientation // Spread the dynamically determined properties
//     },
//     graphic: watermark,
//     grid: gridOptions,
//     xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
//     yAxis: { name: yTitle, type: 'value' },
//     series: series,
//   };

//   // console.log("Option for Chart:", option);
//   // window[config.elementId].setOption(option, true);
//   chart.setOption(option);
// }

function barchart(chart, data) {
  const xName = chart.freq === 'daily' ? 'date' : 'month';
  const elementId = chart._dom.id;
  let yName = chart.yName; // Default to using yName from the chart object

  // Adjust properties based on elementId and yName from the chart
  if (elementId === 'chart_polkadot_monthly_transactions') {
    yName = 'combined';
  } else if (elementId === 'chart_polkadot_unique_addresses') {
    yName = 'number_of_unique_addresses';
  } else if (elementId === 'chart_polkadot_active_addresses') {
    yName = 'combined';
  } else if (elementId === 'chart_xcm_sent_monthly_by_type') {
    yName = 'method_count';
    chart.grouping = 'msg_type'; // updating grouping as it's a special case
  } else if (elementId === 'chart_xcm_message_usage_per_month_hrmp_senders') {
    yName = 'method_count';
  }

  const yTitle = chart.title;
  const grouping = chart.grouping === undefined ? 'chain' : chart.grouping;
  const topN = chart.topN === undefined ? 75 : chart.topN;
  const type = chart.type === undefined ? 'bar' : chart.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors('polkadot');

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      // Include 'other' only if the actual number of chains exceeds topN
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      // If not exceeding topN, just keep the top chains without 'other'
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '75%', top: '0%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? {
    left: '2%', top: '2%', right: '26%', bottom: '5%', containLabel: true
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true
  };

  let selectorOptions = chains.length > 3 ? [{
    type: 'all',
    title: 'Select All'
  }, {
    type: 'inverse',
    title: 'Invert Selection'
  }] : [];

  const magicTypeOptions = chains.length > 1 ? ['bar', 'line', 'stack'] : ['bar', 'line'];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: magicTypeOptions },
      },
      bottom: '0%',
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 0.1,
      textStyle: {
        fontSize: '9.2',
      },
      icon: 'circle',
      itemWidth: 8,
      itemHeight: 8,
      selected: legendData.reduce((obj, item) => {
        obj[item] = true;
        return obj;
      }, {}),
      selector: selectorOptions,
      selectorPosition: 'start',
      left: '700%',
      ...legendPositionAndOrientation
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  chart.setOption(option, true);
}


// function barchart(config, data, chart) {
//   const xName = config.freq == 'daily' ? 'date' : 'month';
//   const yName = config.yName;
//   const yTitle = config.title;
//   const grouping = config.grouping === undefined ? 'chain' : config.grouping;
//   const topN = config.topN === undefined ? 50 : config.topN;
//   const type = config.type === undefined ? 'bar' : config.type;
//   const stack = type === 'bar' ? 'total' : '';

//   const colorScheme = get_chain_colors();

//   data.forEach(item => {
//     item[yName] = parseFloat(item[yName]);
//   });

//   const userLocale = navigator.language || 'en-US';
//   const dateFormatter = new Intl.DateTimeFormat(userLocale, {
//     year: 'numeric',
//     month: 'short',
//     day: config.freq == 'daily' ? '2-digit' : undefined,
//   });

//   const formattedDateMap = new Map();
//   data.forEach(item => {
//     const originalDate = new Date(item[xName]);
//     const formattedDate = dateFormatter.format(originalDate);
//     item.formattedDate = formattedDate;
//     formattedDateMap.set(item[xName], formattedDate);
//   });

//   const dates = Array.from(new Set(data.map(item => item[xName]))).sort((a, b) => new Date(a) - new Date(b));

//   // console.log(data);

//   let aggregatedData = {};
//   data.forEach(item => {
//     const xSeries = item[xName];
//     const chain = item[grouping];
//     if (!aggregatedData[xSeries]) {
//       aggregatedData[xSeries] = {};
//     }
//     if (!aggregatedData[xSeries][chain]) {
//       aggregatedData[xSeries][chain] = 0;
//     }
//     aggregatedData[xSeries][chain] += item[yName];
//   });

//   let topChainsSet = new Set();
//   Object.keys(aggregatedData).forEach(month => {
//     let chains = Object.keys(aggregatedData[month]);
//     chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
//     let topChains = chains.slice(0, topN);
//     topChains.forEach(chain => topChainsSet.add(chain));

//     if (chains.length > topN) {
//       let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
//       aggregatedData[month] = topChains.reduce((obj, key) => {
//         obj[key] = aggregatedData[month][key];
//         return obj;
//       }, {'other': otherSum});
//     } else {
//       aggregatedData[month] = topChains.reduce((obj, key) => {
//         obj[key] = aggregatedData[month][key];
//         return obj;
//       }, {});
//     }
//   });

//   let chains = Array.from(topChainsSet);
//   if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
//     chains.push('other');
//   }

//   chains.sort((a, b) => {
//     let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
//     let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
//     return bSum - aSum;
//   });

//   chains.forEach((chain, index) => {
//     if (!colorScheme[chain]) {
//       colorScheme[chain] = generatedColors[index % generatedColors.length];
//     }
//   });

//   let seriesData = {};
//   chains.forEach(chain => {
//     seriesData[chain] = new Array(dates.length).fill(0);
//   });

//   Object.keys(aggregatedData).forEach(month => {
//     const monthIndex = dates.indexOf(month);
//     chains.forEach(chain => {
//       seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
//     });
//   });

//   let series = chains.map(chain => ({
//     name: chain,
//     type: type,
//     stack: stack,
//     data: seriesData[chain],
//     itemStyle: { 
//       color: colorScheme[chain],
//       opacity: 0.8
//     },
//   }));

//   let legendData = chains;
//   let legendPositionAndOrientation = chains.length > 7 ? {
//     orient: 'vertical', left: '80%', top: '6%', height: '85%'
//   } : {
//     orient: 'horizontal', left: 'center', bottom: '2%' 
//   };

//   let gridOptions = chains.length > 7 ? { 
//     left: '2%', top: '2%', right: '22%', bottom: '5%', containLabel: true 
//   } : {
//     left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
//   }

//   let selectorOptions = chains.length > 3 ? [{
//       type: 'all',
//       title: 'Select All'
//   }, {
//       type: 'inverse',
//       title: 'Invert Selection'
//   }] : [];

//   let option = {
//     toolbox: {
//       show: true,
//       feature: {
//         magicType: { type: ['bar', 'line', 'stack'] },
//       },
//     },
//     tooltip: {
//       trigger: 'axis',
//       backgroundColor: tooltipBackgroundColor,
//       confine: true,
//       axisPointer: { type: 'shadow' },
//       formatter: customFormatter
//     },
//     legend: {
//       data: legendData,
//       orient: 'horizontal',
//       padding: 1,
//       textStyle: {
//         fontSize: '10',
//       },
//       selected: legendData.reduce((obj, item) => {
//           obj[item] = true; // Initially, all items are selected
//           return obj;
//       }, {}),
//       selector: selectorOptions,
//       ...legendPositionAndOrientation 
//     },
//     graphic: watermark,
//     grid: gridOptions,
//     xAxis: { type: 'category', data: dates.map(date => formattedDateMap.get(date)), axisPointer: { type: 'shadow' } },
//     yAxis: { name: yTitle, type: 'value' },
//     series: series,
//   };

//   chart.setOption(option, true);
// }

function line_chart(chart, config, data) {

  // const xName = config.xName === undefined ? (config.freq == 'daily' ? 'date' : 'monthly') : config.xName;
  const xName = config.xName;
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 60 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';


  // console.log("json:", config.jsonPath); 
  // console.log("xName", xName); 
  // console.log("data", data);

  const colorScheme = get_chain_colors();

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      // Include 'other' only if the actual number of chains exceeds topN
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      // If not exceeding topN, just keep the top chains without 'other'
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    areaStyle: {},
    emphasis: {
      focus: 'series'
    },
    data: seriesData[chain],
    itemStyle: { color: colorScheme[chain] },
  }));

  let legendData = chains;//.concat(['Select All', 'Select None']);

  // Dynamically adjust the legend's position based on the number of chains
  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '80%', top: '6%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '22%', bottom: '0%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  // Adjustments for legend and grid
  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['bar', 'line', 'stack'] },
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'vertical',
      // left: '80%',
      // top: '8%',
      padding: 3,
      selected: legendData.reduce((obj, item) => {
          // This line ensures all your series are selected by default except for "Select All" and "Select None"
          // Since "Select All" and "Select None" are not in legendData, they won't affect this
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions, // Use the dynamically set selector options here
        ...legendPositionAndOrientation // Spread the dynamically determined properties
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  // console.log("Option for Chart:", option);
  window[config.elementId].setOption(option, true);
}

export function get_chain_colors(relay) {
  if (relay === 'polkadot') {
    return {
      'other': '#CCCCCC', // Color for 'Other' category
      'polkadot': '#E6007A',
      'astar': '#56F39A', // Bright Light Green
      'aventus': '#552BBF', // Deep Purple
      'equilibrium': '#00B2FF', // Bright Blue
      'moonbeam': '#33D6FF', // Aqua Blue
      'origintrail': '#AA7BDB', // Light Purple
      'phala': '#FF7F50', // Orange
      'acala': '#2f4f4f', // darkslategray
      'ajuna': '#556b2f', // darkolivegreen
      'bifrost': '#8b4513', // saddlebrown
      'bitgreen': '#6b8e23', // olivedrab
      'centrifuge': '#2e8b57', // seagreen
      'clover': '#228b22', // forestgreen
      'collectives': '#8b0000', // darkred
      'composable': '#483d8b', // darkslateblue
      'crust': '#008080', // teal
      'darwinia': '#b8860b', // darkgoldenrod
      'efinity': '#4682b4', // steelblue
      'frequency': '#d2691e', // chocolate
      'hydradx': '#9acd32', // yellowgreen
      'integritee': '#4b0082', // indigo
      'interlay': '#32cd32', // limegreen
      'kapex': '#8fbc8f', // darkseagreen
      'kilt-spiritnet': '#8b008b', // darkmagenta
      'kylin': '#b03060', // maroon3
      'litentry': '#48d1cc', // mediumturquoise
      'manta': '#9932cc', // darkorchid
      'nodle': '#ff0000', // red
      'parallel': '#ff8c00', // darkorange
      'pendulum': '#ffd700', // gold
      'polkadex': '#ffff00', // yellow
      'polkadot-hashed': '#c71585', // mediumvioletred
      'polkadot-t3rn': '#0000cd', // mediumblue
      'statemint': '#00ff00', // lime
      'unique': '#dda0dd', // plum
      'zeitgeist': '#dc143c' // crimson
      // Add more chains and their colors here
    }
  } else {
    return {
      'other': '#CCCCCC', // Color for 'Other' category
      'kusama': '#ff0000', // red
      'altair': '#ffff00', // yellow
      'amplitude': '#00bfff', // deepskyblue
      'bajun': '#b03060', // maroon3
      'basilisk': '#800080', // purple
      'bridgehub': '#f4a460', // sandybrown
      'calamari': '#f0e68c', // khaki
      'crab': '#8fbc8f', // darkseagreen
      'dorafactory': '#daa520', // goldenrod
      'encointer': '#9acd32', // yellowgreen
      'ipci': '#d2691e', // chocolate
      'kabocha': '#000080', // navy
      'karura': '#9370db', // mediumpurple
      'khala': '#0000ff', // blue
      'kintsugi': '#fa8072', // salmon
      'mangata': '#6495ed', // cornflower
      'moonriver': '#a020f0', // purple3
      'parallel-heiko': '#ff6347', // tomato
      'picasso': '#adff2f', // greenyellow
      'pichiu': '#4682b4', // steelblue
      'quartz': '#008b8b', // darkcyan
      'robonomics': '#008000', // green
      'shiden': '#483d8b', // darkslateblue
      'sora': '#7f0000', // maroon2
      'statemine': '#556b2f', // darkolivegreen
      'tinker': '#d3d3d3', // lightgray
      'turing': '#696969' // dimgray
      // Add more chains and their colors here
    }
  }
}

export function createEchartElement(elementId){
  const containerName = 'container_' + elementId;
  window[containerName] = document.getElementById(elementId);

  // Dynamically create chart variables and initialize ECharts instances
  if (window[containerName]) {
      window['general_' + elementId] = echarts.init(window[containerName]);
  }
};

export function loadCSVData(csvPath) {
  return new Promise((resolve, reject) => {
    fetch(csvPath)
      .then(response => response.text())
      .then(data => {
      // Debug: Log raw CSV data
      // consollog('Raw CSV Data:', data);

      const rows = data.split('\n').filter(row => row.trim() !== '');
      if (rows.length === 0) {
        // consolerror('CSV is empty:', csvPath);
        return; // Exit if CSV is empty
      }

      const headers = rows[0].split(',');
      // Basic check for CSV structure (optional)
      // if (headers.length < someExpectedNumberOfColumns) {
      //   consolerror('CSV format error: Expected more columns', csvPath);
      //   return; // Exit if CSV format is not as expected
      // }

      const csvArray = rows.slice(1).map(row => {
        const values = row.split(',');
        const entry = headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
        return entry;
      });

      // Debug: Log processed CSV data
      // consollog('Processed CSV Data:', csvArray);

      // Invoke the callback with the processed data
      resolve(csvArray); // Resolve with the processed CSV array
      })
      .catch(error => {
        console.error('Error loading CSV data:', error);
        reject(error); // Reject the Promise on error
      });
  });
}



// chartsConfig.forEach(config => {
//   createEchartElement(config.elementId);

//   loadCSVData(config.csvPath, (data) => {
//       config.build(config, data);
//   });
// });