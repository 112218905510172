import React from 'react';
import './css/main.css'; // Ensure this CSS file contains the appropriate styles
import Footer from './components/Footer'; // Assuming this is a properly implemented component

const styles = {
  container: {
    padding: '40px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  header: {
    textAlign: 'left',
    marginBottom: '50px'
  },
  mainContent: {
    paddingTop: '20px'
  },
  section: {
    marginBottom: '40px'
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '15px'
  },
  sectionSubtitle: {
    fontSize: '18px',
    color: '#666',
    lineHeight: '1.6',
    marginBottom: '20px'
  },
  link: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#1890ff',
    color: '#fff',
    borderRadius: '5px',
    textDecoration: 'none'
  }
};

function PolkadotPartners() {
  return (
    <div className="container" style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.sectionTitle}>Our Partners</h1>
        <h2 style={styles.sectionSubtitle}>
          Who we're working with in the ecosystem.
        </h2>
      </header>

      <section style={styles.section}>
        <p style={styles.sectionSubtitle}>
          We are curating a list of data players in the ecosystem that you can check out using the link below. These are teams we are in constant exchange with to bring you data, metrics, and insights within the Polkadot ecosystem.
        </p>
        <div style={{ textAlign: 'left', margin: '20px 0' }}>
          <a 
            href="https://polkadotdata.notion.site/Polkadot-Data-Ecosystem-Map-lite-1b7a808b6fff434ba16ea8a9e6e8e881" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={styles.link}
          >
            To the map ↗️
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PolkadotPartners;
