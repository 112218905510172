import React from 'react';

const DotInvaders = () => {
  return (
    <iframe
      src="/dot_invaders.html"
      width="100%"
      height="600"
      style={{ border: 'none' }}
    ></iframe>
  );
};

export default DotInvaders;
