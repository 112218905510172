// HeroSection.js
import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh;
  // background-color: #001529; /* Example background color */
  background-color: #f5f5f5; /* Example background color */
  color: #ffffff; /* Example text color */
  text-align: center;
`;

const HeroSection = ({ children }) => {
  return (
    <HeroContainer>
      {children}
    </HeroContainer>
  );
};

export default HeroSection;
