// import React, { useRef, useState, useEffect } from 'react';
// import useResizeObserver from './useResizeObserver';
// import { Responsive, WidthProvider } from 'react-grid-layout';
// import 'react-grid-layout/css/styles.css';
// import { Layout, Menu, Button, Switch} from 'antd';
// import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
// import { chartsConfig, loadCSVData } from './js/charts';
// import * as echarts from 'echarts';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; 
// import './css/Dashboard.css';
// import logo_light from './assets/polkadot_logo_light.png';
// import logo_dark from './assets/polkadot_logo_dark.png';



// const { Header, Content, Sider } = Layout;
// const { SubMenu } = Menu;
// const ResponsiveGridLayout = WidthProvider(Responsive);
// const styles = {
//   // ... existing styles
//   controlHeader: {
//     position: 'sticky',
//     top: 0,
//     zIndex: 1000,
//     background: '#fff',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     padding: '10px',
//   },
//   // ... existing styles
//   stickyHeader: {
//     position: 'sticky',
//     top: 0,
//     zIndex: 1000,
//     background: '#fff',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     padding: '10px',
//     borderRadius: '20px', // Add this property for rounded corners
//   },
//   chartContainer: {
//     position: 'relative',
//     width: '100%',
//     height: '100%',
//     border: '1px solid #e1e1e1',
//     borderRadius: '20px', // Increase the borderRadius value
//     backgroundColor: 'rgba(255, 255, 255, 0.8)', // Use a slightly transparent background color
//     backdropFilter: 'blur(10px)', // Apply backdrop-filter for blur effect
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//   },
//   chartTitle: {
//     padding: '10px 15px',
//     // borderBottom: '1px solid #e1e1e1',
//     backgroundColor: '',
//     // borderTopLeftRadius: '9px',
//     // borderTopRightRadius: '9px',
//     fontWeight: 'bold',
//     zIndex: 2001,
//     opacity: 1,
//   },
//   dragArrow: {
//     position: 'absolute',
//     bottom: '5px',
//     right: '5px',
//     width: '20px',
//     height: '20px',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)', // Match your container's background color
//     borderRadius: '50%', // Create a circular shape for the arrow
//     cursor: 'pointer',
//     opacity: 0, // Initially hidden
//     transition: 'opacity 0.3s ease-in-out',
//   },
//   chartContainerHovered: {
//     opacity: 1, // Show the arrow on hover
//   },
//   removeButton: {
//     position: 'absolute',
//     top: '10px',
//     right: '10px',
//     background: 'white',
//     color: 'black',
//     border: 'none',
//     borderRadius: '50%',
//     width: '25px',
//     height: '25px',
//     cursor: 'pointer',
//     fontSize: '16px',
//     fontWeight: 'bold',
//     lineHeight: '25px',
//     textAlign: 'center',
//     zIndex: 2000,
//   },
//   chartDragHandle: {
//     position: 'absolute',
//     bottom: '0%', // Position it at the middle vertically
//     right: '15px', // Position at the right of the chart container
//     transform: 'translateY(-50%)', // Center it vertically
//     width: '20px',
//     height: '20px',
//     // backgroundColor: 'blue', // Visible color
//     cursor: 'move', // Cursor indicates it's draggable
//     zIndex: 1000,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     borderRadius: '50%',
//     color: 'white', // Color of the icon or text
//     fontSize: '14px', // Size of the icon or text
//     // Include a drag icon or text here
//     content: '"⇲"', // Unicode character as an example
//     opactiy: 0,
//     transition: 'opacity 0.3s ease-in-out',
//   },
  
  
  
// };

// //get folders for dropdown
// const getUniqueFolders = (chartsConfig) => {
//   const folders = new Set();
//   chartsConfig.forEach((chart) => {
//     folders.add(chart.folder);
//   });
//   return Array.from(folders);
// };

// const uniqueFolders = getUniqueFolders(chartsConfig);

// const sortedCharts = uniqueFolders.map((folder) => ({
//   folder,
//   charts: chartsConfig.filter((chart) => chart.folder === folder),
// }));

// const Dashboard = () => {
//   //consts here
//   const [collapsed, setCollapsed] = useState(false);
//   const [isEditMode, setIsEditMode] = useState(false); // new state for edit mode
//   const [selectedCharts, setSelectedCharts] = useState([]);
//   const [layout, setLayout] = useState([]);
//   const chartRefs = useRef({});
//   const { width, height } = useResizeObserver(chartRefs);
//   const [textBoxes, setTextBoxes] = useState([]);
//   const [darkMode, setDarkMode] = useState(false); // New state for theme mode
//   const [editedCharts, setEditedCharts] = useState([]);
  


//   //button and hovering style

//   const selectedItemStyle = {
//     fontWeight: 'bold', // Style to make text bold
//     background: '#001529', // Keep the existing background style
//     color: 'white', // Adjust text color if needed
//   };
//   //for preventing click overlap on text toolbar
//   const updatedStyles = {
//     ...styles,
//     dragHandle: {
//       ...styles.dragHandle,
//       bottom: '0px', // Place the handle at the bottom left corner
//       left: '0px',
//       backgroundColor: '#cccccc', // Example color for visibility
//       // Add more styles if needed
//     },
//   };
  
//   //toggle theme
//   const toggleTheme = () => {
//     setDarkMode(!darkMode);
//   };

//   const getThemeStyles = () => {
//     return {
//       layoutBackground: darkMode ? '#001529' : '#f0f2f5',
//       headerBackground: darkMode ? '#001529' : '#f0f2f5',
//       headerTextColor: darkMode ? 'white' : '#001529',
//       siderBackground: darkMode ? '#001529' : '#fff',
//       menuTheme: darkMode ? 'dark' : 'light',
//       sidebarStickBarBackground: darkMode ? '#001529' : '#fff',
//       sidebarStickBarTextColor: darkMode ? 'white' : '#000',
//       sidebarStickBarBorderRadius: darkMode ? '20px' : '20px', // Adjust the border radius as needed
//       chartDropdownBackground: darkMode ? '#001529' : '#fff',
//       chartDropdownTextColor: darkMode ? 'white' : '#000',
//       chartDropdownHoverBackground: darkMode ? '#002140' : '#e1e1e1',
//       buttonBackground: darkMode ? '#30475e' : '#f0f2f5', // Darker shade for dark mode, lighter for light mode
//       buttonTextColor: darkMode ? '#e6e6e6' : '#1a1a2e', // Light text for dark mode, dark text for light mode
//       buttonBorderColor: darkMode ? '#16213e' : '#d6e4ff', // Subtle border color difference in dark mode, and a light border in light mode
//       buttonHoverBackground: darkMode ? '#0f3460' : '#1890ff', // Different hover color for better visibility
//       buttonHoverTextColor: '#ffffff', // White text color on hover for both themes
//       buttonHoverBorderColor: darkMode ? '#1b1b2f' : '#40a9ff', // Slightly darker border on hover

//       // Additional styles for dynamic theming
//       contentBackground: darkMode ? '#023e8a' : '#fff',
//       contentTextColor: darkMode ? '#caf0f8' : '#000',
//       controlHeaderBackground: darkMode ? '#03045e' : '#fff',
//       controlHeaderTextColor: darkMode ? '#90e0ef' : '#000',
//     };
//   };

//   const themeStyles = getThemeStyles();
  
    
//   //toggle capture
//   const toggleEditMode = () => {
//       setIsEditMode(!isEditMode);
//     };

//   const toggleCollapsed = () => {
//       setCollapsed(!collapsed);
//     };



//   //render menu & items
//     const menuItemStyle = (isSelected) => ({
//       ...selectedItemStyle,
//       background: isSelected ? themeStyles.chartDropdownHoverBackground : themeStyles.chartDropdownBackground,
//       color: isSelected ? themeStyles.chartDropdownTextColor : themeStyles.chartDropdownTextColor,
//       '&:hover': {
//         backgroundColor: '#91d5ff', // Example hover background color
//         color: '#fff', // Example hover text color
//       }
//     });

//     const renderMenuItems = () => (
//       <SubMenu key="sub1" title="Charts" style={{ background: themeStyles.chartDropdownBackground, color: themeStyles.chartDropdownTextColor }}>
//         {sortedCharts.map((folderItem) => (
//           <SubMenu key={folderItem.folder} title={folderItem.folder}>
//             {folderItem.charts.map((chart) => {
//               const isSelected = selectedCharts.includes(chart.elementId);
//               return (
//                 <Menu.Item
//                   key={chart.elementId}
//                   style={menuItemStyle(selectedCharts.includes(chart.elementId))}
//                   onClick={() => handleChartSelect(chart.elementId)}
//                 >
//                   {chart.elementId}
//                 </Menu.Item>
//               );
//             })}
//           </SubMenu>
//         ))}
//       </SubMenu>
//     );

//     const renderControlButtons = () => (
//       <div style={{ ...styles.controlHeader, background: themeStyles.sidebarStickBarBackground, color: themeStyles.sidebarStickBarTextColor, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
//         <Button 
//           type="primary" 
//           onClick={() => setSelectedCharts([])}
//           style={{ 
//             backgroundColor: themeStyles.buttonBackground, 
//             color: themeStyles.buttonTextColor, 
//             borderColor: themeStyles.buttonBorderColor, 
//             marginLeft: '10px',
//             '&:hover': {
//               backgroundColor: themeStyles.buttonHoverBackground,
//               borderColor: themeStyles.buttonHoverBorderColor,
//             },
//           }}
//         >
//           Reset
//         </Button>
//         <Button 
//           style={{ 
//             marginLeft: '10px', 
//             backgroundColor: themeStyles.buttonBackground, 
//             color: themeStyles.buttonTextColor, 
//             borderColor: themeStyles.buttonBorderColor, 
//             '&:hover': {
//               backgroundColor: themeStyles.buttonHoverBackground,
//               borderColor: themeStyles.buttonHoverBorderColor,
//             },
//           }} 
//           onClick={animateCharts}
//         >
//           Animate
//         </Button>
//         <Button 
//           style={{ 
//             marginLeft: '10px', 
//             backgroundColor: isEditMode ? '#1890ff' : themeStyles.buttonBackground, 
//             color: themeStyles.buttonTextColor, 
//             borderColor: themeStyles.buttonBorderColor,
//             '&:hover': {
//               backgroundColor: themeStyles.buttonHoverBackground,
//               borderColor: themeStyles.buttonHoverBorderColor,
//             },
//           }} 
//           onClick={toggleEditMode}
//         >
//           {isEditMode ? 'Finish Editing' : 'Edit'}
//         </Button>
//         {/* Conditionally render the "Add Text Box" button based on isEditMode */}
//         {isEditMode && (
//           <Button 
//             style={{ 
//               marginLeft: '10px', 
//               backgroundColor: themeStyles.buttonBackground, 
//               color: themeStyles.buttonTextColor, 
//               borderColor: themeStyles.buttonBorderColor,
//               '&:hover': {
//                 backgroundColor: themeStyles.buttonHoverBackground,
//                 borderColor: themeStyles.buttonHoverBorderColor,
//               },
//             }} 
//             onClick={addTextBox}
//             disabled={!isEditMode} // Disable if not in edit mode
//           >
//             Add Text Box
//           </Button>
//         )}
//       </div>
//     );
    



//   //text box functions
//     const renderTextBox = (box) => {
//       const gridConfig = layout.find((l) => l.i === box.id);

//       const handleContentChange = (content) => {
//         setTextBoxes(textBoxes.map((tb) => (tb.id === box.id ? { ...tb, content } : tb)));
//       };

//       return (
//         <div
//           key={box.id}
//           className={`text-box-container ${isEditMode ? 'text-box-edit-mode' : ''}`}
//           data-grid={gridConfig}
//           style={{
//             border: '1px solid #e1e1e1',
//             borderRadius: '20px',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)',
//             backdropFilter: 'blur(10px)',
//             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//             overflow: 'hidden',
//             position: 'relative',
//           }}
//         >
//           {isEditMode ? (
//             <>
//               {/* Drag handle for the text box */}
//               <div className="chart-drag-handle" style={styles.chartDragHandle}></div>

//               <ReactQuill
//                 value={box.content}
//                 onChange={handleContentChange}
//                 theme={isEditMode ? "snow" : "false"}
//                 modules={{
//                   toolbar: [
//                     ['bold', 'italic', 'underline', 'strike'],
//                     ['blockquote', 'code-block'],
//                     [{ header: 1 }, { header: 2 }],
//                     [{ list: 'ordered' }, { list: 'bullet' }],
//                     [{ script: 'sub' }, { script: 'super' }],
//                     [{ indent: '-1' }, { indent: '+1' }],
//                     [{ direction: 'rtl' }],
//                     [{ size: ['small', false, 'large', 'huge'] }],
//                     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//                     [{ color: [] }, { background: [] }],
//                     [{ font: [] }],
//                     [{ align: [] }],
//                     ['clean'],
//                   ],
//                 }}
//               />

//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   removeTextBox(box.id);
//                 }}
//                 style={styles.removeButton}
//               >
//                 X
//               </button>
//             </>
//           ) : (
//             <div
//               className="text-box-display"
//               style={{ padding: '10px', whiteSpace: 'pre-wrap' }}
//               dangerouslySetInnerHTML={{ __html: box.content }}
//             />
//           )}
//         </div>
//       );
//     };
    
//     const addTextBox = () => {
//       const newTextBoxId = `text-box-${Date.now()}`;
//       const newTextBoxLayout = {
//         i: newTextBoxId,
//         x: (layout.length * 2) % 12,
//         y: Infinity, // Puts it at the bottom
//         w: 4,
//         h: 4,
//         minW: 2,
//         minH: 2
//       };
//       setLayout([...layout, newTextBoxLayout]);
//       setTextBoxes([...textBoxes, { id: newTextBoxId, content: 'New Text Box' }]);
//     };

//     const removeTextBox = (boxId) => {
//       const updatedTextBoxes = textBoxes.filter(box => box.id !== boxId);
//       const updatedLayout = layout.filter(l => l.i !== boxId);
//       setTextBoxes(updatedTextBoxes);
//       setLayout(updatedLayout);
//     };
    
//   //use effects
//     useEffect(() => {
//       selectedCharts.forEach(chartId => {
//         if (!chartRefs.current[chartId]) {
//           chartRefs.current[chartId] = React.createRef();
//         }
//         initializeAndLoadChart(chartId);
//       });
//     }, [selectedCharts]);

//     useEffect(() => {
//       const updatedLayout = [...layout]; // Start with the current layout
//       selectedCharts.forEach(chartId => {
//         if (!updatedLayout.find(l => l.i === chartId)) {
//           // Add new layout only if it doesn't exist
//           updatedLayout.push({
//             i: chartId,
//             x: 0, // Modify this logic as needed
//             y: Infinity, // Puts it at the bottom
//             w: 5,
//             h: 8,
//             minW: 2,
//             minH: 3
//           });
//         }
//       });
//       setLayout(updatedLayout);
//     }, [selectedCharts]);

//   //on load
//     const animateCharts = () => {
//       selectedCharts.forEach(chartId => {
//         if (chartRefs.current[chartId] && chartRefs.current[chartId].current) {
//           initializeAndLoadChart(chartId, true); // force reanimation
//         }
//       });
//     };

//     const initializeAndLoadChart = async (chartId) => {
//       // Wait for the next event loop tick to ensure the refs are attached
//       setTimeout(async () => {
//         const chartContainer = chartRefs.current[chartId]?.current;
//         if (!chartContainer) {
//           console.error(`Ref is not attached to DOM element for chartId: ${chartId}`);
//           return;
//         }

//         if (echarts.getInstanceByDom(chartContainer)) {
//           echarts.dispose(chartContainer);
//         }

//         const chartConfig = chartsConfig.find(config => config.elementId === chartId);
//         if (!chartConfig) {
//           console.error(`Chart config not found for chartId: ${chartId}`);
//           return;
//         }

//         let chart = echarts.init(chartContainer);
//         try {
//           const data = await loadCSVData(chartConfig.csvPath);
//           chartConfig.build(chart, data);
          
//         } catch (error) {
//           console.error(`Error loading CSV data for chartId: ${chartId}`, error);
//         }

//         const resizeObserver = new ResizeObserver(() => {
//           chart.resize();
//         });
//         resizeObserver.observe(chartContainer);
//       }, 0);
//     };

//     const handleChartSelect = (chartId) => {
//       if (isEditMode) {
//         const updatedEditedCharts = editedCharts.includes(chartId)
//           ? editedCharts.filter((id) => id !== chartId)
//           : [...editedCharts, chartId];
//         setEditedCharts(updatedEditedCharts);
//       } else {
//         const updatedSelectedCharts = selectedCharts.includes(chartId)
//           ? selectedCharts.filter((id) => id !== chartId)
//           : [...selectedCharts, chartId];
//         setSelectedCharts(updatedSelectedCharts);
//       }
//     };

//     const renderSelectedCharts = () => (
//       <ResponsiveGridLayout
//       className="layout"
//       style={{ height: '100%' }}
//       isDraggable={isEditMode} // Enable dragging only in edit mode
//       isResizable={isEditMode}
//       draggableHandle=".chart-drag-handle" // Specify the drag handle selector
//       cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
//       rowHeight={height / 10}
//       breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
//       layout={layout}
//       onLayoutChange={(newLayout) => setLayout(newLayout)}
//       >
//       {selectedCharts.map((chartId) => (
//         <div 
//           key={chartId} 
//           data-grid={layout.find((l) => l.i === chartId)}
//           style={{ position: 'relative' }} // Wrapper div with relative positioning
//         >
          
//           <div
//             style={selectedCharts.includes(chartId) ? { ...styles.chartContainer, ...styles.chartContainerHovered } : styles.chartContainer}
//             id={`chart_container_${chartId}`}
//             ref={chartRefs.current[chartId]}
//             title={chartId} // Add the title attribute with the chartId
//           >
            
//             {<div style={styles.chartTitle}>{chartId}</div>}
//           </div>
//           {/* Drag handle outside the ECharts container */}
//           <div className="chart-drag-handle" style={styles.chartDragHandle}></div> 
//         </div>
//       ))}
//       {textBoxes.map(renderTextBox)}
//     </ResponsiveGridLayout>
//     );

//   return (
//     <Layout style={{ minHeight: '100vh', backgroundColor: themeStyles.layoutBackground }}>
//       <Header style={{ background: themeStyles.headerBackground, padding: 10, color: themeStyles.headerTextColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           {/* Conditional rendering for the logo based on theme */}
//           <img 
//             src={darkMode ? logo_light : logo_dark} 
//             alt="logo" 
//             style={{ width: '150px', marginRight: '10px', padding: '10px' }} 
//           />
//           <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#001529' }}>
//             {/* Place left-aligned dashboard text here */}
//           </div>
//         </div>
//         <div style={{ textAlign: 'right' }}>
//           {/* Add any content you want to align to the right here */}
//           <Switch
//             checkedChildren="Dark"
//             unCheckedChildren="Light"
//             onChange={toggleTheme}
//             style={{ marginRight: 10 }}
//           />
//           Dashboard Navbar
//         </div>
//       </Header>
//       <Layout>
//       <Sider 
//           width={'400px'} 
//           theme={themeStyles.menuTheme} 
//           collapsible 
//           collapsed={collapsed} 
//           onCollapse={toggleCollapsed} 
//           style={{ 
//             background: themeStyles.sidebarStickBarBackground, 
//             color: themeStyles.sidebarStickBarTextColor,
//             borderRadius: themeStyles.sidebarStickBarBorderRadius,
//           }}
//         >        
//           <Menu mode="inline" theme={themeStyles.menuTheme} selectedKeys={selectedCharts} style={{ background: themeStyles.sidebarStickBarBackground }}>
//             <SubMenu key="chartsSubMenu" title="EOY Report" style={{ color: themeStyles.sidebarStickBarTextColor }}>
//               {renderMenuItems()}
//             </SubMenu>
//           </Menu>
//         </Sider>
//         <Layout className="site-layout">
//           {renderControlButtons()}
//           <Content style={{ margin: '16px' }}>
//             <div ref={chartRefs} style={{ padding: 24, minHeight: 360 }}>
//               {renderSelectedCharts()}
//               {/* {textBoxes.map(renderTextBox)} */}
              
//             </div>
//           </Content>
//         </Layout>
//       </Layout>
//     </Layout>
//     );
// }

// export default Dashboard;




import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useResizeObserver from './useResizeObserver';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { Layout, Menu, Button, Switch, Modal, Input, Select} from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { chartsConfig, loadCSVData } from './js/eoy_charts';
import * as echarts from 'echarts';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import './css/main.css';
import logo_light from './assets/polkadot_logo_light.png';
import logo_dark from './assets/polkadot_logo_dark.png';
import watermarkImage from './assets/dotlake-transparent-high-quality.png'; 



const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const ResponsiveGridLayout = WidthProvider(Responsive);
const styles = {
  // ... existing styles
  controlHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    background: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
  },
  // ... existing styles
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    background: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    borderRadius: '20px', // Add this property for rounded corners
  },
  chartContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: '1px solid #e1e1e1',
    borderRadius: '20px', // Increase the borderRadius value
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Use a slightly transparent background color
    backdropFilter: 'blur(10px)', // Apply backdrop-filter for blur effect
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  chartTitle: {
    padding: '10px 15px',
    // borderBottom: '1px solid #e1e1e1',
    backgroundColor: '',
    // borderTopLeftRadius: '9px',
    // borderTopRightRadius: '9px',
    fontWeight: 'bold',
    zIndex: 2001,
    opacity: 1,
  },
  dragArrow: {
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    width: '20px',
    height: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Match your container's background color
    borderRadius: '50%', // Create a circular shape for the arrow
    cursor: 'pointer',
    opacity: 0, // Initially hidden
    transition: 'opacity 0.3s ease-in-out',
  },
  chartContainerHovered: {
    opacity: 1, // Show the arrow on hover
  },
  removeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'center',
    zIndex: 2000,
  },
  chartDragHandle: {
    position: 'absolute',
    bottom: '0%', // Position it at the middle vertically
    right: '15px', // Position at the right of the chart container
    transform: 'translateY(-50%)', // Center it vertically
    width: '20px',
    height: '20px',
    // backgroundColor: 'blue', // Visible color
    cursor: 'move', // Cursor indicates it's draggable
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: 'white', // Color of the icon or text
    fontSize: '14px', // Size of the icon or text
    // Include a drag icon or text here
    content: '"⇲"', // Unicode character as an example
    opactiy: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  
  
  
};

//get folders for dropdown
const getUniqueFolders = (chartsConfig) => {
  const folders = new Set();
  chartsConfig.forEach((chart) => {
    folders.add(chart.folder);
  });
  return Array.from(folders);
};

const uniqueFolders = getUniqueFolders(chartsConfig);

const sortedCharts = uniqueFolders.map((folder) => ({
  folder,
  charts: chartsConfig.filter((chart) => chart.folder === folder),
}));

const Dashboard = () => {
  //consts here
  const [collapsed, setCollapsed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // new state for edit mode
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [layout, setLayout] = useState([]);
  const chartRefs = useRef({});
  const { width, height } = useResizeObserver(chartRefs);
  const [textBoxes, setTextBoxes] = useState([]);
  const [darkMode, setDarkMode] = useState(false); // New state for theme mode
  const [editedCharts, setEditedCharts] = useState([]);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const { Option } = Select;

  //button and hovering style

  const selectedItemStyle = {
    fontWeight: 'bold', // Style to make text bold
    background: '#001529', // Keep the existing background style
    color: 'white', // Adjust text color if needed
  };
  //for preventing click overlap on text toolbar
  const updatedStyles = {
    ...styles,
    dragHandle: {
      ...styles.dragHandle,
      bottom: '0px', // Place the handle at the bottom left corner
      left: '0px',
      backgroundColor: '#cccccc', // Example color for visibility
      // Add more styles if needed
    },
  };
  
  //toggle theme
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const getThemeStyles = () => {
    return {
      layoutBackground: darkMode ? '#001529' : '#f0f2f5',
      headerBackground: darkMode ? '#001529' : '#f0f2f5',
      headerTextColor: darkMode ? 'white' : '#001529',
      siderBackground: darkMode ? '#001529' : '#fff',
      menuTheme: darkMode ? 'dark' : 'light',
      sidebarStickBarBackground: darkMode ? '#001529' : '#fff',
      sidebarStickBarTextColor: darkMode ? 'white' : '#000',
      sidebarStickBarBorderRadius: darkMode ? '20px' : '20px', // Adjust the border radius as needed
      chartDropdownBackground: darkMode ? '#001529' : '#fff',
      chartDropdownTextColor: darkMode ? 'white' : '#000',
      chartDropdownHoverBackground: darkMode ? '#002140' : '#e1e1e1',
      buttonBackground: darkMode ? '#30475e' : '#f0f2f5', // Darker shade for dark mode, lighter for light mode
      buttonTextColor: darkMode ? '#e6e6e6' : '#1a1a2e', // Light text for dark mode, dark text for light mode
      buttonBorderColor: darkMode ? '#16213e' : '#d6e4ff', // Subtle border color difference in dark mode, and a light border in light mode
      buttonHoverBackground: darkMode ? '#0f3460' : '#1890ff', // Different hover color for better visibility
      buttonHoverTextColor: '#ffffff', // White text color on hover for both themes
      buttonHoverBorderColor: darkMode ? '#1b1b2f' : '#40a9ff', // Slightly darker border on hover

      // Additional styles for dynamic theming
      contentBackground: darkMode ? '#023e8a' : '#fff',
      contentTextColor: darkMode ? '#caf0f8' : '#000',
      controlHeaderBackground: darkMode ? '#03045e' : '#fff',
      controlHeaderTextColor: darkMode ? '#90e0ef' : '#000',
    };
  };

  const themeStyles = getThemeStyles();
  
    
  //toggle capture
  const toggleEditMode = () => {
      setIsEditMode(!isEditMode);
    };

  const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };



  //render menu & items
    const menuItemStyle = (isSelected) => ({
      ...selectedItemStyle,
      background: isSelected ? themeStyles.chartDropdownHoverBackground : themeStyles.chartDropdownBackground,
      color: isSelected ? themeStyles.chartDropdownTextColor : themeStyles.chartDropdownTextColor,
      '&:hover': {
        backgroundColor: '#91d5ff', // Example hover background color
        color: '#fff', // Example hover text color
      }
    });

    const renderMenuItems = () => (
      <SubMenu key="sub1" title="Charts" style={{ background: themeStyles.chartDropdownBackground, color: themeStyles.chartDropdownTextColor }}>
        {sortedCharts.map((folderItem) => (
          folderItem.folder ? (
            <SubMenu key={folderItem.folder} title={folderItem.folder}>
              {folderItem.charts.map((chart) => {
                const isSelected = selectedCharts.includes(chart.elementId);
                return (
                  <Menu.Item
                    key={chart.elementId}
                    style={menuItemStyle(selectedCharts.includes(chart.elementId))}
                    onClick={() => handleChartSelect(chart.elementId)}
                  >
                    {chart.elementId}
                  </Menu.Item>
                );
              })}
            </SubMenu>
          ) : (
            folderItem.charts.map((chart) => {
              const isSelected = selectedCharts.includes(chart.elementId);
              return (
                <Menu.Item
                  key={chart.elementId}
                  style={menuItemStyle(selectedCharts.includes(chart.elementId))}
                  onClick={() => handleChartSelect(chart.elementId)}
                >
                  {chart.elementId}
                </Menu.Item>
              );
            })
          )
        ))}
      </SubMenu>
    );

    const renderControlButtons = () => (
      <div style={{ ...styles.controlHeader, background: themeStyles.sidebarStickBarBackground, color: themeStyles.sidebarStickBarTextColor, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button 
          type="primary" 
          onClick={() => setSelectedCharts([])}
          style={{ 
            backgroundColor: themeStyles.buttonBackground, 
            color: themeStyles.buttonTextColor, 
            borderColor: themeStyles.buttonBorderColor, 
            marginLeft: '10px',
            '&:hover': {
              backgroundColor: themeStyles.buttonHoverBackground,
              borderColor: themeStyles.buttonHoverBorderColor,
            },
          }}
        >
          Reset
        </Button>
        <Button 
          style={{ 
            marginLeft: '10px', 
            backgroundColor: themeStyles.buttonBackground, 
            color: themeStyles.buttonTextColor, 
            borderColor: themeStyles.buttonBorderColor, 
            '&:hover': {
              backgroundColor: themeStyles.buttonHoverBackground,
              borderColor: themeStyles.buttonHoverBorderColor,
            },
          }} 
          onClick={animateCharts}
        >
          Animate
        </Button>
        <Button 
          style={{ 
            marginLeft: '10px', 
            backgroundColor: isEditMode ? '#1890ff' : themeStyles.buttonBackground, 
            color: themeStyles.buttonTextColor, 
            borderColor: themeStyles.buttonBorderColor,
            '&:hover': {
              backgroundColor: themeStyles.buttonHoverBackground,
              borderColor: themeStyles.buttonHoverBorderColor,
            },
          }} 
          onClick={toggleEditMode}
        >
          {isEditMode ? 'Finish Editing' : 'Edit'}
        </Button>
        {/* Conditionally render the "Add Text Box" button based on isEditMode */}
        {isEditMode && (
          <Button 
            style={{ 
              marginLeft: '10px', 
              backgroundColor: themeStyles.buttonBackground, 
              color: themeStyles.buttonTextColor, 
              borderColor: themeStyles.buttonBorderColor,
              '&:hover': {
                backgroundColor: themeStyles.buttonHoverBackground,
                borderColor: themeStyles.buttonHoverBorderColor,
              },
            }} 
            onClick={addTextBox}
            disabled={!isEditMode} // Disable if not in edit mode
          >
            Add Text Box
          </Button>
          
        )}
         <Button style={{ 
              marginLeft: '10px'}} onClick={saveLayout}>Save Layout</Button>
         {/* <Button style={{ 
              marginLeft: '10px'}} onClick={loadLayout}>Load Layout</Button> */}
      </div>
    );
    



  //text box functions
    const renderTextBox = (box) => {
      const gridConfig = layout.find((l) => l.i === box.id);

      const handleContentChange = (content) => {
        setTextBoxes(textBoxes.map((tb) => (tb.id === box.id ? { ...tb, content } : tb)));
      };

      return (
        <div
          key={box.id}
          className={`text-box-container ${isEditMode ? 'text-box-edit-mode' : ''}`}
          data-grid={gridConfig}
          style={{
            border: '1px solid #e1e1e1',
            borderRadius: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {isEditMode ? (
            <>
              {/* Drag handle for the text box */}
              <div className="chart-drag-handle" style={styles.chartDragHandle}></div>

              <ReactQuill
                value={box.content}
                onChange={handleContentChange}
                theme={isEditMode ? "snow" : "false"}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ direction: 'rtl' }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ['clean'],
                  ],
                }}
              />

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeTextBox(box.id);
                }}
                style={styles.removeButton}
              >
                X
              </button>
            </>
          ) : (
            <div
              className="text-box-display"
              style={{ padding: '10px', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: box.content }}
            />
          )}
        </div>
      );
    };
    
    const addTextBox = () => {
      const newTextBoxId = `text-box-${Date.now()}`;
      const newTextBoxLayout = {
        i: newTextBoxId,
        x: (layout.length * 2) % 12,
        y: Infinity, // Puts it at the bottom
        w: 4,
        h: 4,
        minW: 2,
        minH: 2
      };
      setLayout([...layout, newTextBoxLayout]);
      setTextBoxes([...textBoxes, { id: newTextBoxId, content: 'New Text Box' }]);
    };

    const removeTextBox = (boxId) => {
      const updatedTextBoxes = textBoxes.filter(box => box.id !== boxId);
      const updatedLayout = layout.filter(l => l.i !== boxId);
      setTextBoxes(updatedTextBoxes);
      setLayout(updatedLayout);
    };
    
  //use effects
    useEffect(() => {
      selectedCharts.forEach(chartId => {
        if (!chartRefs.current[chartId]) {
          chartRefs.current[chartId] = React.createRef();
        }
        initializeAndLoadChart(chartId);
      });
    }, [selectedCharts]);

    useEffect(() => {
      const updatedLayout = [...layout]; // Start with the current layout
      selectedCharts.forEach(chartId => {
        if (!updatedLayout.find(l => l.i === chartId)) {
          // Add new layout only if it doesn't exist
          updatedLayout.push({
            i: chartId,
            x: 0, // Modify this logic as needed
            y: Infinity, // Puts it at the bottom
            w: 5,
            h: 8,
            minW: 2,
            minH: 3
          });
        }
      });
      setLayout(updatedLayout);
    }, [selectedCharts]);

    useEffect(() => {
      const savedLayout = loadLayoutFromStorage(); // Implement this function
      if (savedLayout) {
        setLayout(savedLayout);
      }
    }, []);

    const getSavedLayoutNames = () => {
      const savedLayoutsJSON = localStorage.getItem('dashboardLayouts');
      if (savedLayoutsJSON) {
        const savedLayouts = JSON.parse(savedLayoutsJSON);
        return Object.keys(savedLayouts); // Return the names of the saved layouts
      }
      return [];
    };

    const saveLayout = () => {
      showSaveModal();
    };
    
    const saveLayoutToStorage = (layout, layoutName) => {
      try {
        const layoutJSON = JSON.stringify(layout);
        let savedLayouts = JSON.parse(localStorage.getItem('dashboardLayouts')) || {};
        savedLayouts[layoutName] = { layout: layoutJSON, textBoxes: textBoxes };
        localStorage.setItem('dashboardLayouts', JSON.stringify(savedLayouts));
      } catch (error) {
        console.error("Error saving layout:", error);
      }
    };
    
    
    const loadLayoutFromStorage = (layoutName) => {
      const savedLayoutsJSON = localStorage.getItem('dashboardLayouts');
      if (savedLayoutsJSON) {
        const savedLayouts = JSON.parse(savedLayoutsJSON);
        if (savedLayouts[layoutName]) {
          console.log("Loaded layout:", savedLayouts[layoutName]); // Debugging line
          const savedLayout = JSON.parse(savedLayouts[layoutName].layout);
          const savedTextBoxes = savedLayouts[layoutName].textBoxes || [];
          setTextBoxes(savedTextBoxes);
          return savedLayout;
        }
      }
      return null;
    };
    
   

const loadLayout = (layoutName) => {
  const savedLayout = loadLayoutFromStorage(layoutName);
  if (savedLayout) {
    setLayout(savedLayout);
    const chartIds = savedLayout.map(item => item.i);
    setSelectedCharts(chartIds);
    chartIds.forEach(chartId => {
      initializeAndLoadChart(chartId);
    });
  } else {
    alert("No saved layout found with that name.");
  }
};
    
    const showSaveModal = () => {
      setIsSaveModalVisible(true);
    };
    
    const handleSaveOk = () => {
      if (newLayoutName) {
        saveLayoutToStorage(layout, newLayoutName);
        alert("Layout saved!");
        setNewLayoutName('');
      } else {
        alert("Please provide a layout name.");
      }
      setIsSaveModalVisible(false);
    };
    
    const handleSaveCancel = () => {
      setIsSaveModalVisible(false);
    };
    
    
    
    


  //on load
    const animateCharts = () => {
      selectedCharts.forEach(chartId => {
        if (chartRefs.current[chartId] && chartRefs.current[chartId].current) {
          initializeAndLoadChart(chartId, true); // force reanimation
        }
      });
    };

    // const initializeAndLoadChart = async (chartId) => {
    //   // Wait for the next event loop tick to ensure the refs are attached
    //   setTimeout(async () => {
    //     const chartContainer = chartRefs.current[chartId]?.current;
    //     if (!chartContainer) {
    //       console.error(`Ref is not attached to DOM element for chartId: ${chartId}`);
    //       return;
    //     }

    //     if (echarts.getInstanceByDom(chartContainer)) {
    //       echarts.dispose(chartContainer);
    //     }

    //     const chartConfig = chartsConfig.find(config => config.elementId === chartId);
    //     if (!chartConfig) {
    //       console.error(`Chart config not found for chartId: ${chartId}`);
    //       return;
    //     }

    //     let chart = echarts.init(chartContainer);
    //     try {
    //       //load json or csv -
    //       const data = await loadCSVData(chartConfig.csvPath);
    //       chartConfig.build(chart, data);
          
    //     } catch (error) {
    //       console.error(`Error loading CSV data for chartId: ${chartId}`, error);
    //     }

    //     const resizeObserver = new ResizeObserver(() => {
    //       chart.resize();
    //     });
    //     resizeObserver.observe(chartContainer);
    //   }, 0);
    // };
    const initializeAndLoadChart = async (chartId, forceReanimate = false) => {
      const initializeChart = async () => {
        const chartContainer = chartRefs.current[chartId]?.current;
        if (!chartContainer) {
          console.error(`Ref is not attached to DOM element for chartId: ${chartId}`);
          return;
        }
    
        // Check if the container is visible and has dimensions
        if (chartContainer.offsetWidth === 0 || chartContainer.offsetHeight === 0) {
          console.warn(`Container for chartId: ${chartId} is not visible or not rendered yet.`);
          // Optionally, you could retry initialization after a delay
          return; // Exit if container is not visible/rendered
        }
    
        const existingChartInstance = echarts.getInstanceByDom(chartContainer);
        if (existingChartInstance && forceReanimate) {
          echarts.dispose(chartContainer); // Dispose only if forcing reanimation
        }
    
        if (!existingChartInstance || forceReanimate) {
          const chartConfig = chartsConfig.find(config => config.elementId === chartId);
          if (!chartConfig) {
            console.error(`Chart config not found for chartId: ${chartId}`);
            return;
          }
    
          let chart = echarts.init(chartContainer);
          try {
            const data = await loadCSVData(chartConfig.csvPath);
            const options = { animation: true, ...chartConfig.options }; // Ensure animation is enabled
            chartConfig.build(chart, data, options);
            
          } catch (error) {
            console.error(`Error loading CSV data for chartId: ${chartId}`, error);
          }
    
          // Attach resize observer to ensure responsiveness
          if (!chartContainer.resizeObserver) {
            const resizeObserver = new ResizeObserver(() => chart.resize());
            resizeObserver.observe(chartContainer);
            chartContainer.resizeObserver = resizeObserver; // Mark as attached
            
          }
          
        }        
      };
      
      // Delay chart initialization to ensure container is ready
      setTimeout(initializeChart, 100); // Adjust delay as necessary
      
    };
    


    const handleChartSelect = (chartId) => {
      if (isEditMode) {
        const updatedEditedCharts = editedCharts.includes(chartId)
          ? editedCharts.filter((id) => id !== chartId)
          : [...editedCharts, chartId];
        setEditedCharts(updatedEditedCharts);
      } else {
        const updatedSelectedCharts = selectedCharts.includes(chartId)
          ? selectedCharts.filter((id) => id !== chartId)
          : [...selectedCharts, chartId];
        setSelectedCharts(updatedSelectedCharts);
      }
    };

    const watermarkStyle = {
      position: 'absolute',
      top: '20%',
      left: '45%',
      transform: 'translate(-50%, -50%) scale(0.5)',
      pointerEvents: 'none',
      zIndex: 1, // Place it behind other elements
      width: '10%', // Adjust the width as per your need
      height: '10%', // Adjust the height as per your need
      opacity: 0.15, // Adjust the opacity to make it more or less subtle
    };
    

    const renderSelectedCharts = () => (
      <ResponsiveGridLayout
        className="layout"
        style={{ height: '100%' }}
        isDraggable={isEditMode} // Enable dragging only in edit mode
        isResizable={isEditMode}
        draggableHandle=".chart-drag-handle" // Specify the drag handle selector
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={height / 10}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        layout={layout}
        onLayoutChange={(newLayout) => setLayout(newLayout)}
      >
        {selectedCharts.map((chartId) => (
          <div
            key={chartId}
            data-grid={layout.find((l) => l.i === chartId)}
            style={{ position: 'relative' }} // Wrapper div with relative positioning
          >
            <div
              style={
                selectedCharts.includes(chartId)
                  ? { ...styles.chartContainer, ...styles.chartContainerHovered }
                  : styles.chartContainer
              }
              id={`chart_container_${chartId}`}
              ref={chartRefs.current[chartId]}
              title={chartId} // Add the title attribute with the chartId
            >
              
            </div>
            {/* Drag handle outside the ECharts container */}
            {/* {<div style={styles.chartTitle}>{chartId}</div>} */}
              {selectedCharts.includes(chartId) && (
                <div style={watermarkStyle}>
                  {/* <img src={watermarkImage} alt="Watermark" /> */}
                </div>
              )}
            <div className="chart-drag-handle" style={styles.chartDragHandle}></div>
          </div>
        ))}
        {textBoxes.map(renderTextBox)}
      </ResponsiveGridLayout>
    );
    

    return (
      <Layout style={{ minHeight: '100vh', backgroundColor: themeStyles.layoutBackground }}>
        <Header style={{ background: themeStyles.headerBackground, padding: 10, color: themeStyles.headerTextColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <img 
              src={darkMode ? logo_light : logo_dark} 
              alt="logo" 
              style={{ width: '150px', marginRight: '10px', padding: '10px' }} 
            /> */}
            <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#001529' }}>
              {/* Dashboard Title Here */}
            </div>
             {/* Load Layout Dropdown */}
            <div style={{ padding: '10px', textAlign: 'center' }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a layout"
                optionFilterProp="children"
                onChange={loadLayout}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {getSavedLayoutNames().map(name => (
                  <Option key={name} value={name}>{name}</Option>
                ))}
              </Select>
            </div>
          </div>
          {/* <div style={{ textAlign: 'right' }}>
            <Switch
              checkedChildren="Dark"
              unCheckedChildren="Light"
              onChange={toggleTheme}
              style={{ marginRight: 10 }}
            />
          </div> */}
          
        </Header>
        <Layout>
          <Sider 
            width={'400px'} 
            theme={themeStyles.menuTheme} 
            collapsible 
            collapsed={collapsed} 
            onCollapse={toggleCollapsed} 
            style={{ 
              background: themeStyles.sidebarStickBarBackground, 
              color: themeStyles.sidebarStickBarTextColor,
              borderRadius: themeStyles.sidebarStickBarBorderRadius,
            }}
          >        
            <Menu mode="inline" theme={themeStyles.menuTheme} selectedKeys={selectedCharts} style={{ background: themeStyles.sidebarStickBarBackground }}>
              <SubMenu key="chartsSubMenu" title="EOY Report" style={{ color: themeStyles.sidebarStickBarTextColor }}>
                {renderMenuItems()}
              </SubMenu>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            {renderControlButtons()}          
            <Content style={{ margin: '16px' }}>
              <div ref={chartRefs} style={{ padding: 24, minHeight: 360 }}>
                {renderSelectedCharts()}
              </div>
            </Content>
          </Layout>
        </Layout>
    
        {/* Save Layout Modal */}
        <Modal
          title="Save Layout"
          visible={isSaveModalVisible}
          onOk={handleSaveOk}
          onCancel={handleSaveCancel}
        >
          <Input placeholder="Enter layout name" value={newLayoutName} onChange={e => setNewLayoutName(e.target.value)} />
        </Modal>
    
       
      </Layout>
    );
    
}

export default Dashboard; 