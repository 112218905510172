import React from 'react';
import './css/main.css'; // Adjust this path to where your CSS file is located
import Footer from './components/Footer';

function PolkadotResearch() {
    return (
        <div className="container" style={{ maxWidth: '1200px', margin: 'auto', padding: '20px' }}>
          <header style={{ textAlign: 'left', margin: '40px 0' }}>
            <h1>Research & News</h1>
            <h2 style={{ fontWeight: 'normal', color: '#666' }}>
              Data insights, research and discoveries.
            </h2>
          </header>
    
          <section style={{ marginBottom: '20px' }}>
            <h2>🔥 One Repo to Rule Them All</h2>
            <p style={{ color: '#555' }}><strong>30 January 2024</strong>: Since moving all our code to the monorepo back in August 2023, we felt that it boosted our productivity. Just to be sure, we decided to back up our intuition with some data and would like to introduce our <a href="https://forum.polkadot.network/t/one-repo-to-rule-them-all-a-data-driven-look-at-polkadot-s-monorepo/5976" target="_blank">Polkadot SDK repository data analysis</a> made with the help of everyone in the Parity Data Team.</p>
            {/* <a href="https://forum.polkadot.network/t/one-repo-to-rule-them-all-a-data-driven-look-at-polkadot-s-monorepo/5976 target="_blank">Read more</a> */}
            <hr />
          </section>
    
          <section style={{ marginBottom: '20px' }}>
            <h2>📊 The Parity Data Team 2023 Polkadot Report</h2>
            <p style={{ color: '#555' }}><strong>17 December 2023</strong>: The data team has prepared a one-of-its-kind yearly data overview, the <a href="https://dashboards.data.paritytech.io/reports/2023/index.html" target="_blank">Polkadot in Numbers - Annual Report 2023</a>, based on our very own DotLake: a treasure trove of data and insights about the Polkadot ecosystem (on-chain & off-chain data).</p>
            {/* <a href="https://dashboards.data.paritytech.io/reports/2023/index.html" target="_blank">Read more</a> */}
            <hr />
          </section>
    
          {/* Additional sections for other articles */}
          <section style={{ marginBottom: '20px' }}>
            <h2>🚀 Polkalytics Data Knowledge base</h2>
            <p style={{ color: '#555' }}><strong>05 August 2023</strong>: Our friends at Polkalytics launched a brand new website, <a href="https://polkalytics.github.io/Polkadot-Data-Knowledge-Base/" target="_blank">the Polkadot Data Knowledge base</a>, a comprehensive library for data analysts, parachain teams, and everyone who is interested in Polkadot data. Check it out.</p>
            {/* <a href="https://polkalytics.github.io/Polkadot-Data-Knowledge-Base/" target="_blank">Read more</a> */}
            <hr />
          </section>
    
          <section style={{ marginBottom: '20px' }}>
            <h2>🔍 Adjusting the current inflation model to sustain Treasury inflow</h2>
            <p style={{ color: '#555' }}><strong>05 July 2023</strong>: In <a href="https://forum.polkadot.network/t/adjusting-the-current-inflation-model-to-sustain-treasury-inflow/3301" target="_blank">this masterclass in data analysis</a>, Jonas Gehrlein | Web3Foundation explains the current Treasury inflow model and suggests the implementation of some significant changes, all support by data.</p>
            {/* <a href="https://forum.polkadot.network/t/adjusting-the-current-inflation-model-to-sustain-treasury-inflow/3301" target="_blank">Read more</a> */}
            <hr />
          </section>

          <section style={{ marginBottom: '20px' }}>
        <h2>💬 Decoded 2023 - Parity Data Team</h2>
        <p style={{ color: '#555' }}><strong>28 June 2023</strong>: Many team members of the Parity data team were present at Polkadot Decoded 2023, <a href="misc-decoded-2023.html" target="_blank">here we compiled all the important links, videos, slides and follow-ups</a>.</p>
        {/* <a href="misc-decoded-2023.html" target="_blank">Read more</a> */}
        <hr />
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>🔥 Subwallet H1 2023 Staking report</h2>
        <p style={{ color: '#555' }}><strong>20 June 2023</strong>: Subwallet released the first comprehensive Polkadot Staking Report, available under the <a href="https://dotinsights.subwallet.app/polkadot-staking-report-h1-2023-en/" target="_blank">Dotinsights website</a>.</p>
        {/* <a href="https://dotinsights.subwallet.app/polkadot-staking-report-h1-2023-en/" target="_blank">Read more</a> */}
        <hr />
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>🙀 4.7 million dollars worth of failed extrinsics on Kusama</h2>
        <p style={{ color: '#555' }}><strong>30 May 2023</strong>: We investigated some curious activity on Kusama and shared the results on the <a href="https://forum.polkadot.network/t/4-7-million-dollars-worth-of-failed-extrinsics-on-kusama/2997" target="_blank">Polkadot forum</a>.</p>
        {/* <a href="https://forum.polkadot.network/t/4-7-million-dollars-worth-of-failed-extrinsics-on-kusama/2997" target="_blank">Read more</a> */}
        <hr />
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>🚀 Web3Metrics</h2>
        <p style={{ color: '#555' }}><strong>24 April 2023</strong>: The team at Apexti launched a new website that compiles and displays data and metrics from different providers in a beautiful way at <a href="https://web3metrics.com" target="_blank">Web3Metrics</a>.</p>
        {/* <a href="https://web3metrics.com" target="_blank">Visit Web3Metrics</a> */}
        <hr />
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>😎 Select * from Polkadot;</h2>
        <p style={{ color: '#555' }}><strong>13 April 2023</strong>: We share about how we built our data warehouse which we dubbed <a href="https://forum.polkadot.network/t/select-from-polkadot/2593" target="_blank" >Dotlake</a>.</p>
        {/* <a href="https://forum.polkadot.network/t/select-from-polkadot/2593" target="_blank">Read more</a> */}
        <hr />
      </section>



    
          {/* Footer could be a separate React component that pulls in site-wide links or data */}
          <Footer />
        </div>
      );
    }


const styles = {
  container: {
    padding: '40px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  header: {
    textAlign: 'left',
    marginBottom: '50px'
  },
  mainContent: {
    paddingTop: '20px'
  },
  section: {
    marginBottom: '40px'
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '15px'
  },
  sectionSubtitle: {
    fontSize: '18px',
    color: '#666',
    lineHeight: '1.6',
    marginBottom: '20px'
  },
  link: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#1890ff',
    color: '#fff',
    borderRadius: '5px',
    textDecoration: 'none'
  }
};

export default PolkadotResearch;
