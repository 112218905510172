import React, { useState, useEffect } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import '../css/main.css';
import BN from 'bn.js';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import MetricBox from '../components/MetricBoxComponent.js'
import LoadingSphere from '../components/LoadingComponent.js'
// import InfoCubeIconComponent from './components/InfoCubeIconComponent'; // Import the InfoCubeIconComponent
import { chartDescriptions} from '../js/echart_utils.js'


const PolkadotStakingMetrics = ( {onLoaded} ) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [retries, setRetries] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  // const [metricsLoaded, setMetricsLoaded] = useState(false);
  const [counterForPoolMembers, setCounterForPoolMembers] = useState(null);
  const [totalValueLocked, setTotalValueLocked] = useState(null);
  const [uniqueBondedPoolsCount, setUniqueBondedPoolsCount] = useState(null);
  const maxRetries = 5;

  useEffect(() => {
    let provider;
    let api;

    const connectToChain = async () => {
      try {
        provider = new WsProvider('wss://rpc.polkadot.io');
        api = await ApiPromise.create({ provider });

        // Fetch Staking Metrics
        const activeEra = await api.query.staking.activeEra();
        const activeEraIndex = activeEra.isSome ? activeEra.unwrap().index.toString() : 'N/A';

        const chillThreshold = await api.query.staking.chillThreshold();
        const counterForNominators = await api.query.staking.counterForNominators();
        const counterForValidators = await api.query.staking.counterForValidators();
        const currentEra = await api.query.staking.currentEra();
        // const currentPlannedSession = await api.query.staking.currentPlannedSession();
        const erasRewardPoints = await api.query.staking.erasRewardPoints(activeEraIndex);
        const erasTotalStake = await api.query.staking.erasTotalStake(activeEraIndex);
        const validatorCount = await api.query.staking.validatorCount();
        const slashRewardFraction = await api.query.staking.slashRewardFraction();
        const minNominatorBond = await api.query.staking.minNominatorBond();
        const maxValidatorsCount = await api.query.staking.maxValidatorsCount();
        const minimumActiveStake = await api.query.staking.minimumActiveStake();
        const totalIssuance = await api.query.balances.totalIssuance();
        
        const stakedPercentage = erasTotalStake.mul(new BN(10000)).div(totalIssuance).toNumber() / 100;
        const counterForPoolMembers = await api.query.nominationPools.counterForPoolMembers();
        const totalValueLocked = await api.query.nominationPools.totalValueLocked();
        const bondedPoolsCounter = await api.query.nominationPools.counterForBondedPools();

        setMetrics({
          activeEra: activeEraIndex,
          chillThreshold: chillThreshold.isSome ? chillThreshold.unwrap().toString() : 'N/A',
          counterForNominators: counterForNominators.toNumber().toLocaleString('en-US'),
          counterForValidators: (counterForValidators.toNumber() - validatorCount.toNumber()).toLocaleString('en-US'),
          currentEra: currentEra.isSome ? currentEra.unwrap().toString() : 'N/A',
          // currentPlannedSession: currentPlannedSession.toString(),
          erasRewardPoints: erasRewardPoints.total.toNumber().toLocaleString('en-US'),
          erasTotalStake: erasTotalStake.div(new BN('10000000000')).toNumber().toLocaleString('en-US'),
          validatorCount: validatorCount.toNumber().toLocaleString('en-US'),
          slashRewardFraction: (slashRewardFraction.toNumber() / 10000000).toLocaleString('en-US'),
          minNominatorBond: minNominatorBond.div(new BN('10000000000')).toString(),
          maxValidatorsCount: maxValidatorsCount.isSome ? maxValidatorsCount.unwrap().toString() : 'N/A',
          minimumActiveStake: minimumActiveStake.div(new BN('10000000000')).toString(),
          stakedPercentage: stakedPercentage.toFixed(2),
          counterForPoolMembers: parseInt(counterForPoolMembers.toString()).toLocaleString('en-US'),
          totalValueLocked: (parseInt(totalValueLocked.toString()) / 10 ** 12).toLocaleString('en-US') + ' DOT',
          uniqueBondedPoolsCount: bondedPoolsCounter.toString(),
        });

        setLoading(false);
        if (onLoaded) onLoaded();
        
        // setMetricsLoaded(true);
      } catch (err) {
        console.error('Failed to load Polkadot API:', err);
        setError('Failed to connect to the Polkadot network.');
        setLoading(false);
      }
    };

    connectToChain();

    return () => {
      if (provider) {
        provider.disconnect();
      }
    };
  }, [retries]);

  useEffect(() => {
    if (error && retries < maxRetries) {
      const retryDelay = Math.min(1000 * (2 ** retries), 30000); // 1s, 2s, 4s, 8s, ... up to a maximum of 30s
      const timeoutId = setTimeout(() => {
        setRetries(retries + 1);
        setError('');
        setLoading(true);
      }, retryDelay);
      return () => clearTimeout(timeoutId);
    }
  }, [error, retries, maxRetries]);

  if (loading) {
    return <div className="loading"><LoadingSphere/></div>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  const PieChart = ({ percentage }) => {
    const radius = 15.91549431; // Radius of the pie chart
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${(percentage / 100) * circumference} ${circumference}`;

    return (
      <svg width="100" height="75" viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0-31.831"
          fill="none"
          stroke="#eee"
          strokeWidth="3.8"
        />
        <path
          className="circle"
          strokeDasharray={strokeDasharray}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0-31.831"
          fill="none"
          stroke="#e6007a"
          strokeWidth="3.8"
        />
        <text x="18" y="20.35" className="percentage">{percentage}%</text>
      </svg>
    );
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="polkadot-card">
      <h1 style={{ position: 'relative' }}>
        Staking Metrics
        <button onClick={toggleExpansion} className="staking-toggle-button">
          {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </button>
      </h1>
      {isExpanded && (
        <div className="metrics-container">
          <div className="metrics-subsection">
            <h2>Era</h2>
            <MetricBox title="Current Era" value={metrics.currentEra} description={chartDescriptions.currentEra} />
            <MetricBox title="Eras Reward Points" value={metrics.erasRewardPoints} description={chartDescriptions.erasRewardPoints} />
          </div>
          <div className="metrics-subsection">
            <h2>Staking Details</h2>
            <MetricBox title="Staked %" description={chartDescriptions.stakedPercentage}>
              <PieChart percentage={metrics.stakedPercentage} />
            </MetricBox>
            <MetricBox title="Eras Total Stake" value={metrics.erasTotalStake} unit="DOT" description={chartDescriptions.erasTotalStake} />
          </div>
          <div className="metrics-subsection">
            <h2>Validators</h2>
            <MetricBox title="Active Validators" value={metrics.validatorCount} description={chartDescriptions.validatorCount} />
            <MetricBox title="Waiting Validators" value={metrics.counterForValidators} description={chartDescriptions.counterForValidators} />
            <MetricBox title="Max Validators" value={metrics.maxValidatorsCount} description={chartDescriptions.maxValidatorsCount} />
          </div>
          <div className="metrics-subsection">
            <h2>Nominators</h2>
            <MetricBox title="Nominators" value={metrics.counterForNominators} description={chartDescriptions.counterForNominators} />
            <MetricBox title="Min Nominator Bond" value={metrics.minNominatorBond} unit="DOT" description={chartDescriptions.minNominatorBond} />
            <MetricBox title="Min Active Stake" value={metrics.minimumActiveStake} unit="DOT" description={chartDescriptions.minimumActiveStake} />
          </div>
          <div className="metrics-subsection">
            <h2>Nomination Pools</h2>
            <MetricBox title="Pools" value={metrics.uniqueBondedPoolsCount} description={chartDescriptions.counterForPools} />
            <MetricBox title="Pool Members" value={metrics.counterForPoolMembers} description={chartDescriptions.counterForPoolMembers} />
            <MetricBox title="Amount in Pools" value={metrics.totalValueLocked} description={chartDescriptions.totalValueLocked} />          
          </div>
          <div className="metrics-subsection">
            <h2>Other Metrics</h2>
            <MetricBox title="Chill Threshold" value={metrics.chillThreshold} unit="%" description={chartDescriptions.chillThreshold} />
            <MetricBox title="Slash %" value={metrics.slashRewardFraction} unit="%" description={chartDescriptions.slashRewardFraction} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PolkadotStakingMetrics;

