import { Tooltip, Icon } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { chartDescriptions } from '../js/echart_utils';

const ChartDescriptionComponent = ({ chartId }) => {
  const description = chartDescriptions[chartId];

  return (
    <Tooltip title={description}>
      <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', position: 'absolute', top: 10, right: 10 }} />
    </Tooltip>
  );
};

export default ChartDescriptionComponent;
