import React, { useState, useEffect, useRef } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import ReactECharts from 'echarts-for-react';

const PolkadotJSComponent = () => {
  const [latestBlock, setLatestBlock] = useState(null);
  const [chainInfo, setChainInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const echartsRef = useRef([]);

  useEffect(() => {
    const connectToChain = async () => {
      try {
        const provider = new WsProvider('wss://rpc.polkadot.io');
        const api = await ApiPromise.create({ provider });

        // Get chain information
        const [chain, nodeName, nodeVersion] = await Promise.all([
          api.rpc.system.chain(),
          api.rpc.system.name(),
          api.rpc.system.version()
        ]);

        const totalIssuance = await api.query.balances.totalIssuance();
        const activeValidators = await api.query.session.validators();

        setChainInfo({
          chain: chain.toString(),
          nodeName: nodeName.toString(),
          nodeVersion: nodeVersion.toString(),
          totalIssuance: totalIssuance.toString(),
          activeValidators: activeValidators.length
        });

        // Subscribe to new heads
        api.rpc.chain.subscribeNewHeads(async (lastHeader) => {
          const blockHash = await api.rpc.chain.getBlockHash(lastHeader.number);
          const { block } = await api.rpc.chain.getBlock(blockHash);
          const blockAuthor = await api.derive.chain.getHeader(blockHash);

          const extrinsics = block.extrinsics.map((extrinsic, index) => ({
            id: index,
            method: `${extrinsic.method.section}.${extrinsic.method.method}`,
            args: extrinsic.method.args.map(arg => arg.toString()),
            hash: extrinsic.hash.toHex(),
            isSigned: extrinsic.isSigned
          }));

          setLatestBlock({
            number: block.header.number.toNumber(),
            hash: blockHash.toString(),
            parentHash: block.header.parentHash.toString(),
            stateRoot: block.header.stateRoot.toString(),
            extrinsicsRoot: block.header.extrinsicsRoot.toString(),
            author: blockAuthor.author.toString(),
            timestamp: new Date().toString(),
            transactions: extrinsics.length,
            extrinsics
          });

          setLoading(false);
        });
      } catch (err) {
        console.error('Failed to load Polkadot API:', err);
        setError('Failed to connect to the Polkadot network.');
        setLoading(false);
      }
    };

    connectToChain();

    const handleResize = () => {
      if (echartsRef.current[0]) {
        echartsRef.current[0].resize(); // Resize the chart dynamically
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const blockVisualizationOption = {
    tooltip: {
      formatter(params) {
        if (params.dataType === 'node') {
          return `<strong>${params.data.name}</strong><br/>
                  Hash: ${params.data.value}<br/>
                  Details: ${params.data.details.replace(/\n/g, '<br/>')}`;
        }
        return '';
      },
      backgroundColor: '#333',
      textStyle: { color: '#fff' }
    },
    animationDurationUpdate: 1500,
    animationEasingUpdate: 'quinticInOut',
    series: [{
      type: 'graph',
      layout: 'none',
      symbolSize(value, params) {
        return params.data.isSigned ? 60 : 50; // Dynamic sizing
      },
      roam: true,
      focusNodeAdjacency: true, // Focus on node when clicked
      nodes: latestBlock?.extrinsics.map((ext, i) => ({
        name: `${ext.method} - Extrinsic ${i}`,
        value: ext.hash,
        details: `Method: ${ext.method}\nArgs: ${ext.args.join(', ')}\nHash: ${ext.hash}`,
        x: Math.cos(i * 2 * Math.PI / latestBlock.extrinsics.length) * 200 + 300, // Circular layout
        y: Math.sin(i * 2 * Math.PI / latestBlock.extrinsics.length) * 200 + 300,
        itemStyle: {
          color: getColorByMethod(ext.method),
          shadowBlur: 10,
          shadowColor: '#333'
        },
        label: {
          position: 'right',
          formatter: '{b}'
        }
      })),
      edges: latestBlock?.extrinsics.map((ext, i) => ({
        source: 0, // Center node (block)
        target: i + 1,
        label: { show: true, formatter: '' },
        lineStyle: {
          width: 2,
          curveness: 0.3
        }
      })),
      lineStyle: { curveness: 0.3 }
    }]
  };

  function getColorByMethod(method) {
    if (method.startsWith('balances.transfer')) return '#FF5722';
    if (method.startsWith('contract.call')) return '#4CAF50';
    if (method.startsWith('sudo.sudo')) return '#9C27B0';
    return '#E6007A';
  }

  if (loading) {
    return (
      <div className="loading"><p>Loading latest block information...</p></div>
    );
  }

  if (error) {
    return (
      <p className="error-message">{error}</p>
    );
  }

  return (
    <div className="polkadot-card" style={{ width: '90%', maxWidth: '1400px', margin: '0 auto' }}>
      <h1 className="header-title">Polkadot Chain Explorer</h1>
      <br></br>
      <h2>Latest Block Details</h2>
      <br></br>
      <div className="metricsLive">
        <div className="metric-item" data-title="Total Issuance"><span>{chainInfo.totalIssuance * 1e-10}</span></div>
        <div className="metric-item" data-title= "Active Validators"><span>{chainInfo.activeValidators}</span></div>
        <div className="metric-item" data-title= "Block Number"><span>{latestBlock?.number}</span></div>
        <div className="metric-item" data-title= "Block Hash"><span>{latestBlock?.hash}</span></div>
        <div className="metric-item" data-title= "Author"><span>{latestBlock?.author}</span></div>
        <div className="metric-item" data-title= "Timestamp"><span>{latestBlock?.timestamp}</span></div>
        <div className="metric-item" data-title= "Transactions"><span>{latestBlock?.transactions}</span></div>
      </div>
      <div className="chart-container" style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactECharts 
          ref={inst => { echartsRef.current[0] = inst; }} // Storing the chart instance
          option={blockVisualizationOption} style={{ height: '40vh', width: '100%' }} 
        />
      </div>
      <div className="table-container">
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Pallet</th>
              <th>Method</th>
              <th>Hash</th>
              <th>Signed</th>
            </tr>
          </thead>
          <tbody>
            {latestBlock?.extrinsics.map((ext, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{ext.pallet}</td>
                <td>{ext.name}</td>
                <td>{ext.hash}</td>
                <td>{ext.isSigned ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PolkadotJSComponent;

