import React from 'react';
import { Table, Tabs } from 'antd';
const { TabPane } = Tabs;

const AppendicesAndResources = () => {
  const columns = [
    {
        title: 'Parachain',
        dataIndex: 'parachain',
        key: 'parachain',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            <p>
              <a href={record.link} target="_blank" rel="noopener noreferrer">
                {record.name}
              </a>
            </p>
            <img
              alt={record.name}
              src={record.image}
              style={{ width: record.imgWidth, height: record.imgHeight }}
            />
          </div>
        )
      },
      {
        title: 'Category',
        textAlign: 'center',
        dataIndex: 'category',
        key: 'category'
      },
      {
        title: 'Applications',
        textAlign: 'center',
        dataIndex: 'applications',
        key: 'applications'
      }
    ];

  const data = [
    {
      key: '1',
      name: 'Acala',
      link: 'https://acala.network',
      image: 'assets/eoyr/image27.png',
      imgWidth: '47.70px',
      imgHeight: '47.70px',
      category: 'De-Fi',
      applications: 'Acala is the all-in-one DeFi and liquidity hub of Polkadot. It’s an Ethereum-compatible platform for financial applications to use smart contracts or built-in protocols with out-of-the-box cross-chain capabilities and robust security.'
    },
    {
      key: '2',
      name: 'Ajuna Network',
      link: 'https://ajuna.io',
      image: 'assets/eoyr/image29.png',
      imgWidth: '45.38px',
      imgHeight: '45.38px',
      category: 'Gaming, NFT, Scaling',
      applications: 'Incentive layer that allows game developers to integrate blockchain technology into Unreal and Unity games, enabling secure and scalable decentralized gaming with tokenized virtual goods.'
    },
    {
      key: '3',
      name: 'Astar',
      link: 'https://astar.network',
      image: 'assets/eoyr/image57.png',
      imgWidth: '49.93px',
      imgHeight: '49.93px',
      category: 'Smart Contracts',
      applications: 'Astar Network, Japan\'s leading blockchain, supports EVM, Substrate, WebAssembly (Wasm), and ink! environments, offering a scalable, cross-layer and cross-machine protocol for the development of multichain smart contracts, with its unique Build2Earn mechanism empowering developers to earn incentives.'
    },
    {
      key: '4',
      name: 'Aventus',
      link: 'https://www.aventus.io',
      image: 'assets/eoyr/image38.png',
      imgWidth: '54.50px',
      imgHeight: '54.50px',
      category: 'DAO, Bridge, NFT',
      applications: 'Scalability of a permissioned network along with the security and interoperability of public blockchains, targeting businesses with a range of use cases like NFTs, gaming, loyalty, and supply chain, and fostering accessibility to blockchain technology.'
    },
    {
      key: '5',
      name: 'Bifrost',
      link: 'https://bifrost.finance',
      image: 'assets/eoyr/image62.png',
      imgWidth: '53.02px',
      imgHeight: '53.02px',
      category: 'DeFi, Smart Contracts',
      applications: 'Bifrost Finance on Polkadot is a decentralized, non-custodial cross-chain finance platform that focuses on staking liquidity through its unique protocols, allowing users to earn rewards and maintain liquidity for staked assets like DOT and KSM, while also facilitating cross-chain asset swaps and other DeFi functionalities. ​​'
    },
    {
      key: '6',
      name: 'Bitgreen',
      link: 'https://www.bitgreenswiss.org',
      image: 'assets/eoyr/image3.png',
      imgWidth: '50.50px',
      imgHeight: '50.50px',
      category: 'DeFi, Smart Contracts, Sustainability',
      applications: 'Eco-friendly blockchain platform designed for NGOs and ESG-focused organizations, facilitating investments in sustainable markets through its green DeFi platform, including the purchase of tokenized carbon credits.'
    },
    {
      key: '7',
      name: 'Centrifuge',
      link: 'https://centrifuge.io',
      image: 'assets/eoyr/image28.png',
      imgWidth: '51.50px',
      imgHeight: '51.50px',
      category: 'DeFi, RWA',
      applications: 'Centrifuge parachain is engineered for decentralized finance of real-world assets, streamlining transactions between borrowers and lenders, and facilitating the tokenization and securitization of various assets to democratize finance for SMEs.'
    },
    {
      key: '8',
      name: 'Clover',
      link: 'https://clv.org',
      image: 'assets/eoyr/image21.png',
      imgWidth: '45.50px',
      imgHeight: '45.50px',
      category: 'DeFi',
      applications: 'Parachain that aims to simplify blockchain infrastructure for developers, offering a foundational layer for cross-chain compatibility and efficient on-chain trading services between different chains.'
    },
    {
      key: '9',
      name: 'Composable Finance',
      link: 'https://www.composable.finance',
      image: 'assets/eoyr/image73.png',
      imgWidth: '51.31px',
      imgHeight: '51.31px',
      category: 'DeFi, Smart Contracts',
      applications: 'Composable Finance\'s parachain enables interoperability among diverse blockchain networks by running multiple bytecode types, facilitating the integration and communication of various smart contract languages and enhancing cross-chain functionalities.'
    },
    {
      key: '10',
      name: 'Crust',
      link: 'https://crust.network',
      image: 'assets/eoyr/image5.png',
      imgWidth: '49.59px',
      imgHeight: '49.59px',
      category: 'Data',
      applications: 'Crust Network is a decentralized storage platform on Polkadot, leveraging TEE, MPoW, and GPoS to offer secure and efficient decentralized storage for applications like website hosting, NFT storage, and P2P content distribution, serving as a user-friendly alternative to traditional cloud services.'
    },
    {
      key: '11',
      name: 'Darwinia',
      link: 'https://darwinia.network',
      image: 'assets/eoyr/image59.png',
      imgWidth: '52.50px',
      imgHeight: '52.50px',
      category: 'DeFi, Bridge, NFT, Gaming, DAO',
      applications: 'Darwinia Network offers a programmable cross-chain messaging infrastructure for the Polkadot ecosystem, enhancing interoperability and smart contract functionality for applications like DeFi and gaming, with faster transactions and lower costs than Ethereum.'
    },
    {
      key: '12',
      name: 'Equilibrium',
      link: 'https://equilibrium.io',
      image: 'assets/eoyr/image43.png',
      imgWidth: '53.50px',
      imgHeight: '50.32px',
      category: 'DeFi',
      applications: 'Equilibrium is a DeFi parachain that combines a money market and an orderbook-based DEX, enabling high-leverage trading and borrowing of digital assets with its native EQ token used for communal governance.'
    },
    {
      key: '13',
      name: 'Frequency',
      link: 'https://www.frequency.xyz',
      image: 'assets/eoyr/image20.png',
      imgWidth: '52.50px',
      imgHeight: '52.50px',
      category: 'Data, Identity, Privacy, SocialFi',
      applications: 'Frequency is a Polkadot parachain designed to enable scalable, decentralized social media functionality, bridging the gap between Web3 and traditional social media platforms by accommodating high volumes of social interactions like messages and posts at Web2 scale.'
    },
    {
      key: '14',
      name: 'Hashed Network',
      link: 'https://hashed.network',
      image: 'assets/eoyr/image11.png',
      imgWidth: '49.50px',
      imgHeight: '49.50px',
      category: 'DeFi',
      applications: 'Hashed Network, a parachain in the Polkadot ecosystem, orchestrates native Bitcoin Core standards like PSBTs, Schnorr signatures, and Tapscript. It uses Substrate to ensure secure address generation, verified addresses, spending policies, and security, especially in proof-of-reserves reporting for each vault.'
    },
    {
      key: '15',
      name: 'Hydra DX',
      link: 'https://hydradx.io',
      image: 'assets/eoyr/image51.png',
      imgWidth: '46.50px',
      imgHeight: '49.26px',
      category: 'DeFi',
      applications: 'HydraDX is a next-generation DeFi protocol on Polkadot, featuring the HydraDX Omnipool, an innovative Automated Market Maker (AMM) that consolidates all assets into a single trading pool for unparalleled efficiency in liquidity provision.'
    },
    {
      key: '16',
      name: 'Interlay',
      link: 'https://www.interlay.io',
      image: 'assets/eoyr/image22.png',
      imgWidth: '61.50px',
      imgHeight: '50.93px',
      category: 'DeFi',
      applications: 'Interlay is a decentralized network focusing on bridging Bitcoin to DeFi platforms like Polkadot and Ethereum, enabling secure and efficient interaction of cryptocurrencies across various blockchains.'
    },
    {
      key: '17',
      name: 'InvArch',
      link: 'https://invarch.network',
      image: 'assets/eoyr/image71.png',
      imgWidth: '51.99px',
      imgHeight: '51.99px',
      category: 'DAO, NFT, Governance',
      applications: 'InvArch Network is a blockchain and service parachain in the Polkadot ecosystem, providing a dynamic multisig solution called Saturn to serve the entire ecosystem and enable efficient IP management, utility, and authentication across multiple blockchains.'
    },
    {
      key: '18',
      name: 'KILT',
      link: 'https://www.kilt.io',
      image: 'assets/eoyr/image56.png',
      imgWidth: '55.50px',
      imgHeight: '55.50px',
      category: 'Data, Identity, Privacy',
      applications: 'KILT Protocol is a blockchain-based identity platform on Polkadot, enabling the creation of decentralized identifiers (DIDs) and verifiable credentials, focused on providing secure identity solutions for both individuals and enterprises. ​​'
    },
    {
      key: '19',
      name: 'Litentry',
      link: 'https://www.litentry.com',
      image: 'assets/eoyr/image70.png',
      imgWidth: '55.50px',
      imgHeight: '55.50px',
      category: 'Data, Identity, Privacy',
      applications: 'Litentry is a Web3.0 identity aggregation protocol on Polkadot that enables cross-chain credit computations, empowering users with control over their digital identities and facilitating interoperability and privacy in the decentralized ecosystem.'
    },
    {
      key: '20',
      name: 'Manta',
      link: 'https://manta.network',
      image: 'assets/eoyr/image26.png',
      imgWidth: '49.03px',
      imgHeight: '49.03px',
      category: 'DeFi, Privacy, Smart Contracts',
      applications: 'Manta Network is a privacy-focused parachain in the Polkadot ecosystem, utilizing advanced cryptographic techniques like zk-SNARKs to ensure end-to-end privacy for blockchain applications, thereby enhancing interoperability and ease of use across various platforms.'
    },
    {
      key: '21',
      name: 'Moonbeam',
      link: 'https://moonbeam.network',
      image: 'assets/eoyr/image50.png',
      imgWidth: '46.50px',
      imgHeight: '46.50px',
      category: 'Smart Contracts',
      applications: 'Moonbeam is a highly Ethereum-compatible smart contract parachain in the Polkadot ecosystem, enabling developers to seamlessly port projects and dapps with minimal code changes, thus connecting Polkadot\'s assets and capabilities with Ethereum\'s developer ecosystem.'
    },
    {
      key: '22',
      name: 'Moonsama',
      link: 'https://moonsama.com',
      image: 'assets/eoyr/image65.png',
      imgWidth: '48.50px',
      imgHeight: '48.50px',
      category: 'Gaming, NFT',
      applications: 'Moonsama is an EVM-compatible Polkadot parachain designed to support a diverse range of games, NFTs, tokens, and applications, providing a decentralized and interoperable platform for the Moonsama ecosystem.'
    },
    {
      key: '23',
      name: 'Nodle',
      link: 'https://www.nodle.com',
      image: 'assets/eoyr/image7.png',
      imgWidth: '52.50px',
      imgHeight: '52.50px',
      category: 'Data, IOT, Privacy, Scaling',
      applications: 'Nodle is a decentralized wireless network parachain on Polkadot, using Bluetooth Low Energy via smartphones and routers to connect IoT devices to the Internet at low cost, while maintaining privacy and security, especially for enterprises and smart cities.'
    },
    {
      key: '24',
      name: 'OriginTrail',
      link: 'https://parachain.origintrail.io',
      image: 'assets/eoyr/image46.png',
      imgWidth: '47.50px',
      imgHeight: '47.50px',
      category: 'Data, Knowledge Graph',
      applications: 'OriginTrail is a decentralized knowledge graph parachain on Polkadot, designed to organize, discover, and verify the world\'s most important assets, enhancing their value and accessibility in various sectors like supply chain, healthcare, and scientific research.'
    },
    {
      key: '25',
      name: 'Parallel',
      link: 'https://parallel.fi',
      image: 'assets/eoyr/image13.png',
      imgWidth: '57.50px',
      imgHeight: '57.50px',
      category: 'DeFi',
      applications: 'Parallel Finance is a DeFi super DApp protocol in the Polkadot ecosystem, aiming to bring decentralized finance to a broader audience by building a decentralized future that enhances DeFi accessibility, capital efficiency, and security.'
    },
    {
      key: '26',
      name: 'Pendulum',
      link: 'https://pendulumchain.org',
      image: 'assets/eoyr/image18.png',
      imgWidth: '52.50px',
      imgHeight: '52.50px',
      category: 'DeFi',
      applications: 'Pendulum is a parachain that integrates DeFi applications with the foreign exchange market, allowing for the creation of Automated Market Makers (AMMs) for fiat currencies and scalable liquidity pools, fostering yield earning opportunities and a decentralized future for fiat tokens. ​​'
    },
    {
      key: '27',
      name: 'Phala Network',
      link: 'https://phala.network',
      image: 'assets/eoyr/image58.png',
      imgWidth: '53.25px',
      imgHeight: '53.25px',
      category: 'Data, Privacy',
      applications: 'Phala Network is a privacy-preserving cloud computing service in the Polkadot ecosystem, leveraging the TEE-Blockchain Hybrid Architecture to offer secure and trustless computing while enabling confidential smart contracts and data protection. ​'
    },
    {
      key: '28',
      name: 'Polkadex',
      link: 'https://polkadex.trade',
      image: 'assets/eoyr/image14.png',
      imgWidth: '51.93px',
      imgHeight: '51.93px',
      category: 'DeFi, Exchange, Bridge',
      applications: 'Polkadex is a decentralized peer-to-peer trading platform that merges the benefits of both centralized and decentralized exchanges into a single ecosystem, featuring high transaction throughput and low latency to facilitate efficient and trustless trading on the Polkadot network. ​'
    },
    {
      key: '29',
      name: 'Subsocial',
      link: 'https://subsocial.network',
      image: 'assets/eoyr/image44.png',
      imgWidth: '52.50px',
      imgHeight: '52.50px',
      category: 'SocialFi',
      applications: 'Subsocial is a decentralized social networking platform on Polkadot, designed for the future of social networks with built-in monetization and censorship resistance, enabling users to own and control their content and social graphs. ​​'
    },
    {
      key: '30',
      name: 't3rn',
      link: 'https://www.t3rn.io',
      image: 'assets/eoyr/image61.png',
      imgWidth: '45.50px',
      imgHeight: '45.50px',
      category: 'Smart Contracts',
      applications: 't3rn is a multichain protocol designed to enable trust-free collaboration between blockchains, facilitating interoperable smart contract execution and fair developer rewards within a decentralized ecosystem that spans across various blockchain networks.'
    },
    {
      key: '31',
      name: 'Unique Network',
      link: 'https://unique.network',
      image: 'assets/eoyr/image23.png',
      imgWidth: '50.50px',
      imgHeight: '50.50px',
      category: 'NFT',
      applications: 'Unique Network is a scalable blockchain designed for NFTs with advanced functionalities, offering tools for flexible economic models, sponsored transactions, re-fungibility, sustainable NFTs, and interoperability on Polkadot and Kusama.'
    },
    {
      key: '32',
      name: 'Watr Network',
      link: 'https://watr.org',
      image: 'assets/eoyr/image53.png',
      imgWidth: '49.50px',
      imgHeight: '49.50px',
      category: 'DeFi, Smart Contracts, Sustainability',
      applications: 'Watr is an open, ethics-driven platform for programmable commodities on the Polkadot network, connecting commodity flows with a robust ecosystem of participants, and encompassing commodities financing and trade, thereby enhancing the transparency and efficiency of the global commodities market.'
    },
    {
      key: '33',
      name: 'Zeitgeist',
      link: 'https://zeitgeist.pm',
      image: 'assets/eoyr/image25.png',
      imgWidth: '51.50px',
      imgHeight: '51.50px',
      category: 'Oracle',
      applications: 'Zeitgeist is a decentralized prediction market protocol built on the Polkadot network, enabling users to create, participate in, and resolve prediction markets across a wide range of topics, leveraging its native ZTG token for network governance and dispute resolution.'
    }
  ];

  return (

      <Tabs
                        defaultActiveKey="1"
                        // onChange={(key) => setActiveNestedKey(key)}
                        type="card"
                        tabPosition="top"
                        className='custom-tab-bar'
                        style={{ maxWidth: '2000px' }}
                    >
      <TabPane tab="7.1. Polkadot Parachains" key="1">
        <Table columns={columns} dataSource={data} pagination={false} bordered />
        <p>
          Explore 300+ dapps in the Polkadot ecosystem:{' '}
          <a href="https://polkadot.network/ecosystem/dapps" target="_blank" rel="noopener noreferrer">
            https://polkadot.network/ecosystem/dapps
          </a>
        </p>
      </TabPane>
      <TabPane tab="7.2. Resources" key="2">
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ flex: 1 }}>
            <h2>Links</h2>
            <ul>
              <li>
                <a href="https://polkadot.network" target="_blank" rel="noopener noreferrer">
                  https://polkadot.network
                </a>
              </li>
              <li>
                <a href="https://wiki.polkadot.network" target="_blank" rel="noopener noreferrer">
                  https://wiki.polkadot.network
                </a>
              </li>
              <li>
                <a href="https://parachains.info" target="_blank" rel="noopener noreferrer">
                  https://parachains.info
                </a>
              </li>
              <li>
                <a href="https://substrate.io" target="_blank" rel="noopener noreferrer">
                  https://substrate.io
                </a>
              </li>
              <li>
                <a href="https://polkadot.polkassembly.io/opengov" target="_blank" rel="noopener noreferrer">
                  https://polkadot.polkassembly.io/opengov
                </a>
              </li>
              <li>
                <a href="https://polkadot.subsquare.io" target="_blank" rel="noopener noreferrer">
                  https://polkadot.subsquare.io
                </a>
              </li>
              <li>
                <a href="https://substrate.stackexchange.com" target="_blank" rel="noopener noreferrer">
                  https://substrate.stackexchange.com
                </a>
              </li>
              <li>
                <a href="https://forum.polkadot.network" target="_blank" rel="noopener noreferrer">
                  https://forum.polkadot.network
                </a>
              </li>
              <li>
                <a href="https://github.com/paritytech/polkadot-sdk" target="_blank" rel="noopener noreferrer">
                  https://github.com/paritytech/polkadot-sdk
                </a>
              </li>
            </ul>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img className="m-1" src="assets/eoyr/dots.png" alt="Staking" style={{ width: '60%'}} />
          </div>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default AppendicesAndResources;