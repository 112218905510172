// // import React, { useState, useEffect } from 'react';
// // import Papa from 'papaparse';
// // import './css/CSVSqlReader.css';

// // const CSVSqlReader = () => {
// //   const [files, setFiles] = useState(['dummy_data.csv']);
// //   const [selectedFile, setSelectedFile] = useState('dummy_data.csv');
// //   const [data, setData] = useState([]);
// //   const [columns, setColumns] = useState([]);
// //   const [query, setQuery] = useState('SELECT name, age FROM data WHERE city = "New York"');
// //   const [results, setResults] = useState([]);

// //   useEffect(() => {
// //     const dummyCSV = `name,age,city
// // John,28,New York
// // Alice,24,Los Angeles
// // Robert,34,Chicago
// // Emily,40,San Francisco
// // Michael,22,Houston`;

// //     Papa.parse(dummyCSV, {
// //       header: true,
// //       complete: (results) => {
// //         setData(results.data);
// //         setColumns(Object.keys(results.data[0] || {}));
// //         setResults(results.data); // Initial results display
// //       },
// //     });
// //   }, []);

// //   const executeQuery = () => {
// //     if (query.trim() === '') {
// //       setResults(data);
// //       return;
// //     }

// //     const queryParts = query.match(/SELECT (.+) FROM data(?: WHERE (.+))?/i);
// //     if (!queryParts) {
// //       alert('Invalid query');
// //       return;
// //     }

// //     const selectedColumns = queryParts[1].trim().split(',').map(col => col.trim());
// //     const whereClause = queryParts[2];

// //     let filteredData = data;
// //     if (whereClause) {
// //       const condition = new Function('row', `return ${whereClause.replace(/(\w+)/g, 'row["$1"]')};`);
// //       filteredData = data.filter(condition);
// //     }

// //     const queriedData = filteredData.map(row =>
// //       selectedColumns.reduce((obj, col) => {
// //         obj[col] = row[col];
// //         return obj;
// //       }, {})
// //     );

// //     setResults(queriedData);
// //   };

// //   return (
// //     <div className="csv-sql-reader">
// //       <h1 className="title">Data SQL Reader</h1>
// //       <div className="file-selection">
// //         <label htmlFor="file-select">Choose a file:</label>
// //         <select id="file-select" value={selectedFile} onChange={(e) => setSelectedFile(e.target.value)}>
// //           {files.map(file => (
// //             <option key={file} value={file}>{file}</option>
// //           ))}
// //         </select>
// //       </div>
// //       <div className="query-section">
// //         <textarea
// //           className="query-input"
// //           value={query}
// //           onChange={(e) => setQuery(e.target.value)}
// //           rows="4"
// //           cols="50"
// //           placeholder='e.g., SELECT name, age FROM data WHERE city = "New York"'
// //         />
// //         <button className="run-query-button" onClick={executeQuery}>Run Query</button>
// //       </div>
// //       <div className="results-section">
// //         <h2>Results</h2>
// //         <table className="results-table">
// //           <thead>
// //             <tr>
// //               {columns.map((key) => <th key={key}>{key}</th>)}
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {results.map((row, index) => (
// //               <tr key={index}>
// //                 {columns.map((col) => (
// //                   <td key={col}>{row[col]}</td>
// //                 ))}
// //               </tr>
// //             ))}
// //           </tbody>
// //         </table>
// //       </div>
// //     </div>
// //   );
// // };

// // export default CSVSqlReader;



// import React, { useState, useEffect } from 'react';
// import Papa from 'papaparse';
// import alasql from 'alasql';
// import './css/CSVSqlReader.css';

// const CSVSqlReader = () => {
//   const fileList = [
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov_csv", file: "data/eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_participation_csv", file: "data/eoyr_governance_monthly_participation000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_voting_power_by_type_conviction__direct_and_delegated_tokens_breakdown_w_conviction_opengov_csv", file: "data/eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_origin_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_origin_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_history_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov_history000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_voters_csv", file: "data/eoyr_governance_number_of_voters000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_votes_by_duration_of_lock_opengov_csv", file: "data/eoyr_governance_number_of_votes_by_duration_of_lock_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_balance_csv", file: "data/eoyr_governance_treasury_balance000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_flows_csv", file: "data/eoyr_governance_treasury_flows000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_sum_of_stake_pct_staked_csv", file: "data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_number_of_nominators_csv", file: "data/eoyr_shared_security_number_of_nominators000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake_csv", file: "data/eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_nakamoto_coefficient_csv", file: "data/eoyr_shared_security_nakamoto_coefficient000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_active_addresses_csv", file: "data/eoyr_parachains_number_of_active_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_hrmp_channels_csv", file: "data/eoyr_parachains_number_of_hrmp_channels000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_parachains_csv", file: "data/eoyr_parachains_number_of_parachains000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_transactions_extrinsics_csv", file: "data/eoyr_parachains_number_of_transactions_extrinsics000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_unique_addresses_csv", file: "data/eoyr_parachains_number_of_unique_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_activity_csv", file: "data/eoyr_stack_exchange_activity000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_top_25_tags_csv", file: "data/eoyr_stack_exchange_top_25_tags000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_user_growth_csv", file: "data/eoyr_stack_exchange_user_growth000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_messages_by_type_csv", file: "data/eoyr_xcm_messages_by_type000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_parachain_message_usag_per_month_hrmp_senders_csv", file: "data/eoyr_xcm_parachain_message_usag_per_month_hrmp_senders000000000000.csv" },
//   ];

//   const [selectedFile, setSelectedFile] = useState(fileList[0].file);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [query, setQuery] = useState('SELECT * FROM data');
//   const [results, setResults] = useState([]);
//   const [resultColumns, setResultColumns] = useState([]);

//   useEffect(() => {
//     const loadData = async (file) => {
//       const response = await fetch(`/${file}`);
//       const fileData = await response.text();

//       Papa.parse(fileData, {
//         header: true,
//         complete: (results) => {
//           setData(results.data);
//           setColumns(Object.keys(results.data[0] || {}));
//           setResults(results.data); // Initial results display
//           setResultColumns(Object.keys(results.data[0] || {}));
//         },
//       });
//     };

//     loadData(selectedFile);
//   }, [selectedFile]);

//   const executeQuery = () => {
//     console.log(`Executing query: ${query}`);
//     if (query.trim() === '') {
//       setResults(data);
//       setResultColumns(columns);
//       return;
//     }

//     try {
//       const result = alasql(query.replace('data', '?'), [data]);
//       setResults(result);
//       if (result.length > 0) {
//         setResultColumns(Object.keys(result[0]));
//       } else {
//         setResultColumns([]);
//       }
//     } catch (error) {
//       alert('Invalid query');
//       console.error(error);
//     }
//   };

//   return (
//     <div className="csv-sql-reader">
//       <h1 className="title">Data SQL Reader</h1>
//       <div className="file-selection">
//         <label htmlFor="file-select">Choose a file:</label>
//         <select id="file-select" value={selectedFile} onChange={(e) => setSelectedFile(e.target.value)}>
//           {fileList.filter(file => file.type === 'csv').map(file => (
//             <option key={file.file} value={file.file}>{file.query}</option>
//           ))}
//         </select>
//       </div>
//       <div className="query-section">
//         <textarea
//           className="query-input"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           rows="4"
//           cols="50"
//           placeholder='e.g., SELECT DISTINCT column1 FROM data WHERE column2 = "value"'
//         />
//         <button className="run-query-button" onClick={executeQuery}>Run Query</button>
//       </div>
//       <div className="results-section">
//         <h2>Results</h2>
//         <table className="results-table">
//           <thead>
//             <tr>
//               {resultColumns.map((key) => <th key={key}>{key}</th>)}
//             </tr>
//           </thead>
//           <tbody>
//             {results.map((row, index) => (
//               <tr key={index}>
//                 {resultColumns.map((col) => (
//                   <td key={col}>{row[col]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CSVSqlReader;

// import React, { useState, useEffect, useRef } from 'react';
// import Papa from 'papaparse';
// import alasql from 'alasql';
// import { Controlled as CodeMirror } from 'react-codemirror2';
// import * as echarts from 'echarts';
// import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/material.css';
// import 'codemirror/mode/sql/sql';
// import './css/CSVSqlReader.css';

// const CSVSqlReader = () => {
//   const fileList = [
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov_csv", file: "data/eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_participation_csv", file: "data/eoyr_governance_monthly_participation000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_voting_power_by_type_conviction__direct_and_delegated_tokens_breakdown_w_conviction_opengov_csv", file: "data/eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_origin_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_origin_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_history_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov_history000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_voters_csv", file: "data/eoyr_governance_number_of_voters000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_votes_by_duration_of_lock_opengov_csv", file: "data/eoyr_governance_number_of_votes_by_duration_of_lock_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_balance_csv", file: "data/eoyr_governance_treasury_balance000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_flows_csv", file: "data/eoyr_governance_treasury_flows000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_sum_of_stake_pct_staked_csv", file: "data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_number_of_nominators_csv", file: "data/eoyr_shared_security_number_of_nominators000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake_csv", file: "data/eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_nakamoto_coefficient_csv", file: "data/eoyr_shared_security_nakamoto_coefficient000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_active_addresses_csv", file: "data/eoyr_parachains_number_of_active_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_hrmp_channels_csv", file: "data/eoyr_parachains_number_of_hrmp_channels000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_parachains_csv", file: "data/eoyr_parachains_number_of_parachains000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_transactions_extrinsics_csv", file: "data/eoyr_parachains_number_of_transactions_extrinsics000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_unique_addresses_csv", file: "data/eoyr_parachains_number_of_unique_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_activity_csv", file: "data/eoyr_stack_exchange_activity000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_top_25_tags_csv", file: "data/eoyr_stack_exchange_top_25_tags000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_user_growth_csv", file: "data/eoyr_stack_exchange_user_growth000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_messages_by_type_csv", file: "data/eoyr_xcm_messages_by_type000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_parachain_message_usag_per_month_hrmp_senders_csv", file: "data/eoyr_xcm_parachain_message_usag_per_month_hrmp_senders000000000000.csv" },
//   ];

//   const [selectedFile, setSelectedFile] = useState(fileList[0].file);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [query, setQuery] = useState('SELECT * FROM data');
//   const [results, setResults] = useState([]);
//   const [resultColumns, setResultColumns] = useState([]);
//   const [chartOptions, setChartOptions] = useState({ xColumn: '', yColumn: '' });

//   const chartRef = useRef(null);

//   useEffect(() => {
//     const loadData = async (file) => {
//       const response = await fetch(`/${file}`);
//       const fileData = await response.text();

//       Papa.parse(fileData, {
//         header: true,
//         complete: (results) => {
//           setData(results.data);
//           setColumns(Object.keys(results.data[0] || {}));
//           setResults(results.data); // Initial results display
//           setResultColumns(Object.keys(results.data[0] || {}));
//         },
//       });
//     };

//     loadData(selectedFile);
//   }, [selectedFile]);

//   const executeQuery = () => {
//     console.log(`Executing query: ${query}`);
//     if (query.trim() === '') {
//       setResults(data);
//       setResultColumns(columns);
//       return;
//     }

//     try {
//       const result = alasql(query.replace('data', '?'), [data]);
//       setResults(result);
//       if (result.length > 0) {
//         setResultColumns(Object.keys(result[0]));
//       } else {
//         setResultColumns([]);
//       }
//     } catch (error) {
//       alert('Invalid query');
//       console.error(error);
//     }
//   };

//   const generateChart = () => {
//     if (!chartOptions.xColumn || !chartOptions.yColumn) {
//       alert('Please select both X and Y columns for the chart.');
//       return;
//     }

//     const chartData = results.map(row => ({
//       x: row[chartOptions.xColumn],
//       y: row[chartOptions.yColumn]
//     }));

//     const chartInstance = echarts.init(chartRef.current);
//     chartInstance.setOption({
//       xAxis: {
//         type: 'category',
//         data: chartData.map(item => item.x),
//         name: chartOptions.xColumn
//       },
//       yAxis: {
//         type: 'value',
//         name: chartOptions.yColumn
//       },
//       series: [{
//         data: chartData.map(item => item.y),
//         type: 'bar'
//       }],
//       tooltip: {
//         trigger: 'axis'
//       }
//     });
//   };

//   return (
//     <div className="csv-sql-reader">
//       <h1 className="title">Data SQL Reader</h1>
//       <div className="file-selection">
//         <label htmlFor="file-select">Choose a file:</label>
//         <select id="file-select" value={selectedFile} onChange={(e) => setSelectedFile(e.target.value)}>
//           {fileList.filter(file => file.type === 'csv').map(file => (
//             <option key={file.file} value={file.file}>{file.query}</option>
//           ))}
//         </select>
//       </div>
//       <div className="query-section">
//          <textarea
//           className="query-input"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           rows="4"
//           cols="50"
//           placeholder='e.g., SELECT DISTINCT column1 FROM data WHERE column2 = "value"'
//         />
//         <button className="run-query-button" onClick={executeQuery}>Run Query</button>
//       </div>
//       <div className="results-section">
//         <h2>Results</h2>
//         <table className="results-table">
//           <thead>
//             <tr>
//               {resultColumns.map((key) => <th key={key}>{key}</th>)}
//             </tr>
//           </thead>
//           <tbody>
//             {results.map((row, index) => (
//               <tr key={index}>
//                 {resultColumns.map((col) => (
//                   <td key={col}>{row[col]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="chart-section">
//         <h2>Generate Chart</h2>
//         <div>
//           <label>
//             X-Axis:
//             <select value={chartOptions.xColumn} onChange={(e) => setChartOptions({ ...chartOptions, xColumn: e.target.value })}>
//               <option value="">Select Column</option>
//               {resultColumns.map((col) => (
//                 <option key={col} value={col}>{col}</option>
//               ))}
//             </select>
//           </label>
//           <label>
//             Y-Axis:
//             <select value={chartOptions.yColumn} onChange={(e) => setChartOptions({ ...chartOptions, yColumn: e.target.value })}>
//               <option value="">Select Column</option>
//               {resultColumns.map((col) => (
//                 <option key={col} value={col}>{col}</option>
//               ))}
//             </select>
//           </label>
//           <button className="run-query-button" onClick={generateChart}>Generate Chart</button>
//         </div>
//         <div ref={chartRef} className="chart-container"></div>
//       </div>
//     </div>
//   );
// };

// export default CSVSqlReader;

// import React, { useState, useEffect, useRef } from 'react';
// import Papa from 'papaparse';
// import alasql from 'alasql';
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import * as echarts from 'echarts';
// import './css/CSVSqlReader.css';

// const CSVSqlReader = () => {
//   const fileList = [
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov_csv", file: "data/eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_participation_csv", file: "data/eoyr_governance_monthly_participation000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_voting_power_by_type_conviction__direct_and_delegated_tokens_breakdown_w_conviction_opengov_csv", file: "data/eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_origin_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_origin_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_history_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov_history000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_voters_csv", file: "data/eoyr_governance_number_of_voters000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_votes_by_duration_of_lock_opengov_csv", file: "data/eoyr_governance_number_of_votes_by_duration_of_lock_opengov000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_balance_csv", file: "data/eoyr_governance_treasury_balance000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_flows_csv", file: "data/eoyr_governance_treasury_flows000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_sum_of_stake_pct_staked_csv", file: "data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_number_of_nominators_csv", file: "data/eoyr_shared_security_number_of_nominators000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake_csv", file: "data/eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_nakamoto_coefficient_csv", file: "data/eoyr_shared_security_nakamoto_coefficient000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_active_addresses_csv", file: "data/eoyr_parachains_number_of_active_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_hrmp_channels_csv", file: "data/eoyr_parachains_number_of_hrmp_channels000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_parachains_csv", file: "data/eoyr_parachains_number_of_parachains000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_transactions_extrinsics_csv", file: "data/eoyr_parachains_number_of_transactions_extrinsics000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_unique_addresses_csv", file: "data/eoyr_parachains_number_of_unique_addresses000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_activity_csv", file: "data/eoyr_stack_exchange_activity000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_top_25_tags_csv", file: "data/eoyr_stack_exchange_top_25_tags000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_user_growth_csv", file: "data/eoyr_stack_exchange_user_growth000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_messages_by_type_csv", file: "data/eoyr_xcm_messages_by_type000000000000.csv" },
//     { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_parachain_message_usag_per_month_hrmp_senders_csv", file: "data/eoyr_xcm_parachain_message_usag_per_month_hrmp_senders000000000000.csv" },
//   ];

//   const [selectedFile, setSelectedFile] = useState(fileList[0].file);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [query, setQuery] = useState('SELECT * FROM data');
//   const [results, setResults] = useState([]);
//   const [resultColumns, setResultColumns] = useState([]);
//   const [chartOptions, setChartOptions] = useState({ xColumn: '', yColumn: '' });

//   const chartRef = useRef(null);

//   useEffect(() => {
//     const loadData = async (file) => {
//       const response = await fetch(`/${file}`);
//       const fileData = await response.text();

//       Papa.parse(fileData, {
//         header: true,
//         complete: (results) => {
//           setData(results.data);
//           setColumns(Object.keys(results.data[0] || {}));
//           setResults(results.data); // Initial results display
//           setResultColumns(Object.keys(results.data[0] || {}));
//         },
//       });
//     };

//     loadData(selectedFile);
//   }, [selectedFile]);

//   const executeQuery = () => {
//     console.log(`Executing query: ${query}`);
//     if (query.trim() === '') {
//       setResults(data);
//       setResultColumns(columns);
//       return;
//     }

//     try {
//       const result = alasql(query.replace('data', '?'), [data]);
//       setResults(result);
//       if (result.length > 0) {
//         setResultColumns(Object.keys(result[0]));
//       } else {
//         setResultColumns([]);
//       }
//     } catch (error) {
//       alert('Invalid query');
//       console.error(error);
//     }
//   };

//   const generateChart = () => {
//     if (!chartOptions.xColumn || !chartOptions.yColumn) {
//       alert('Please select both X and Y columns for the chart.');
//       return;
//     }

//     const chartData = results.map(row => ({
//       x: row[chartOptions.xColumn],
//       y: row[chartOptions.yColumn]
//     }));

//     const chartInstance = echarts.init(chartRef.current);
//     chartInstance.setOption({
//       xAxis: {
//         type: 'category',
//         data: chartData.map(item => item.x),
//         name: chartOptions.xColumn
//       },
//       yAxis: {
//         type: 'value',
//         name: chartOptions.yColumn
//       },
//       series: [{
//         data: chartData.map(item => item.y),
//         type: 'bar'
//       }],
//       tooltip: {
//         trigger: 'axis'
//       }
//     });
//   };

//   return (
//     <div className="csv-sql-reader">
//       <h1 className="title">Data SQL Reader</h1>
//       <div className="file-selection">
//         <label htmlFor="file-select">Choose a file:</label>
//         <select id="file-select" value={selectedFile} onChange={(e) => setSelectedFile(e.target.value)}>
//           {fileList.filter(file => file.type === 'csv').map(file => (
//             <option key={file.file} value={file.file}>{file.query}</option>
//           ))}
//         </select>
//       </div>
//       <div className="query-section">
//         <textarea
//           className="query-input"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           rows="4"
//           cols="50"
//           placeholder='e.g., SELECT DISTINCT column1 FROM data WHERE column2 = "value"'
//         />
//         <SyntaxHighlighter language="sql" style={docco}>
//           {query}
//         </SyntaxHighlighter>
//         <button className="run-query-button" onClick={executeQuery}>Run Query</button>
//       </div>
//       <div className="results-section">
//         <h2>Results</h2>
//         <table className="results-table">
//           <thead>
//             <tr>
//               {resultColumns.map((key) => <th key={key}>{key}</th>)}
//             </tr>
//           </thead>
//           <tbody>
//             {results.map((row, index) => (
//               <tr key={index}>
//                 {resultColumns.map((col) => (
//                   <td key={col}>{row[col]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="chart-section">
//         <h2>Generate Chart</h2>
//         <div>
//           <label>
//             X-Axis:
//             <select value={chartOptions.xColumn} onChange={(e) => setChartOptions({ ...chartOptions, xColumn: e.target.value })}>
//               <option value="">Select Column</option>
//               {resultColumns.map((col) => (
//                 <option key={col} value={col}>{col}</option>
//               ))}
//             </select>
//           </label>
//           <label>
//             Y-Axis:
//             <select value={chartOptions.yColumn} onChange={(e) => setChartOptions({ ...chartOptions, yColumn: e.target.value })}>
//               <option value="">Select Column</option>
//               {resultColumns.map((col) => (
//                 <option key={col} value={col}>{col}</option>
//               ))}
//             </select>
//           </label>
//           <button className="run-query-button" onClick={generateChart}>Generate Chart</button>
//         </div>
//         <div ref={chartRef} className="chart-container"></div>
//       </div>
//     </div>
//   );
// };

// export default CSVSqlReader;


import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import alasql from 'alasql';
import { TextField, Select, MenuItem, Button, Tabs, Tab, Container, Typography, Box, Paper } from '@mui/material';
import './css/CSVSqlReader.css';
import EChartsBuilder from './components/EChartsBuilderComponent';


const CSVSqlReader = () => {
  const fileList = [
    { freq: "m", type: "jsonl", query: "block_weights", file: "data/block_weights000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_general_chain_activity_tracker", file: "data/ecosystem_general_chain_activity_tracker000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_general_metrics_polkadot", file: "data/ecosystem_general_metrics_polkadot000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_monthly_treasury_assets_polkadot", file: "data/ecosystem_monthly_treasury_assets_polkadot000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_monthly_treasury_relay_new", file: "data/ecosystem_monthly_treasury_relay_new000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_monthly_treasury_relay", file: "data/ecosystem_monthly_treasury_relay000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_stablecoins_usdc_parachains", file: "data/ecosystem_stablecoins_usdc_parachains000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_stablecoins_usdc", file: "data/ecosystem_stablecoins_usdc000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_stablecoins_usdt_parachains", file: "data/ecosystem_stablecoins_usdt_parachains000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ecosystem_stablecoins_usdt", file: "data/ecosystem_stablecoins_usdt000000000000.jsonl" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov_csv", file: "data/eoyr_governance_monthly_capital_by_type_no_conviction_direct_and_delegated_tokens_breakdown_no_conviction_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_participation_csv", file: "data/eoyr_governance_monthly_participation000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov_csv", file: "data/eoyr_governance_monthly_voting_power_by_type_conviction_-_direct_and_delegated_tokens_breakdown_w_conviction_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_origin_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_origin_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_history_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov_history000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_referenda_by_outcome_opengov_csv", file: "data/eoyr_governance_number_of_referenda_by_outcome_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_no_conviction_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov_csv", file: "data/eoyr_governance_number_of_tokens_by_vote_direction_w_conviction_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_voters_csv", file: "data/eoyr_governance_number_of_voters000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_number_of_votes_by_duration_of_lock_opengov_csv", file: "data/eoyr_governance_number_of_votes_by_duration_of_lock_opengov000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_balance_csv", file: "data/eoyr_governance_treasury_balance000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_governance_treasury_flows_csv", file: "data/eoyr_governance_treasury_flows000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_sum_of_stake_pct_staked_csv", file: "data/eoyr_shared_security_sum_of_stake_pct_staked000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_number_of_nominators_csv", file: "data/eoyr_shared_security_number_of_nominators000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake_csv", file: "data/eoyr_shared_security_difference_between_minimum_and_maximum_validator_stake000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_shared_security_nakamoto_coefficient_csv", file: "data/eoyr_shared_security_nakamoto_coefficient000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_active_addresses_csv", file: "data/eoyr_parachains_number_of_active_addresses000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_hrmp_channels_csv", file: "data/eoyr_parachains_number_of_hrmp_channels000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_parachains_csv", file: "data/eoyr_parachains_number_of_parachains000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_transactions_extrinsics_csv", file: "data/eoyr_parachains_number_of_transactions_extrinsics000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_parachains_number_of_unique_addresses_csv", file: "data/eoyr_parachains_number_of_unique_addresses000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_activity_csv", file: "data/eoyr_stack_exchange_activity000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_top_25_tags_csv", file: "data/eoyr_stack_exchange_top_25_tags000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_stack_exchange_user_growth_csv", file: "data/eoyr_stack_exchange_user_growth000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_messages_by_type_csv", file: "data/eoyr_xcm_messages_by_type000000000000.csv" },
    { freq: "m", type: "csv", query: "public_dashboards_eoyr_xcm_parachain_message_usag_per_month_hrmp_senders_csv", file: "data/eoyr_xcm_parachain_message_usag_per_month_hrmp_senders000000000000.csv" },
    { freq: "m", type: "jsonl", query: "ink_fees_usd_daily", file: "data/ink_fees_usd_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_num_code_hash_daily", file: "data/ink_num_code_hash_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_num_contracts_deployed_daily", file: "data/ink_num_contracts_deployed_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_num_owners_daily", file: "data/ink_num_owners_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_tvl_daily", file: "data/ink_tvl_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_version_daily", file: "data/ink_version_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "ink_view_daily", file: "data/ink_view_daily000000000000.jsonl" },
    { freq: "m", type: "csv", query: "metadata", file: "data/metadata000000000000.csv" },
    { freq: "m", type: "jsonl", query: "metadata", file: "data/metadata000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_accounts_active_daily", file: "data/parachains_accounts_active_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_accounts_active_monthly", file: "data/parachains_accounts_active_monthly000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_events_daily", file: "data/parachains_events_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_events_monthly", file: "data/parachains_events_monthly000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_transactions_daily", file: "data/parachains_transactions_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_transactions_monthly", file: "data/parachains_transactions_monthly000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_transfers_daily", file: "data/parachains_transfers_daily000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_transfers_monthly", file: "data/parachains_transfers_monthly000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "parachains_unique_accounts_monthly", file: "data/parachains_unique_accounts_monthly000000000000.jsonl" },
    { freq: "m", type: "csv", query: "polkadot_OpenGov_voter_history", file: "data/polkadot_OpenGov_voter_history.csv" },
    { freq: "m", type: "csv", query: "polkadot_OpenGov_voter_participation", file: "data/polkadot_OpenGov_voter_participation.csv" },
    { freq: "m", type: "jsonl", query: "ref_684", file: "data/ref_684000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "staking_total_addresses_staked", file: "data/staking_total_addresses_staked000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "staking_total_dot_staked", file: "data/staking_total_dot_staked000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "staking_total_nominators_polkadot", file: "data/staking_total_nominators_polkadot000000000000.jsonl" },
    { freq: "m", type: "jsonl", query: "staking_total_validators_polkadot", file: "data/staking_total_validators_polkadot000000000000.jsonl" }
  ];
  

  const [selectedFile, setSelectedFile] = useState(fileList[0].file);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [query, setQuery] = useState('SELECT * FROM data');
  const [results, setResults] = useState([]);
  const [resultColumns, setResultColumns] = useState([]);
  const [chartOptions, setChartOptions] = useState({ xColumn: '', yColumn: '', chartType: 'bar', seriesName: '' });
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;

  useEffect(() => {
    const loadData = async (file) => {
      const response = await fetch(`/${file}`);
      const fileData = await response.text();
      const fileExtension = file.split('.').pop();

      let parsedData = [];

      if (fileExtension === 'csv') {
        Papa.parse(fileData, {
          header: true,
          dynamicTyping: true,
          complete: (results) => {
            parsedData = results.data.filter(row => Object.values(row).some(value => value !== null && value !== ''));
            processData(parsedData);
          },
        });
      } else if (fileExtension === 'jsonl') {
        parsedData = fileData.split('\n').filter(line => line).map(line => JSON.parse(line));
        processData(parsedData);
      }
    };

    loadData(selectedFile);
  }, [selectedFile]);

  const processData = (parsedData) => {
    const processedData = parsedData.map(row => {
      Object.keys(row).forEach(key => {
        if (!isNaN(row[key]) && row[key] !== null && row[key] !== '') {
          row[key] = Number(row[key]);
        }
      });
      return row;
    });

    setData(processedData);
    setColumns(Object.keys(processedData[0] || {}));
    setResults(processedData);
    setResultColumns(Object.keys(processedData[0] || {}));
  };

  const executeQuery = () => {
    if (query.trim() === '') {
      setResults(data);
      setResultColumns(columns);
      setCurrentPage(1);
      return;
    }

    try {
      const result = alasql(query.replace('data', '?'), [data]);
      setResults(result);
      if (result.length > 0) {
        setResultColumns(Object.keys(result[0]));
      } else {
        setResultColumns([]);
      }
      if (chartOptions.xColumn && chartOptions.yColumn) {
        setResults(result);
      }
      setCurrentPage(1);
    } catch (error) {
      alert('Invalid query');
      console.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(results.length / rowsPerPage);
  const displayedResults = results.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <Container className="csv-sql-reader" sx={{ backgroundColor: '#f9f9f9', padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          textAlign: 'left',
          marginBottom: '20px',
          fontWeight: 'bold',
          color: '#333',
          fontFamily: "'Unbounded', cursive"
        }}
      >
        SQL & Charts
      </Typography>
      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#fff' }}>
        <Box className="file-selection" sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Select
            value={selectedFile}
            onChange={(e) => setSelectedFile(e.target.value)}
            sx={{ minWidth: '10px', maxWidth: '800px', padding: '10px', fontSize: '1em' }}
          >
            {fileList.map(file => (
              <MenuItem key={file.file} value={file.file}>
                {file.query}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="query-section" sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder='e.g., SELECT DISTINCT column1 FROM data WHERE column2 = "value"'
            fullWidth
            sx={{ flexGrow: 1, marginRight: '10px' }}
          />
          <Button variant="contained" color="primary" onClick={executeQuery} sx={{ height: 'fit-content' }}>Run Query</Button>
        </Box>
      </Paper>
      <Paper sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#fff' }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          sx={{ marginBottom: '20px' }}
        >
          <Tab label="Results" />
          <Tab label="Chart" />
        </Tabs>
        {activeTab === 0 && (
          <Box className="results-section" sx={{ marginTop: '20px' }}>
            <Box sx={{ overflowX: 'auto' }}>
              <table className="results-table" sx={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                <thead>
                  <tr>
                    {resultColumns.map((key) => <th key={key} sx={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', backgroundColor: '#f4f4f4' }}>{key}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {displayedResults.map((row, index) => (
                    <tr key={index}>
                      {resultColumns.map((col) => (
                        <td key={col} sx={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left' }}>{row[col]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            {totalPages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                {Array.from({ length: totalPages }, (_, i) => (
                  <Button
                    key={i + 1}
                    onClick={() => handlePageChange(i + 1)}
                    variant={currentPage === i + 1 ? 'contained' : 'outlined'}
                    sx={{ margin: '0 5px' }}
                  >
                    {i + 1}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        )}
        {activeTab === 1 && (
          <EChartsBuilder
            resultColumns={resultColumns}
            chartOptions={chartOptions}
            setChartOptions={setChartOptions}
            results={results}
          />
        )}
      </Paper>
    </Container>
  );
};

export default CSVSqlReader;