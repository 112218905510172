import React from 'react';

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{
        padding: '20px',
        borderRadius: '5px',
        fontSize: '14px',
        textAlign: 'justify',
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #ddd',
        color: '#333',
      }}
    >
      <div
        className="content"
        style={{
          margin: '0 auto',
          maxWidth: '1100px',
          lineHeight: '1.6',
        }}
      >
        <p style={{ margin: '0' }}>
          <strong>Disclaimer:</strong> These metrics and charts are provided by Parity Technologies Limited "as is" and without warranties of any kind, either express or implied. While we strive to maintain the accuracy and reliability of the data presented, errors may occur, and we disclaim any liability for such occurrences. Should you identify any inaccuracies, please feel free to contact us.
          <br /><br />
          Usage of Parity DotLake's dashboards and data requires attribution to Parity DotLake as the source. This acknowledgment is crucial not only for respecting intellectual property rights but also for supporting the ongoing provision and enhancement of our services to the community.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
