import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import * as echarts from 'echarts';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import PolkadotJSGeneralComponent from './components/PolkadotJSGeneralComponent';
import { lineChart, get_chain_colors } from './js/charts_ecosystem.js';
import { chartDescriptions } from './js/echart_utils.js';

const PolkadotStatsGeneral = () => {
  const chartRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  const chartInstances = useRef([]);
  const [chartsVisible, setChartsVisible] = useState(false); // State to manage chart visibility

  const chartTitles = [
    'Number of Blocks',
    'Number of Events',
    'Number of Transfers',
    'Number of Extrinsics',
    'Number of New Accounts'
  ];

  const chartContents = [
    chartDescriptions.blocks,
    chartDescriptions.events,
    chartDescriptions.transfers,
    chartDescriptions.extrinsics,
    chartDescriptions.newAccounts
  ];

  const resizeCharts = useCallback(() => {
    chartInstances.current.forEach((chart) => {
      if (chart) {
        chart.resize();
      }
    });
  }, []);

  const debouncedResizeCharts = useMemo(() => {
    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    };
    return debounce(resizeCharts, 200);
  }, [resizeCharts]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedResizeCharts);
    chartRefs.forEach((ref, index) => {
      if (ref.current) {
        const chart = echarts.init(ref.current);
        chartInstances.current[index] = chart;
        resizeObserver.observe(ref.current);
      }
    });

    const handleWindowResize = () => {
      debouncedResizeCharts();
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      resizeObserver.disconnect();
      chartInstances.current.forEach((chart) => {
        if (chart) {
          chart.dispose();
        }
      });
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [debouncedResizeCharts]);

  const fetchDataAndInitChart = async (endpoint, chartIndex, chartTitle, keyForData) => {
    try {
      const response = await fetch(endpoint);
      const rawData = await response.text();
      const data = rawData.split('\n')
        .filter(Boolean)
        .map(JSON.parse)
        .filter(item => chartTitle.includes("Parachains") ? item.chain !== "polkadot" : item.chain === "polkadot")
        .reverse();

      if (chartRefs[chartIndex].current) {
        const chart = chartInstances.current[chartIndex] || echarts.init(chartRefs[chartIndex].current);
        const config = {
          xName: 'day',
          yName: keyForData,
          title: chartTitle,
          type: 'line',
          colorScheme: get_chain_colors('polkadot'),
        };
        lineChart(chart, config, data);
        chartInstances.current[chartIndex] = chart;
      }
    } catch (error) {
      console.error(`Error fetching and initializing chart for ${chartTitle}:`, error);
    }
  };

  useEffect(() => {
    if (chartsVisible) {
      const baseUrl = 'data/ecosystem_general_chain_activity_tracker000000000000.jsonl';
      chartRefs.forEach((ref, index) => {
        fetchDataAndInitChart(
          baseUrl,
          index,
          chartTitles[index],
          chartTitles[index] === 'Number of New Accounts' ? 'number_of_new_accounts' :
            chartTitles[index] === 'Number of Blocks' ? 'num_blocks' :
              chartTitles[index] === 'Number of Events' ? 'num_events' :
                chartTitles[index] === 'Number of Transfers' ? 'number_of_transfers' :
                  chartTitles[index] === 'Number of Extrinsics' ? 'num_extrinsics' : 'default_key' // default_key as a fallback, adjust if necessary
        );
      });
    }
  }, [chartsVisible]);

  const [infoVisibility, setInfoVisibility] = useState(Array(chartRefs.length).fill(false));
  
  const handleMouseEnter = index => {
    setInfoVisibility({ ...infoVisibility, [index]: true });
  };

  const handleMouseLeave = index => {
    setInfoVisibility({ ...infoVisibility, [index]: false });
  };

  const items = chartRefs.map((ref, index) => (
    <div key={`chart-${index}`} style={{ width: '100%', maxWidth: '100%', padding: '20px', border: '2px solid #e0e0e0', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'left' }}>
        <h2 style={{ margin: 0, marginRight: '10px' }}>{chartTitles[index]}</h2>
        <InfoCubeIconComponent
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        />
      </div>
      {infoVisibility[index] && (
        <div style={{
          position: 'absolute',
          transform: 'translate(-50%, -30%)',
          zIndex: 2,
          fontSize: '14px',
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '50%',
          textAlign: 'full',
          boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
        }}>
          {chartContents[index]}
        </div>
      )}
      <div ref={ref} style={{ height: '350px', width: '100%', maxWidth: '100%' }}></div>
    </div>
  ));

  return (
  <div className="website-grid-container">
    <PolkadotJSGeneralComponent onLoaded={() => setChartsVisible(true)} />
      {chartsVisible && items}
    </div>
  );
};

export default PolkadotStatsGeneral;
