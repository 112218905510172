import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import InfoCubeIconComponent from './components/InfoCubeIconComponent';
import { lineChart, get_chain_colors } from './js/charts_ecosystem.js';
import { chartDescriptions } from './js/echart_utils.js'

const PolkadotStatsStablecoins = () => {
  const usdtAssetHubRef = useRef(null);
  const usdtParachainsRef = useRef(null);
  const usdcAssetHubRef = useRef(null);
  const usdcParachainsRef = useRef(null);
  const [hovered, setHovered] = useState({});
  const chartRefs = [usdtAssetHubRef, usdtParachainsRef, usdcAssetHubRef, usdcParachainsRef];


  const chartTitles = [
    'USDT in Polkadot Asset Hub',
    'USDT in Parachains',
    'USDC in Polkadot Asset Hub',
    'USDC in Parachains'
  ];

  const chartContents = [
    chartDescriptions.USDTAssetHub,
    chartDescriptions.USDTParachains,
    chartDescriptions.USDCAssethub,
    chartDescriptions.USDCParachains,
  ];

  const resizeCharts = useCallback(() => {
    chartRefs.forEach(ref => {
      if (ref.current) {
        const chart = echarts.getInstanceByDom(ref.current);
        if (chart) chart.resize();
      }
    });
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeCharts);
    chartRefs.forEach(ref => {
      if (ref.current) {
        ref.current.chart = echarts.init(ref.current);
        resizeObserver.observe(ref.current);
      }
    });

    window.addEventListener('resize', resizeCharts);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', resizeCharts);
      chartRefs.forEach(ref => {
        if (ref.current?.chart) {
          ref.current.chart.dispose();
        }
      });
    };
  }, [resizeCharts]);

  const fetchDataAndInitChart = async (url, chartRef, chartTitle, tokenType) => {
    try {
      const response = await fetch(url);
      const textData = await response.text();
      const data = textData.split('\n')
        .filter(Boolean)
        .map(JSON.parse)
        .map(item => ({
          ...item,
          x: item.day,
          y: item[`sum_of_${tokenType}`],
          chain: item.chain,
          relay_chain: 'polkadot'
        }))
        .reverse();

      if (chartRef.current) {
        const chart = chartRef.current.chart || echarts.init(chartRef.current);
        const config = {
          xName: 'day',
          yName: `sum_of_${tokenType}`,
          title: chartTitle,
          type: 'line',
        };
        lineChart(chart, config, data);
        chartRef.current.chart = chart;
      }
    } catch (error) {
      console.error(`Error fetching and initializing chart for ${chartTitle}:`, error);
    }
  };

  useEffect(() => {
    fetchDataAndInitChart('data/ecosystem_stablecoins_usdt000000000000.jsonl', usdtAssetHubRef, 'USDT in Polkadot Asset Hub', 'usdt');
    fetchDataAndInitChart('data/ecosystem_stablecoins_usdt_parachains000000000000.jsonl', usdtParachainsRef, 'USDT in Parachains', 'usdt');
    fetchDataAndInitChart('data/ecosystem_stablecoins_usdc000000000000.jsonl', usdcAssetHubRef, 'USDC in Polkadot Asset Hub', 'usdc');
    fetchDataAndInitChart('data/ecosystem_stablecoins_usdc_parachains000000000000.jsonl', usdcParachainsRef, 'USDC in Parachains', 'usdc');
  }, []);

  return (
    <div className="website-grid-container">
      {chartTitles.map((title, index) => (
        <div key={index} style={{ width: '95%', maxWidth: '95%', padding: '20px', border: '2px solid #e0e0e0', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', boxSizing: 'border-box', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0, marginRight: '10px' }}>{title}</h2>
            <InfoCubeIconComponent
              onMouseEnter={() => setHovered({ ...hovered, [title]: true })}
              onMouseLeave={() => setHovered({ ...hovered, [title]: false })}
            />
          </div>
          {hovered[title] && (
            <div style={{
              position: 'absolute',
              transform: 'translate(-50%, -30%)',
              zIndex: 2,
              fontSize: '14px',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.75)',
              padding: '20px',
              borderRadius: '10px',
              maxWidth: '50%',
              textAlign: 'full',
              boxShadow: '0 2px 4px rgba(0,0,0,0.4)'
            }}>
              {chartContents[index]}
            </div>
          )}
          <div ref={chartRefs[index]} style={{ height: '350px', width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
        </div>
      ))}
    </div>
  );
};

export default PolkadotStatsStablecoins;