import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { HomeOutlined, DotChartOutlined, RocketOutlined, BarChartOutlined, PictureOutlined, HeartOutlined, UserOutlined, TeamOutlined, ExperimentOutlined, AreaChartOutlined, BankOutlined, DatabaseOutlined, FileTextOutlined, FireOutlined, LineOutlined, MoreOutlined, DollarOutlined } from '@ant-design/icons';
import PolkadotIcon from './components/PolkadotIconComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/main.css';
import logo_light from './assets/polkadot_logo_light.png';
import logo_dark from './assets/polkadot_logo_dark.png';
import logo_small from './assets/Polkadot_Token_PolkadotToken_Pink.png';
import HeaderWalletComponent from './components/HeaderWalletComponent'
import PolkadotStatsStaking from './PolkadotStatsStaking';
import PolkadotStatsTreasury from './PolkadotStatsTreasury';
import PolkadotStatsStablecoins from './PolkadotStatsStablecoins';
import PolkadotStatsGeneral from './PolkadotStatsGeneral';
import PolkadotStatsEcosystem from './PolkadotStatsEcosystem';
import PolkadotStatsEcosystemRace from './PolkadotStatsEcosystemRace';
import PolkadotMetadata from './PolkadotMetadata'
import PolkadotInk from './PolkadotInk';
import PolkadotBlockWeight from './PolkadotBlockWeight';
import PolkadotMonthlyReport from './PolkadotMonthlyReportV2';
import PolkadotResearch from './PolkadotResearch';
import PolkadotDatasets from './PolkadotDatasets';
import PolkadotPartners from './PolkadotPartners';
import PolkadotTeam from './PolkadotTeam';
import PolkadotAccountExplorer from './PolkadotAccountExplorer';
import PolkadotJSLiveView from './PolkadotJSLiveView';
import DashboardAppBetaV1 from './DashboardAppBetaV1';
import ClickHouseTest from './ClickHouseTest';
import MintNft from './MintNft';
import ParityDataDashboards from './PolkadotWebsiteHome';
import DotInvaders from './DotInvaders';
import APITutorial from './APITutorial';
import PolkadotOverview from './PolkadotOverview'

import CsvSqlPage from './SQLInterface';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  ////wallet function
  // const [account, setAccount] = useState('');
  // const [provider, setProvider] = useState(null);
  // const [contract, setContract] = useState(null);

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveMenu(path ? path : 'home');
  }, [location]);

  const [activeMenu, setActiveMenu] = useState('home');

  const themeStyles = {
    layoutBackground: darkMode ? '#001529' : '#f0f2f5',
    headerBackground: darkMode ? '#001529' : '#f0f2f5',
    headerTextColor: darkMode ? 'white' : '#001529',
    siderBackground: darkMode ? '#001529' : '#fff',
  };

  const toggleCollapsed = () => setCollapsed(!collapsed);
  ////theme function
  // const toggleTheme = () => setDarkMode(!darkMode);

  const handleMenuSelect = ({ key }) => {
    setActiveMenu(key);
    navigate(`/${key}`);
    // Optionally expand the sidebar when navigating away from DotInvaders
    if (key === 'dotinvaders') {
      setCollapsed(true);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: themeStyles.layoutBackground }}>
      <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed} style={{ background: themeStyles.siderBackground }}>
        <div className="logo">
          <img
            src={collapsed ? logo_small : darkMode ? logo_light : logo_dark}
            alt="Logo"
            width={400}
            style={{ width: collapsed ? '50%' : '70%', margin: '16px auto', display: 'flex' }}
          />
        </div>
        <Menu
          theme={darkMode ? 'dark' : 'light'}
          defaultSelectedKeys={[activeMenu]}
          mode="inline"
          onSelect={handleMenuSelect}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>Home</Menu.Item>
          <Menu.Item key="polkadot-overview" icon={<PolkadotIcon />}>{!collapsed && "Polkadot"}</Menu.Item>
          <SubMenu key="polkadot" icon={<MoreOutlined />} title="App Metrics">
            <Menu.Item key="staking" icon={<BarChartOutlined />}>Staking</Menu.Item>
            <Menu.Item key="stable-coins" icon={<DollarOutlined />}>Stable Coins</Menu.Item>
            <Menu.Item key="treasury" icon={<BankOutlined />}>Treasury</Menu.Item>
          </SubMenu>
          <SubMenu key="ecosystem" icon={<MoreOutlined />} title="Chain Metrics">
          <Menu.Item key="chain-stats" icon={<BarChartOutlined />}>Relay Chain</Menu.Item>
            <Menu.Item key="parachain-stats" icon={<BarChartOutlined />}>Parachains</Menu.Item>
            <Menu.Item key="race" icon={<BarChartOutlined />}>Metric Race</Menu.Item>
            <Menu.Item key="ink" icon={<BarChartOutlined />}>ink!</Menu.Item>
            <Menu.Item key="block-weights" icon={<BarChartOutlined />}>Block Weights</Menu.Item>
            <Menu.Item key="metadata" icon={<BarChartOutlined />}>Metadata</Menu.Item>
          </SubMenu>
          <SubMenu key="research" icon={<MoreOutlined />} title="Research">
            <Menu.Item key="monthly-report" icon={<FireOutlined />}>Monthly Report</Menu.Item>
            <Menu.Item key="research" icon={<ExperimentOutlined />}>Research</Menu.Item>
          </SubMenu>
          <SubMenu key="resources" icon={<MoreOutlined />} title="Resources">
            <Menu.Item key="api-tutorial" icon={<FireOutlined />}>API Tutorial</Menu.Item>
            {/* <Menu.Item key="research" icon={<ExperimentOutlined />}>Research</Menu.Item> */}
          </SubMenu>
          <SubMenu key="about-us" icon={<MoreOutlined />} title="About Us">
            <Menu.Item key="datasets" icon={<DatabaseOutlined />}>Datasets</Menu.Item>
            <Menu.Item key="partners" icon={<HeartOutlined />}>Partners</Menu.Item>
            <Menu.Item key="team" icon={<TeamOutlined />}>Team</Menu.Item>
          </SubMenu>
          <SubMenu key="v2" icon={<MoreOutlined />} title="V2">
            <Menu.Item key="account-explorer" icon={<UserOutlined />}>Account Explorer</Menu.Item>
            {/* <Menu.Item key="polkadot-explorer" icon={<ExperimentOutlined />}>Polkadot Explorer</Menu.Item> */}
            <Menu.Item key="polkadotdashbeta" icon={<ExperimentOutlined />}>Beta Dash</Menu.Item>
            {/* <Menu.Item key="clickhousetest" icon={<ExperimentOutlined />}>ClickHouseTest</Menu.Item> */}
            <Menu.Item key="nft" icon={<PictureOutlined />}>NFT</Menu.Item>
            <Menu.Item key="dotinvaders" icon={<PictureOutlined />}>Dot Invaders</Menu.Item>
            <Menu.Item key="csv-sql-page" icon={<PictureOutlined />}>SQL</Menu.Item>

          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
      {/* <HeaderWalletComponent 
        setAccount={setAccount} 
        setProvider={setProvider} 
        setContract={setContract} 
        account={account}
      /> */}
        <Content style={{ margin: '0 16px' }}>
          {activeMenu === 'home' && <ParityDataDashboards />}
          {activeMenu === 'chain-stats' && <PolkadotStatsGeneral />}
          {activeMenu === 'staking' && <PolkadotStatsStaking />}
          {activeMenu === 'treasury' && <PolkadotStatsTreasury />}
          {activeMenu === 'stable-coins' && <PolkadotStatsStablecoins />}
          {activeMenu === 'parachain-stats' && <PolkadotStatsEcosystem />}
          {activeMenu === 'race' && <PolkadotStatsEcosystemRace />}
          {activeMenu === 'ink' && <PolkadotInk />}
          {activeMenu === 'block-weights' && <PolkadotBlockWeight />}
          {activeMenu === 'monthly-report' && <PolkadotMonthlyReport />}
          {activeMenu === 'research' && <PolkadotResearch />}
          {activeMenu === 'datasets' && <PolkadotDatasets />}
          {activeMenu === 'partners' && <PolkadotPartners />}
          {activeMenu === 'team' && <PolkadotTeam />}
          {activeMenu === 'account-explorer' && <PolkadotAccountExplorer />}
          {activeMenu === 'polkadot-explorer' && <PolkadotJSLiveView />}
          {activeMenu === 'polkadotdashbeta' && <DashboardAppBetaV1 />}
          {activeMenu === 'clickhousetest' && <ClickHouseTest />}
          {activeMenu === 'nft' && <MintNft />}
          {activeMenu === 'metadata' && <PolkadotMetadata />}
          {activeMenu === 'dotinvaders' && <DotInvaders />}

          {activeMenu === 'api-tutorial' && <APITutorial />}
          {activeMenu === 'csv-sql-page' && <CsvSqlPage />}
          {activeMenu === 'polkadot-overview' && <PolkadotOverview />}


          
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

//write code for token gated with wallet connect